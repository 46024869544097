<template>
  <div id="itp-comp" v-bind="$props">
    <div class="py-1 px-3" v-for="(cat, catIndex) of categories" :key="cat">
      <h4 class="wrapper-title">{{ cat }}</h4>
      <v-row class="ma-0 pa-0" no-gutters>
        <template v-for="(itps, index) of data?.itps">
          <v-col cols="12" sm="6" v-if="itps.categories.includes(cat)" :key="itps.itpID" style="cursor: pointer">
            <div
              @click="handleOnClick(index, catIndex, itps)"
              :class="`d-flex pa-1 ${activeIndex == index && activeCatIndex == catIndex ? 'active-wrapper' : ''}`"
            >
              <v-avatar
                size="50"
                rounded
                color="primary"
                class="v-avatar-light-bg primary--text mt-1"
                :style="`background-color:${itps.itpIconColor} !important`"
              >
                <v-avatar>
                  <img style="height: unset" :src="itps.itpIcon" />
                </v-avatar>
              </v-avatar>

              <div class="ms-3">
                <p class="title font-weight-semibold text--primary mb-0">{{ itps.itpName }}</p>
                <span class="text-xs">{{ itps.helpType }}</span>
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppITPDataLight',
  props: ['userId', 'ITPs'],
  data() {
    return {
      name: '',
      itpListLoading: false,
      modal: false,
      el: -1,
      data: null,
      date: new Date().toISOString().substr(0, 10),
      categories: ['General', 'Anxiety'],
      activeIndex: 0,
      activeCatIndex: 0,
    };
  },
  async mounted() {
    this.data = this.ITPs;
  },
  methods: {
    handleOnClick(index, catIndex, itps) {
      this.activeIndex = index;
      this.activeCatIndex = catIndex;
      this.$emit('handleClick', itps.itpID);
    },
  },
};
</script>

<style lang="scss">
#itp-comp {
  .title {
    font-size: 14px !important;
    line-height: unset !important;
    color: white !important;
  }

  .text-xs {
    font-size: 12px !important;
    line-height: unset !important;
    color: rgb(242, 242, 242);
  }

  .active-wrapper {
    border-radius: 0.5rem;
    border: 1px solid white;
    box-shadow: 0px 1px 3px 0px #24978d;
  }
}
</style>
