
import {  ref, set, get, onValue, orderByChild, query, equalTo } from "firebase/database"; //Import the Firebase Realtime Database library
import { doc, getDoc, setDoc } from "firebase/firestore"; //Import the Firebase Firestore library
import { EventBus } from '../components/eventBus.js'; //Import the event bus to allow for communication between components

//Import the UUID library
import { v4 as uuidv4 } from "uuid";

export class communicationHandler {
  constructor(Preferences,db,rt) {
    this.EventBus = EventBus;
    this.dockID = null;
    this.isConnected = false;
    this.rt = rt;
    this.db = db;
    this.Preferences = Preferences;
    this.allowPing = true;
    this.webDockResults = [];
  }


  async setListeningStatus(isListening){
    this.tempSpeech.isListening = isListening;
      if(this.isConnected){
        const speechRef = rt.ref(
          "MARCo/v2/deviceSyncs/" + this.dockID + "/fromDevice/transcription/isListening"
        );
        speechRef.set(isListening);
      }

      if(this.tempSpeech.isListening){
        //Pause MARCo from talking
        try{
          this.audioHandler.pauseMARCoTalk();
        }
        catch(err){
          console.log(err);
        }
      }
      else{
        if(this.tempSpeech.speech.length < 1){
          //Resume MARCo talking if there was no input
          this.audioHandler.resumeMARCoTalk();
        }
      }
  }



  /**async holdUntilWifi() {
    return new Promise((resolve, reject) => {
      let wifiInterval = setInterval(() => {
        if (this.wifiConnected) {
          clearInterval(wifiInterval);
          resolve(true);
        } else {
          console.log("Waiting for wifi to connect");
          require("dns").resolve("www.google.com", function (err) {
            if (err) {
              console.log("No connection");
              this.wifiConnected = false;
            } else {
              console.log("Connected");
              this.wifiConnected = true;
              clearInterval(wifiInterval);
              resolve(true);
            }
          });
        }
      }, 1000);
    });
  }*/

  async initializeCommlink() {

    

    //eslint-disable-next-line no-async-promise-executor
    const checkForDockID = new Promise(async (resolve, reject) => {
      //Step 1: Check if there is a device ID
      if (this.dockID) {
        //If the dockID already exists, then we can just connect to the realtime database and start getting data
        resolve(true);
      } else {
        //If the dockID does not exist, then we need to get the dockID from preferences
        let dockID = await this.Preferences.get({key: "dockID"});
        //eslint-disable-next-line no-constant-condition
        if(dockID.value){
          this.dockID = dockID.value;
          resolve(true);
        }
        else{
          //console.log("Device has not been previously connected or could not be found.")
          //Generate a UUID for the device
          this.dockID = uuidv4();
          //Save the UUID to preferences
          await this.Preferences.set({key: "dockID", value: this.dockID});

          //Copy the template realtime database to a new realtime database with the new UUID
          const copyTemplate = new Promise((resolve, reject) => {
            let copyTemplateRef = ref(this.rt,
              "MARCo/v2/deviceSyncs/TEMPLATE"
            );
            let newDockRef = ref(this.rt,
              "MARCo/v2/deviceSyncs/" + this.dockID
            );
            get(copyTemplateRef).then(async (snapshot) => {
              console.log("Got the template ref");
              if (snapshot.exists()) {
                console.log("Template exists");
                const template = snapshot.val();
                console.log("The template is", snapshot.val());
                await set(newDockRef, template);
                resolve(true);
              } else {
                console.log("No data available");
              }
            }).catch((error) => {
              console.error(error);
            });
           
          });

          copyTemplate.then(async (result) => {
            console.log("Copied the template to the new dock");
            resolve(result);
          });
        }
      }
    });

    checkForDockID.then(async (result) => {
      console.log("Dock ID is: " + this.dockID);

      //Enable the dock
      const ref3 = ref(this.rt,
        "MARCo/v2/deviceSyncs/" + this.dockID + "/handshake/dockEnabled"
      );
      set(ref3, true);
      //Start listening for changes in the dock's state
      const botStateRef = ref(this.rt,
        `MARCo/v2/deviceSyncs/${this.dockID}/handshake/robotEnabled`
      );

      //Continuously listen to the dock state ref for changes in the event it disconnects.
      onValue(botStateRef, (snapshot) => {
        console.log("Received a robot state update from the realtime database");
        let response = snapshot.val();
        this.updateConnectionStatus(response);
      });

      //Start listening for the realtime database connection for API calls
      const jsonRef = ref(this.rt,
        "MARCo/v2/deviceSyncs/" + this.dockID + "/fromDevice/jsonResponse"
      );
      onValue(jsonRef, (snapshot) => {
        console.log("Received a response from the realtime database");
        let response = snapshot.val();  
        //Only if there actually is an object, send it to the paramHandler
        if(typeof(response) == "object" && response != null){
        if(Object.keys(response).length > 0){
          this.EventBus.$emit("paramHandler", response);
        }
      }
      });

      //Start listening to the realtime database for SFX updates
      const ref2 = ref(this.rt,
        "MARCo/v2/deviceSyncs/" + this.dockID + "/fromDevice/transcription"
      );
      onValue( ref2 , (snapshot) => {
        console.log("Received a transcription update from the realtime database");
        let transcript = snapshot.val();
        this.EventBus.$emit("transcript",transcript);
      });

      //Listen for pings from the realtime database to ensure live connection
      const pingRef = ref(this.rt,
        "MARCo/v2/deviceSyncs/" + this.dockID + "/handshake/pingDock/toDock"
      );
      onValue(pingRef, (snapshot) => {
        console.log("Received a ping from the realtime database");
        let response = snapshot.val();
        if (response) {
          const responseRef = ref(this.rt,
            "MARCo/v2/deviceSyncs/" + this.dockID + "/handshake/pingDock/fromDock"
          );
          set(responseRef, response);
        }
      });

      //Listen for updates from the dock for hardware events
      const hardwareRef = ref(this.rt,
        "MARCo/v2/deviceSyncs/" + this.dockID + "/fromDevice/hardwareEvents"
      );
      onValue(hardwareRef, (snapshot) => {
        console.log("Received a hardware event from the realtime database");
        let response = snapshot.val();
        //this.hardwareHandler.mapEvent(response);
      });

      this.EventBus.$on("updateRealtimeResponseSync", (params) => {
        this.updateRealtimeResponse(params);
      });

      this.EventBus.$on("pingBot", () => {
        this.pingBot();
      });

      this.EventBus.$on("onboardTalk", (params) => {
        //Update the ref for the response text
        const responseRef = ref(this.rt,
          "MARCo/v2/deviceSyncs/" + this.dockID + "/fromDock/jsonResponse/responseText"
        );
        set(responseRef, []);
        set(responseRef, params);
      });

      
      this.EventBus.$on("updateDeviceVolume", (volume) => {
        const volumeRef = ref(this.rt,
          `MARCo/v2/deviceSyncs/${this.dockID}/fromDock/deviceVolume`
        );
        set(volumeRef, parseInt(volume));
    })

      this.EventBus.$on("initializedStatus", (status)=>{
        const initializedRef = ref(this.rt,
          `MARCo/v2/deviceSyncs/${this.dockID}/handshake/initialized`
        );
        set(initializedRef, status);

      });
      this.EventBus.$on("checkConnectionStatus", () => {
        const botEnabledRef = ref(this.rt,
          `MARCo/v2/deviceSyncs/${this.dockID}/handshake/robotEnabled`);
        get(botEnabledRef).then((snapshot) => {
          if (snapshot.exists()) {
            this.isConnected = snapshot.val();
          this.updateConnectionStatus(this.isConnected);
          }
      });
      });
      this.EventBus.$on("audioHandler", (params) => {
        this.updateAudioResponse(params);
      });

      this.EventBus.$on("updateUser", (params) => {
        const userRef = ref(this.rt,
          "MARCo/v2/deviceSyncs/" + this.dockID + "/handshake/currUsrID"
        );
        set(userRef, params);
      });

      this.EventBus.$on("scanForUserID", (userID) => {
        this.queryFirebaseRealtimeDatabase(userID).then((results) => {
          this.EventBus.$emit("userScanResults", results);
        });
      });

      this.EventBus.$on("checkMultipleDocks", () => {
        this.checkForLiveDock();
      });

    });

    
  }

  async updateRealtimeResponse(params) {
    const jsonRef = ref(this.rt,
      "MARCo/v2/deviceSyncs/" + this.dockID + "/fromDock/jsonResponse"
    );
    set(jsonRef, params);

    const ref2 = ref(this.rt,
      "MARCo/v2/deviceSyncs/" + this.dockID + "/handshake/dockEnabled"
    );
    set(ref2, true);

    this.pingBot();
  }

  async updateAudioResponse(params) {
    console.log("Updating the audio ref with the following params: ", params);
    const audioRef = ref(this.rt,
      "MARCo/v2/deviceSyncs/" + this.dockID + "/fromDock/sfxHandler"
    );
    set(audioRef, params);
  }

  async pingBot(notifyPings=3,maxPings=8) {
    //eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
    console.log("Ping?")
    if(this.allowPing){
      console.log("Ping!")
      this.allowPing = false;
   //Create a random integer between 1 and 1000 to check if the dock is alive
    let randomInt = Math.floor(Math.random() * 1000) + 1;
    set(ref(this.rt, `MARCo/v2/deviceSyncs/${this.dockID}/handshake/pingBot/toBot`), randomInt);

    let pingCheck = 0;
    //Set an interval to check if the dock has responded
    let pingInterval = setInterval(async () => {
      pingCheck++;
      const ref2 = ref( this.rt,
        `MARCo/v2/deviceSyncs/${this.dockID}/handshake/pingBot/fromBot`
      );
      let response = await get(ref2);
      console.log(`Sent ping of ${randomInt} to dock, received: `, response.val());
      if (parseInt(response.val()) == randomInt) {
        this.EventBus.$emit("pendingConnectionStatus", false);
        clearInterval(pingInterval);
        this.isConnected = true;
        setTimeout(() => {
          this.allowPing = true;
        }, 20000);
        resolve(true);
      } else {
        if (pingCheck > maxPings) {
          this.updateConnectionStatus(false);
          clearInterval(pingInterval);
          this.allowPing = true;
          resolve(false);
        }
        else if(pingCheck > notifyPings){
          this.EventBus.$emit("pendingConnectionStatus", true);
        }
      }
    }, 1500);
  }
});
  }
  updateConnectionStatus(isConnected) {
    this.isConnected = isConnected;
    this.EventBus.$emit("pendingConnectionStatus", false);
    this.EventBus.$emit("connectionStatus", isConnected);
    const botEnabledRef = ref(this.rt,
      `MARCo/v2/deviceSyncs/${this.dockID}/handshake/robotEnabled`);
    set(botEnabledRef, isConnected);
  }

  compareDockIDs(dockID) {
    if (dockID == this.dockID) {
      return true;
    } else {
      return false;
    }
  }
async queryFirebaseRealtimeDatabase(userID) {
  const deviceSyncsRef = ref(this.rt, "/MARCo/v2/deviceSyncs");
  const check = query(deviceSyncsRef, orderByChild("handshake/currUsrID"), equalTo(userID));
  const snapshot = await get(check);
  const results = [];
  if (snapshot.exists()) {
    snapshot.forEach((childSnapshot) => {
      const key = childSnapshot.key;
      results.push(key);
    });
 

  //If there is only one result, then we need to set the dockID to that result and update the preferences
  if(results.length == 1){
    this.dockID = results[0];
    await this.Preferences.set({key: "dockID", value: this.dockID});
    EventBus.$emit("userScanResultSingle", true);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }
  else{
    EventBus.$emit("userScanResultMultiple", results);
    this.webDockResults = results;
  
}
}
else{
  console.log("No results found");
  EventBus.$emit("userScanResultEmpty", false);
}
}

async checkForLiveDock(){
  for (let i = 0; i < this.webDockResults.length; i++) {
    this.dockID = this.webDockResults[i];
    let nextDock = await this.pingBot(10,4);
    if (nextDock) {
      
      await this.Preferences.set({key: "dockID", value: this.dockID});
      EventBus.$emit("userScanResultSingle", true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      break;
    }
    else{
      console.log("Dock is not responding, moving on to the next dock");

    }
  }
  EventBus.$emit("userScanResultEmpty", true);
}

}
