<template>
  <div>
    <v-row class="justify-center">
      <v-img :src="img" :max-width="imgSize" :max-height="imgSize"></v-img>
    </v-row>
    <v-row class="justify-center center--text text-center my-2 mx-6">
      <slot>
        <p>{{ msg }}</p>
      </slot>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: '/assets/images/marcosorry.png',
    },
    imgSize: {
      type: Number,
      default: 128,
    },
    msg: {
      type: String,
      default: "Looks empty 'round these parts.",
    },
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style></style>
