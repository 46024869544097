<template>
  <div v-bind="$props">
    <v-btn-toggle group flat rounded :color="value == 'upvote' ? 'green' : 'error'" v-model="value">
      <v-btn icon @click="handleUpvoteWish" value="upvote" class="text--center">
        <v-icon>mdi-thumb-up-outline</v-icon>
      </v-btn>
      <v-btn icon @click="handleDownvoteWish" value="downvote">
        <v-icon>mdi-thumb-down-outline</v-icon>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  props: ['mValue'],
  data() {
    return {
      value: this.mValue,
    };
  },
  methods: {
    handleUpvoteWish() {
      this.value = 'upvote';
      this.$emit('upvote');
    },
    handleDownvoteWish() {
      this.value = 'downvote';
      this.$emit('downvote');
    },
  },
  watch: {
    mValue: function (newVal, oldVal) {
      this.value = newVal;
    },
  },
};
</script>

<style>
.ui-like-dislike-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.ui-like-dislike-button > * {
  background: white;
}
</style>
