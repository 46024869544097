<template>
  <div>
    <img
      src="/assets/content-moderation/images/image_7.png"
      style="margin-inline: auto; display: block; max-width: 150px"
      alt=""
      class="mb-8"
    />
    <p class="text-center text-h4" style="font-weight: bold">Some Content Is Restricted</p>
    <p class="text-caption text-center">Based on your age, MARCo will filter out potentially mature content.</p>
    <v-divider class="mb-2 mx-2"></v-divider>
    <p class="text-body-2 mx-2 text-center">MARCo IS filtering potentially mature content.</p>
    <v-btn block color="orange lighten-1" class="white--text" elevation="0" rounded> UPDATE BIRTHDAY </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Bellow18Comp',
};
</script>

<style></style>
