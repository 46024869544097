<template>
  <div>
    <v-window v-model="networkSetupStep" v-if="mode === 'deviceSetup'">
      <v-row align-content="center" justify="center" v-if="networkSetupStep > 0 && networkSetupStep < 3">
        <v-spacer></v-spacer>

        <div id="wifi-loader">
          <svg class="circle-outer" viewBox="0 0 86 86">
            <circle :class="`back ${networkSetupStep > 1 ? 'animated' : ''}`" cx="43" cy="43" r="40"></circle>
            <circle :class="`front ${networkSetupStep > 1 ? 'animated' : ''}`" cx="43" cy="43" r="40"></circle>
            <circle :class="`new ${networkSetupStep > 1 ? 'animated' : ''}`" cx="43" cy="43" r="40"></circle>
          </svg>
          <svg class="circle-middle" viewBox="0 0 60 60">
            <circle :class="`back ${networkSetupStep > 1 ? 'animated' : ''}`" cx="30" cy="30" r="27"></circle>
            <circle :class="`front ${networkSetupStep > 1 ? 'animated' : ''}`" cx="30" cy="30" r="27"></circle>
          </svg>
          <svg class="circle-inner" viewBox="0 0 34 34">
            <circle :class="`back ${networkSetupStep > 1 ? 'animated' : ''}`" cx="17" cy="17" r="14"></circle>
            <circle :class="`front ${networkSetupStep > 1 ? 'animated' : ''}`" cx="17" cy="17" r="14"></circle>
          </svg>
          <svg class="circle-center" viewBox="0 0 34 34">
            <circle :class="`back ${networkSetupStep > 1 ? 'animated' : ''}`" cx="17" cy="17" r="1"></circle>
            <circle :class="`front ${networkSetupStep > 1 ? 'animated' : ''}`" cx="17" cy="17" r="1"></circle>
          </svg>
        </div>

        <v-spacer></v-spacer>
      </v-row>
      <v-window-item :value="0">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-wifi</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h3>
              Choose a network
              <span v-if="mode !== 'deviceSetup'">- OR - </span>
            </h3>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn outlined rounded @click="altWifiMethod" v-if="mode !== 'deviceSetup'">TRY A DIFFERENT WAY</v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <m-network-list :networks="networks" :color="color" @selectNetwork="selectNetwork"></m-network-list>
      </v-window-item>

      <v-window-item :value="1">
        <v-alert border="left" dismissible type="error" v-if="networkConnectFailure">
          <h4>Could not connect to network. Please try again.</h4>
          <v-row align="center" no-gutters>
            <v-col class="grow">
              <v-btn color="white" outlined @click="altWifiMethod" v-if="mode !== 'deviceSetup'">
                TRY A DIFFERENT WAY
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <m-network-details-form
          :network="currNetwork"
          :color="color"
          @connectToNetwork="connectToNetwork"
          @changeNetworkSetupStep="networkSetupStep += event"
        ></m-network-details-form>
      </v-window-item>
      <v-window-item :value="2">
        <v-row align-content="center" justify-content="center">
          <v-spacer></v-spacer>
          <h4 class="text--center">{{ networkStepLabel }}</h4>
          <v-spacer></v-spacer>
        </v-row>
        <v-card-actions>
          <v-btn @click="networkSetupStep--" outlined rounded>CANCEL</v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="altWifiMethod" v-if="mode !== 'deviceSetup'"> TRY A DIFFERENT WAY </v-btn>
        </v-card-actions>
      </v-window-item>
      <v-window-item :value="3">
        <lottie-animation path="assets/images/lotties/lightgreen-check.json" :loop="false" :width="128" :height="128" />
        <v-row align-content="center" justify-content="center">
          <v-spacer></v-spacer>
          <h2 class="text--center">Connected!</h2>
          <v-spacer></v-spacer>
        </v-row>
        <v-row align-content="center" justify-content="center">
          <v-spacer></v-spacer>
          <p class="text--center">You are now connected to the network.</p>
          <v-spacer></v-spacer>
        </v-row>
      </v-window-item>
    </v-window>
    <div v-else>
      <v-card-text>
        <v-row align-content="center" justify-content="center">
          <v-spacer></v-spacer>
          <h2 class="text--center">Opening wifi settings</h2>
          <v-spacer></v-spacer>
        </v-row>
        <v-row align-content="center" justify-content="center">
          <v-spacer></v-spacer>
          <p class="text--center">
            A new window will open with WiFi settings shortly. Once you enter wifi details, tap the home button to come
            back to this screen.
            <br />
            If you are not redirected after 10 seconds, please click the button below.
          </p>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined color="white" @click="openWifiSettings" rounded block> OPEN SETTINGS </v-btn>
      </v-card-actions>
      <v-row class="pa-3 justify-center align-center mt-5">
        <p>Not working?</p>
        <v-btn outlined x-small rounded @click="altWifiMethod"> Troubleshoot </v-btn>
      </v-row>
    </div>
    <v-dialog v-model="altSetupVid">
      <div class="video-container">
        <video autoplay="true" loop="true" v-if="altSetupVid" id="wifiSetupVid">
          <source src="assets/video/troubleshooting/wifiAlt-1.mp4" type="video/mp4" />
        </video>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import NetworkList from './networkList.vue';
import NetworkDetailsForm from './networkDetailsForm.vue';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'; // import lottie-vuejs
import { EventBus } from '../../eventBus.js';
import { Network } from '@capacitor/network';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

export default {
  name: 'm-network-setup-window',
  components: {
    'm-network-list': NetworkList,
    'm-network-details-form': NetworkDetailsForm,
    LottieAnimation,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    mode: {
      type: String,
      default: 'dockSetup',
    },
  },
  data() {
    return {
      networks: [],
      networkSetupStep: 0,
      currNetwork: {},
      networkConnectFailure: false,
      networkAttempts: 0,
      networkStepLabel: 'Checking the network...',
      altSetupVid: false,
      checkNetworkInterval: null,
      rescanInterval: null,
    };
  },
  methods: {
    openWifiSettings() {
      try {
        NativeSettings.open({
          optionAndroid: AndroidSettings.Wifi,
          optionIOS: IOSSettings.App,
        });
      } catch (err) {
        console.log(err);
      }
    },
    selectNetwork(network) {
      this.currNetwork = network;
      this.networkSetupStep++;
      console.log('emitting onboardingStep');
      EventBus.$emit('onboardingStep', 25);
    },
    networkLoaded() {
      this.networkStepLabel = 'Verifying internet connection...';
      this.networkAttempts = 0;
      let networkCheckInterval = setInterval(() => {
        this.checkNetwork((status) => {
          if (status) {
            this.networkSetupStep++;
            EventBus.$emit('onboardingStep', 75);
            setTimeout(() => {
              this.$emit('networkLoaded', this.currNetwork);
            }, 3000);
            clearInterval(networkCheckInterval);
          } else {
            this.networkAttempts++;
            if (this.networkAttempts > 10) {
              this.networkSetupStep--;
              this.networkConnectFailure = true;
              clearInterval(networkCheckInterval);
            }
          }
        });
      }, 3000);
    },
    checkNetwork: async function (callback = false) {
      this.networkStatus = (await Network.getStatus()).connected;
      if (callback) {
        return callback(this.networkStatus);
      }
    },
    connectToNetwork(network, pwd) {
      this.currNetwork = {
        ssid: network.SSID,
        password: pwd,
      };

      this.networkSetupStep++;
      console.log('Connecting to network', network);
      console.log('Using a password of', pwd);

      //Check what mode this is - if it is dockSetup, firstTime, or deviceSetup, then connect the dock to WiFi
      if (this.mode !== 'deviceSetup') {
        if (typeof WifiWizard2 == 'object') {
          setTimeout(() => {
            this.networkStepLabel = 'Having trouble connecting? Try a different way to connect.';
          }, 5000);

          WifiWizard2.suggestConnection(network.SSID, pwd, 'WPA', false)
            .then((res) => {
              console.log('Connected to network', res);
              this.networkLoaded();
            })
            .catch((err) => {
              console.log('Error connecting to network', err);
              this.networkSetupStep--;
              this.networkConnectFailure = true;
            });
        }
      } else {
        //It is setting up the device, so send the network credentials
        this.$sendDeviceNetwork(network.SSID, pwd);
        // this.networkSetupStep++;
      }
    },
    altWifiMethod() {
      this.altSetupVid = true;
      document.getElementById('wifiSetupVid').play();
      console.log('Running the alternate wifi method');

      try {
        clearInterval(this.checkNetworkInterval);
      } catch (err) {
        console.log('Error clearing interval', err);
      }

      this.checkNetworkInterval = setInterval(() => {
        this.checkNetwork((status) => {
          if (status) {
            this.networkSetupStep++;
            EventBus.$emit('onboardingStep', 75);
            setTimeout(() => {
              this.$emit('networkLoaded', this.currNetwork);
              document.getElementById('wifiSetupVid').pause();
            }, 250);
            clearInterval(this.checkNetworkInterval);
            this.altSetupVid = false;
          } else {
            this.networkAttempts++;
            if (this.networkAttempts > 1000) {
              this.networkSetupStep--;
              this.networkConnectFailure = true;
              this.altSetupVid = false;
              clearInterval(this.checkNetworkInterval);
            }
          }
        });
      }, 250);
    },
  },
  mounted: async function () {
    //Watch the network status to see if it goes up or down
    Network.addListener('networkStatusChange', (status) => {
      console.log('The network status changed 1124124', status);
      if (status.connected) {
        console.log('The network is up');
        this.$emit('networkLoaded');
      } else {
        console.log('The network is down');
      }
    });
    if (typeof WifiWizard2 == 'object') {
      try {
        // let currSSID = await WifiWizard2.getConnectedSSID();
        //   console.log("The current ssID", currSSID);

        //Check if it's connected to wifi first if this is the dockSetup setup
        console.log('THE WIFI MODE IS:', this.mode);
        if (this.mode === 'dockSetup' || this.mode === 'firstTime') {
          //this.altWifiMethod();
          this.networkStatus = (await Network.getStatus()).connected;
          console.log('The network status', this.networkStatus);

          if (!this.networkStatus) {
            try {
              this.networks = await WifiWizard2.scan();
            } catch (err) {
              console.log(err);
            }
          } else {
            this.networkSetupStep = 3;
            EventBus.$emit('onboardingStep', 75);
            setTimeout(() => {
              this.$emit('networkLoaded');
            }, 3000);
          }
        } else {
          try {
            this.networks = await WifiWizard2.scan();
          } catch (err) {
            console.log(err);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    this.rescanInterval = setInterval(async () => {
      if (typeof WifiWizard2 == 'object') {
        try {
          this.networks = await WifiWizard2.scan();
        } catch (err) {
          console.log(err);
        }
      }
    }, 10000);

    EventBus.$on('network-connected', () => {
      this.networkSetupStep++;
    });
  },
  beforeDestroy: function () {
    clearInterval(this.rescanInterval);
  },
};
</script>

<style>
#wifi-loader {
  --background: #62abff;
  --front-color: #ffffff;
  --back-color: #c3c8de85;
  --text-color: #414856;
  width: 128px;
  height: 128px;
  margin-top: 10px;
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
#wifi-loader svg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
#wifi-loader svg circle {
  position: absolute;
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(-100deg);
  transform-origin: center;
}
#wifi-loader svg circle.back {
  stroke: var(--back-color);
}
#wifi-loader svg circle.front {
  stroke: var(--front-color);
}
#wifi-loader svg.circle-outer {
  height: 86px;
  width: 86px;
}
#wifi-loader svg.circle-outer circle {
  stroke-dasharray: 62.75 188.25;
}
#wifi-loader svg.circle-outer circle.back {
  stroke-dashoffset: 25;
}
#wifi-loader svg.circle-outer circle.front {
  stroke-dashoffset: 25;
}
#wifi-loader svg.circle-outer circle.back.animated {
  -webkit-animation: circle-outer 1.8s ease infinite 0.3s;
  animation: circle-outer 1.8s ease infinite 0.3s;
}
#wifi-loader svg.circle-outer circle.front.animated {
  -webkit-animation: circle-outer 1.8s ease infinite 0.15s;
  animation: circle-outer 1.8s ease infinite 0.15s;
}
#wifi-loader svg.circle-middle {
  height: 60px;
  width: 60px;
}
#wifi-loader svg.circle-middle circle {
  stroke-dasharray: 42.5 127.5;
}
#wifi-loader svg.circle-middle circle.back {
  stroke-dashoffset: 17;
}
#wifi-loader svg.circle-middle circle.front {
  stroke-dashoffset: 17;
}
#wifi-loader svg.circle-middle circle.back.animated {
  -webkit-animation: circle-middle 1.8s ease infinite 0.25s;
  animation: circle-middle 1.8s ease infinite 0.25s;
}
#wifi-loader svg.circle-middle circle.front.animated {
  -webkit-animation: circle-middle 1.8s ease infinite 0.1s;
  animation: circle-middle 1.8s ease infinite 0.1s;
}
#wifi-loader svg.circle-inner {
  height: 34px;
  width: 34px;
}
#wifi-loader svg.circle-inner circle {
  stroke-dasharray: 22 66;
}
#wifi-loader svg.circle-inner circle.back {
  stroke-dashoffset: 9;
}
#wifi-loader svg.circle-inner circle.front {
  stroke-dashoffset: 9;
}

#wifi-loader svg.circle-inner circle.back.animated {
  -webkit-animation: circle-inner 1.8s ease infinite 0.2s;
  animation: circle-inner 1.8s ease infinite 0.2s;
}
#wifi-loader svg.circle-inner circle.front.animated {
  -webkit-animation: circle-inner 1.8s ease infinite 0.05s;
  animation: circle-inner 1.8s ease infinite 0.05s;
}

#wifi-loader svg.circle-center {
  height: 16px;
  width: 16px;
}

@-webkit-keyframes circle-outer {
  0% {
    stroke-dashoffset: 25;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 301;
  }
  80% {
    stroke-dashoffset: 276;
  }
  100% {
    stroke-dashoffset: 276;
  }
}

@keyframes circle-outer {
  0% {
    stroke-dashoffset: 25;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 301;
  }
  80% {
    stroke-dashoffset: 276;
  }
  100% {
    stroke-dashoffset: 276;
  }
}
@-webkit-keyframes circle-middle {
  0% {
    stroke-dashoffset: 17;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 204;
  }
  80% {
    stroke-dashoffset: 187;
  }
  100% {
    stroke-dashoffset: 187;
  }
}
@keyframes circle-middle {
  0% {
    stroke-dashoffset: 17;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 204;
  }
  80% {
    stroke-dashoffset: 187;
  }
  100% {
    stroke-dashoffset: 187;
  }
}
@-webkit-keyframes circle-inner {
  0% {
    stroke-dashoffset: 9;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 106;
  }
  80% {
    stroke-dashoffset: 97;
  }
  100% {
    stroke-dashoffset: 97;
  }
}
@keyframes circle-inner {
  0% {
    stroke-dashoffset: 9;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 106;
  }
  80% {
    stroke-dashoffset: 97;
  }
  100% {
    stroke-dashoffset: 97;
  }
}

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-container video {
  /* Make video to at least 100% wide and tall */

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: 90%;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
