<template>
  <v-card dark style="min-height: fit-content" class="pa-3 rotate-in-left" id="corrective-action-dialog">
    <v-card-title
      >{{ errorMessage[mode] }} <v-spacer></v-spacer>
      <v-icon @click="$emit('closeDialog')">mdi-close</v-icon></v-card-title
    >
    <div v-if="mode == 'dockError'" class="pa-4">
      <m-empty
        emptyMessage="Sorry, this dock is not connected to WiFi. Swipe down from the top of the screen to get it set up."
      >
      </m-empty>
      <v-dialog v-model="altSetupVid">
        <div class="video-container">
          <video autoplay="true" loop="true" v-if="altSetupVid" id="wifiSetupVid">
            <source src="assets/video/troubleshooting/wifiAlt-1.mp4" type="video/mp4" />
          </video>
        </div>
      </v-dialog>
      <v-card-actions>
        <v-btn outlined color="white" @click="openWifiSettings" rounded block> OPEN SETTINGS </v-btn>
      </v-card-actions>
      <v-row class="pa-3 justify-center align-center mt-5">
        <p>Not working?</p>
        <v-btn outlined x-small rounded @click="altWifiMethod"> Troubleshoot </v-btn>
      </v-row>
    </div>
    <!--<m-network v-if="mode == 'dockError'" color="white"></m-network>-->
    <div v-else>
      <v-window v-model="deviceErrorStep">
        <v-window-item :value="'start'" class="pa-6">
          <m-empty
            emptyMessage="Could not find your MARCo. Please make sure your MARCo is powered on, and if it is, scan for it locally using the button below."
          >
          </m-empty>
          <v-card-actions>
            <v-btn @click="scanForMARCos" elevation="0" rounded outlined>SCAN</v-btn>
            <v-spacer></v-spacer>
            <v-btn elevation="0" rounded outlined @click="deviceErrorStep = 'troubleshoot'">TROUBLESHOOT</v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item :value="'scanForMARCos'" class="pa-6">
          <m-marco-connection
            v-if="mode == 'deviceError' && deviceErrorStep == 'scanForMARCos'"
            v-bind="marcoConnectionProps"
            @selectDevice="selectDevice"
            @troubleshoot="deviceErrorStep = 'troubleshoot'"
          ></m-marco-connection>
        </v-window-item>
        <v-window-item :value="'troubleshoot'" class="pa-6">
          <m-troubleshoot @rescan="scanForMARCos"></m-troubleshoot>
        </v-window-item>
        <v-window-item :value="'webConnectLoading'" class="pa-6">
          <m-empty
            emptyMessage="Searching for previously connected MARCo's..."
          >
          </m-empty>
        </v-window-item>
        <v-window-item :value="'webDockFound'" class="pa-6">
          <m-empty
            emptyMessage="Found a MARCo! Please wait a few seconds and this page will refresh to connect..."
          >
          </m-empty>
        </v-window-item>
        <v-window-item :value="'multipleWebDocksFound'" class="pa-6">
          <m-empty
            :emptyMessage="`Looks like this account has been used on ${webScanResults.length} MARCo's or tablets. We'll scan for the most recently used MARCo. It will take a maximum of ${maxWebScanTime} minutes. Please ensure your MARCo is powered on and connected to the internet, and then tap the SCAN button below.`"
          >
          </m-empty>
          <v-card-actions>
            <v-btn @click="checkMultipleDocks" elevation="0" rounded outlined>SCAN</v-btn>
            <v-spacer></v-spacer>
            <v-btn elevation="0" rounded outlined @click="deviceErrorStep = 'troubleshoot'">TROUBLESHOOT</v-btn>
          </v-card-actions>
        </v-window-item>
      </v-window>
    </div>
  </v-card>
</template>

<script>
import { EventBus } from '../../eventBus.js';
import empty from './empty.vue';
import networkSetupWindow from '../networkSetup/networkSetupWindow.vue';
//Import the marco connection dialog here
import marcoConnectionDialog from '../marcoHdw/marcoConnectionDialog.vue';
import marcoTroubleShootingDialog from '../utility/troubleshootDialog.vue';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { Capacitor } from '@capacitor/core';

export default {
  name: 'corrective-action-dialog',
  components: {
    'm-empty': empty,
    'm-network': networkSetupWindow,
    'm-marco-connection': marcoConnectionDialog,
    'm-troubleshoot': marcoTroubleShootingDialog,
  },
  props: {
    correctiveAction: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'dockError', //Can  be one of the following dockError, deviceError
    },
  },
  data: function () {
    return {
      deviceErrorStep: 'start',
      errorMessage: {
        dockError: 'This dock lost network connection.',
        deviceError: "Can't find a MARCo...",
      },
      altSetupVid: false,
      checkNetworkInterval: null,
      maxWebScanTime: 0,
      webScanResults: [],
      marcoConnectionProps: {
        connectionStep: 0,
        connectedToMARCo: false,
        marcoConnectionAttempts: 0,
        marcoFound: {
          name: 'marcoII',
          wifiStatus: false,
        },
      },
    };
  },
  methods: {
    openWifiSettings() {
      try {
        NativeSettings.open({
          optionAndroid: AndroidSettings.Wifi,
          optionIOS: IOSSettings.App,
        });
      } catch (err) {
        console.log(err);
      }
    },
    selectDevice: async function (device) {
      console.log('Calling selectDevice...');
      if (!this.marcoConnectionProps.marcoFound.wifiStatus) {
        console.log('There was no wifi connection...');
        //The device doesn't have wifi, so let's try to connect that
        //TODO: Correct this with the proper connection to wifi
        if (this.currNetwork) {
          //If a network was previously selected in setting up wifi for this device, let's use that
          this.marcoConnectionProps.connectionStep = 3;
          this.$sendDeviceNetwork(this.currNetwork.ssid, this.currNetwork.password);
          this.marcoConnectionProps.connectionStep = 3;
          let b = await this.$getDefaultDockID();
          console.log('The dock ID that the device has saved is');
          console.log(b);

          //Check if the dock ID is valid
          if (b.length != 32) {
            EventBus.$emit('checkDockID', b);
          } else {
            //The dock ID is not valid, so let's overwrite it with our current dock ID
            this.$sendDockID();
          }
        } else {
          this.marcoConnectionProps.connectionStep++;
        }
      } else {
        //The device has wifi, so let's verify its device ID and overwrite it with our current ID
        this.marcoConnectionProps.connectionStep = 3;
        let b = await this.$getDefaultDockID();
        console.log('The dock ID that the device has saved is');
        console.log(b);

        //Check if the dock ID is valid
        if (b.length != 32) {
          EventBus.$emit('checkDockID', b);
        } else {
          //The dock ID is not valid, so let's overwrite it with our current dock ID
          this.$sendDockID();
          EventBus.$emit('pingBot');
        }
      }
    },
    scanForMARCos: function () {
      //If the platform is not web, then we can scan for MARCos using bluetooth
      if(Capacitor.getPlatform() != 'web'){
        
      console.log('SCANNING FOR MARCOS...');
      this.deviceErrorStep = 'scanForMARCos';
      this.$launchBLE(10);
      }
      else{
        //To handle for web, call the communication handler to scan for any realtime entries that match the user's id
        this.deviceErrorStep = 'webConnectLoading';
        console.log("Scanning for MARCOS on web...");
        console.log(this.$auth.currentUser.uid);
        EventBus.$emit('scanForUserID', this.$auth.currentUser.uid);
      }
    },
    altWifiMethod() {
      this.altSetupVid = true;
      document.getElementById('wifiSetupVid').play();
      console.log('Running the alternate wifi method');
      try {
        clearInterval(this.checkNetworkInterval);
      } catch (err) {
        console.log('Error clearing interval', err);
      }

      this.checkNetworkInterval = setInterval(() => {
        this.checkNetwork((status) => {
          if (status) {
            this.networkSetupStep++;
            EventBus.$emit('onboardingStep', 75);
            setTimeout(() => {
              this.$emit('networkLoaded', this.currNetwork);
              document.getElementById('wifiSetupVid').pause();
            }, 250);
            clearInterval(this.checkNetworkInterval);
            this.altSetupVid = false;
          } else {
            this.networkAttempts++;
            if (this.networkAttempts > 1000) {
              this.networkSetupStep--;
              this.networkConnectFailure = true;
              this.altSetupVid = false;
              clearInterval(this.checkNetworkInterval);
            }
          }
        });
      }, 250);
    },
    checkMultipleDocks() {
      EventBus.$emit('checkMultipleDocks');
      this.deviceErrorStep = 'webConnectLoading';
    },
  },
  mounted() {
    console.log('Corrective action mounted...');
    //Add EventBus handlers for ble events
    EventBus.$on('ble-initialized', (e) => {
      //Notify that it was initialized
    });

    EventBus.$on('ble-scan-failed', (e) => {
      //If a scan failed, go to window page 3
      this.setupStepper = 3;
    });

    EventBus.$on('ble-scan-running', (e) => {
      //If a scan is running, set the value of the scan attempts
      this.scanAttempts = e + 1;
    });

    EventBus.$on('ble-connect-attempt', (e) => {
      //If it is attempting to connect, ensure the messaging mentions that it is attempting to connect
      //Then mention the number of connection attempts
      this.connectAttempts = e + 1;
    });

    EventBus.$on('ble-connected', async (e) => {
      // let verifyInterval = setInterval(() => {
      //   if (this.networkStatus) {
      //     clearInterval(verifyInterval);
      //     //1. TODO: Play a sound that it successfully connected

      //     //2. TODO: Send a message to MARCo's hardware that it is connected

      //     //3. Move up towards the checking user page
      //     this.setupStepper = 5;

      //     //4. Check if a user exists

      //     var user = this.$auth.currentUser;

      //     if (user != null) {
      //       //A user exists, move to the home page
      //       this.$router.push("/home");
      //     } else {
      //       this.$router.push("/login");
      //     }
      //   }
      // }, 1000);
      this.bleConnected = true;
      this.marcoConnectionProps.connectionStep = 1;
      this.marcoConnectionProps.connectedToMARCo = true;
      let a = await this.$getDeviceNetworkStatus();

      console.log('The network status of the device is');
      console.log(a);
      this.marcoConnectionProps.marcoFound.wifiStatus = a.status;
    });

    /*let networkCheckInterval = setInterval(() => {
      this.checkNetwork((status) => {
        if (status) {
          this.setupStepper = 2;
          this.setupHeadline = "Connecting to MARCo...";
          clearInterval(networkCheckInterval);
        } else {
          this.networkAttempts++;
          if (this.networkAttempts > 5) {
            this.setupStepper = 1;
            this.setupHeadline = "No Internet Connection";
            clearInterval(networkCheckInterval);
          }
        }
      });
    }, 3000);*/

    // 2.在mounted生命周期函数中，调用定时器
    EventBus.$on('dock-id-sent', async (dockID) => {
      //Verify that the device saved the dockID
      console.log('Says the dock was sent');
      setTimeout(async () => {
        let b = await this.$getDefaultDockID();
        console.log('The dock ID that the device has saved 2');
        console.log(b);

        //Double check the dock ID again to make sure it was actually saved
        EventBus.$emit('checkDockID', b);
      }, 2500);
    });
    EventBus.$on('dock-id-send-failed', () => {
      //TODO: Handle error gracefully
    });

    EventBus.$on('dock-id-valid', () => {
      setTimeout(async () => {
        //let b = await this.$getDefaultDockID();

        this.onboardingStepUp();
      }, 1500);
    });

    EventBus.$on('network-sent', async () => {
      let intervalNum = 0;
      //Run an interval to check if the device has connected to the network yet
      let interval = setInterval(async () => {
        console.log('Checking network status of the connected device...');
        intervalNum++;
        if (intervalNum > 60) {
          clearInterval(interval);
          EventBus.$emit('network-connect-failed');
        } else {
          let networkStatus = await this.$getDeviceNetworkStatus();
          if (networkStatus) {
            clearInterval(interval);
            EventBus.$emit('network-connected');
            console.log('The network status of the device is');
            console.log(a);
            this.marcoConnectionProps.marcoFound.wifiStatus = a;

            if (this.onboardingStep == 2) {
              //Now, check the device's default dock ID
              let dockID = await this.$getDefaultDockID();

              //Check if the dock ID is valid
              if (dockID.length > 10) {
                EventBus.$emit('checkDockID', b);
              } else {
                //The dock ID is not valid, so let's overwrite it with our current dock ID
                this.$sendDockID();
              }
            }
          }
        }
      }, 1000);
    });

    EventBus.$on('connectionStatus', (status) => {
      if (status) {
        //The device successfully connected and the dock is also connected to the internet
        this.onboardingStep = 2;
        this.onboardingStepUp();
      }
    });

    EventBus.$on('userScanResults', (results) => {
      console.log("The results are:");
      console.log(results);
    });

    EventBus.$on('userScanResultSingle',(result)=>{
      console.log("The single result is:");
      console.log(result);
      this.deviceErrorStep = 'webDockFound';
    });

    EventBus.$on('userScanResultMultiple', (results) => {
      this.deviceErrorStep = 'webConnectLoading';
      this.maxWebScanTime = parseInt(Math.ceil(results.length * 7.5/60));
      this.webScanResults = results;
      console.log("There are multiple scan results");
      this.deviceErrorStep = 'multipleWebDocksFound';
    });
  },
};
</script>

<style>
.rotate-in-left {
  position: relative;
  -webkit-animation: rotate-in-left 0.5s cubic-bezier(0.49, -0.01, 0.76, 1.73) both;
  animation: rotate-in-left 1.5s cubic-bezier(0.41, 0.52, 0.4, 0.91) both;
}
/* ----------------------------------------------
   * Generated by Animista on 2023-4-5 15:56:42
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

/**
   * ----------------------------------------
   * animation rotate-in-left
   * ----------------------------------------
   */
@-webkit-keyframes rotate-in-left {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: left;
    transform-origin: left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: left;
    transform-origin: left;
    opacity: 1;
  }
}
@keyframes rotate-in-left {
  0% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg) translateY(-40vh);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg), translateY(0px);
    opacity: 1;
  }
}
</style>
