<template>
  <v-app :class="`launchapp appbkg-${onboardingStep}`">
    <v-app-bar app elevation="0" color="transparent">
      <v-row>
        <v-col cols="3">
          <v-progress-linear color="white" v-model="onboardingStepCompletion[0]"></v-progress-linear>
        </v-col>
        <v-col cols="3">
          <v-progress-linear color="white" v-model="onboardingStepCompletion[1]"></v-progress-linear>
        </v-col>
        <v-col cols="3">
          <v-progress-linear color="white" v-model="onboardingStepCompletion[2]"></v-progress-linear>
        </v-col>
        <v-col cols="3">
          <v-progress-linear color="white" v-model="onboardingStepCompletion[3]"></v-progress-linear>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid class="white--text">
        <v-row>
          <v-col cols="12">
            <v-slide-y-reverse-transition>
              <h2 :key="`onboarding-title-${onboardingStep}`">
                {{ onboardingTitle }}
              </h2>
            </v-slide-y-reverse-transition>
          </v-col>
          <v-col cols="8">
            <v-slide-y-reverse-transition>
              <p :key="`onboarding-subtitle-${onboardingStep}`">
                {{ onboardingSubTitle }}
              </p>
            </v-slide-y-reverse-transition>
          </v-col>
          <v-col cols="4">
            <v-spacer></v-spacer>
          </v-col>
        </v-row>

        <v-sheet :class="`pa-6 onboarding-box onboarding-box-${onboardingStep}`" dark elevation="16">
          <v-window v-model="onboardingStep" vertical touchless>
            <v-window-item :value="0">
              <v-row>
                <v-col class="grow">
                  <h3 @click="onboardingStepUp()">Tap Here To Start</h3>
                </v-col>
                <v-col class="shrink">
                  <v-icon class="shake-horizontal"> mdi-arrow-right </v-icon>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="1">
              <m-network-window color="white" @networkLoaded="networkLoaded"></m-network-window>
            </v-window-item>
            <v-window-item :value="2">
              <v-window v-model="deviceErrorStep">
                <v-window-item :value="'scanForMARCos'">
                  <m-connection-dialog
                    v-bind="marcoConnectionProps"
                    @selectDevice="selectDevice"
                    @troubleshoot="deviceErrorStep = 'troubleshoot'"
                    @skipStep="confirmSkipStep = true"
                  ></m-connection-dialog>
                </v-window-item>
                <v-window-item :value="'troubleshoot'">
                  <m-troubleshoot @rescan="scanForMARCos"></m-troubleshoot>
                </v-window-item>
              </v-window>

              <!--<h4 @click="showTroubleshootDialog">Troubleshoot connection</h4>-->
            </v-window-item>
            <v-window-item :value="3">
              <h4 @click="onboardingStepUp()">All done!</h4>
            </v-window-item>
            <v-window-item :value="4">
              <m-troubleshoot @rescan="$launchBLE"></m-troubleshoot>
            </v-window-item>
          </v-window>
        </v-sheet>
      </v-container>
      <v-dialog v-model="confirmSkipStep" max-width="290">
        <v-card color="orange darken-4" outlined>
          <v-card-title class="text-h5 orange--text text--lighten-4" color="orange lighten-4">
            <v-icon color="orange lighten-4">mdi-alert</v-icon>
            Skip connecting to MARCo?
          </v-card-title>

          <v-card-text color="orange lighten-4" class="orange--text text--lighten-4">
            You can set up a profile, and chat on this tablet, but to use the robot itself you will need to scan and
            find it, which can be done under settings.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-4" text @click="confirmSkipStep = false"> Cancel </v-btn>

            <v-btn
              color="orange lighten-4"
              text
              @click="
                onboardingStepUp();
                confirmSkipStep = false;
              "
            >
              SKIP
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <div class="zoomer" v-if="onboardingStep == 2">
      <div class="small"></div>
      <div class="mag"></div>
    </div>
    <v-fade-transition>
      <v-img
        :src="bottomImgSrc[onboardingStep]"
        contain
        class="onboardingBottomImg"
        :key="`onboardingBottomImg-${onboardingStep}`"
      ></v-img>
    </v-fade-transition>
  </v-app>
</template>

<script>
import { EventBus } from './eventBus.js';
import { Network } from '@capacitor/network';
import networkSetupWindow from './ui/networkSetup/networkSetupWindow.vue';
import MARCoConnectionDialog from './ui/marcoHdw/marcoConnectionDialog.vue';
import marcoTroubleShootingDialog from './ui/utility/troubleshootDialog.vue';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { Capacitor } from '@capacitor/core';

export default {
  name: 'Launch',
  components: {
    'm-network-window': networkSetupWindow,
    'm-connection-dialog': MARCoConnectionDialog,
    'm-troubleshoot': marcoTroubleShootingDialog,
  },
  data() {
    return {
      // 1.定义一个变量，用来存储定时器的返回值
      deviceErrorStep: 'scanForMARCos',
      confirmSkipStep: false,
      timer: null,
      setupDialog: true,
      setupStepper: 0,
      setupHeadline: 'Verifying Internet Connection...',
      networkAttempts: 0,
      networkStatus: false,
      bleStatus: false,
      userStatus: false,
      scanAttempts: 1,
      connectAttempts: 1,
      onboardingStep: 0,
      bleConnected: false,
      currNetwork: null,
      bottomImgSrc: [
        require('../../public/assets/images/onboarding/MARCo-With-Couch-Isolated 1-1x.png'),
        require('../../public/assets/images/onboarding/MARCo-touch-screen-isolated 2.png'),
        require('../../public/assets/images/onboarding/marco-chair-2 2.png'),
        require('../../public/assets/images/onboarding/MARCo-Hug-Retouched 1.png'),
      ],
      onboardingStepCompletion: [100, 0, 0, 0, 0],
      onboardingTitle: 'Welcome to MARCo!',
      onboardingSubTitle:
        'MARCo - the Mental-Health Assisting Robot Companion - is getting set up now. Be sure that the robot is powered on and is close to this docking station.',
      troubleshootingDialog: false,
      marcoConnectionProps: {
        connectionStep: 0,
        connectedToMARCo: false,
        marcoConnectionAttempts: 0,
        marcoFound: {
          name: 'marcoII',
          wifiStatus: false,
        },
      },
    };
  },
  mounted: async function () {
    //Remove the loading overlay in case it is active
    setTimeout(() => {
      EventBus.$emit('loadingOverlay', false);
    }, 3000);

    //Start the BLE process
    this.$launchBLE(10);

    //Add EventBus handlers for ble events
    EventBus.$on('ble-initialized', (e) => {
      //Notify that it was initialized
    });

    EventBus.$on('ble-scan-failed', (e) => {
      //If a scan failed, go to window page 3
      this.setupStepper = 3;
    });

    EventBus.$on('ble-scan-running', (e) => {
      //If a scan is running, set the value of the scan attempts
      this.scanAttempts = e + 1;
    });

    EventBus.$on('ble-connect-attempt', (e) => {
      //If it is attempting to connect, ensure the messaging mentions that it is attempting to connect
      //Then mention the number of connection attempts
      this.connectAttempts = e + 1;
    });

    EventBus.$on('ble-connected', async (e) => {
      // let verifyInterval = setInterval(() => {
      //   if (this.networkStatus) {
      //     clearInterval(verifyInterval);
      //     //1. TODO: Play a sound that it successfully connected

      //     //2. TODO: Send a message to MARCo's hardware that it is connected

      //     //3. Move up towards the checking user page
      //     this.setupStepper = 5;

      //     //4. Check if a user exists

      //     var user = this.$auth.currentUser;

      //     if (user != null) {
      //       //A user exists, move to the home page
      //       this.$router.push("/home");
      //     } else {
      //       this.$router.push("/login");
      //     }
      //   }
      // }, 1000);
      this.bleConnected = true;
      this.marcoConnectionProps.connectionStep = 1;
      this.marcoConnectionProps.connectedToMARCo = true;
      let a = await this.$getDeviceNetworkStatus();

      console.log('The network status of the device is');
      console.log(a.status);
      this.marcoConnectionProps.marcoFound.wifiStatus = a.status;

      if (this.onboardingStep == 2) {
        //Move the stepper up to show that a device was found
        //this.onboardingStepUp();
      }
    });

    /*let networkCheckInterval = setInterval(() => {
      this.checkNetwork((status) => {
        if (status) {
          this.setupStepper = 2;
          this.setupHeadline = "Connecting to MARCo...";
          clearInterval(networkCheckInterval);
        } else {
          this.networkAttempts++;
          if (this.networkAttempts > 5) {
            this.setupStepper = 1;
            this.setupHeadline = "No Internet Connection";
            clearInterval(networkCheckInterval);
          }
        }
      });
    }, 3000);*/

    // 2.在mounted生命周期函数中，调用定时器
    EventBus.$on('dock-id-sent', async (dockID) => {
      //Verify that the device saved the dockID
      console.log('Says the dock was sent');
      setTimeout(async () => {
        let b = await this.$getDefaultDockID();
        console.log('The dock ID that the device has saved 2');
        console.log(b);

        //Double check the dock ID again to make sure it was actually saved
        EventBus.$emit('checkDockID', b);
      }, 2500);
    });
    EventBus.$on('dock-id-send-failed', () => {
      //TODO: Handle error gracefully
    });

    EventBus.$on('dock-id-valid', () => {
      setTimeout(async () => {
        //let b = await this.$getDefaultDockID();

        this.onboardingStepUp();
      }, 1500);
    });

    EventBus.$on('network-sent', async () => {
      let intervalNum = 0;
      //Run an interval to check if the device has connected to the network yet
      let interval = setInterval(async () => {
        console.log('Checking network status of the connected device...');
        intervalNum++;
        if (intervalNum > 60) {
          clearInterval(interval);
          EventBus.$emit('network-connect-failed');
        } else {
          let networkStatus = await this.$getDeviceNetworkStatus();
          if (networkStatus.status) {
            clearInterval(interval);
            EventBus.$emit('network-connected');
            console.log('The network status of the device is');
            console.log(a);
            this.marcoConnectionProps.marcoFound.wifiStatus = a;

            if (this.onboardingStep == 2) {
              //Now, check the device's default dock ID
              let dockID = await this.$getDefaultDockID();

              //Check if the dock ID is valid
              if (dockID.length > 10) {
                EventBus.$emit('checkDockID', b);
              } else {
                //The dock ID is not valid, so let's overwrite it with our current dock ID
                this.$sendDockID();
              }
            }
          } else if (networkStatus.connectionAttempts == 2) {
            //This indicates that connection failed for one reason or another
            clearInterval(interval);
            EventBus.$emit('network-connect-failed');
          }
        }
      }, 1000);
    });

    EventBus.$on('connectionStatus', (status) => {
      if (status) {
        //The device successfully connected and the dock is also connected to the internet
        this.onboardingStep = 2;
        this.onboardingStepUp();
      }
    });
  },
  methods: {
    goHome() {
      EventBus.$emit('loadingOverlay', true);

      // 4.在函数中，清除定时器
      clearInterval(this.timer);
      // 5.跳转到首页
      this.$router.push('/home');
    },
    checkNetwork: async function (callback = false) {
      this.networkStatus = (await Network.getStatus()).connected;
      if (callback) {
        return callback(this.networkStatus);
      }
    },
    onboardingStepUp: function () {
      //Check first to make sure the platform is not web
      if (window.location.pathname.includes('firstTime') && Capacitor.getPlatform() != 'web') {
        //Only run the onboarding if the user is on the first time page
        this.onboardingStepCompletion[this.onboardingStep + 1] = 100;
        this.onboardingStep++;

        if (this.onboardingStep == 3) {
          setTimeout(() => {
            //4. Check if a user exists
            EventBus.$emit('loadingOverlay', true);
            EventBus.$emit('setup-finished', true);

            var user = this.$auth.currentUser;

            if (user != null) {
              //A user exists, move to the home page
              this.$router.push('/home');
            } else {
              this.$router.push('/login');
            }
          }, 3000);
        } else if (this.onboardingStep == 2) {
          if (this.bleConnected) {
            this.marcoConnectionProps.connectionStep = 1;
            /*
          setTimeout(() => {
            this.onboardingStepUp();
          }, 1500);*/
          }
        } else if (this.onbardingStep == 1) {
          setTimeout(() => {
            NativeSettings.open({
              optionAndroid: AndroidSettings.Wifi,
              optionIOS: IOSSettings.App,
            });
          }, 5000);
        }

        this.onboardingTitle = ['Welcome to MARCo!', 'Choose a network', 'Searching for MARCos...', 'Ready to start!'][
          this.onboardingStep
        ];
        this.onboardingSubTitle = [
          'MARCo - the Mental-Health Assisting Robot Companion - is getting set up now. Be sure that the robot is powered on and is close to this docking station.',
          "MARCo needs an internet connection to work. Let's check that a network is available.",
          'Please make sure that the robot is on and is near this screen during setup.',
          'You are ready to start with your friend, coach, counselor, advocate for life!',
        ][this.onboardingStep];
      }
      else{
        //Go to the login page
        this.$router.push('/login');
      }
    },
    showTroubleshootDialog() {
      this.troubleshootingDialog = true;
    },
    selectDevice: async function (device) {
      console.log('Calling selectDevice...');
      if (!this.marcoConnectionProps.marcoFound.wifiStatus) {
        console.log('There was no wifi connection...');
        //The device doesn't have wifi, so let's try to connect that
        //TODO: Correct this with the proper connection to wifi
        if (this.currNetwork) {
          //If a network was previously selected in setting up wifi for this device, let's use that
          this.marcoConnectionProps.connectionStep = 3;
          this.$sendDeviceNetwork(this.currNetwork.ssid, this.currNetwork.password);
          this.marcoConnectionProps.connectionStep = 3;
          let b = await this.$getDefaultDockID();
          console.log('The dock ID that the device has saved is');
          console.log(b);

          //Check if the dock ID is valid
          if (b.length != 32) {
            EventBus.$emit('checkDockID', b);
          } else {
            //The dock ID is not valid, so let's overwrite it with our current dock ID
            this.$sendDockID();
          }
        } else {
          this.marcoConnectionProps.connectionStep++;
        }
      } else {
        //The device has wifi, so let's verify its device ID and overwrite it with our current ID
        this.marcoConnectionProps.connectionStep = 3;
        let b = await this.$getDefaultDockID();
        console.log('The dock ID that the device has saved is');
        console.log(b);

        //Check if the dock ID is valid
        if (b.length != 32) {
          EventBus.$emit('checkDockID', b);
        } else {
          //The dock ID is not valid, so let's overwrite it with our current dock ID
          this.$sendDockID();
        }
      }
    },
    networkLoaded: async function (network) {
      this.currNetwork = network;
      if (this.onboardingStep == 1) {
        this.onboardingStepUp();
      }
      // this.onboardingStepUp();
    },
    scanForMARCos: function () {
      console.log('SCANNING FOR MARCOS...');
      this.deviceErrorStep = 'scanForMARCos';
      this.$launchBLE(10);
    },
  },
};
</script>

<style>
.v-application.launchapp {
  padding: 25px 35px;
  gap: 30px;
  isolation: isolate;

  position: relative;
  transition: all cubic-bezier(0.42, 0, 0, 1.03) 1.5s;
  background: linear-gradient(180deg, #0acffe, #495aff, #6a11cb, #2575fc, #005bea, #00c6fb, #09a03a, #f9f047);
  background-size: 800% 800%;
}

.v-application.launchapp.appbkg-0 {
  background-position: 0% 0%;
}

.v-application.launchapp.appbkg-1 {
  background-position: 0% 33%;
}

.v-application.launchapp.appbkg-2 {
  background-position: 0% 66%;
}

.v-application.launchapp.appbkg-3 {
  background-position: 0% 100%;
}

.onboardingBottomImg {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100vw;
  z-index: 1;
}

.theme--dark.v-sheet.onboarding-box {
  width: 100%;
  border: 1px solid white;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(12px);
  transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.5s;
}

.theme--dark.v-sheet.onboarding-box-0 {
  position: relative;
  transform: translateY(calc(40vh - 120px));
  border-radius: 56px;
  z-index: 99;
}

.theme--dark.v-sheet.onboarding-box-1 {
  position: relative;
  border-radius: 28px;
  z-index: 99;
}

.theme--dark.v-sheet.onboarding-box-2 {
  position: relative;
  border-radius: 60px;
  width: 100%;
  /*height: 60px;*/
  z-index: 99;
  overflow: auto;
  max-height: 60vh;
}

/*.zoomer,
.small {
  background: url('../../public/assets/images/onboarding/marco-chair-2\ 2.png') no-repeat;
  position: absolute;
  bottom:0px;
  right: 0px;
}

.zoomer {
  background-size: contain;
  height: 384px;
  left: 50%;
  margin: -192px;
  top: 50%;
  width: 512px;
}

.small {
  height: 100%;
 -webkit-mask-image: radial-gradient(circle 0, rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%);
 mask-image: radial-gradient(circle 0, rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%);
  width: 100%;
  animation: magSmallPath infinite 6s;
}

@keyframes magSmallPath {
  0% {
    
    background-position: 40% 20%;
  }
  50% {
    
    background-position: 60% 0%;
  }
  100% {
   
    background-position: 30% -15%;
  }
}*/

/*glass*/
.mag {
  position: absolute;
  border: 3px solid #666;
  border-radius: 50%;
  pointer-events: none;
  width: 240px;
  height: 240px;
  margin: -73px 0 0 -73px;
  position: absolute;
  animation: magGlassPath infinite 6s;
  z-index: 98;
}

@keyframes magGlassPath {
  0% {
    transform: rotate(0deg);
    left: 40%;
    bottom: 50%;
    background-position: 40% 20%;
    opacity: 0;
  }
  50% {
    transform: rotate(-30deg);
    left: 60%;
    bottom: 30%;
    background-position: 60% 0%;
    opacity: 1;
  }
  100% {
    transform: rotate(-10deg);
    left: 30%;
    bottom: 0%;
    background-position: 30% -15%;
    opacity: 0;
  }
}

/*shine*/
.mag:before {
  content: '';
  border-radius: 50%;
  position: absolute;
  transform: rotate(30deg);
  height: 235px;
  width: 235px;
  top: 3px;
  left: 3px;
  border-top: 12px solid rgba(255, 255, 255, 0.5);
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.5) inset;
}

/*handle*/
.mag:after {
  content: '';
  position: absolute;
  transform: rotate(25deg);
  height: 190px;
  width: 22px;
  background: black;
  bottom: -178px;
  left: 26px;
  border-top: 10px solid #999;
  border-bottom: 3px solid #999;
  box-sizing: border-box;
}

.shake-horizontal {
  -webkit-animation: shake-horizontal 12s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: shake-horizontal 12s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-7 13:45:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
</style>
