<template>
  <div id="content-moderation" class="pa-4 mb-4">
    <div v-if="isLoading" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <v-row justify="center" v-else>
      <v-card flat outlined rounded="xl" style="width: 100%">
        <v-expansion-panels accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div style="display: flex; align-items: center; gap: 8px">
                <img src="/assets/content-moderation/images/image_4.png" style="width: 25px; height: 25px" alt="" />
                <span>Age Restriction</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Above18Comp :userId="userId" v-if="age > 18" />
              <Bellow18Comp :userId="userId" v-else />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div style="display: flex; align-items: center; gap: 8px">
                <img src="/assets/content-moderation/images/image_5.png" style="width: 25px; height: 25px" alt="" />
                <span>Restricted Content</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <RestrictedContent :userId="userId"></RestrictedContent>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div style="display: flex; align-items: center; gap: 8px">
                <img src="/assets/content-moderation/images/image_6.png" style="width: 25px; height: 25px" alt="" />
                <span>Topics to Avoid</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content> <TopicsToAvoid :userId="userId"></TopicsToAvoid> </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div style="display: flex; align-items: center; gap: 8px">
                <img src="/assets/content-moderation/images/image_8.png" style="width: 25px; height: 25px" alt="" />
                <span>My Content</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content> <MyContent :userId="userId"></MyContent> </v-expansion-panel-content>
          </v-expansion-panel> </v-expansion-panels
      ></v-card>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarTitle }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Above18Comp from './tabs/AgeRestriction/Above18Comp.vue';
import Bellow18Comp from './tabs/AgeRestriction/Bellow18Comp.vue';
import RestrictedContent from './tabs/RestrictedContent.vue';
import MyContent from './tabs/MyContent.vue';
import TopicsToAvoid from './tabs/TopicsToAvoid.vue';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { EventBus } from '../eventBus';
export default {
  name: 'ContentModeration',
  props: ['userId'],
  components: { RestrictedContent, Above18Comp, Bellow18Comp, TopicsToAvoid, MyContent },
  data() {
    return {
      age: null, // age is help to determine the user is above 18 or under 18
      isLoading: true, // check the contents is still loading
      snackbar: false,
      snackbarTitle: '',
    };
  },
  created() {
    EventBus.$on('show-toast', (title, time = 2500) => {
      this.snackbar = true;
      this.snackbarTitle = title;
      setTimeout(() => {
        this.snackbar = false;
        this.snackbarTitle = '';
      }, time);
    });
  },
  async mounted() {
    console.log(this.userId);
    /**
     * We are getting the user doc to retrieve the birthday for age calculation
     * If the setting is not present in content collection we create a setting object.
     */
    this.isLoading = true;
    const settingsDocRef = doc(this.$db, 'users', this.userId, 'content', 'settings');
    const userDocRef = doc(this.$db, 'users', this.userId);
    const userDoc = await getDoc(userDocRef);
    const user = userDoc.data();
    const dob = user.basicInformation.birthDate;
    const age = this._calculateAge(new Date(dob));
    this.age = age;
    const settingsDoc = await getDoc(settingsDocRef);
    const settings = settingsDoc.data();
    if (!settings) {
      await setDoc(settingsDocRef, {
        userReadWrite: 2,
        userForceAgeModeration: false,
        userContentFilters: [],
        userDialogueFilters: [],
        onlyCuratedContent: false,
        lastUpdate: serverTimestamp(),
      });
    }
    this.isLoading = false;
  },
  methods: {
    // for calculating age according to birthday
    _calculateAge(birthday) {
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    showToast(title, time = 2500) {
      this.snackbar = true;
      this.snackbarTitle = title;
      setTimeout(() => {
        this.snackbar = false;
        this.snackbarTitle = '';
      }, time);
    },
  },
};
</script>

<style>
#content-moderation .topic-chip {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#content-moderation .topic-chip > div:first-child {
  display: flex;
  align-items: center;
  gap: 10px;
}
#content-moderation .topic-chip > div:last-child {
  gap: 8px;
}
#content-moderation .topic-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
#content-moderation .topic-icon-button {
  background-color: #ffcc80;
}
#content-moderation .topic-icon-button.red-color {
  background-color: #ef9a9a;
}
#content-moderation .custom-input {
  padding: 4px 0px;
  outline: none;
  color: white;
  border-bottom: 1px solid white;
}
#content-moderation .combobox .v-input__slot {
  box-shadow: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #757575;
}
#content-moderation .v-list-item,
#content-moderation .v-subheader {
  height: 40px !important;
}
#content-moderation .v-menu__content {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 20px !important;
}
/* my content start */
.custom-dialog-wrapper .chip-group .v-slide-group__content {
  justify-content: center;
}
.custom-dialog-wrapper .v-stepper {
  box-shadow: none !important;
}
.custom-dialog-wrapper .v-stepper__content {
  padding: 0px 24px !important;
  padding-bottom: 24px !important;
}
.custom-dialog-wrapper .upload-button {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  gap: 39px;
  background: #cecece;
  border: 3px dashed #6b6b6b;
  border-radius: 18px;
}
.custom-dialog-wrapper .upload-button .plus-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}
.custom-dialog-wrapper .upload-button p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #3c3c3c;
}
.custom-dialog-wrapper .file-input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.custom-dialog-wrapper .file-input::-webkit-file-upload-button {
  visibility: hidden;
}
#content-moderation .pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
#content-moderation .pagination-controls-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
#content-moderation .content-items {
  padding: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#content-moderation .content-items {
  border-bottom: 1px solid lightgray;
}
#content-moderation .content-items > div:first-child {
  gap: 12px;
}
#content-moderation .content-items > div,
#content-moderation .chip-wrapper {
  display: flex;
  align-items: center;
}
#content-moderation .chip-wrapper {
  gap: 4px;
}
#content-moderation .g-button {
  background: linear-gradient(116.48deg, #07f 15.9%, #00e0ff 75.29%);
  box-shadow: 0px 5px 12px rgba(24, 197, 246, 0.39);
}
#content-moderation .filter-wrapper::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
#content-moderation .filter-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
#content-moderation .filter {
  min-width: fit-content;
  display: flex;
  gap: 8px;
}
#content-moderation .filter-chip {
  user-select: none;
  cursor: pointer;
}
#content-moderation .v-dialog {
  box-shadow: 0px 4px 30px 12px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  overflow: hidden;
}
.custom-dialog-wrapper .modal-content-head {
  position: relative;
}
.custom-dialog-wrapper .modal-content-head img.top-img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom center;
  z-index: 1;
}
.custom-dialog-wrapper .modal-content-head h4 {
  position: relative;
  z-index: 2;
}
.custom-dialog-wrapper .dialog-content {
  padding-top: 56px;
  padding-bottom: 32px;
  padding-inline: 32px;
}
.custom-dialog-wrapper .v-or {
  position: relative;
}
.custom-dialog-wrapper .v-or span {
  position: absolute;
  z-index: 1;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-wrapper .v-dialog {
  box-shadow: 0px 4px 30px 12px rgba(0, 0, 0, 0.25);
  border-radius: 36px;
  overflow: hidden;
}
.dialog-wrapper {
  position: relative;
}
.dialog-wrapper img.top-img {
  position: absolute;
  top: 0;
  width: 100%;
}
.dialog-wrapper .dialog-content {
  padding-top: 56px;
  padding-bottom: 32px;
  padding-inline: 32px;
}
</style>
