<template>
  <v-container color="transparent">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="4" class="text--center">
        <v-progress-circular indeterminate color="white" size="96"> </v-progress-circular>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-card-title>{{ searchingStatuses[searchingStatus] }}</v-card-title>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <p>{{ bleMessage }}</p>
      <v-spacer></v-spacer>
    </v-row>

    <v-card-actions>
      <v-btn
        color="white"
        class="mt-5 rounded-pill"
        @click="$emit('troubleshoot')"
        elevation="0"
        outlined
        v-if="searchingStatus"
        >Troubleshoot</v-btn
      >

      <v-btn
        color="white"
        class="mt-5 rounded-pill"
        @click="$emit('scan-for-marcos')"
        elevation="0"
        outlined
        v-if="!searchingStatus"
        >Rescan</v-btn
      >
      <v-btn
        color="white"
        class="mt-5 rounded-pill"
        @click="$emit('skipStep')"
        elevation="0"
        outlined
        :disabled="!allowSkip"
      >
        SKIP
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { EventBus } from '@/components/eventBus';

export default {
  name: 'marco-local-search',
  props: {
    searchingStatus: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      search: false,
      searchingStatuses: [
        "Couldn't find a MARCo, try again?",
        'Searching for MARCos...',
        'Found a MARCo, connecting...',
      ],
      bleMessage: '',
      allowSkip: false,
    };
  },
  beforeMount() {
    EventBus.$on('ble-message', (message) => {
      this.bleMessage = message;
    });
    EventBus.$on('ble-marco-found', () => {
      this.searchingStatus = 2;
    });

    setTimeout(() => {
      this.allowSkip = true;
    }, 10000);
  },
};
</script>
