<template>
  <div>
    <p class="text-h5 mb-1" style="font-weight: 500; line-height: 24px">What shouldn’t MARCo talk about?</p>
    <p class="text-body-2" style="line-height: 16px">
      In addition to moderating the kinds of content that MARCo brings up, you can also give MARCo topics that MARCo
      should avoid during conversation.
    </p>
    <form v-on:submit="onItemAdd">
      <v-text-field
        v-model="topic"
        append-icon="mdi-plus"
        type="text"
        label="New Topic"
        hint="Type a few words of what MARCo shouldn’t bring up"
        outlined
        persistent-hint
        @click:append="onItemAdd"
      ></v-text-field>
    </form>

    <div class="topic-list-wrapper">
      <div
        class="pl-3 pr-2 py-2 topic-chip"
        v-for="item in list"
        v-bind:key="item.id"
        v-bind:class="{
          'red lighten-4 ': !item.show,
          'orange lighten-4': item.show,
          'orange darken-1': isEditing(item.id),
        }"
      >
        <div>
          <div v-if="isEditing(item.id)"><v-icon color="white"> mdi-alert </v-icon></div>
          <v-btn
            v-else
            elevation="0"
            :color="item.show ? 'orange' : 'red'"
            icon
            class="topic-icon-button"
            v-bind:class="{ 'red-color': !item.show }"
            @click="onItemChangeVisibility(item.id)"
          >
            <v-icon dark v-if="item.show"> mdi-eye </v-icon>
            <v-icon dark v-else> mdi-eye-off </v-icon>
          </v-btn>

          <input
            class="custom-input"
            v-model="editingValue"
            placeholder="Edit topic..."
            v-focus=""
            v-if="isEditing(item.id)"
          />
          <span v-bind:class="{ 'red--text': !item.show, 'orange--text': item.show }" v-else>{{
            item.show ? censor(item.label) : item.label
          }}</span>
        </div>

        <div style="min-width: 72px" v-if="isEditing(item.id)">
          <v-btn elevation="0" color="white" icon @click="onItemSave(item.id, item.label)">
            <v-icon dark> mdi-check </v-icon></v-btn
          >
          <v-btn elevation="0" color="white" icon @click="onItemClose()">
            <v-icon dark> mdi-close-circle-outline </v-icon>
          </v-btn>
        </div>
        <div style="min-width: 72px" v-else>
          <v-btn elevation="0" :color="item.show ? 'orange' : 'red'" icon @click="onItemEdit(item.id, item.label)">
            <v-icon dark> mdi-pencil </v-icon></v-btn
          >
          <v-btn elevation="0" :color="item.show ? 'orange' : 'red'" icon @click="onItemDelete(item.id)">
            <v-icon dark> mdi-trash-can </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { EventBus } from '../../eventBus';

export default {
  name: 'TopicsToAvoid',
  props: ['userId'],
  data() {
    return {
      isLoading: true,
      editingValue: '',
      topic: '',
      show: true,
      editing: false,
      editingId: -1,
      list: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    const settingsDocRef = doc(this.$db, 'users', this.userId, 'content', 'settings');
    const settingsDoc = await getDoc(settingsDocRef);
    const settings = settingsDoc.data();
    const userDialogueFilters = settings.userDialogueFilters;
    this.list = userDialogueFilters.map((item) => ({
      id: Math.random(),
      label: item,
      show: true,
    }));
    this.isLoading = false;
  },
  methods: {
    /**
     * calls when new item is added
     * e -> is the event
     */
    onItemAdd(e) {
      e.preventDefault();
      if (!this.topic) return;
      this.list = [...this.list, { id: String(Math.random()), label: this.topic, show: true }];
      this.topic = '';
      this.update(() => {
        EventBus.$emit('show-toast', 'Item Added');
      });
    },
    // calls when the user press eye button
    onItemChangeVisibility(id) {
      this.list = this.list.map((item) => (item.id === id ? { ...item, show: !item.show } : item));
    },
    // for deleting items
    onItemDelete(id) {
      this.list = this.list.filter((item) => item.id !== id);
      this.update(() => {
        EventBus.$emit('show-toast', 'Item Deleted');
      });
    },
    // calls when user press the edit button of the element
    onItemEdit(id, value) {
      this.editingValue = value;
      this.editing = true;
      this.editingId = id;
    },
    // after editing when the user press save button.
    onItemSave(id, oldValue) {
      if (!this.editingValue || this.editingValue === oldValue) return;
      this.list = this.list.map((item) => (item.id === id ? { ...item, label: this.editingValue } : item));
      this.editingValue = '';
      this.editing = false;
      this.editingId = -1;
      this.update(() => {
        EventBus.$emit('show-toast', 'Item Updated');
      });
    },
    // editing close
    onItemClose() {
      this.editingValue = '';
      this.editing = false;
      this.editingId = -1;
    },
    // for star te text
    censor: function (text) {
      return text.replace(/(?<!^).(?!$)/g, '*');
    },
    // checking item is in the editing mode
    isEditing: function (id) {
      return this.editing && this.editingId === id;
    },
    // update function update in firebase
    async update(cb) {
      const settingsDocRef = doc(this.$db, 'users', this.userId, 'content', 'settings');
      await updateDoc(settingsDocRef, {
        userDialogueFilters: this.list.map((item) => item.label),
        lastUpdate: serverTimestamp(),
      });
      cb && cb();
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>

<style scoped>
.topic-chip {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topic-chip > div:first-child {
  display: flex;
  align-items: center;
  gap: 10px;
}
.topic-chip > div:last-child {
  gap: 8px;
}
.topic-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.topic-icon-button {
  background-color: #ffcc80;
}
.topic-icon-button.red-color {
  background-color: #ef9a9a;
}
.custom-input {
  padding: 4px 0px;
  outline: none;
  color: white;
  border-bottom: 1px solid white;
}
</style>
