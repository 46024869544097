<template>
  <div>
    <img
      src="/assets/content-moderation/images/image_1.png"
      style="margin-inline: auto; display: block; max-width: 150px"
      alt=""
      class="mb-8"
    />
    <p class="text-center text-h4 text-center" style="font-weight: bold">See what you want to see</p>
    <p class="text-caption text-center text-center">
      MARCo won’t filter out potentially mature content unless you specify.
    </p>
    <p class="text-body-2 orange--text text--lighten-2 text-center" v-if="userForceAgeModeration">
      MARCo IS filtering potentially mature content.
    </p>
    <p class="text-body-2 blue--text text--lighten-2 text-center" v-else>
      MARCo IS NOT filtering potentially mature content.
    </p>
    <v-row no-gutters class="mb-4">
      <v-col cols="6" class="pe-2">
        <v-btn block color="blue lighten-1" class="white--text" elevation="0" rounded @click="openDialog">
          FILTER MATURE
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn block color="blue lighten-3" class="white--text" elevation="0" rounded @click="goToSetting"
          >UPDATE BIRTHDAY</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="400" class="dialog-wrapper">
      <div class="dialog white dialog-wrapper">
        <img src="/assets/content-moderation/images/image_3.png" class="top-img" />
        <div class="dialog-content">
          <img
            src="/assets/content-moderation/images/image_2.png"
            style="margin-inline: auto; display: block; max-width: 150px"
            alt=""
            class="mb-8"
          />
          <p class="text-center text-h4" style="font-weight: bold" v-if="userForceAgeModeration">
            Do you want to remove age restriction?
          </p>
          <p class="text-center text-h4" style="font-weight: bold" v-else>Do you want to age restrict content?</p>
          <p class="text-caption my-6 text-center">You can change this setting at any time.</p>
          <div class="d-flex align-center justify-center" style="gap: 16px">
            <v-btn
              color="blue lighten-1"
              class="white--text"
              elevation="0"
              rounded
              :loading="isUpdating"
              @click="handleYesButton"
            >
              YES
            </v-btn>
            <v-btn color="blue lighten-3" class="white--text" elevation="0" rounded @click="openDialog"> NO </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { EventBus } from '../../../eventBus';
export default {
  name: 'Above18Comp',

  props: ['userId'],
  data: () => {
    return { dialog: false, userForceAgeModeration: false, isUpdating: false };
  },
  methods: {
    // for opening and closing the dialog box
    openDialog: function () {
      this.dialog = !this.dialog;
    },
    // For set the value in the firebase setting whether is enable or not
    async handleYesButton() {
      this.isUpdating = true;
      const docRef = doc(this.$db, 'users', this.userId, 'content', 'settings');
      await updateDoc(docRef, {
        userForceAgeModeration: !this.userForceAgeModeration,
        lastUpdate: serverTimestamp(),
      });
      this.userForceAgeModeration = !this.userForceAgeModeration;
      EventBus.$emit(
        'show-toast',
        this.userForceAgeModeration
          ? 'MARCo IS filtering potentially mature content.'
          : ' MARCo IS NOT filtering potentially mature content.'
      );
      this.isUpdating = false;
      this.dialog = !this.dialog;
    },
    goToSetting() {
      EventBus.$emit('go-to-setting', null);
    },
  },
  async mounted() {
    // getting the filed userForceAgeModeration from setting
    const docRef = doc(this.$db, 'users', this.userId, 'content', 'settings');
    const settingsDoc = await getDoc(docRef);
    const settings = settingsDoc.data();
    this.userForceAgeModeration = settings.userForceAgeModeration;
  },
};
</script>

<style></style>
