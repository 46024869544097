<template>
  <v-app>
    <v-overlay color="blue lighten-2" class="icon-bg" opacity="1" :value="loadingOverlay" style="z-index: 9999">
      <v-row justify="center" class="my-6">
        <v-avatar size="128" style="box-shadow: 0 0 0 rgba(255, 255, 255, 0.4); animation: avatarpulse 2s infinite">
          <v-img src="/assets/images/meditationcardbkg.jpg"></v-img>
        </v-avatar>
      </v-row>

      <div class="white--text">Loading MARCo...</div>
    </v-overlay>
    <router-view></router-view>
    <v-row
      style="
        z-index: 9999;
        position: fixed;
        top: 25;
        width: 110vw;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      "
      class="justify-center"
    >
      <v-spacer></v-spacer>
      <v-alert
        :value="checkSyncingDevice"
        type="info"
        color="info"
        transition="scale-transition"
        class="my-5"
        elevation="16"
      >
        <v-row> Trying to reconnect to MARCo... </v-row>
        <v-row>
          <v-progress-linear indeterminate color="white" width="3"></v-progress-linear>
        </v-row>
      </v-alert>
      <v-spacer></v-spacer>
    </v-row>

    <v-row style="z-index: 9999; position: fixed; top: 25; width: 110vw" class="justify-center">
      <v-spacer></v-spacer>
      <v-alert
        :value="checkingForUpdate"
        type="info"
        color="info"
        transition="scale-transition"
        class="my-5"
        elevation="16"
        dismissible
      >
        <v-row> Checking for updates... </v-row>
        <v-row>
          <v-progress-linear indeterminate color="white" width="3"></v-progress-linear>
        </v-row>
      </v-alert>
      <v-spacer></v-spacer>
    </v-row>

    <v-dialog v-model="correctiveAction" persistent transition="dialog-top-transition">
      <m-corrective-action
        v-if="correctiveAction"
        :mode="correctiveActionMode"
        @closeDialog="correctiveAction = !correctiveAction"
      ></m-corrective-action>
    </v-dialog>

    <v-dialog v-model="correctiveAction2" persistent transition="dialog-top-transition">
      <m-corrective-action
        v-if="correctiveAction2"
        mode="deviceError"
        @closeDialog="correctiveAction2 = !correctiveAction2"
      ></m-corrective-action>
    </v-dialog>

    <v-dialog v-model="updatingAppDialog" persistent>
      <v-card dark style="min-height: fit-content" class="pa-3" id="updating-action-dialog">
        <v-card-title
          >Update time!
          <v-spacer></v-spacer>
          <v-btn icon @click="updatingAppDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6">
            <lottie-animation
              path="assets/images/lotties/109052-chop-robot.json"
              :width="200"
              :height="200"
              :loop="true"
              :autoplay="true"
            />
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-card-text>Downloading your update...</v-card-text>
        <v-card-text>Don't see anything happening?</v-card-text>
        <v-card-actions>
          
          <v-btn outlined color="white" class="rounded-pill" block @click="manualUpdate">OPEN UPDATE PAGE</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { EventBus } from './components/eventBus.js';
import correctiveActionDialog from './components/ui/utility/correctiveActionDialog.vue';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import { Network } from '@capacitor/network';

Network.addListener('networkStatusChange', (status) => {
  console.log('Network status changed', status);
});

export default {
  components: {
    'm-corrective-action': correctiveActionDialog,
    LottieAnimation,
  },
  data: function () {
    return {
      loadingOverlay: false,
      checkSyncingDevice: false,
      correctiveAction: false,
      correctiveActionMode: 'dockError',
      correctiveAction2: false,
      checkingForUpdate: false,
      updatingAppDialog: false,
      connectionStatus: true,
    };
  },
  methods:{
    manualUpdate: async function () {
      try {
        await AppUpdate.openAppStore();
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    EventBus.$on('loadingOverlay', (value) => {
      console.log('Switching the loading overlay to ', value);
      this.loadingOverlay = value;
      if (this.loadingOverlay) {
        //If the loading overlay is set to true, set it to false after a couple seconds just in case something goes wrong
        setTimeout(() => {
          this.loadingOverlay = false;
        }, 5000);
      }
    });
    EventBus.$on('pendingConnectionStatus', (status) => {
      // this.isSyncingApp = status;
      this.checkSyncingDevice = status;
    });

    EventBus.$on('connectionStatus', (status) => {
      //Update connection status only during the live app
      //First time setup already uses a different handler for this flow
      if (!window.location.pathname.includes('firstTime')) {
        console.log('Connection status is ', status);
        this.correctiveActionMode = 'deviceError';
        this.correctiveAction2 = !status;

        if (Capacitor.getPlatform() != 'web' && this.connectionStatus != status) {
          console.log(`Changing the correctiveAction to ${!status} because the connection status is ${status}`);
          this.correctiveAction2 = !status;
          this.connectionStatus = status;
        }
      }
    });

    EventBus.$on('update-available', (value) => {
      this.updatingAppDialog = true;
      console.log('Updating app dialog is ', this.updatingAppDialog);
    });

    const getCurrentAppVersion = async () => {
      //eslint-disable-next-line
      if(Capacitor.getPlatform() == 'web'){
        return false;
      }
      else{
      //eslint-disable-next-line
      return new Promise(async (resolve, reject) => {
        try {
          const result = await AppUpdate.getAppUpdateInfo();
          resolve(result.currentVersion);
        } catch (err) {
          reject(err);
        }
      });
    }
    };

    const getAvailableAppVersion = async () => {
      //eslint-disable-next-line
      return new Promise(async (resolve, reject) => {
        try {
          const result = await AppUpdate.getAppUpdateInfo();
          resolve(result.availableVersion);
        } catch (err) {
          reject(err);
        }
      });
    };

    const performImmediateUpdate = async () => {
      try {
        const result = await AppUpdate.getAppUpdateInfo();
        if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
          console.warn('Could not perform the update...');
          return;
        }
        if (result.immediateUpdateAllowed) {
          EventBus.$emit('update-available');
          await AppUpdate.performImmediateUpdate();
        } else {
          console.log('Immediate update not allowed');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const startFlexibleUpdate = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
        console.warn('Could not perform the update...');
        return;
      }
      if (result.flexibleUpdateAllowed) {
        await AppUpdate.startFlexibleUpdate();
      }
    };

    const completeFlexibleUpdate = async () => {
      await AppUpdate.completeFlexibleUpdate();
    };

    EventBus.$on('check-for-update', async () => {
      console.log('Trying to check for an update...');
      try {
        let currVersion;
        let nextVersion;
        getCurrentAppVersion()
          .then((version) => {
            currVersion = version;
            console.log('Current version: ' + currVersion);
            return getAvailableAppVersion();
          })
          .then((version) => {
            nextVersion = version;

            console.log('Next version: ' + nextVersion);
            this.checkingForUpdate = false;

            performImmediateUpdate();
            if (currVersion != nextVersion) {
              console.log('The versions do not match, which means that the app needs to be refreshed...');
            }
          })
          .catch((err) => {
            console.error('ERR: 374');
            console.log(err);
          });
      } catch (err) {
        console.error('ERR: 378');
        console.error(err);
      }
    });

    const logCurrentNetworkStatus = async () => {
      const status = await Network.getStatus();

      console.log('Network status:', status);

      if (!status.connected && !window.location.pathname.includes('firstTime')) {
        this.correctiveActionMode = 'dockError';
        this.correctiveAction = true;
      } else if (this.correctiveActionMode == 'dockError' && status.connected) {
        this.correctiveAction = false;
      }
    };

    setTimeout(() => {
      if (Capacitor.getPlatform() != 'web') {
      console.log("That's it, I'm checking for updates!");
      EventBus.$emit('check-for-update');
    
      this.checkingForUpdate = true;
      }

      logCurrentNetworkStatus();
    }, 2000);

    //Watch the network status to see if it goes up or down
    Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed', status);
      console.log('And the route name is ', window.location.pathname);

      if (status.connected) {
        this.correctiveAction = false;
      } else if (!window.location.pathname.includes('firstTime')) {
        this.correctiveActionMode = 'dockError';
        this.correctiveAction = true;
      }
    });
  },
};
</script>

<style></style>
