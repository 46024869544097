<template>
  <v-container>
    <v-card-title>
      <h3>Network Details</h3>
    </v-card-title>
    <v-divider></v-divider>
    <v-form>
      <v-text-field label="Network Name" :value="network.SSID" disabled></v-text-field>
      <v-text-field
        label="Network Password"
        hint="Leave blank if network is open"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
        v-model="pwd"
        @keyup.enter="connectToNetwork"
      ></v-text-field>
    </v-form>
    <v-divider color="white"></v-divider>
    <v-expansion-panels>
      <!--Create a dropdown expansion panel that allows additional network details to be entered-->
      <v-expansion-panel>
        <v-expansion-panel-header>Advanced</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form>
            <v-text-field label="IP Address" hint="Leave blank if network is open" v-model="network.ip"></v-text-field>
            <v-text-field
              label="Subnet Mask"
              hint="Leave blank if network is open"
              v-model="network.subnet"
            ></v-text-field>
            <v-text-field
              label="Gateway"
              hint="Leave blank if network is open"
              v-model="network.gateway"
            ></v-text-field>
            <v-text-field label="DNS Server" hint="Leave blank if network is open" v-model="network.dns"></v-text-field>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions>
      <v-btn @click="changeNetworkSetupStep(-1)" elevation="0" text>Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="rounded-pill" elevation="0" outlined @click="connectToNetwork">Submit</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
export default {
  name: 'm-network-details-form',

  props: {
    network: {
      type: Object,

      default: () => ({}),
    },
    color: {
      type: String,

      default: 'primary',
    },
  },
  data: () => ({
    show1: false,
    pwd: '',
  }),
  methods: {
    connectToNetwork() {
      this.$emit('connectToNetwork', ...[this.network, this.pwd]);
    },
    changeNetworkSetupStep(val) {
      this.$emit('changeNetworkSetupStep', val);
    },
  },
};
</script>
