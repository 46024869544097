<template>
  <div>
    <v-window v-model="troubleshootStep">
      <v-window-item :value="0" transition="scale-transition" origin="center center" class="pa-6">
        <p>Is your MARCo lit up or do you hear any sounds coming from your MARCo?</p>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-card width="100%" light class="rounded-xl">
              <video width="100%" autoplay loop mute>
                <source
                  :src="require('../../../../public/assets/video/troubleshooting/troubleshooting-1.mp4')"
                  type="video/mp4"
                />
                Your tablet does not support this video :(
              </video>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-window-item>
      <v-window-item :value="1" transition="scale-transition" origin="center center" class="pa-6">
        <p>
          Click the power button on MARCo and wait a couple seconds. (see below for details) Did the face light up now?
        </p>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-card width="100%" light class="rounded-xl">
              <video width="100%" autoplay loop mute>
                <source
                  :src="require('../../../../public/assets/video/troubleshooting/troubleshooting-2.mp4')"
                  type="video/mp4"
                />
                Your tablet does not support this video :(
              </video>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-window-item>

      <v-window-item :value="2" transition="scale-transition" class="pa-6">
        <p>
          Connect the MARCo to a power supply - some models MUST be connected, some have a battery pack. In either case,
          wait a minute or two and try to click the power button again - did it light up this time?
        </p>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-card width="100%" light class="rounded-xl">
              <video width="100%" autoplay loop mute>
                <source
                  :src="require('../../../../public/assets/video/troubleshooting/troubleshooting-3.mp4')"
                  type="video/mp4"
                />
                Your tablet does not support this video :(
              </video>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-window-item>
      <v-window-item :value="3" transition="scale-transition">
        <v-row class="pa-6">
          <v-spacer></v-spacer>
          <v-col cols="12" class="text--center">
            <m-empty
              emptyMessage="We're sorry, it sounds like MARCo needs a little help then. Choose one of the options below to contact a member of our support team:"
            ></m-empty>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-6">
          <v-list style="height: 40vh; width: 100%; overflow: auto; background: rgba(0, 0, 0, 0)">
            <v-list-item v-for="(option, o) in supportOptions" :key="`support-option-${o}`" @click.stop="option.action">
              <v-list-item-avatar>
                <v-icon :color="color">{{ `${option.icon}` }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content :class="`${color}--text`">{{ option.title }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-window-item>
      <v-window-item :value="4" class="pa-6">
        <p>
          {{ chosenSupportOption.text }}
        </p>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-card width="100%" light class="rounded-xl">
              <v-img :src="chosenSupportOption.image" height="100%"></v-img>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-window-item>
    </v-window>
    <v-card-actions v-if="troubleshootStep < 3">
      <v-row>
        <v-col cols="6">
          <v-btn
            color="white"
            class="mt-5 rounded-pill"
            @click="$emit('rescan')"
            elevation="0"
            outlined
            style="width: 100%"
            >Yes</v-btn
          >
        </v-col>
        <v-spacer></v-spacer>

        <v-col cols="6">
          <v-btn
            color="white"
            class="mt-5 rounded-pill"
            @click="troubleshootStep++"
            elevation="0"
            outlined
            style="width: 100%"
            >No</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-actions v-if="troubleshootStep == 4">
      <v-btn color="white" class="mt-5 rounded-pill" @click="troubleshootStep--" elevation="0" outlined block
        >BACK</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
import empty from '../utility/empty.vue';

export default {
  name: 'marco-troubleshooting-dialog',
  components: {
    'm-empty': empty,
  },
  props: {
    searchingStatus: {
      type: Boolean,
      default: true,
    },
    sendingStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      troubleshootStep: 0,
      chosenSupportOption: {
        text: '',
        image: '',
      },
      supportOptions: [
        {
          title: 'Video call from this tablet',
          icon: 'mdi-video',
          action: () => {
            this.$emit('video-call');
          },
        },
        {
          title: 'Call from your phone',
          icon: 'mdi-phone',
          action: () => {
            //this.$emit("phone-call");
            this.chosenSupportOption = {
              text: 'Open your phone. Scan the QR code or call us at +1-201-650-5096',
              image: require('../../../../public/assets/images/troubleshooting/phone-QR-code.jpg'),
            };
            this.troubleshootStep = 4;
          },
        },
        {
          title: 'Email support',
          icon: 'mdi-email',
          action: () => {
            this.chosenSupportOption = {
              text: 'Open your phone. Scan the QR code or email us at support@marcohealthtech.com',
              image: require('../../../../public/assets/images/troubleshooting/email-QR-code.png'),
            };
            this.troubleshootStep = 4;
          },
        },
        {
          title: 'Chat with support',
          icon: 'mdi-chat',
          action: () => {
            this.chosenSupportOption = {
              text: 'Open your phone. Scan the QR code or tap here to open up the chat on this tablet',
              image: require('../../../../public/assets/images/troubleshooting/chat-QR-code.png'),
            };
            this.troubleshootStep = 4;
          },
        },
      ],
    };
  },
};
</script>
