<template>
  <div>
    <v-window v-model="connectionStep">
      <v-window-item :value="0" transition="scale-transition" origin="center center">
        <m-searching-card
          @troubleshoot="troubleshoot"
          @scan-for-marcos="scanForMARCos"
          @skipStep="skipStep"
        ></m-searching-card>
      </v-window-item>
      <v-window-item :value="1" transition="scale-transition" origin="center center">
        <m-detected-card
          @selectDevice="selectCurrentMARCo"
          @cancel="connectionStep--"
          :marcoName="marcoFound.name"
          :wifiStatus="marcoFound.wifiStatus"
          @skipStep="skipStep"
        ></m-detected-card>
      </v-window-item>

      <v-window-item :value="2" transition="scale-transition">
        <!--<v-card-title>Connect MARCo to a Network</v-card-title>
                <v-card-subtitle>For security of personal data, we ask you to do this again.</v-card-subtitle>-->
        <m-network-setup mode="deviceSetup" @skipStep="skipStep" color="white"></m-network-setup>
      </v-window-item>
      <v-window-item :value="3" transition="scale-transition">
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4" class="text--center">
            <v-progress-circular indeterminate color="white" size="96"> </v-progress-circular>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-card-title>{{
            sendingStatus ? 'Sending Data to MARCo...' : 'MARCo could not connect to the internet, try again?'
          }}</v-card-title>
          <v-spacer></v-spacer>
        </v-row>

        <v-card-actions>
          <v-btn
            color="white"
            class="mt-5 rounded-pill"
            @click="$emit('connect')"
            elevation="0"
            outlined
            v-if="searchingStatus"
            >Troubleshoot</v-btn
          >

          <v-btn
            color="white"
            class="mt-5 rounded-pill"
            @click="connectionStep--"
            elevation="0"
            outlined
            v-if="!searchingStatus"
            >Rescan</v-btn
          >
          <v-btn color="white" class="mt-5 rounded-pill" @click="skipStep" elevation="0" outlined> SKIP </v-btn>
        </v-card-actions>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import MARCoDetectedCard from './marcoDetectedCard.vue';
import MARCoSearchingCard from './searchingForMARCosCard.vue';
import networkSetupWindowVue from '../networkSetup/networkSetupWindow.vue';
import EventBus from '../../eventBus.js';

export default {
  name: 'marco-connection-dialog',
  components: {
    'm-detected-card': MARCoDetectedCard,
    'm-searching-card': MARCoSearchingCard,
    'm-network-setup': networkSetupWindowVue,
  },
  props: {
    connectionStep: {
      type: Number,
      default: 0,
    },
    sendingStatus: {
      type: Boolean,
      default: true,
    },
    marcoFound: {
      type: Object,
      default: function () {
        return {
          name: 'MARCo',
          wifiStatus: false,
        };
      },
    },
    connectedToMARCo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchingStatus: true,
    };
  },
  methods: {
    selectCurrentMARCo: async function () {
      this.$emit('selectDevice', this.marcoFound);
    },
    scanForMARCos: async function () {
      this.$launchBLE(10);
    },
    troubleshoot: async function () {
      this.$emit('troubleshoot');
      console.log('troubleshoot');
    },
    skipStep: async function () {
      this.$emit('skipStep');
      console.log('skipStep');
    },
  },
  beforeMount() {
    console.log('before mount');
    console.log(this.marcoFound);
    console.log('SHOW ME THE PROPS');
    console.log(this.$props);
  },
};
</script>
