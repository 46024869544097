<template>
  <v-list style="height: 40vh; overflow: auto; background: rgba(0, 0, 0, 0)">
    <div v-if="networks.length < 1">
      <v-skeleton-loader
        v-for="n in 4"
        type="list-item-avatar, divider"
        :key="`network-skeleton-${n}`"
      ></v-skeleton-loader>
    </div>
    <div v-if="networks.length > 0">
      <v-list-item v-for="(network, n) in networks" :key="`network-${n}`" @click.stop="selectNetwork(network)">
        <v-list-item-avatar>
          <v-icon :color="color">{{ `mdi-wifi-strength-${rssiCalculator(network.level)}` }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content :class="`${color}--text`">{{ network.SSID }}</v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
export default {
  name: 'm-network-list',
  props: {
    networks: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  methods: {
    selectNetwork(network) {
      this.$emit('selectNetwork', network);
    },
    rssiCalculator(level) {
      let num = parseInt(level);

      if (num > -50) {
        return 4;
      } else if (num > -60) {
        return 3;
      } else if (num > -70) {
        return 2;
      } else {
        return 1;
      }
    },
  },
};
</script>
