<template>
  <v-container color="transparent">
    <v-subheader class="white--text"> Is this your MARCo? </v-subheader>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-img :src="marcoTypes[marcoName].img"></v-img>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-card-title>{{ marcoTypes[marcoName].title }}</v-card-title>
    <v-row style="border-top: 1px solid white; border-bottom: 1px solid white">
      <v-col cols="6">
        <div>
          <span>Wifi:</span>
          <span><v-icon :color="wifiStatus ? 'green' : 'red'">mdi-circle</v-icon></span>
          <span>
            {{ wifiStatus ? 'Connected' : 'Not Connected' }}
          </span>
        </div>
      </v-col>
      <v-divider vertical color="white"></v-divider>
      <v-col cols="6">
        <div>MARCo's Nickname: <i>None</i></div>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-btn color="primary" class="mt-5 rounded-pill" @click="$emit('selectDevice')" elevation="0">Select</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="white" class="mt-5 rounded-pill" @click="$emit('cancel')" elevation="0" outlined>Rescan</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
export default {
  name: 'marco-dected-card',
  props: {
    marcoName: {
      type: String,
      default: 'marcoII',
    },
    wifiStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      marcoTypes: {
        marcoI: {
          img: require('../../../../public/assets/images/onboarding/marco_I.png'),
          title: 'MARCo-I',
        },
        marcoII: {
          img: require('../../../../public/assets/images/onboarding/marco_II.png'),
          title: 'MARCo-II',
        },
        marcoIILite: {
          img: require('../../../../public/assets/images/onboarding/marco_II.png'),
          title: 'MARCo-II Lite',
        },
      },
    };
  },
};
</script>
