<template>
  <v-app>
    <!--Login Screen-->
    <v-dialog fullscreen opacity="1" v-model="showFirstTimeDialog" persistent>
      <v-sheet dark class="white--text soothing-gradient-bg pa-2" style="width: 100vw; height: 100vh">
        <v-container>
          <v-snackbar
            v-model="toast"
            timeout="3500"
            elevation="0"
            bottom
            color="rgba(0,0,0,0)"
            transition="scale-transition"
            style="z-index: 9999"
          >
            <v-alert v-bind:type="alertType" elevation="8" width="100%" height="100%">{{ alertMsg }}</v-alert>
          </v-snackbar>
          <v-row align="center" style="height: 100vh">
            <v-window
              v-model="startupStep"
              style="overflow-y: auto; overflow-x: hidden; max-height: 100vh"
              :touchless="false"
            >
              <v-window-item :value="1" transition="fade-transition" style="overflow: visible">
                <v-card
                  style="
                    position: fixed;
                    top: 10vh;
                    height: 90vh;
                    border-radius: 30px 30px 0px 0px;
                    width: 100vw;
                    left: 0px;
                    overflow: auto;
                  "
                  class="pa-5 slide-in-bottom"
                  light
                  elevation="0"
                  :disabled="loadingUser"
                  :loading="loadingUser"
                >
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="3">
                      <v-img src="/assets/images/logo.png"></v-img>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <h2 class="mb-3">Welcome back!</h2>
                  <v-row v-if="loginMode > 0">
                    <v-text-field label="Name" filled rounded dense v-model="newName"></v-text-field>
                  </v-row>
                  <v-row class="mt-3">
                    <v-text-field
                      v-model="usrEmailLogin"
                      label="Email"
                      filled
                      rounded
                      dense
                      type="email"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      label="Password"
                      v-model="usrPasswordLogin"
                      filled
                      rounded
                      dense
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @keydown.enter="loginMode > 0 ? false : emailLogin()"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-row>
                  <v-row v-if="loginMode > 0">
                    <v-text-field
                      label="Confirm Password"
                      filled
                      rounded
                      dense
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                      @keydown.enter="createNewUserEmailPass()"
                      v-model="usrPasswordConfirm"
                    ></v-text-field>
                  </v-row>
                  <v-btn
                    block
                    rounded
                    color="blue lighten-1"
                    class="white--text my-2"
                    style="box-shadow: 0px 12px 36px -12px #0075e2"
                    @click="loginMode == 0 ? emailLogin() : createNewUserEmailPass()"
                    >{{ loginMode == 0 ? 'SIGN IN' : 'SIGN UP' }}<v-icon>mdi-arrow-right</v-icon></v-btn
                  >

                  <v-row>
                    <v-col cols="5">
                      <v-divider></v-divider>
                    </v-col>

                    <v-col cols="2"> OR </v-col>

                    <v-col cols="5">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="3">
                      <v-btn outlined elevation="0" style="border-color: #ccc" class="pa-6" @click="googleLogin">
                        <v-img src="/assets/images/google-login-icon.svg" width="32px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="3">
                      <v-btn outlined elevation="0" style="border-color: #ccc" class="pa-6" @click="facebookLogin">
                        <v-img src="/assets/images/facebook-login.svg" width="32px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="3">
                      <v-btn outlined elevation="0" style="border-color: #ccc" class="pa-6" @click="appleLogin">
                        <v-img src="/assets/images/apple-svgrepo-com.svg" width="32px"></v-img>
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col shrink
                      >{{ loginMode == 0 ? 'New?' : 'Have an account?' }}
                      <a
                        style="text-decoration: underline; text-decoration-style: dotted; color: rgb(18, 162, 8)"
                        @click="loginMode == 0 ? (loginMode = 1) : (loginMode = 0)"
                        >{{ loginMode == 0 ? 'Sign Up' : 'Sign In' }}</a
                      ></v-col
                    >
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row>
                    <v-col
                      ><a
                        style="text-decoration: underline; text-decoration-style: dotted; color: rgb(18, 162, 8)"
                        @click="getPrivacyTerms(true)"
                        >Privacy Policy</a
                      ></v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col
                      ><a
                        style="text-decoration: underline; text-decoration-style: dotted; color: rgb(18, 162, 8)"
                        @click="getPrivacyTerms(false)"
                        >Terms and Conditions</a
                      ></v-col
                    >
                  </v-row>
                </v-card>
              </v-window-item>

              <v-window-item :value="2" transition="fade-transition">
                <iframe src="https://embed.lottiefiles.com/animation/8558" style="border: none"></iframe>
                <v-card-title style="word-break: break-word" class="text-center text--blue-4"
                  >Before Starting With MARCo
                </v-card-title>

                <v-card-text
                  >Before beginning, please verify that you agree to our
                  <a style="font-size: 1.3em" @click="getPrivacyTerms(true)">Privacy Policy</a>
                  and our
                  <a style="font-size: 1.3em" @click="getPrivacyTerms(false)">Terms & Conditions</a></v-card-text
                >

                <v-checkbox
                  v-model="privacyTermsAgreement"
                  label="I agree to the terms and conditions and privacy policy."
                  style="padding-left: 15%; padding-right: 15%"
                ></v-checkbox>
                <v-btn
                  color="white"
                  elevation="0"
                  class="green--text rounded-pill mx-2"
                  block
                  @click="startupStep++"
                  :disabled="!privacyTermsAgreement"
                  >Next</v-btn
                >
              </v-window-item>

              <v-window-item :value="3" style="max-width: 90vw">
                <v-card-title style="word-break: break-word">How can MARCo best help you?</v-card-title>
                <v-card-subtitle
                  >You can optionally pick a goal below that you want to work on with MARCo. This can help guide your
                  sessions together and keep you on track to feel your best!</v-card-subtitle
                >
                <v-card
                  outlined
                  elevation="0"
                  class="pa-3 rounded-xl"
                  style="
                    max-height: 60vh;
                    overflow-y: auto;
                    border: 1px solid white;
                    background: rgba(255, 255, 255, 0);
                  "
                >
                  <AppITPDataLight
                    v-if="!isLoading"
                    :ITPs="ITPs"
                    @handleClick="handleItpClick($event)"
                  ></AppITPDataLight>
                </v-card>


                <v-card-actions>
                  <v-btn text rounded color="white" @click="startupStep -= 1">Back</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    color="white"
                    @click="
                      handleSaveItp();
                      startupStep += 1;
                      updateUser();
                    "
                    >Next</v-btn
                  >
                </v-card-actions>
              </v-window-item>

              <!--Create a new window item with value 3 for the goal selection-->
              <v-window-item :value="4" style="max-width: 90vw">
                <v-card-title>About Me</v-card-title>
                <v-card-subtitle
                  >Sharing some basic info helps MARCo personalize activities to you. MARCo will learn more about you
                  during your sessions together.</v-card-subtitle
                >
                <v-tabs v-model="likedTabs" background-color="transparent" fixed-tabs>
                  <v-tab href="#liked-tab-0">Favorite Animals</v-tab>
                  <v-tab href="#liked-tab-1">Hobbies</v-tab>
                  <v-tab href="#liked-tab-2">Faith/Religion</v-tab>
                </v-tabs>
                <v-tabs-items v-model="likedTabs" style="background: transparent; padding: 12px">
                  <v-tab-item key="4" value="liked-tab-0">
                    <v-combobox
                      label="Favorite Animals"
                      outlined
                      multiple
                      small-chips
                      v-model="rawUsrData.userPersonality.userClassifications['liked-animals']"
                      :items="animalsList"
                      item-text="name"
                      color="white"
                      class="rounded-xl"
                      item-value="value"
                      @keydown.enter.stop.prevent="$event.target.blur()"
                    ></v-combobox>
                  </v-tab-item>
                  <v-tab-item key="5" value="liked-tab-1">
                    <v-combobox
                      label="Hobbies"
                      outlined
                      multiple
                      small-chips
                      v-model="rawUsrData.userPersonality.userClassifications['classifications-hobbies']"
                      :items="hobbiesList"
                      item-text="name"
                      color="white"
                      class="rounded-xl"
                      item-value="value"
                      @keydown.enter.stop.prevent="$event.target.blur()"
                    ></v-combobox>
                  </v-tab-item>
                  <v-tab-item key="6" value="liked-tab-2">
                    <v-combobox
                      label="Faith/Religion"
                      color="white"
                      outlined
                      small-chips
                      v-model="rawUsrData.userPersonality.userClassifications['classifications-religion']"
                      :items="faithList"
                      item-text="name"
                      class="rounded-xl"
                      item-value="value"
                      @keydown.enter.stop.prevent="$event.target.blur()"
                    ></v-combobox>
                  </v-tab-item>
                </v-tabs-items>

                <v-card-actions>
                  <v-btn text rounded color="white" @click="startupStep -= 1">Back</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    color="white"
                    @click="
                      startupStep += 1;
                      updateUser();
                    "
                    >Next</v-btn
                  >
                </v-card-actions>
              </v-window-item>

              <v-window-item :value="5">
                <v-card-title>Choose your language:</v-card-title>
                <v-select
                  v-model="rawUsrData.userPersonality.userGeneral.spokenLanguage"
                  :items="langs"
                  item-text="language"
                  item-value="code"
                  dense
                  outlined
                  rounded
                  label="Language"
                  @change="translateText(previewingMsgDefault, rawUsrData.userPersonality.userGeneral.spokenLanguage)"
                >
                </v-select>
                <v-card-title>Choose MARCo's Voice:</v-card-title>
                <v-select
                  v-model="rawUsrData.userPersonality.userGeneral.preferredVoice"
                  :items="voiceProfs"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  rounded
                  label="Voice Profile"
                >
                </v-select>
                <v-btn
                  text
                  rounded
                  color="white"
                  block
                  @click="previewMARCoVoice(previewingMsg)"
                  :loading="previewingVoice"
                >
                  <v-icon>mdi-volume-high</v-icon> Preview
                </v-btn>
                <v-card-actions>
                  <v-btn text @click="startupStep -= 1">Back</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    color="white"
                    @click="
                      updateUserPreferences();
                      startupStep += 1;
                    "
                    >Next</v-btn
                  >
                </v-card-actions>
              </v-window-item>

              <v-window-item :value="6">
                <span class="text-center text--blue-4">What is your phone number?</span>
                <v-subheader
                  >MARCo will, by default, only contact you to share additional mental health resources if you need
                  outside support. You can opt in to receive regular messages regarding the goals and sessions you and
                  MARCo schedule together.</v-subheader
                >
                <v-subheader
                  >MARCo will, by default, only contact you to share additional mental health resources if you need
                  extra help. You can opt in to receive regular messages regarding the goals and sessions you and MARCo
                  schedule together.</v-subheader
                >

                <v-text-field
                  style="padding-left: 15px"
                  label="Phone Number:"
                  append-outer-icon="mdi-content-save-outline"
                  @click:append-outer="savePhone"
                  placeholder="5555555555"
                  v-model="rawUsrData.basicInformation['basic-user-phone-num']"
                  @input="phoneNumModified = true"
                >
                  <template v-slot:prepend>
                    <v-dialog v-model="countryCodeHandler.countryCodeDialog">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          :color="countryCodeHandler.selectedCountryCode.length > 0 ? 'black' : 'red'"
                          v-on="on"
                          @click="loadCountryCodes()"
                          >{{
                            countryCodeHandler.selectedCountryCode.length > 0
                              ? countryCodeHandler.selectedCountryCode.split('-')[0]
                              : '+?'
                          }}</v-btn
                        >
                      </template>
                      <v-card class="pa-2">
                        <v-card-title>Set country code:</v-card-title>
                        <v-divider></v-divider>
                        <v-text-field
                          v-model="countryCodeHandler.filter"
                          filled
                          rounded
                          dense
                          class="mx-4"
                          clearable
                          label="Filter"
                          style="margin-top: 5px"
                          prepend-icon="mdi-filter"
                        ></v-text-field>
                        <v-row class="ma-4" v-if="!countryCodeHandler.isLoaded">
                          <v-spacer></v-spacer>
                          <v-col cols="6">
                            <v-progress-circular color="blue" indeterminate></v-progress-circular>
                          </v-col>
                          <v-spacer></v-spacer>
                        </v-row>
                        <v-radio-group
                          style="max-height: 45vh; overflow: scroll"
                          v-if="countryCodeHandler.isLoaded"
                          v-model="countryCodeHandler.selectedCountryCode"
                        >
                          <v-radio
                            v-for="code in filteredCountryCodes"
                            :key="`${code['dial_code']}-${code.code}`"
                            :label="`${code['dial_code']} - ${code.name}`"
                            :value="`${code['dial_code']}-${code.code}`"
                          ></v-radio>
                        </v-radio-group>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-btn text @click="countryCodeHandler.countryCodeDialog = false">Cancel</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              rawUsrData.basicInformation.countryCode = countryCodeHandler.selectedCountryCode;
                              countryCodeHandler.countryCodeDialog = false;
                              updateUser();
                              updateMessagingSubscriptions();
                            "
                            >SET</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-text-field>

                <div
                  v-if="rawUsrData.basicInformation['basic-user-phone-num'].length > 8"
                  style="max-height: 60vh; overflow: auto"
                >
                  <v-card-title>Notification Preferences</v-card-title>
                  <v-card-subtitle>MARCo can text me to check in on me:</v-card-subtitle>

                  <v-radio-group
                    style="margin-left: 15px"
                    v-model="dailyContactSubscriptions.contactFrequency"
                    @change="updateMessagingSubscriptions()"
                  >
                    <v-radio label="Daily" :value="5"> </v-radio>
                    <v-radio label="Every other day" :value="4"> Every other day </v-radio>
                    <v-radio label="Weekly" :value="3"> Weekly </v-radio>
                    <v-radio label="Bi-Weekly" :value="2"> </v-radio>
                    <v-radio label="Monthly" :value="1"> </v-radio>
                    <v-radio label="Never" :value="0"> </v-radio>
                  </v-radio-group>

                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><v-icon>mdi-sleep</v-icon> Quiet Hours</v-list-item-title>
                      <v-list-item-subtitle>When is MARCo NOT allowed to text you?</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list two-line>
                    <template v-for="(quietHour, q) in dailyContactSubscriptions.quietHours">
                      <v-list-item :key="`quietTitle-${q}`">
                        <v-list-item-content
                          ><v-card-subtitle>Quiet Hours {{ q + 1 }}</v-card-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-dialog :ref="`deletequiethour-${q}`" height="200px" v-model="quietHour.showRemoveDialog">
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>

                            <v-card height="200" class="pa-2">
                              <v-card-title color="red darken-2">Remove this quiet hour?</v-card-title>
                              <v-card-subtitle>This action cannot be undone.</v-card-subtitle>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-btn text @click="quietHour.showRemoveDialog = false">NO</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="error"
                                  @click="
                                    quietHour.showRemoveDialog = false;
                                    removeQuietHour(q);
                                  "
                                  >YES</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-list-item-action>
                      </v-list-item>
                      <v-dialog
                        :ref="`timepickdialog-${q}`"
                        v-model="quietHour.showStartTimeDialog"
                        :return-value.sync="timepicktime"
                        persistent
                        width="290px"
                        :key="`quietstartend-dialog-${q}`"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="quietHour.startTime"
                            label="Start Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker v-if="quietHour.showStartTimeDialog" v-model="quietHour.startTime" full-width>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="quietHour.showStartTimeDialog = false"> Cancel </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              quietHour.showStartTimeDialog = false;
                              updateMessagingSubscriptions();
                            "
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                      <v-dialog
                        :ref="`timepickdialog2-${q}`"
                        v-model="quietHour.showEndTimeDialog"
                        persistent
                        width="290px"
                        :key="`quietend-dialog-${q}`"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="quietHour.endTime"
                            label="End Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker v-if="quietHour.showEndTimeDialog" v-model="quietHour.endTime" full-width>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="quietHour.showEndTimeDialog = false"> Cancel </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              quietHour.showEndTimeDialog = false;
                              updateMessagingSubscriptions();
                            "
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                      <v-divider :key="`ruleDivider-${q}`"></v-divider>
                    </template>
                  </v-list>
                  <v-list-item-group>
                    <v-divider></v-divider>
                    <v-list-item @click="addQuietHourRule">
                      <v-list-item-action>
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-card-subtitle>Add quiet hours</v-card-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list-item-group>
                </div>
                <!-- <v-select
                  v-model="contactFrequency"
                  :items="contactFrequencyItems"
                  item-text="text"
                  item-value="value"
                  dense
                  label="Text frequency"
                  :disabled="!rawUsrData.basicInformation['basic-user-phone-num']"
                  :error-messages="rawUsrData.basicInformation['basic-user-phone-num'] ? 'Choose how frequently you want MARCo to text you.':'Fill in a phone number under settings first.'"
                  :error="!rawUsrData.basicInformation['basic-user-phone-num']"
                  >
                  </v-select> -->

                <v-card-actions>
                  <v-btn text rounded color="white" @click="startupStep -= 1">Back</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text rounded color="white" @click="startupStep += 1">Next</v-btn>
                </v-card-actions>
              </v-window-item>

              <v-window-item :value="7">
                <span class="text-center text--blue-4">Add Emergency Contact Numbers:</span>
                <v-subheader
                  >These are people you want MARCo to contact in the event you are in an emergency.</v-subheader
                >

                <v-text-field
                  v-model="nextPhoneNum"
                  label="Add an emergency contact number"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addEmergencyContact"
                  @keydown="checkToAppendEmergency"
                >
                </v-text-field>
                <v-card-text>Your emergency contact #'s:</v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(num, n) in rawUsrData.basicInformation['basic-emergency-phone-num']"
                    :key="`emergencynum-${n}`"
                  >
                    <v-list-item-title>{{ num }}</v-list-item-title>
                    <v-list-item-action>
                      <v-btn
                        icon
                        color="error"
                        @click="rawUsrData.basicInformation['basic-emergency-phone-num'].splice(n, 1)"
                        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-card-actions>
                  <v-btn text rounded color="white" @click="startupStep -= 1">Back</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    rounded
                    color="white"
                    @click="
                      updateUser();
                      goToRobot();
                    "
                    >Next</v-btn
                  >
                </v-card-actions>
              </v-window-item>

              <v-window-item :value="8">
                <v-container style="width: 100vw">
                  <h5 class="text-center">That's all the info we need to get you started!</h5>
                  <h2 class="text-center my-3">Setting up MARCo now...</h2>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="5">
                      <v-progress-circular color="white" indeterminate size="96"></v-progress-circular>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-container>
              </v-window-item>
              <!-- <v-window-item :value="11">
      <span class="text-center text--blue-4">Is your MARCo set up and connected to wifi?</span> 

      <v-card-actions style="padding-left:5%;padding-right:5%;">
      
      
      <v-spacer></v-spacer>
      <v-btn elevation="0"  color="primary" @click="startupStep += 2">Yes</v-btn>
      <v-btn text  color="accent" @click="startupStep += 1">No</v-btn>
  </v-card-actions>
</v-window-item>

<v-window-item :value="12">
  <span class="text-center text--blue-4">Watch the following video to set up your MARCo and get it connected to wifi.</span> 

  <iframe width="320" height="220" src="https://www.youtube.com/embed/a6EZDlbDJAw" title="YouTube video player" frameborder="0" allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  
  <v-card-actions>
  <v-btn text   @click="startupStep -= 1">Back</v-btn>
  <v-spacer></v-spacer>
  <v-btn elevation="0" color="primary" @click="startupStep += 1">Next</v-btn>

  </v-card-actions>
  
  
</v-window-item>

<v-window-item :value="13">

<v-card-title>Great! Now let's connect...</v-card-title>

<v-list class="text-left">
  <v-list-item>1. On MARCo, tap "Wake MARCo" if you have not already</v-list-item>
  <v-list-item>2. On MARCo, tap the far left button that says "Sync from device"</v-list-item>
  <v-list-item>3. Enter the code that appears below (capitalization does not matter):</v-list-item>
</v-list>

<v-text-field label="Sync to MARCo" placeholder="QQQQQ" v-model="syncCode" :disabled="isSyncingApp">
  <template v-slot:append-outer>
      <v-btn elevation="0" color="secondary darken--2" :disabled="syncCode.length != 5 || isSyncingApp" @click="syncAccountHandler.syncToMARCo()"><v-icon>mdi-sync</v-icon> Sync</v-btn>
  </template>
</v-text-field>
<v-list-item></v-list-item>


</v-window-item>

<v-window-item :value="14">
<v-card-title>You're connected to MARCo!</v-card-title>

<v-card-subtitle>Get ready for a lifetime companion.</v-card-subtitle>

</v-window-item> -->
            </v-window>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
  </v-app>
</template>

<script>
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';

import axios from 'axios';
import AppITPDataLight from './ui/AppITPDataLight.vue';
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

import { EventBus } from './eventBus.js';
import { Capacitor } from '@capacitor/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { signInWithCredential } from 'firebase/auth';

import { Browser } from '@capacitor/browser';

import { Device } from '@capacitor/device';

var cliID = getUrlParam('cliid', false);

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  console.log(urlparameter);
  return urlparameter;
}

//BEGIN REQUIRED VARIABLES
var sfxAudio = new Audio();
sfxAudio.volume = 0.3;
const isDevenv = window.location.href.includes('localhost');
const isCordova = typeof cordova == 'object';

//CHECK WHAT BROWSER IT IS:
// Opera 8.0+
var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
var isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
var isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
var isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
var isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Edge (based on chromium) detection
var isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1;

// Blink engine detection
var isBlink = (isChrome || isOpera) && !!window.CSS;

var ua = window.navigator.userAgent;
var iOSBase = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
var webkitExists = !!ua.match(/WebKit/i);
var isiOSSafari = iOSBase && webkitExists && !ua.match(/CriOS/i);
let requestBody = {
  talkCase: false,
  dialogText: '',
  serverLoaded: false,
  cbtRequest: false,
  journalAnalysis: {
    isTrue: false,
    content: '',
    userLabels: [],
  },
  goodbyeScores: {
    isTrue: false,
    preNumber: 50,
    postNumber: 50,
  },
  initialize: false,
  responseTextOnly: false,
  cssrsForm: {
    isTrue: false,
    responseJSON: {},
    recommendationMessage: '',
    highestStep: 0,
  },
  sleepRequest: {
    isTrue: false,
    playAlarm: false,
    endAlarm: false,
    sleepQuality: {},
    finishSleepProtocol: false,
  },
  loginForm: {
    isTrue: false,
    firstName: '',
    lastName: '',
    password: '',
  },
  currentUser: {
    sessionId: 0,
    basicInformation: {
      'basic-given-name': 'default_name',
      'basic-last-name': 'default_last',
      'basic-gender': 'null',
      'basic-age': 'null',
      'basic-emergency-phone-num': [],
      'basic-user-phone-num': '',
      userID: '',
    },
    userPersonality: {
      userRelations: {
        family: 'null',
      },
      userClassifications: {
        'liked-animals': [],
        'classifications-hobbies': [],
        'classifications-job': 'null',
        'classifications-religion': 'null',
        'classification-ethnicity': 'null',
        'classification-sex-orient': 'null',
      },
      userGeneral: {
        spokenLanguage: 'en-US',
        preferredVoice: false,
      },
    },
    knowsCBT: false,
    requestTimestamp: '',
    timestampOffset: 0,
  },
  onboardResponse: false,
  interimSentiment: false,
  speak: false,
  speechVoice: 'en-US-Standard-D',
};

var usrTemplate = {
  basicInformation: {
    'basic-given-name': 'default_name',
    'basic-last-name': 'default_last',
    'basic-gender': 'null',
    'basic-age': 'null',
    'basic-emergency-phone-num': [],
    'basic-user-phone-num': '',
    userID: '',
    basicUserEmail: '',
  },
  userPersonality: {
    userClassifications: {
      'liked-animals': [],
      'classifications-hobbies': [],
      'classifications-job': 'null',
      'classifications-religion': 'null',
      'classification-ethnicity': 'null',
      'classification-sex-orient': 'null',
    },
    userGeneral: {
      spokenLanguage: 'en',
      preferredVoice: 'en-US-Standard-D',
      firstDate: '',
      lastSessionDate: '',
      dayStreak: 0,
      accountInfo: {
        accountType: 'Trial',
        accountKey: '',
        custid: '',
        numCredits: 0,
        accountManager: {
          name: 'MARCo Technologies',
          id: '',
        },
        startDate: '',
        renewalDate: '',
        MARCoins: 0,
        purchases: {
          backgrounds: { default: 'defaultBkgnd', all: [] },
          marcos: {
            default: 'DefaultMARCo2',
            all: ['DefaultMARCo2', 'DefaultMARCo'],
          },
          headAccessories: { default: 'none', all: [] },
          audioFiles: {
            all: [
              {
                sounds: [],
                name: 'No audio',
              },
              {
                name: 'Default Nature',
                sounds: [
                  'https://firebasestorage.googleapis.com/v0/b/newagent-e3a67.appspot.com/o/Audio%2Fbkgs%2Fnature-sounds.mp3?alt=media&token=b01aee89-6f95-4caf-bc56-ace9c21b00b0',
                ],
              },
            ],
            default: {
              name: 'Default Nature',
              sounds: [
                'https://firebasestorage.googleapis.com/v0/b/newagent-e3a67.appspot.com/o/Audio%2Fbkgs%2Fnature-sounds.mp3?alt=media&token=b01aee89-6f95-4caf-bc56-ace9c21b00b0',
              ],
            },
          },
        },
      },
    },
  },
  knowsCBT: false,
};

//END REQUIRED VARIABLES

export default {
  components: { AppITPDataLight },
  data: function () {
    return {
      isLoading: true,
      ITPs: null,
      selectedITPId: null,
      //First Time Dialog Data
      openingAnimation: {
        step1: false,
        step2: false,
        step3: false,
      },
      phoneNumModified: false,
      show1: false,
      loginMode: 0,
      requestBody: requestBody,
      welcomeScreen: false,
      openingChip: ['Coach', 'Counselor', 'Friend'],
      openingChipVal: 0,
      showFirstTimeDialog: true,
      privacyTermsAgreement: false,
      startupStep: 1,
      likedTabs: 0,
      contacts: ['DefaultMARCo2'],
      startingMARCoSrc: 'DefaultMARCo2',
      startingBkgSrc: 'marconline-background-1',
      startingAudio: {},
      audioContext: null,
      source: null,
      iosAudio: new Audio(),
      sfxHandler: {
        bkgAudio: new Audio(),
        bkgAudioVolume: 0.4,
        eventAudio: new Audio(),
        audioClick: function () {
          this.eventAudio.src = '../audio/sfx/ui-click.mp3';
          this.eventAudio.play();
        },
        audioWoosh: function () {
          this.eventAudio.src = '../audio/sfx/woosh-1.mp3';
          this.eventAudio.play();
        },
        audioPop: function () {
          this.eventAudio.src = '../audio/sfx/ui-popup.mp3';
          this.eventAudio.play();
        },
        audioCoin: function () {
          this.eventAudio.src = '/audio/sfx/coin-1.mp3';
          this.eventAudio.play();
        },
        audioPurchase: function () {
          this.eventAudio.src = '/audio/sfx/purchase-sfx-1.mp3';
          this.eventAudio.play();
        },
        switchAudioPref: (audio) => {
          console.log('Switching audio pref', audio);
          this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.audioFiles.default = {
            name: audio.displayName,
            sounds: audio.files,
          };
          setTimeout(() => {
            this.updateUser();
            this.sfxHandler.handleBkgAudio();
          }, 800);
        },
        handleBkgAudio: () => {
          let audioArray = this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.audioFiles.default.sounds;
          if (audioArray.length > 0) {
            this.bkgAudioVolume = this.rawUsrData.userPersonality.userGeneral.preferredBkgVolume;

            try {
              this.bkgAudio.pause();
            } catch (err) {
              console.log('There is no audio to pause');
            }

            if (typeof this.bkgAudio != 'object') {
              this.bkgAudio = new Audio();
            }
            this.bkgAudio.dataset.index = 0;

            this.bkgAudio.volume = this.rawUsrData.userPersonality.userGeneral.preferredBkgVolume
              ? this.rawUsrData.userPersonality.userGeneral.preferredBkgVolume
              : 0.3; //TODO: GET VALUE FROM A VOLUME SLIDER OR OTHER INPUT

            if (audioArray.length > 1) {
              this.bkgAudio.addEventListener('ended', () => {
                if (parseInt(this.bkgAudio.dataset.index) < audioArray.length - 1) {
                  this.bkgAudio.dataset.index = parseInt(this.bkgAudio.dataset.index) + 1;
                } else {
                  this.bkgAudio.dataset.index = 0;
                }

                this.bkgAudio.src = audioArray[parseInt(this.bkgAudio.dataset.index)];
                this.bkgAudio.pause();
                this.bkgAudio.load();
                this.bkgAudio.play();
              });
            } else {
              this.bkgAudio.loop = true;
            }

            this.bkgAudio.src = audioArray[parseInt(this.bkgAudio.dataset.index)];
            this.bkgAudio.load();
            this.bkgAudio.play();
          }
        },
      },
      alertMsg: '',
      alertType: 'info',
      toast: false,
      syncWindow: 1,
      newName: null,
      usrEmailLogin: null,
      usrPasswordLogin: null,
      usrPasswordConfirm: null,
      loadingUser: false,

      syncOptions: [
        {
          icon: 'mdi-sync-circle',
          title: 'Pair to last MARCo',
          action: () => {
            console.log('Quick pairing...');
            this.syncWindow = 4;
            setTimeout(() => {
              this.syncingDialog = false;
            }, 2500);
          },
        },
        {
          icon: 'mdi-numeric',
          title: 'Enter Code',
          action: () => {
            console.log('Entering code...');
            console.log(this.syncWindow);
            this.syncWindow = 3;
          },
        },
        {
          icon: 'mdi-plus-circle-multiple-outline',
          title: 'Setup New Device',
          action: () => {
            console.log('Adding new device...');
            this.syncWindow = 2;
          },
        },
      ],

      syncingDialog: false,
      customizeTab: 'visual-custom',
      audioList: [],
      marcosList: [],
      bkgList: [],
      rawUsrData: {
        basicInformation: {
          'basic-given-name': 'default_name',
          'basic-last-name': 'default_last',
          'basic-gender': 'null',
          'basic-age': 'null',
          'basic-emergency-phone-num': [],
          'basic-user-phone-num': '',
          userID: '',
          basicUserEmail: '',
        },
        userPersonality: {
          userClassifications: {
            'liked-animals': [],
            'classifications-hobbies': [],
            'classifications-job': 'null',
            'classifications-religion': 'null',
            'classification-ethnicity': 'null',
            'classification-sex-orient': 'null',
          },
          userGeneral: {
            spokenLanguage: 'en',
            preferredVoice: 'en-US-Standard-D',
            firstDate: '',
            lastSessionDate: '',
            dayStreak: 0,
            preferredBkgVolume: 0.3,
            accountInfo: {
              accountType: 'Trial',
              accountKey: '',
              accountManager: {
                name: 'MARCo Technologies',
                id: '',
              },
              startDate: '',
              renewalDate: '',
              MARCoins: 0,
              desktopThemeNum: 1,
              purchases: {
                backgrounds: { default: 'defaultBkgnd', all: [] },
                marcos: { default: 'default', all: [] },
                headAccessories: { default: 'none', all: [] },
                audioFiles: {
                  default: {
                    name: 'Default Nature',
                    sounds: [
                      'https://firebasestorage.googleapis.com/v0/b/newagent-e3a67.appspot.com/o/Audio%2Fbkgs%2Fnature-sounds.mp3?alt=media&token=b01aee89-6f95-4caf-bc56-ace9c21b00b0',
                    ],
                  },
                  all: [
                    { name: 'No audio', sounds: [] },
                    {
                      name: 'Default Nature',
                      sounds: [
                        'https://firebasestorage.googleapis.com/v0/b/newagent-e3a67.appspot.com/o/Audio%2Fbkgs%2Fnature-sounds.mp3?alt=media&token=b01aee89-6f95-4caf-bc56-ace9c21b00b0',
                      ],
                    },
                  ],
                },
              },
            },
          },
        },
        knowsCBT: false,
      },
      dailyContactSubscriptions: {
        contactFrequency: 0,
        quietHours: [],
      },

      setupOption: -1,
      setAsDefault: false,
      countryCodeHandler: {
        countryCodes: [],
        isLoaded: false,
        filter: null,
        selectedCountryCode: '',
        countryCodeDialog: false,
      },

      syncCode: '',
      isSyncingApp: false,
      langs: [],
      voiceProfs: [],
      previewingVoice: false,
      previewingMsg: 'This is what your MARCo will sound like',

      nextPhoneNum: '',
      confirmCloseSync: false,
      privacyTermsData: '',
      showPrivacyTerms: false,
      isPrivacyPolicy: true,

      //Other data
      deleteAccountModal: false,
      deleteLoading: false,
      upgradeBtnText: 'MANAGE',
      usrAccountType: 'Full - Company Managed',
      subscriptionCode: '',

      speechBubInterval: 0,
      callMARCoTransition: false,
      eventAudio: new Audio(),
      audioCall: function () {
        this.eventAudio.src = '../../../audio/sfx/marco-call-2.mp3';
        this.eventAudio.play();
      },

      networks: [],
      currNetwork: {},
      networkSetupStep: 0,
      showNetworkPwd: false,
      wifiQR: false,
      currSSID: '',
      currNetworkPWD: '',
      currNetworkSecurity: '',
      networkDialog: false,
    };
  },
  computed: {
    filteredCountryCodes() {
      if (typeof this.countryCodeHandler.filter !== 'string') {
        console.log('Returning the country codes');
        return this.countryCodeHandler.countryCodes;
      } else {
        console.log('Filtering the country codes');
        return this.countryCodeHandler.countryCodes.filter((code) =>
          code.name.toLowerCase().includes(this.countryCodeHandler.filter.toLowerCase())
        );
      }
    },
  },
  methods: {
    handleItpClick(id) {
      this.selectedITPId = id;
    },
    handleSaveItp() {
      if (!this.selectedITPId) return;
      console.log('save itp');
      console.log(`${this.$host}/v1/ITP/${this.rawUsrData.basicInformation.userID}/add/${this.selectedITPId}`);
      axios
        .post(`${this.$host}/v1/ITP/${this.rawUsrData.basicInformation.userID}/add/${this.selectedITPId}`, {
          startDate: new Date().toISOString().substr(0, 10),
        })
        .then((response) => {
          this.$notification.scheduleNotificationAfterCreateItp(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        case 'md':
          return false;
        case 'lg':
          return false;
        case 'xl':
          return false;
      }
      //ADD ANY ADDITIONAL METHODS TO BE CALLED BY COMPONENTS, DATA, OR OTHER METHODS HERE
    },
    addQuietHourRule: function () {
      this.dailyContactSubscriptions.quietHours.splice(this.dailyContactSubscriptions.quietHours.length, 0, {
        startTime: null,
        endTime: null,
        showStartTimeDialog: false,
        showEndTimeDialog: false,
        isActive: false,
        showRemoveDialog: false,
      });

      this.$set(
        this.dailyContactSubscriptions.quietHours[this.dailyContactSubscriptions.quietHours.length - 1],
        'isActive',
        true
      );
    },
    removeQuietHour: function (index) {
      this.dailyContactSubscriptions.quietHours.splice(index, 1);
      this.updateMessagingSubscriptions();
      this.showToast('Removed quiet hours', 'success');
    },
    updateMessagingSubscriptions: async function () {
      this.dailyContactSubscriptions.usrPhone = this.rawUsrData.basicInformation['basic-user-phone-num'];
      this.dailyContactSubscriptions.usrCountryCode = this.countryCodeHandler.selectedCountryCode;
      let contactTimeOffsetRef = new Date();
      this.dailyContactSubscriptions.timezoneOffset = contactTimeOffsetRef.getTimezoneOffset();
      //this.dailyContactSubscriptions.lastContact = new Timestamp();
      this.dailyContactSubscriptions.usrName = this.rawUsrData.basicInformation['basic-given-name'];

      let req = {
        usrID: this.rawUsrData.basicInformation.userID,
        settings: JSON.parse(JSON.stringify(this.dailyContactSubscriptions)),
      };
      const rawResponse = await fetch(`${this.$host}/setnotificationsub`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });
      var q = await rawResponse.json();
      console.log(q);
    },

    loadMessagingSubscriptions: async function () {
      if (typeof this.rawUsrData.basicInformation.countryCode === 'string') {
        this.countryCodeHandler.selectedCountryCode = this.rawUsrData.basicInformation.countryCode;
      }
      console.log('The contact subscriptions BEFORE loading data.');
      console.log(this.dailyContactSubscriptions);
      let req = { usrID: this.rawUsrData.basicInformation.userID };
      const rawResponse = await fetch(`${this.$host}/getnotificationsub`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });
      var q = await rawResponse.json();
      for (var subKey in this.dailyContactSubscriptions) {
        if (typeof q[subKey] !== 'undefined') {
          this.dailyContactSubscriptions[subKey] = q[subKey];
        }
      }
      console.log('The contact subscriptions AFTER loading data.');
      console.log(this.dailyContactSubscriptions);
    },

    loadBkgs: async function () {
      let bkgDoc = await getDoc(doc(this.$db, '/public/marconlineStore/backgrounds/Jy8HDWJkpuXv8i6iG2Jx'));
      if (bkgDoc.exists()) {
        let bkgList = bkgDoc.data();

        for (let b = 0; b < bkgList.backgrounds.length; b++) {
          this.bkgList.push(bkgList.backgrounds[b]);
        }
      }
    },
    getPrivacyTerms: async function (isPrivacy = true) {
      this.isPrivacyPolicy = isPrivacy;
      this.showPrivacyTerms = true;
      if (isPrivacy) {
        const privacyRaw = await fetch(`${this.$host}/privacy-policy`, {
          method: 'GET',
        });
        var privacyData = await privacyRaw.text();
        var parser = new DOMParser();
        var doc = parser.parseFromString(privacyData, 'text/html');
        this.privacyTermsData = new XMLSerializer().serializeToString(doc);
      } else {
        const termsRaw = await fetch(`${this.$host}/terms-and-conditions`, {
          method: 'GET',
        });
        var termsData = await termsRaw.text();
        var parser = new DOMParser();
        var doc = parser.parseFromString(termsData, 'text/html');

        this.privacyTermsData = new XMLSerializer().serializeToString(doc);
      }
    },

    serverRequest: async function (req, callback = false) {
      req.cbtRequest = this.globalCBTRequest;
      req.currentUser.requestTimestamp = new Date();
      req.currentUser.timestampOffset = req.currentUser.requestTimestamp.getTimezoneOffset();
      console.log('Requesting the server...');
      console.log('The request is:');
      console.log(req);
      var responseJSON;

      const rawResponse = await fetch(`${this.$host}/clicked`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });
      responseJSON = await rawResponse.json();
      if (callback) {
        callback(responseJSON);
        if (this.isSyncingApp) {
          this.updateRealtimeResponseSync(responseJSON);
        }
      } else {
        if (this.isSyncingApp) {
          this.updateRealtimeResponseSync(responseJSON);
        }
        this.paramHandler(responseJSON);
        console.log(JSON.stringify(responseJSON));
      }
    },
    appendText: async function (response, callback) {
      const chatRef = this.isMobile() ? this.$refs.mobilechatform : this.$refs.desktopchatform;
      if (response.responseText && response.responseText.length > 0) {
        //Preprocess text in case there are additional splits

        let currText = response.responseText;
        let finalText = [];

        for (let i = 0; i < currText.length; i++) {
          if (currText[i].includes('<split-speech/>')) {
            let nextArray = currText[i].split('<split-speech/>');
            for (let n = 0; n < nextArray.length; n++) {
              finalText.push(nextArray[n]);
            }
          } else {
            //finalText.push(currText[i]);
            let textArr = [];
            let text = currText[i];

            if (text[text.length - 1] !== '.' && text[text.length - 1] !== '!' && text[text.length - 1] !== '?') {
              text += '.';
            }
            textArr = text.match(/[^\.!\?]+[\.!\?]+/g);

            finalText.push(textArr[0]);

            for (let j = 1; j < textArr.length; j++) {
              if (finalText[finalText.length - 1].length + textArr[j].length < 312) {
                finalText[finalText.length - 1] += textArr[j];
              } else {
                finalText.push(textArr[j]);
              }
            }
          }
        }
        if (this.langCode !== 'en') {
          finalText = await this.translateText(finalText, this.langCode);

          console.log(response.responseText);
          let currentResponse = 0;
          let addText = setInterval(() => {
            if (currentResponse == finalText.length) {
              /*if(isMobile)
									{
										document.getElementById("speechbubbletext").innerHTML = response.responseText[0].substr(0,137) + "...";
										document.getElementById("speech-prong").click();
										
									}*/
              clearInterval(addText);
            } else {
              this.chatHandler.appendMARCosResponse(finalText[currentResponse]);
              currentResponse += 1;
            }
          }, 1500);

          setTimeout(() => {
            this.speechBubText = finalText.join(' ');
            return callback(finalText);
          }, 1500);
        } else {
          console.log(response.responseText);
          let currentResponse = 0;
          let addText = setInterval(() => {
            if (currentResponse == finalText.length) {
              /*if(isMobile)
									{
										document.getElementById("speechbubbletext").innerHTML = response.responseText[0].substr(0,137) + "...";
										document.getElementById("speech-prong").click();
										
									}*/
              clearInterval(addText);
            } else {
              this.chatHandler.appendMARCosResponse(finalText[currentResponse]);
              currentResponse += 1;
            }
          }, 1500);

          setTimeout(() => {
            this.speechBubText = finalText.join(' ');
            return callback(finalText);
          }, 1500);
        }
      }
    },
    appendTextWithAudio: async function (response, emote = 'netural', callback = false) {
      if (!this.isSyncingApp) {
        this.appendText(response, (finalText) => {
          try {
            this.marcoTalk(finalText, emote, this.currVoice);
          } catch (err) {
            console.log(err);
          }
          if (callback) {
            return callback();
          }
        });
      } else {
        let tempSpeech = [];
        if (typeof response.responseText !== 'object') {
          tempSpeech.push(response.responseText);
        } else {
          tempSpeech = response.responseText;
        }
        console.log('The speech is: ' + tempSpeech);

        let spokenSpeech = await this.translateText(tempSpeech, this.langCode);
        console.log(spokenSpeech);
        let speechJSON = JSON.parse(JSON.stringify(requestBody));

        speechJSON.echo = spokenSpeech;

        console.log(speechJSON);
        this.serverRequest(speechJSON);
      }
    },
    showToast(msg, type = 'info', timeout = 3500) {
      this.alertMsg = msg;
      this.alertType = type;
      this.toast = true;
      setTimeout(() => {
        this.toast = false;
      }, timeout);
    },
    paramHandler: async function (response) {
      //Do something eventually

      var speechEmote = 'happy'; //TODO: Fix this so that it plays MARCo's actual emotion
      if (response.marcosEmotion) {
        speechEmote = response.marcosEmotion;
      }

      const chatRef = this.isMobile() ? this.$refs.mobilechatform : this.$refs.desktopchatform;
      const windowRef = !this.isMobile() ? this.$refs.desktoppopup : this.$refs.mobilePopup;
      console.log(windowRef);
      console.log(response);
      this.appendText(response, (finalText) => {
        this.marcoTalk(finalText, speechEmote, this.currVoice);
        if (response.startTalkSupport) {
          this.globalCBTRequest = true;
          this.chatHandler.switchToTalkSupport();

          if (!requestBody.currentUser.knowsCBT) {
            requestBody.currentUser.knowsCBT = true;
            this.rawUsrData.knowsCBT = true;

            this.updateUser();
          }
        }
        if (response.launchStartupQuestionnaire) {
          setTimeout(() => {
            this.showEmotionForm = true;
          }, 4500);
        }
        if (response.endTalkSupport) {
          this.globalCBTRequest = false;
          this.chatHandler.endTalkSupport();
        }
        if (response.quickReplyArray) {
          this.chatHandler.appendQuickReplies(response.quickReplyArray);
        }
        if (response.embedMedia.isTrue) {
          this.mediaSrc = response.embedMedia.mediaSource;
        }
        if (response.cbtGoals) {
          this.cbtGoals = response.cbtGoals;
        }
        if (response.clickElement.isTrue) {
          if (response.clickElement.elementID != 'close_media') {
            this.chatHandler.appendClickElement(response.clickElement.elementID);
          }
          //document.getElementById(response.clickElement.elementID).click();
          /*if(isMobile){
					$("#mobileMediaBar").fadeIn();
				} */
        }
        if (response.intentName) {
          this.checkActivity(response.intentName);
        }

        if (response.currEmotions) {
          this.emoteArray = response.currEmotions;
          //openActivityScheduler(response.currEmotions);
        }

        if (response.onboardingEvents) {
          if (this.dailyTracking.chosenActs.length < 1) {
            this.dailyTracking.chosenActs.push({
              name: 'Play two truths and a lie',
              whatToSay: 'Play two truths and a lie!',
              marcoins: 125,
              intent: 'Online Onboarding - Two Truths and Lie Start',
            });
          }
          this.onboardingEvents = [];
          for (let i = 0; i < response.onboardingEvents.length; i++) {
            let fields = response.onboardingEvents[i].structValue.fields;

            this.onboardingEvents.push(fields.target.stringValue);
          }
        }

        if (response.switchWindow.isTrue) {
          //Based on the number response of the switchWindow value, change the current window to a different page
          //KEY: 0 = home page; 1 = meditation page; 2 = add user page; 3 = psychotherapy page; 4 = boot page; 5 = settings page; 6 = wifi settings page
          switch (response.switchWindow.switchNum) {
            //TODO: Insert case numbers
            /* case 0:
                    document.location.href = '../views/main.html'; 
                    break;
                case 1:
                    document.location.href = '../views/med-page.html'; 
                    break;
                case 2: 
                    document.location.href = '../views/add-user-form.html';
                    break;
                case 3:
                    document.location.href = '../views/cbt-page.html';
                    break;
                case 4:
                    document.location.href = '../views/boot-page.html';
                    break;
                case 5:
                    document.location.href = '../views/settings-page.html';
                    break;
                case 6:
                    document.location.href = '../views/wifi-page.html';
                    break;
		         case 7:
		          document.location.href='../views/pomodoro-page-25.html';
		            break;
                case 8:
		          document.location.href='../views/pomodoro-page-2HR.html';
		            break;
                case 9:
		          document.location.href='../views/high-alert-page.html';
		          break;
                case 10:
		          document.location.href='../views/journal-form.html';
		          break;*/
            case 7:
              this.popupEnabled = true;
              this.currPopupTitle = 'Pomodoro Timer';
              this.pomodoroCase = 0;
              this.windowCase = 7;
              break;
            case 8:
              this.popupEnabled = true;
              this.currPopupTitle = 'Pomodoro Timer';
              this.pomodoroCase = 1;
              this.windowCase = 7;
              break;
            case 9:
              this.highalertHandler.initializeCSSRS();
              this.popupEnabled = true;
              this.currPopupTitle = 'You are not alone';
              this.windowCase = 6;
              break;
            case 11:
              //This is the goodbye form
              this.popupEnabled = true;
              this.currPopupTitle = 'Feedback';
              this.windowCase = 11;

              break;
            /*case 12:
					document.location.href='../views/sleep-page.html';
                    	break;*/

            //TODO: Add cases for other pages
            default:
              //document.location.href = '../views/main.html';
              //document.getElementById('Case: ' + jsonResponse.switchWindow.isTrue);
              break;
          }
        }
      });
    },
    translateText: async function (msg, langCode) {
      let formattedMsg;
      if (typeof msg !== 'object') {
        formattedMsg = [msg];
      } else {
        formattedMsg = msg;
      }

      let req = { text: formattedMsg };
      const rawResponse = await fetch(`${this.$host}/translate/${langCode}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });
      var q = await rawResponse.json();
      this.previewingMsg = q.text[0];
      return q.text[0];
    },
    previewMARCoVoice: function (speech = `Hello`) {
      let speechJSON = JSON.parse(JSON.stringify(requestBody));
      this.previewingVoice = true;

      speechJSON.speak = speech;
      speechJSON.speechVoice = this.rawUsrData.userPersonality.userGeneral.preferredVoice;

      this.serverRequest(speechJSON, (response) => {
        if (!isSafari && !isiOSSafari) {
          this.toAudioBuff(response.audioContent.data, (buff) => {
            this.playSound(buff, (event) => {
              //document.getElementById("speech-prong").click();
              this.previewingVoice = false;
            });
          });
        }
        //ELSE it is safari and we need to use an audio tag instead
        else {
          this.arrayBufferToBase64(response.audioContent.data, (base64) => {
            let audioDatURI = 'data:audio/wav;base64,' + base64;
            this.iosAudio.src = audioDatURI;
            this.iosAudio.play();
            this.iosAudio.addEventListener('ended', () => {
              this.iosAudio.currentTime = 0;
              // console.log("ended");
              this.previewingVoice = false;
            });
          });
        }
      });
    },
    marcoTalk: function (speech = `Hello`, emotion = 'happy', voiceProfID = 'en-US-Standard-D') {
      if (this.marcoIsCalled) {
        const marcoAvatar = this.isMobile() ? this.$refs.marcomobilewindow : this.$refs.marcowindow;
        //TODO: Add code to make MARCo actually speak
        requestBody.dialogText = '';
        let tempSpeech = ``;
        if (typeof speech == 'object') {
          console.log('it is an object');
          console.log(speech);
          tempSpeech += `<speak>`;
          for (let m = 0; m < speech.length; m++) {
            let next = speech[m].replace('<speak>', '');
            next = next.replace('</speak>', '');
            tempSpeech += next + ' ';
          }

          tempSpeech += `</speak>`;
        } else {
          tempSpeech = `${speech}`;
        }
        //	console.log("The speech is: " + tempSpeech);

        let spokenSpeech = tempSpeech;

        try {
          marcoAvatar.marcoEyes.animations.normal();
        } catch (err) {
          console.warn(err);
        }

        if (this.audioOn && !this.isSyncingApp) {
          //	console.log(`The spoken speech is ${spokenSpeech}`);

          //utterance.voice = document.getElementById("voiceSelector")?speechSynthesis.getVoices[document.getElementById("voiceSelector").value]:speechSynthesis.getVoices()[voiceProfID];
          let speechJSON = JSON.parse(JSON.stringify(requestBody));

          speechJSON.speak = spokenSpeech;
          speechJSON.speechVoice = this.rawUsrData.userPersonality.userGeneral.preferredVoice;

          this.serverRequest(speechJSON, (response) => {
            marcoAvatar.marcoHead.animations.rest();

            if (emotion == 'neutral') {
              marcoAvatar.marcoMouth.animations.neutralTalk();
            } else if (emotion == 'sad') {
              marcoAvatar.marcoMouth.animations.frownTalk();
            } else if (emotion == 'happy') {
              marcoAvatar.marcoMouth.animations.smileTalk();
            } else {
              marcoAvatar.marcoMouth.animations.smileTalk();
            }
            //console.log("The audio content is " + response.audioContent.data)
            if (!isSafari && !isiOSSafari) {
              this.toAudioBuff(response.audioContent.data, (buff) => {
                this.playSound(buff, (event) => {
                  //document.getElementById("speech-prong").click();
                  marcoAvatar.marcoHead.animations.bobRotate();
                  if (emotion == 'neutral') {
                    marcoAvatar.marcoMouth.animations.neutral();
                  } else if (emotion == 'sad') {
                    marcoAvatar.marcoMouth.animations.frown();
                  } else if (emotion == 'happy') {
                    marcoAvatar.marcoMouth.animations.smile();
                  } else {
                    marcoAvatar.marcoMouth.animations.smile();
                  }
                });
              });
            }
            //ELSE it is safari and we need to use an audio tag instead
            else {
              this.arrayBufferToBase64(response.audioContent.data, (base64) => {
                let audioDatURI = 'data:audio/wav;base64,' + base64;
                this.iosAudio.src = audioDatURI;
                this.iosAudio.play();
                this.iosAudio.addEventListener('ended', () => {
                  this.iosAudio.currentTime = 0;
                  // console.log("ended");
                  marcoAvatar.marcoHead.animations.bobRotate();
                  if (emotion == 'neutral') {
                    marcoAvatar.marcoMouth.animations.neutral();
                  } else if (emotion == 'sad') {
                    marcoAvatar.marcoMouth.animations.frown();
                  } else if (emotion == 'happy') {
                    marcoAvatar.marcoMouth.animations.smile();
                  } else {
                    marcoAvatar.marcoMouth.animations.smile();
                  }
                });
              });
            }
          });
        }
      }
    },

    savePhone() {
      //Save the user's phone number and alert that it is done
      if (this.countryCodeHandler.selectedCountryCode.length > 0) {
        this.updateUser();
        this.updateMessagingSubscriptions();
        this.showToast('Saved your phone number!', 'success', 1500);
        this.phoneNumModified = false;
      } else {
        this.showToast("Please add a country code by pressing the '+?' icon", 'warning', 3500);
      }
    },
    loadMARCos: async function () {
      let marcosDoc = await getDoc(doc(this.$db, '/public/marconlineStore/marcos/FhUfPmJacY6JOTxgKcXn'));
      if (marcosDoc.exists()) {
        let marcosList = marcosDoc.data();

        for (let m = 0; m < marcosList.marcos.length; m++) {
          if (!this.contacts.includes(marcosList.marcos[m].backendName)) {
            this.marcosList.push(marcosList.marcos[m]);
            this.marcosList[m].purchased = false;
          } else {
            this.marcosList.unshift(marcosList.marcos[m]);
            this.marcosList[m].purchased = true;
          }
        }
      }
    },
    loadAudios: async function () {
      let audioDoc = await getDoc(doc(this.$db, '/public/marconlineStore/audio/audioPaths'));
      if (audioDoc.exists()) {
        let audioList = audioDoc.data();

        for (let a = 0; a < audioList.audioFiles.length; a++) {
          this.audioList.push(audioList.audioFiles[a]);
        }
      }
    },

    firstTimeStepper: function () {
      console.log(this.setupOption);
      switch (this.setupOption) {
        case 0:
          this.startupStep += 2;
          this.maxStartupSteps += 6;
          break;
        case 1:
          this.startupStep += 1;
          setTimeout(() => {
            EventBus.$emit('loadingOverlay', true);
            this.$router.push('/?startupmessage=firstTimeUser');
          }, 3500);
          break;
      }
    },
    goToRobot: function () {
      this.startupStep++;
      setTimeout(() => {
        EventBus.$emit('loadingOverlay', true);
        this.$router.push('/?startupmessage=firstTimeRobot');
      }, 3500);
    },

    toAudioBuff: function (buf, callback) {
      var ab = new ArrayBuffer(buf.length);
      var view = new Uint8Array(ab);
      for (var i = 0; i < buf.length; ++i) {
        view[i] = buf[i];
      }
      this.audioContext.decodeAudioData(ab, (buffer) => {
        return callback(buffer);
      });
    },

    arrayBufferToBase64: function (buffer, callback) {
      let binary = '';
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      callback(window.btoa(binary));
    },
    playSound: function (buffer, callback) {
      if (this.source) {
        try {
          this.source.stop();
        } catch (err) {
          console.log(err);
        }
      }
      this.source = this.audioContext.createBufferSource(); // creates a sound source
      this.source.buffer = buffer; // tell the source which sound to play
      this.source.connect(this.audioContext.destination); // connect the source to the context's destination (the speakers)
      this.source.start ? this.source.start(0) : this.source.noteOn(0); // play the source now
      this.source.onended = function (event) {
        return callback(event); // note: on older systems, may have to use deprecated noteOn(time);
      };
    },
    selectNetwork(network) {
      this.currNetwork = network;
      this.currSSID = network.SSID;
      this.networkSetupStep++;
    },
    openNetworkDialog: async function () {
      this.networkDialog = true;
      if (typeof WifiWizard2 == 'object') {
        try {
          this.networks = await WifiWizard2.scan();
        } catch (err) {
          console.log(err);
        }
      }
    },
    chooseNetwork: async function () {
      this.networkSetupStep++;
      if (typeof WifiWizard2 == 'object') {
        try {
          this.networks = await WifiWizard2.scan();
        } catch (err) {
          console.log(err);
        }
      }
    },
    generateNetworkQR() {
      this.networkSetupStep++;
      this.wifiQR = false;

      if (typeof QRCode === 'undefined') {
        var s = document.createElement('script'); // Create a script element
        s.type = 'text/javascript'; // optional in html5
        s.async = true; // asynchronous? true/false
        s.src = '../../../js/qrcode.js';
        var fs = document.getElementsByTagName('script')[0]; // Get the first script
        fs.parentNode.insertBefore(s, fs);
      }

      let qrGenerator = setInterval(() => {
        try {
          console.log(typeof QRCode);
          document.getElementById('wifiQRCode').innerHTML = '';

          this.wifiQR = new QRCode(document.getElementById('wifiQRCode'), {
            text: `WIFI:WPA;${this.currNetworkPWD.length > 0 ? 'P:' + this.currNetworkPWD + ';' : ''}S:${
              this.currSSID
            };U:${this.rawUsrData.basicInformation.userID}`,
            width: 300,
            height: 500,
          });
          clearInterval(qrGenerator);
        } catch (err) {
          console.log(err);
        }
      }, 300);
    },
    getCurrentNetwork: async function () {
      if (typeof WifiWizard2 == 'object') {
        try {
          this.currSSID = await WifiWizard2.getConnectedSSID();
          console.log(this.currSSID);
          this.networkSetupStep = 3;
        } catch (err) {
          console.log(err);
        }
      }
    },
    rssiCalculator(level) {
      let num = parseInt(level);

      if (num > -50) {
        return 4;
      } else if (num > -60) {
        return 3;
      } else if (num > -70) {
        return 2;
      } else {
        return 1;
      }
    },
    launchOnboarding: async function () {
      this.welcomeScreen = true;

      setTimeout(() => {
        this.openingAnimation.step1 = true;
      }, 2400);
      setTimeout(() => {
        this.openingAnimation.step2 = true;
        let openingChipInterval = setInterval(() => {
          if (this.openingChipVal == this.openingChip.length) {
            clearInterval(openingChipInterval);
          } else {
            this.openingChipVal++;
          }
        }, 1600);
      }, 2900);
      setTimeout(() => {
        this.openingAnimation.step3 = true;
      }, 9000);
    },
    createNewUserEmailPass: async function () {
      var newName = this.newName;
      var newEmail = this.usrEmailLogin;
      var newPwd = this.usrPasswordLogin;
      var confPwd = this.usrPasswordConfirm;

      console.log('Made it to the new user pass function');

      if (
        newName.length > 1 &&
        newEmail.length > 1 &&
        newEmail.includes('@') &&
        newPwd.length > 5 &&
        newPwd == confPwd
      ) {
        this.loadingUser = true;
        console.log('Loading user...');
        createUserWithEmailAndPassword(this.$auth, newEmail, newPwd)
          .then(async (userCredential) => {
            var user = userCredential.user;

            console.log('The user is', user);

            if (cliID) {
              let docRef = await getDoc(doc(this.$db, `/productKeys/${cliID}`));

              if (docRef.exists) {
                let d = docRef.data();
                if (newEmail.includes(d.emailTrailing)) {
                  if (d.users.length >= d.maxClients - 1) {
                    this.showToast(
                      "Sorry, it seems your organization has used all its subscriptions. Contact your organization's administrator to find out more.",
                      'warning',
                      3500
                    );
                    this.loadingUser = false;
                  } else {
                    d = docRef.data();
                    //document.getElementsByClassName("flexdivchild trialend")[1].style="display:none;"
                    //document.getElementsByClassName("flexdivchild trialend")[0].innerHTML=`<p>This subscription to MARConline is sponsored by ${entDoc.ownerOrg}, so you agree to the usage terms and any applicable payment agreements with ${entDoc.ownerOrg}. Click next to register your account.</p>`;
                    this.rawUsrData = JSON.parse(JSON.stringify(usrTemplate));
                    this.rawUsrData['basicInformation']['basic-given-name'] = newName.split(' ')[0]
                      ? newName.split(' ')[0]
                      : 'Apple';
                    this.rawUsrData['basicInformation']['basic-last-name'] = this.newName.split(' ')[1]
                      ? newName.split(' ')[1]
                      : 'User';
                    this.rawUsrData['basicInformation']['basicUserEmail'] = newEmail;
                    this.rawUsrData.userPersonality.userGeneral.firstDate = Timestamp.fromDate(new Date());
                    this.rawUsrData.basicInformation.userID = user.uid;
                    //  startupMessage = "firstTimeUser";

                    console.log(this.rawUsrData);
                    this.updateUser();

                    d.users.push({
                      name: newName,
                      id: user.uid,
                      email: user.email,
                    });
                    this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full';

                    setDoc(doc(this.$db, `/productKeys/${cliID}`), d);
                    //window.location.href = `/?startupmessage=firstTimeUser&${reactURLComp}`;

                    setTimeout(() => {
                      this.loadingUser = false;
                      this.startupStep++;
                    }, 1500);

                    // ...
                  }
                } else {
                  this.showToast(
                    'Sorry, your email does not belong to the proper organization. Try again with an email from your organization.',
                    'warning',
                    6000
                  );
                  this.loadingUser = false;
                }
              }
            } else {
              console.log('Creating a user profile...');
              //window.location.href = window.location.href + signinUrl;
              this.rawUsrData = JSON.parse(JSON.stringify(usrTemplate));
              this.rawUsrData['basicInformation']['basic-given-name'] = newName.split(' ')[0]
                ? newName.split(' ')[0]
                : 'Apple';
              this.rawUsrData['basicInformation']['basic-last-name'] = this.newName.split(' ')[1]
                ? newName.split(' ')[1]
                : 'User';
              this.rawUsrData['basicInformation']['basicUserEmail'] = newEmail;
              this.rawUsrData.userPersonality.userGeneral.firstDate = Timestamp.fromDate(new Date());

              this.rawUsrData.basicInformation.userID = user.uid;
              // startupMessage = "firstTimeUser";

              console.log(this.rawUsrData);
              this.updateUser();
              setTimeout(() => {
                this.loadingUser = false;
                this.startupStep++;
                console.log('Made it through!');
              }, 1500);
            }
          })
          .catch((error) => {
            // Handle Errors here.
            if (error) {
              var errorCode = error.code;
              var errorMessage = error.message;
              this.showToast(`Error ${errorCode}: ${errorMessage}`, 'error', 6000);
              this.loadingUser = false;
              console.warn(`Error ${errorCode}: ${errorMessage}`);
            }
          });
      } else {
        this.showToast('Something is wrong, check your entries again', 'error', 3000);
        console.warn('Something is wrong, check your entries again');
        this.loadingUser = false;
      }
    },
    addEmergencyContact() {
      if (this.nextPhoneNum.length > 9 && this.nextPhoneNum.length < 12) {
        this.rawUsrData.basicInformation['basic-emergency-phone-num'].push(this.nextPhoneNum);
        // this.updateUserPreferences();
        this.nextPhoneNum = null;
        // this.showToast("Added a new emergency contact", 'success',2000);
      }
    },

    checkToAppendEmergency() {
      if (event.keycode == 13 || event.which == 13) {
        this.addEmergencyContact();
      }
    },
    loadCountryCodes: async function () {
      let codeDoc = await getDoc(doc(this.$db, '/public/countryCodes'));
      if (codeDoc.exists() && !this.countryCodeHandler.isLoaded) {
        let c = codeDoc.data();
        //this.countryCodeHandler.countryCodes = c.codes;
        let codes = c.codes;
        console.log(codes.length);
        for (let i = 0; i < codes.length; i++) {
          this.countryCodeHandler.countryCodes.splice(this.countryCodeHandler.countryCodes.length, 0, codes[i]);
          console.log(this.countryCodeHandler.countryCodes.length);
        }
        console.log(this.countryCodeHandler.countryCodes);
        this.countryCodeHandler.isLoaded = true;
      } else {
        console.warn('No country codes found');
      }
    },
    updateUser: async function (callback = false) {
      console.log(this.rawUsrData);
      try {
        await setDoc(doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}`), this.rawUsrData);
        if (callback) return callback();
      } catch (err) {
        console.warn('USR ERR: ' + err);
      }
    },

    updateUserPreferences: function () {
      this.updateUser(() => {
        this.showToast('Preferences saved!', 'success', '1500');
      });
    },
    updateUserCustomizations: function () {
      let purchases = this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases;
      purchases.marcos.all.push(this.startingMARCoSrc);
      purchases.marcos.default = this.startingMARCoSrc;
      purchases.backgrounds.all.push(this.startingBkgSrc);
      purchases.backgrounds.default = this.startingBkgSrc;
      let id = {
        name: this.startingAudio.displayName,
        sounds: this.startingAudio.files,
      };
      purchases.audioFiles.all.push(id);
      purchases.audioFiles.default = id;

      this.updateUserPreferences();
    },

    checkUserExists: async function (user, callback = false) {
      this.loadingUser = true;
      var name, email, photoUrl, uid, emailVerified;

      name = user.displayName;
      photoUrl = user.photoUrl && user.photoUrl.length > 0 ? user.photoUrl : '/assets/images/marco-wave.png';
      email = user.email;
      uid = user.uid;

      EventBus.$emit('updateUser', uid);
      let userDoc = await getDoc(doc(this.$db, `/users/${uid}`));
      if (userDoc.exists()) {
        //The user exists!
        EventBus.$emit('loadingOverlay', true);
        this.$router.push('/home');
      } else {
        //The user does not exist, create a user
        //window.location.href = window.location.href + signinUrl;
        this.rawUsrData = JSON.parse(JSON.stringify(usrTemplate));
        this.rawUsrData['basicInformation']['basic-given-name'] = name.split(' ')[0] ? name.split(' ')[0] : 'Apple';
        this.rawUsrData['basicInformation']['basic-last-name'] = name.split(' ')[1] ? name.split(' ')[1] : 'User';
        this.rawUsrData['basicInformation']['basicUserEmail'] = email;
        this.rawUsrData.userPersonality.userGeneral.firstDate = Timestamp.fromDate(new Date());
        this.rawUsrData.basicInformation.userID = user.uid;
        // startupMessage = "firstTimeUser";

        this.updateUser();

        console.log(this.rawUsrData);
        setTimeout(() => {
          this.loadingUser = false;
          this.startupStep++;
        }, 1500);
      }
    },
    googleLogin: async function () {
      if (typeof cordova != 'object') {
        //document.getElementsByClassName("firebaseui-idp-google")[1].click();

        signInWithPopup(this.$auth, googleProvider)
          .then((result) => {
            /** @type {firebase.auth.OAuthCredential} */
            var credential = GoogleAuthProvider.credentialFromResult(result);

            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            console.log('The user is: ' + JSON.stringify(user));
            this.checkUserExists(user);
            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
      } else {
        try {
          /*FirebaseAuthentication.signIn({providerId:'google.com'}).then((data)=>{
				var token = data.idToken;
				var credential = firebase.auth.GoogleAuthProvider.credential(token);
				firebase.auth().signInWithCredential(credential).then((userCredential) =>{
                    var user = userCredential.user;

                    console.log("The user is: " + JSON.stringify(user));
                    this.checkUserExists(user);
                });
			})*/
          const result = await FirebaseAuthentication.signInWithGoogle();
          console.log('The result is: ');
          console.log(result);
          // 2. Sign in on the web layer using the id token
          const credential = GoogleAuthProvider.credential(result.credential?.idToken);

          const userResult = await signInWithCredential(this.$auth, credential);
          const user = userResult.user;
          console.log('The user is: ' + JSON.stringify(user));
          this.checkUserExists(user);
        } catch (err) {
          this.showToast(err, 2, 5000);
          console.warn(err);
        }
      }
    },

    facebookLogin: async function () {
      if (typeof cordova != 'object') {
        //document.getElementsByClassName("firebaseui-idp-facebook")[1].click();\
        var provider = new FacebookAuthProvider();

        signInWithPopup(this.$auth, provider)
          .then((result) => {
            /** @type {firebase.auth.OAuthCredential} */
            // var credential = result.credential;

            // The signed-in user info.
            var user = result.user;

            this.checkUserExists(user);

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // var accessToken = credential.accessToken;

            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            // ...
          });
      } else {
        try {
          /*FirebaseAuthentication.signIn({providerId:'facebook.com'}).then((data)=>{
				var token = data.idToken;
				var credential = firebase.auth.FacebookAuthProvider.credential(token);
				firebase.auth().signInWithCredential(credential).then((userCredential) =>{
                    var user = userCredential.user;

                    console.log("The user is: " + JSON.stringify(user));
                    this.checkUserExists(user);
                });
			})*/

          const result = await FirebaseAuthentication.signInWithFacebook();
          // 2. Sign in on the web layer using the id token
          const credential = FacebookAuthProvider.credential(result.credential?.idToken);

          const userResult = await signInWithCredential(this.$auth, credential);
          const user = userResult.user;
          console.log('The user is: ' + JSON.stringify(user));
          this.checkUserExists(user);
        } catch (err) {
          this.showToast(err, 2, 5000);
          console.warn(err);
        }
      }
    },

    appleLogin: async function () {
      if (typeof cordova != 'object') {
        signInWithPopup(this.$auth, appleProvider)
          .then((result) => {
            /** @type {firebase.auth.OAuthCredential} */
            var credential = OAuthProvider.credentialFromResult(result);

            // The signed-in user info.
            var user = result.user;

            this.checkUserExists(user);

            // You can also get the Apple OAuth Access and ID Tokens.
            var accessToken = credential.accessToken;
            var idToken = credential.idToken;

            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            // ...
          });
      } else {
        try {
          /*FirebaseAuthentication.signIn({providerId:'apple.com'}).then((data)=>{
				var token = data.idToken;
				var rawNonce = data.rawNonce;
				//var appleProvider = new firebase.auth.OAuthProvider('apple.com');
				var credential = appleProvider.credential({idToken:token,rawNonce:rawNonce});
				firebase.auth().signInWithCredential(credential).then((userCredential) =>{
                    var user = userCredential.user;

                    console.log("The user is: " + JSON.stringify(user));
                    this.checkUserExists(user);
                });
			})*/
          const result = await FirebaseAuthentication.signInWithApple({
            skipNativeAuth: true,
          });
          // 2. Sign in on the web layer using the id token and nonce

          const credential = appleProvider.credential({
            idToken: result.credential?.idToken,
            rawNonce: result.credential?.nonce,
          });
          const userResult = await signInWithCredential(this.$auth, credential);
          const user = userResult.user;
          console.log('The user is: ' + JSON.stringify(user));
          this.checkUserExists(user);
        } catch (err) {
          this.showToast(err, 2, 5000);
          console.warn(err);
        }
      }
    },

    emailLogin: async function () {
      var chckEmail = this.usrEmailLogin;
      var chckPswd = this.usrPasswordLogin;
      signInWithEmailAndPassword(this.$auth, chckEmail, chckPswd)
        .then((userCredential) => {
          var user = userCredential.user;

          console.log('The user is: ' + JSON.stringify(user));
          this.checkUserExists(user);
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log('The error is' + error);
          this.showToast('Check your email or password', 'error', 3000);
          // ...
        });
    },
  },
  beforeMount: async function () {
    //IF YOU NEED TO RUN A SCRIPT BEFORE ALL THE COMPONENTS FULLY LOAD, DO SO HERE.

    EventBus.$emit('check-for-update');
    this.animalsList = [
      { name: 'Cats', value: 'cat' },
      { name: 'Dogs', value: 'dog' },
      { name: 'Birds', value: 'bird' },
      { name: 'Monkeys', value: 'monkey' },
      { name: 'Foxes', value: 'fox' },
      {
        name: 'Horses',
        value: 'horse',
      },
      {
        name: 'Pandas',
        value: 'panda',
      },
      {
        name: 'Sloths',
        value: 'sloth',
      },
      {
        name: 'Rabbits',
        value: 'rabbit',
      },
      {
        name: 'Lizards',
        value: 'lizard',
      },
      {
        name: 'Snakes',
        value: 'snake',
      },
    ];
    this.hobbiesList = [
      { name: 'Playing music', value: 'music' },
      { name: 'Makeup', value: 'makeup' },
      { name: 'Art', value: 'general art' },
      { name: 'Drawing', value: 'drawing' },
      { name: 'Painting', value: 'painting' },
      {
        name: 'Theater',
        value: 'theater',
      },
      {
        name: 'Gaming',
        value: 'gaming',
      },
      {
        name: 'Exercise',
        value: 'exercising',
      },
      {
        name: 'Magic/Magic Tricks',
        value: 'magic',
      },
      {
        name: 'Cooking',
        value: 'cooking',
      },
      {
        name: 'Photography',
        value: 'photography',
      },
      {
        name: 'Reading',
        value: 'reading',
      },
      {
        name: 'Writing',
        value: 'writing',
      },
      {
        name: 'Playing/Watching Sports',
        value: 'sports',
      },
    ];
    this.faithList = [
      { name: 'Christianity', value: 'christianity' },
      { name: 'Judaism', value: 'judaism' },
      { name: 'Islam', value: 'islam' },
      { name: 'Hinduism', value: 'hinduism' },
      { name: 'Buddhism', value: 'buddhism' },
      {
        name: 'Spiritual/New Religion',
        value: 'spiritual',
      },
      {
        name: 'Sikh',
        value: 'sikh',
      },
      {
        name: 'Taoism',
        value: 'taoism',
      },
      {
        name: 'Atheism',
        value: 'atheism',
      },
    ];
    this.setupOptions = [
      {
        name: 'In-Person',
        icon: '/assets/images/meditationcardbkg.jpg',
      },
      {
        name: 'App',
        icon: '/assets/images/chat-phone-img.jpg',
      },
    ];
    this.previewingMsgDefault = 'This is what your MARCo will sound like.';
    this.loadMARCos();
    this.loadBkgs();
    this.loadAudios();
    let langDocRef = await getDoc(doc(this.$db, 'public', 'langs'));
    let langDoc = langDocRef.data();
    this.langs = langDoc.text;

    let voiceDocRef = await getDoc(doc(this.$db, 'public', 'standardVoices'));
    let voiceDoc = voiceDocRef.data();
    this.voiceProfs = voiceDoc.voices;

    try {
      // Fix up for prefixing
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();
      this.source = audioContext.createBufferSource();
    } catch (e) {
      //console.log('Web Audio API is not supported in this browser');
    }

    this.launchOnboarding();
    EventBus.$emit('loadingOverlay', false);
    //document.getElementById("whiteoverlay").remove();
  },
  mounted: async function () {
    this.isLoading = true;
    this.ITPs = await new Promise((resolve, reject) => {
      axios
        .get(`${this.$host}/ITPList`)
        .then((response) => {
          console.log('response in store', response.data);
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
    this.isLoading = false;
  },
};

//THREE INPUTS: 1) the triple object, 2) the merging object, 3) is it firstSentence or secondSentence
function addThird(triple, merging, firstOrSecond) {
  //Iterate over merging object keys with a for loop

  for (var keys in merging) {
    //If the key includes first or second that matches the first or second criteria:
    //Get the part of the key that trails after "first" or "second"; e.g. firstSentenceIndex => sentenceIndex <=== STRIPPED_KEY
    //Set the key of the triple object equivalent to "third + STRIPPED_KEY" equal to the value of the current key
    //Else delete the key from the merging object
  }
}
</script>

<style>
/* Add any additional CSS styles you need here */
.v-application {
  font-family: 'Poppins';
}
.v-sheet.v-card.active-option {
  box-shadow: rgba(3, 15, 24, 0.639) 0px 16px 35px -12px;
  transform: scale(1.2);
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  animation: scale-in-center 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-24 15:46:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-6-24 16:47:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
  }
}

.avatarpulse {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  animation: avatarpulse 2s infinite;
}

.first-time-dialog-bkg {
  background: rgb(182, 213, 57);
  background: linear-gradient(175deg, rgba(182, 213, 57, 1) 0%, rgba(69, 168, 225, 1) 100%, rgba(69, 168, 225, 1) 100%);
}

@-webkit-keyframes avatarpulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes avatarpulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.soothing-gradient-bg {
  background: linear-gradient(-45deg, #2eb813, #3ce7b1, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  /*animation: gradientBG 15s ease infinite;*/
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/*.icon-bg{
    background:linear-gradient(-45deg, #2eb81399, #3ce7b199, #23a6d599, #23d5ab99),url('/assets/images/bkg-tile-1.jpg');
    background-size: 400% 400%;
    animation: iconbganim 20s linear infinite;
}*/
@keyframes iconbganim {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 40%;
  }
}

.welcomeScreenBkg {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  /*  background-image: url('/assets/images/welcome-bkg-2.png');       
    background-size:cover;
    animation: background-intro 12s linear infinite;*/
}

@keyframes background-intro {
  0% {
    background-position: 200% 400%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-1 12:8:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-right
 * ----------------------------------------
 */
.roll-in-right {
  -webkit-animation: roll-in-right 1s ease-out both;
  animation: roll-in-right 1s ease-out both;
}
@-webkit-keyframes roll-in-right {
  0% {
    -webkit-transform: translateX(800px) rotate(540deg);
    transform: translateX(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    opacity: 1;
  }
}
@keyframes roll-in-right {
  0% {
    -webkit-transform: translateX(800px) rotate(540deg);
    transform: translateX(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    opacity: 1;
  }
}

.roll-in-left {
  -webkit-animation: roll-in-left 1s ease-out both;
  animation: roll-in-left 1s ease-out both;
}
@-webkit-keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(540deg);
    transform: translateX(-800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    opacity: 1;
  }
}
@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(540deg);
    transform: translateX(-800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(45deg);
    transform: translateX(0) rotate(45deg);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-1 12:14:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: scale-in-center 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
</style>
