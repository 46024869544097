<template>
  <v-app>
    <v-app-bar>{{ titleEl }}</v-app-bar>
    <v-row></v-row>
    <v-select v-model="micType" :items="micOptions" label="Microphone Type"> </v-select>
    <v-row>
      <v-btn class="my-6" @click="run">{{ buttonEl }}</v-btn>
      <v-btn class="my-6" @click="run2">{{ buttonEl }}2</v-btn>
      <v-btn class="my-6" @click="run3">{{ buttonEl }}3</v-btn>
      <v-btn class="my-6" @click="run4">{{ buttonEl }}4</v-btn>
      <v-slider :min="1" :max="5" v-model="gain"></v-slider>
    </v-row>
    <v-textarea label="Transcript" v-model="msg"></v-textarea>
  </v-app>
</template>

<script>
const audioStream = new AudioContext();
var gainNode = audioStream.createGain();
gainNode.gain.value = 2.0;
const dest = audioStream.createMediaStreamDestination();
let base64DataCOMPILEDTemplate = 'data:audio/wav;base64,';
let compiledBase64 = '';
let audioBufferArr = [];

function onAudioInput(evt) {
  // 'evt.data' is an integer array containing raw audio data
  //
  console.log('Audio data received: ' + evt.data.length + ' samples');
  audioBufferArr.push(evt.data);

  // ... do something with the evt.data array ...
}

// Listen to audioinput events
window.addEventListener('audioinput', onAudioInput, false);

export default {
  data: function () {
    return {
      isRecording: false,
      recorder: null,
      buttonEl: 'Record',
      titleEl: 'Start Recording',
      msg: '',
      socket: null,
      bkgAudio: new Audio(),
      bkgAudioVolume: 0.4,
      gain: 1,
      iosAudio: new Audio(),
      micType: audioinput.AUDIOSOURCE_TYPE.MIC,
      micOptions: [
        audioinput.AUDIOSOURCE_TYPE.MIC,
        audioinput.AUDIOSOURCE_TYPE.DEFAULT,
        audioinput.AUDIOSOURCE_TYPE.CAMCORDER,
        audioinput.AUDIOSOURCE_TYPE.UNPROCESSED,
        audioinput.AUDIOSOURCE_TYPE.VOICE_COMMUNICATION,
        audioinput.AUDIOSOURCE_TYPE.VOICE_RECOGNITION,
      ],
    };
  },
  methods: {
    // runs real-time transcription and handles global variables
    run: async function () {
      this.bkgAudio.src =
        'https://firebasestorage.googleapis.com/v0/b/newagent-e3a67.appspot.com/o/Audio%2Fbkgs%2Fnature-sounds.mp3?alt=media&token=b01aee89-6f95-4caf-bc56-ace9c21b00b0';
      this.bkgAudio.pause();
      this.bkgAudio.load();
      this.bkgAudio.play();
      if (this.isRecording) {
        if (this.socket) {
          this.socket.send(JSON.stringify({ terminate_session: true }));
          this.socket.close();
          this.socket = null;
        }

        if (this.recorder) {
          this.recorder.pauseRecording();
          this.recorder = null;
        }
      } else {
        console.log('Starting recording');
        const response = await fetch(`${this.$host}/speech/v2/assemblytoken`); // get temp session token from server.js (backend)

        const data = await response.json();
        console.log('Response token is: ', data);

        if (data.error) {
          alert(data.error);
        }

        const { token } = data;

        // establish wss with AssemblyAI (AAI) at 16000 sample rate
        this.socket = await new WebSocket(`wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`);

        // handle incoming messages to display transcription to the DOM
        const texts = {};
        this.socket.onmessage = (message) => {
          const res = JSON.parse(message.data);
          console.log(res);
          texts[res.audio_start] = res.text;
          const keys = Object.keys(texts);
          console.log(texts);
          keys.sort((a, b) => a - b);
          for (const key of keys) {
            if (texts[key]) {
              this.msg = ` ${texts[key]}`;
            }
          }
        };

        this.socket.onerror = (event) => {
          console.error(event);
          this.socket.close();
        };

        this.socket.onclose = (event) => {
          console.log(event);
          this.socket = null;
        };

        this.socket.onopen = () => {
          console.log('Opened socket');
          // once socket is open, begin recording
          //messageEl.style.display = '';
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
              this.recorder = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/webm;codecs=pcm', // endpoint requires 16bit PCM audio
                recorderType: StereoAudioRecorder,
                timeSlice: 250, // set 250 ms intervals of data that sends to AAI
                desiredSampRate: 16000,
                numberOfAudioChannels: 1, // real-time requires only one channel
                bufferSize: 4096,
                audioBitsPerSecond: 128000,
                ondataavailable: (blob) => {
                  console.log(blob);
                  const reader = new FileReader();
                  reader.onload = () => {
                    const base64data = reader.result;

                    // audio data must be sent as a base64 encoded string
                    if (this.socket) {
                      this.socket.send(
                        JSON.stringify({
                          audio_data: base64data.split('base64,')[1],
                        })
                      );
                      compiledBase64 += base64data.split('base64,')[1];
                      compiledBase64 += '\n';
                    }
                  };
                  reader.readAsDataURL(blob);
                },
              });

              this.recorder.startRecording();
            })
            .catch((err) => console.error(err));
        };
      }

      this.isRecording = !this.isRecording;
      this.buttonEl = this.isRecording ? 'Stop' : 'Record';
      this.titleEl = this.isRecording ? 'Click stop to end recording!' : 'Click start to begin recording!';
    },
    run2: async function () {
      if (typeof audioinput === 'object') {
        if (!this.isRecording) {
          var captureCfg = {
            // The Sample Rate in Hz.
            // For convenience, use the audioinput.SAMPLERATE constants to set this parameter.
            sampleRate: audioinput.SAMPLERATE.VOIP_16000Hz,

            // Maximum size in bytes of the capture buffer. Should be a power of two and <= 16384.
            bufferSize: 4096,

            // The number of channels to use: Mono (1) or Stereo (2).
            // For convenience, use the audioinput.CHANNELS constants to set this parameter.
            channels: audioinput.CHANNELS.MONO,

            // The audio format. Currently PCM_16BIT and PCM_8BIT are supported.
            // For convenience, use the audioinput.FORMAT constant to access the possible
            // formats that the plugin supports.
            format: audioinput.FORMAT.PCM_16BIT,

            // Specifies if the audio data should be normalized or not.
            normalize: true,

            // Specifies the factor to use if normalization is performed.
            normalizationFactor: 32767.0,

            // If set to true, the plugin will handle all conversion of the data to
            // web audio. The plugin can then act as an AudioNode that can be connected
            // to your web audio node chain.
            streamToWebAudio: false,

            // Used in conjunction with streamToWebAudio. If no audioContext is given,
            // one (prefixed) will be created by the plugin.
            audioContext: null,

            // Defines how many chunks will be merged each time, a low value means lower latency
            // but requires more CPU resources.
            concatenateMaxChunks: 10,

            // Specifies the type of the type of source audio your app requires.
            // For convenience, use the audioinput.AUDIOSOURCE_TYPE constants to set this parameter:
            // -DEFAULT
            // -CAMCORDER - Microphone audio source with same orientation as camera if available.
            // -UNPROCESSED - Unprocessed sound if available.
            // -VOICE_COMMUNICATION - Tuned for voice communications such as VoIP.
            // -MIC - Microphone audio source. (Android only)
            // -VOICE_RECOGNITION - Tuned for voice recognition if available (Android only)
            audioSourceType: audioinput.AUDIOSOURCE_TYPE.MIC,

            // Optionally specifies a file://... URL to which the audio should be saved.
            // If this is set, then no audioinput events will be raised during recording.
            // When stop is called, a single audioinputfinished event will be raised, with
            // a "file" argument that contains the URL to which the audio was written,
            // and the callback passed into stop() will be invoked.
            // Currently, only WAV format files are guaranteed to be supported on all platforms.
            // When called initialize(), this should be a URL to the directory in which files will
            // be saved when calling start(), so that initialize() can ensure access to the directory
            // is available.
            fileUrl: null,
          };
          audioinput.initialize(captureCfg, async () => {
            console.log('Initialized.');
            const response = await fetch(`${this.$host}/speech/v2/assemblytoken`); // get temp session token from server.js (backend)
            const data = await response.json();

            if (data.error) {
              alert(data.error);
            }

            const { token } = data;

            // establish wss with AssemblyAI (AAI) at 16000 sample rate
            this.socket = await new WebSocket(
              `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`
            );

            // handle incoming messages to display transcription to the DOM
            const texts = {};
            this.socket.onmessage = (message) => {
              const res = JSON.parse(message.data);
              console.log(res);
              texts[res.audio_start] = res.text;
              const keys = Object.keys(texts);
              console.log(texts);
              keys.sort((a, b) => a - b);
              for (const key of keys) {
                if (texts[key]) {
                  this.msg = ` ${texts[key]}`;
                }
              }
            };

            this.socket.onerror = (event) => {
              console.error(event);
              this.socket.close();
            };

            this.socket.onclose = (event) => {
              console.log(event);
              this.socket = null;
            };

            this.socket.onopen = () => {
              audioinput.start(captureCfg);
              this.isRecording = true;
            };
          });
        } else {
          if (this.socket) {
            this.socket.send(JSON.stringify({ terminate_session: true }));
            this.socket.close();
            this.socket = null;
          }

          this.isRecording = false;
          audioinput.stop();
        }
      }
    },

    run3: async function () {
      if (!this.isRecording) {
        this.bkgAudio.src =
          'https://firebasestorage.googleapis.com/v0/b/newagent-e3a67.appspot.com/o/Audio%2Fbkgs%2Fnature-sounds.mp3?alt=media&token=b01aee89-6f95-4caf-bc56-ace9c21b00b0';
        this.bkgAudio.pause();
        this.bkgAudio.load();
        this.bkgAudio.play();
        this.isRecording = true;
        try {
          if (window.audioinput) {
            const response = await fetch(`${this.$host}/speech/v2/assemblytoken`); // get temp session token from server.js (backend)
            const data = await response.json();

            if (data.error) {
              alert(data.error);
            }

            const { token } = data;

            // establish wss with AssemblyAI (AAI) at 16000 sample rate
            this.socket = await new WebSocket(
              `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`
            );

            // handle incoming messages to display transcription to the DOM
            const texts = {};
            this.socket.onmessage = (message) => {
              const res = JSON.parse(message.data);
              console.log(res);
              texts[res.audio_start] = res.text;
              const keys = Object.keys(texts);
              console.log(texts);
              keys.sort((a, b) => a - b);
              for (const key of keys) {
                if (texts[key]) {
                  this.msg = ` ${texts[key]}`;
                }
              }
            };

            this.socket.onerror = (event) => {
              console.error(event);
              this.socket.close();
            };

            this.socket.onclose = (event) => {
              console.log(event);
              this.socket = null;
            };

            this.socket.onopen = () => {
              if (!audioinput.isCapturing()) {
                // Start with default values and let the plugin handle conversion from raw data to web audio
                audioinput.start({
                  // The Sample Rate in Hz.
                  // For convenience, use the audioinput.SAMPLERATE constants to set this parameter.
                  sampleRate: audioinput.SAMPLERATE.VOIP_16000Hz,

                  // Maximum size in bytes of the capture buffer. Should be a power of two and <= 16384.
                  bufferSize: 4096,

                  // The number of channels to use: Mono (1) or Stereo (2).
                  // For convenience, use the audioinput.CHANNELS constants to set this parameter.
                  channels: audioinput.CHANNELS.MONO,

                  // The audio format. Currently PCM_16BIT and PCM_8BIT are supported.
                  // For convenience, use the audioinput.FORMAT constant to access the possible
                  // formats that the plugin supports.
                  format: audioinput.FORMAT.PCM_16BIT,

                  // Specifies if the audio data should be normalized or not.
                  normalize: true,

                  // Specifies the factor to use if normalization is performed.
                  normalizationFactor: 32767.0,
                  streamToWebAudio: true,
                  audioContext: audioStream,
                  // Defines how many chunks will be merged each time, a low value means lower latency
                  // but requires more CPU resources.
                  concatenateMaxChunks: 10,

                  // Specifies the type of the type of source audio your app requires.
                  // For convenience, use the audioinput.AUDIOSOURCE_TYPE constants to set this parameter:
                  // -DEFAULT
                  // -CAMCORDER - Microphone audio source with same orientation as camera if available.
                  // -UNPROCESSED - Unprocessed sound if available.
                  // -VOICE_COMMUNICATION - Tuned for voice communications such as VoIP.
                  // -MIC - Microphone audio source. (Android only)
                  // -VOICE_RECOGNITION - Tuned for voice recognition if available (Android only)
                  audioSourceType: this.micType,
                });

                // Connect the audioinput to the speaker(s) in order to hear the captured sound
                audioinput.connect(gainNode);

                gainNode.connect(dest);

                this.recorder = new RecordRTC(dest.stream, {
                  type: 'audio',
                  mimeType: 'audio/webm;codecs=pcm', // endpoint requires 16bit PCM audio
                  recorderType: StereoAudioRecorder,
                  timeSlice: 250, // set 250 ms intervals of data that sends to AAI
                  desiredSampRate: 16000,
                  numberOfAudioChannels: 1, // real-time requires only one channel
                  bufferSize: 4096,
                  audioBitsPerSecond: 128000,
                  ondataavailable: async (blob) => {
                    //console.log(blob);

                    const reader = new FileReader();
                    reader.onload = () => {
                      const base64data = reader.result;

                      // audioBufferArr.push(`${base64DataCOMPILEDTemplate}${base64data.split('base64,')[1]}`);

                      // audio data must be sent as a base64 encoded string
                      if (this.socket) {
                        this.socket.send(
                          JSON.stringify({
                            audio_data: base64data.split('base64,')[1],
                          })
                        );
                        compiledBase64 += base64data.split('base64,')[1];
                        compiledBase64 += '\n';
                      }
                    };
                    reader.readAsDataURL(blob);
                  },
                });

                this.recorder.startRecording();

                console.log('Capturing audio!');

                //disableStartButton();
              } else {
                alert('Already capturing!');
              }
            };
          }
        } catch (ex) {
          alert('startCapture exception: ' + ex);
        }
      } else {
        this.bkgAudio.pause();

        this.isRecording = false;
        if (window.audioinput && audioinput.isCapturing()) {
          audioinput.stop();
          //var audio = new Audio(),
          // disableStopButton();
        }
        if (this.recorder) {
          this.recorder.pauseRecording();
          this.recorder = null;
        }

        if (this.socket) {
          this.socket.send(JSON.stringify({ terminate_session: true }));
          this.socket.close();
          this.socket = null;
        }

        console.log('Stopped!');

        //const blob2 = new Blob(audioBufferArr);
        //const url = URL.createObjectURL(blob2);
        //this.iosAudio.src = url;

        //this.iosAudio.play();

        console.log(compiledBase64);
      }
    },
    run4: async function () {
      gainNode.gain.value = this.gain;
      if (!this.isRecording) {
        this.isRecording = true;
        if (!audioinput.isCapturing()) {
          // Start with default values and let the plugin handle conversion from raw data to web audio
          audioinput.start({
            // The Sample Rate in Hz.
            // For convenience, use the audioinput.SAMPLERATE constants to set this parameter.
            sampleRate: audioinput.SAMPLERATE.VOIP_16000Hz,

            // Maximum size in bytes of the capture buffer. Should be a power of two and <= 16384.
            bufferSize: 4096,

            // The number of channels to use: Mono (1) or Stereo (2).
            // For convenience, use the audioinput.CHANNELS constants to set this parameter.
            channels: audioinput.CHANNELS.MONO,

            // The audio format. Currently PCM_16BIT and PCM_8BIT are supported.
            // For convenience, use the audioinput.FORMAT constant to access the possible
            // formats that the plugin supports.
            format: audioinput.FORMAT.PCM_16BIT,

            // Specifies if the audio data should be normalized or not.
            normalize: true,

            // Specifies the factor to use if normalization is performed.
            normalizationFactor: 32767.0,
            streamToWebAudio: true,
            audioContext: audioStream,
            // Defines how many chunks will be merged each time, a low value means lower latency
            // but requires more CPU resources.
            concatenateMaxChunks: 10,

            // Specifies the type of the type of source audio your app requires.
            // For convenience, use the audioinput.AUDIOSOURCE_TYPE constants to set this parameter:
            // -DEFAULT
            // -CAMCORDER - Microphone audio source with same orientation as camera if available.
            // -UNPROCESSED - Unprocessed sound if available.
            // -VOICE_COMMUNICATION - Tuned for voice communications such as VoIP.
            // -MIC - Microphone audio source. (Android only)
            // -VOICE_RECOGNITION - Tuned for voice recognition if available (Android only)
            audioSourceType: this.micType,
          });

          // Connect the audioinput to the speaker(s) in order to hear the captured sound
          audioinput.connect(gainNode);
          gainNode.connect(audioStream.destination);

          console.log('Capturing audio!');
          // eslint-disable-next-line
          function onAudioInput(evt) {
            // 'evt.data' is an integer array containing raw audio data
            //
            console.log('Audio data received: ' + evt.data.length + ' samples');
            console.log(evt);
            var blob = new Blob([new Uint8Array(evt.data)], {
              type: 'audio/wav',
            });
            console.log(blob);

            const reader = new FileReader();
            reader.onload = () => {
              const base64data = reader.result;

              // audio data must be sent as a base64 encoded string
              if (this.socket) {
                this.socket.send(JSON.stringify({ audio_data: base64data.split('base64,')[1] }));
              }
            };
            reader.readAsDataURL(blob);

            // ... do something with the evt.data array ...
          }
          window.addEventListener('audioinput', onAudioInput, false);

          //disableStartButton();
        } else {
          alert('Already capturing!');
        }
      } else {
        this.bkgAudio.pause();

        this.isRecording = false;
        if (window.audioinput && audioinput.isCapturing()) {
          audioinput.stop();
          // disableStopButton();
        }
        if (this.recorder) {
          this.recorder.pauseRecording();
          this.recorder = null;
        }

        if (this.socket) {
          this.socket.send(JSON.stringify({ terminate_session: true }));
          this.socket.close();
          this.socket = null;
        }

        console.log('Stopped!');
      }
    },
  },
  beforeMount() {
    /* function onAudioInput( evt ) {
    // 'evt.data' is an integer array containing raw audio data
    //   
    console.log( "Audio data received: " + evt.data.length + " samples" );
    console.log(evt);
    var blob = new Blob([new Uint8Array(evt.data)], {type: "audio/wav"});
    console.log(blob);
    
    const reader = new FileReader();
              reader.onload = () => {
                const base64data = reader.result;

                // audio data must be sent as a base64 encoded string
                if (this.socket) {
                  this.socket.send(JSON.stringify({ audio_data: base64data.split('base64,')[1] }));
                }
              };
              reader.readAsDataURL(blob);

    
    
    // ... do something with the evt.data array ...
}
window.addEventListener( "audioinput", onAudioInput, false ); */
  },
};
</script>

<style></style>
