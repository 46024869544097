<template>
  <v-app>
    <!--Main Application -->
		<AppWidget
      @onExplain="widgetHeaderExplain"
      v-if="uid && showWidget"
      :defaultTemplate="defaultTemplate"
      :widgetInfo="widgetInfo"
      :userId="uid"
      @close="close()"
    ></AppWidget>
    <div
      style="display: none"
      id="cbt-journal-overlay"
      onclick="document.getElementById('journal-overlay').click()"
    ></div>
    <div style="display: none" id="new-window-overlay" @click="openNewWindow"></div>
    <v-app-bar elevation="0" dark absolute color="transparent" style="z-index: 9997; top:0px;">
      <v-app-bar-nav-icon color="white" @click="navDrawer = !navDrawer"></v-app-bar-nav-icon>
      <v-scroll-y-reverse-transition>
        <h2 v-if="popupEnabled || chatPopup" :key="currPopupTitle">
          {{ chatPopup ? 'Chat' : currPopupTitle }}
        </h2>
      </v-scroll-y-reverse-transition>
      <v-spacer></v-spacer>
      <v-btn v-if="popupEnabled || chatPopup" icon dark @click="closePopups()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      class="app-nav"
      style="z-index: 9999; border-radius: 0px 3em 3em 0px"
      v-model="navDrawer"
      :permanent="false"
      app
    >
      <v-list-item class="px-2">
        <v-list-item-avatar color="blue">
          <v-img v-bind:src="usrProf"></v-img>
        </v-list-item-avatar>

        <v-list-item-title
          >{{ rawUsrData.basicInformation['basic-given-name'] }}
          {{ rawUsrData.basicInformation['basic-last-name'] }}</v-list-item-title
        >
      </v-list-item>
      <v-divider></v-divider>
      <v-list>
        <v-list-item-group v-model="navSelect" color="secondary">
          <v-list-item
            v-for="item in navItems"
            :key="item.key"
            v-bind:color="item.color"
            @click="
              item.action();
              navDrawer = false;
            "
            :id="item.id"
            flat
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-badge overlap avatar :value="!!(onboardingEvents.indexOf(item.badgeCriteria) + 1)" color="white">
                  <template v-slot:badge>
                    <v-avatar class="onboard-pulse"> </v-avatar>
                  </template>
                  <v-list-item-avatar v-bind="attrs" v-on="on">
                    <v-icon :class="item.color" dark style="color: white">{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                </v-badge>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <!--<v-list-item
        @click="
          isSyncingApp
            ? decideSyncHandling()
            : syncAccountHandler.openSyncDialog()
        "
        flat
      >
        <v-list-item-avatar>
          <v-icon class="orange" dark style="color: white">mdi-refresh</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{
            !isSyncingApp ? "Connect to MARCo" : "Disconnect from MARCo"
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->

      <!--Give the user the option to save and quit out of MARCo-->
      <v-list-item @click="openSaveAndQuitDialog()" flat>
        <v-list-item-avatar>
          <v-icon class="red" dark style="color: white">mdi-logout</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Save and Quit</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
    <v-main app class="blue-grey lighten-5">
      <v-container fluid> </v-container>

      <marco-renderer
        :speechBubText="speechBubText"
        :speechBubTop="showEmotionForm || popupEnabled"
        :showSpeechBub="!chatPopup"
        :mobileDisplay="true"
        ref="marcomobilewindow"
        :marcoWasCalled="marcoIsCalled"
        @startCall="callMARCo(rawUsrData.userPersonality.userGeneral.accountInfo.purchases.marcos.default)"
        @startSync="syncAccountHandler.openSyncDialog"
        @openNav="navDrawer = true"
      ></marco-renderer>
      <v-dialog
        fullscreen
        hide-overlay
        style="z-index: 999"
        transition="dialog-bottom-transition"
        v-model="popupEnabled"
        persistent
      >
        <m-popup
          ref="mobilepopup"
          :voiceProfs="voiceProfs"
          :popupOpacity="popupOpacity"
          :mediaSrc="mediaSrc"
          :title="currPopupTitle"
          :mobileDisplay="isMobile()"
          :rawUsrData="rawUsrData"
          :windowCase="windowCase"
          :activityList="fullActivities"
          :usrAvatar="usrProf"
          :chosenActivities="dailyTracking.chosenActs"
          :eventHub="rootEventHub"
          :progressVal="dailyTracking.progressVal"
          :emoteArray="emoteArray"
          :isCbt="globalCBTRequest"
          :cssrsStep="highalertHandler.currStep"
          :cssrsQuestions="highalertHandler.stepArray"
          :showCssrsDesc="highalertHandler.showDescBox"
          :cbtGoals="cbtGoals"
          :marcoIsCalled="marcoIsCalled"
          :pomodoroCase="pomodoroCase"
          :baseTime="timerTime"
          :requestNotifs="dailyTracking.requestNotificationCapability"
          :backedupJournal="backedupJournal"
          :talkCase="talkCase"
          :usrSessionSummary="usrSessionSummary"
          :previewingVoice="previewingVoice"
          :isSyncingApp="isSyncingApp"
          @close-popup="
            popupEnabled = false;
            isJournaling = false;
          "
          @switchMARCo="switchMARCo"
          @makepurchase="makePurchase"
          @textsend="sndTxt"
          @addActivities="addActivities"
          @journalsend="journalSend"
          @showtoast="showToast"
          @switchwindow="switchWindow"
          @appendtext="appendTextWithAudio"
          @stepYes="highalertHandler.stepYes()"
          @stepNo="highalertHandler.stepNo()"
          @cssrsDesc="highalertHandler.stepYesSubmit"
          @notificationsEnabled="dailyTracking.handleActivityNotifications"
          @notificationsDenied="dailyTracking.requestNotificationCapability = false"
          @saveJournal="saveJournal"
          @showPreviousSuggestedActivities="showPreviousSuggestedActivities"
          @submitgoodbye="submitGoodbye"
          @changeopacity="popupOpacity == 1 ? (popupOpacity = 0.3) : (popupOpacity = 1)"
          @openNav="navDrawer = true"
          @updateVidBkg="updateVidBkg"
          @switchAudioPref="sfxHandler.switchAudioPref"
          @updateVoice="updateVoice"
          @previewMARCoVoice="previewMARCoVoice"
          @manageMARCoins="manageMARCoins"
          @silenttextsend="chatHandler.sentMsg"
        >
        </m-popup>
      </v-dialog>

      <v-navigation-drawer
        :permanent="false"
        :temporary="isMobile()"
        right
        v-model="chatPopup"
        :width="isMobile() ? '100vw' : '50vw'"
        app
      >
        <m-chat
          :mobileDisplay="isMobile()"
          :chatMsgs="chatHandler.chatMsgs"
          :usrAvatar="usrProf"
          marcoAvatar="/assets/images/marco-wave.png"
          :isCbt="globalCBTRequest"
          ref="mobilechatform"
          @sendmsg="sndTxt"
          @openwindow="clickElement"
          @isfocused="showFab = false"
          @isblurred="showFab = true"
          :isIntake="false"
          :chatType="0"
          @sendslider="sndTxt"
          @showtoast="showToast"
          @closechat="chatPopup = !chatPopup"
          :quickReplies="chatHandler.quickReplies"
        ></m-chat>
      </v-navigation-drawer>
      <transition name="scroll-y-reverse-transition" mode="out-in">
        <v-footer
          absolute
          color="transparent"
          class="fab-container"
          justify="center"
          v-if="
            showFab &&
            (marcoIsCalled || isSyncingApp) &&
            !navDrawer &&
            !chatPopup &&
            !popupEnabled &&
            !payDialog &&
            !showEmotionForm &&
            !showLastSessionRating &&
            marcoIsCalled

          "
        >
          <v-row justify="center" align="center">
            <v-text-field
              class="mx-3 chatbar white--text"
              color="white"
              filled
              rounded
              append-icon="mdi-send"
              v-model="chatbarTxt"
              @click:append.stop="sndTxt(chatbarTxt)"
              @keydown="checkForSend"
              @focus="marcoListen"
              @blur="endListen"
            >
              <template v-slot:label>
                <span class="white--text">Talk to MARCo</span>
              </template>
            </v-text-field>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="4" justify="center" align="center" class="slide-in-bottom" style="animation-delay: 100ms">
              <v-btn
                fab
                right
                v-model="micOn"
                :disabled="!isSyncingApp && !marcoIsCalled"
                @click="switchSpeechRecognition"
                class="micOn ? 'onboard-pulse':''"
                :color="micOn ? 'green' : 'white'"
                outlined
                style="background: rgba(0, 0, 0, 0.1); border-width: 2px"
              >
                <v-icon :color="micOn ? 'green' : 'white'">mdi-microphone-outline</v-icon>
              </v-btn>
            </v-col>

            <v-col justify="center" align="center" cols="4" class="slide-in-bottom" style="animation-delay: 200ms">
              <v-badge
                :content="
                  chatHandler.totMessages - chatHandler.msgsRead > 99
                    ? '99+'
                    : chatHandler.totMessages - chatHandler.msgsRead
                "
                :value="chatHandler.totMessages - chatHandler.msgsRead"
                color="green"
                overlap
                style="box-shadow: 2px 2px 12px -10px rgba(0, 0, 0, 0.4)"
              >
                <v-btn
                  fab
                  :disabled="!isSyncingApp && !marcoIsCalled"
                  right
                  @click="
                    chatHandler.msgsRead = chatHandler.totMessages;
                    chatPopup = !chatPopup;
                    chatHandler.openMedia = false;
                  "
                  :color="chatPopup ? 'red' : 'white'"
                  outlined
                  :style="`background:${audioOn ? 'rgba(0,0,0,0.1)' : 'rgba(255,0,0,0.1)'}; border-width: 2px; `"
                  elevation="0"
                >
                  <v-icon color="white">{{ chatPopup ? 'mdi-chat-remove' : 'mdi-chat-outline' }}</v-icon>
                </v-btn>
              </v-badge>
            </v-col>

            <v-col justify="center" align="center" cols="4" class="slide-in-bottom" style="animation-delay: 250ms">
              <v-btn
                fab
                right
                v-model="audioOn"
                @click="marcoSpeakOnOff()"
                :disabled="!isSyncingApp && !marcoIsCalled"
                :color="audioOn ? 'white' : 'red'"
                outlined
                :style="`background:${audioOn ? 'rgba(0,0,0,0.1)' : 'rgba(255,0,0,0.1)'}; border-width: 2px; `"
                elevation="0"
              >
                <v-icon>{{ audioOn ? 'mdi-volume-high' : 'mdi-volume-mute' }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div style="height: 20px; width: 100%"></div>
        </v-footer>
      </transition>
      <template v-if="!chatPopup && chatHandler.chatMsgs.length > 0">
        <v-bottom-sheet v-model="chatHandler.openMedia" style="z-index: 999; border-radius: 1em 1em 0px 0px">
          <v-list style="z-index: 999">
            <v-subheader>Open Media?</v-subheader>
            <v-list-item
              @click="
                clickElement(chatHandler.currClickElement);
                chatHandler.openMedia = false;
              "
            >
              <v-list-item-avatar>
                <v-avatar size="32px" tile>
                  <img src="/assets/images/media-monitor.svg" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title>Open Media</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-bottom-sheet>
      </template>

      <v-bottom-sheet v-model="syncActivityDialogue" style="z-index: 1005">
        <v-list style="z-index: 1006">
          <v-subheader>{{ syncActivityDescription }}</v-subheader>
          <v-list-item @click="launchSyncActivityOnMARCo()">
            <v-list-item-avatar>
              <v-avatar size="32px" tile>
                <img src="/assets/images/marco-in-chair-sync.png" />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>On MARCo</v-list-item-title>
          </v-list-item>
          <v-list-item @click="launchSyncActivityOnDevice()">
            <v-list-item-avatar>
              <v-avatar size="32px" tile>
                <img src="/assets/images/chat-phone-img.jpg" />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>In App</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>

      <m-emotion-form 
        :screeningRawData="screeningData"
        :activated="showEmotionForm"
        @closeEmotionScreening="
          showEmotionForm = !showEmotionForm;
          loadUserSessionLogs();
        "
        @closeEmotionForm="
          showEmotionForm = !showEmotionForm;
          loadUserSessionLogs();
        "
        @genspeech="appendTextWithAudio"
        @selectedActivities="
          showEmotionForm = false;
          popupEnabled = true;
          currPopupTitle = 'Activities & Goals';
          windowCase = 5;
          loadUserSessionLogs();
          sndTxt('Do activities');
        "
        :rawUsrData="rawUsrData"
        :mode="emotionFormMode"
        @saveForm="saveEmotionForm"
        ref="emotionform"
        @selecttalk="selectTalkCase"
      ></m-emotion-form>
      <v-dialog
        persistent
        v-model="ShowSettings"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        style="z-index: 20000"
      >
        <v-card>
          <v-toolbar color="blue" dark elevation="3">
            <v-toolbar-title>Settings</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="ShowSettings = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <template v-slot:extension>
              <v-tabs center-active v-model="settingsTab" show-arrows>
                <v-tab href="#tab-0">Basic Settings</v-tab>
                <v-tab href="#tab-1">MARCo Settings</v-tab>
                <v-tab href="#tab-2">Preferences</v-tab>
                <v-tab href="#tab-3">Linked Accounts</v-tab>
                <v-tab href="#tab-4">About MARCo</v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="settingsTab">
            <!--First is the basic user account info-->
            <v-tab-item key="0" value="tab-0">
              <v-container>
                <v-alert
                  prominent
                  outlined
                  text
                  color="cyan"
                  v-if="rawUsrData.userPersonality.userGeneral.accountInfo.accountManager.id.length > 2"
                  dismissible
                >
                  <template v-slot:prepend>
                    <v-avatar class="mr-2">
                      <!--<v-img :src="require(loadLogoUrl())" alt="/assets/images/meditationcardbkg.jpg"></v-img>-->
                      <v-icon>mdi-bank</v-icon>
                    </v-avatar>
                  </template>
                  Your MARCo account is provided by
                  {{ rawUsrData.userPersonality.userGeneral.accountInfo.accountManager.name }}! Check out the
                  {{ rawUsrData.userPersonality.userGeneral.accountInfo.accountManager.name }}'s terms of use here.
                  <v-divider class="my-2"></v-divider>
                  <v-btn outlined color="cyan darken-2">READ MORE</v-btn>
                </v-alert>

                <v-subheader>Your info:</v-subheader>
                <v-form>
                  <v-text-field
                    style="padding-left: 15px"
                    label="Your Name"
                    append-outer-icon="mdi-content-save-outline"
                    @click:append-outer="saveName"
                    placeholder="User Name"
                    v-model="usrDisplayName"
                  ></v-text-field>

                  <v-text-field
                    style="padding-left: 15px"
                    label="User Since:"
                    :value="rawUsrData.userPersonality.userGeneral.firstDate"
                    readonly
                  >
                  </v-text-field>
                  <v-text-field style="padding-left: 15px" label="User Type:" :value="usrAccountType" readonly>
                    <!-- <template v-slot:append-outer>
                                    <v-btn color="success" :outlined="upgradeBtnText.toLowerCase() == 'manage' " :style="upgradeBtnText.toLowerCase() == 'manage' ? '':'box-shadow:rgb(70,201,58) 0px 24px 35px -10px'"  class="rounded-xl"  @click="upgradeManageUsr()">{{upgradeBtnText}}</v-btn>
                                </template> -->
                  </v-text-field>

                  <div id="birthday">
                    <v-subheader>My Birthday:</v-subheader>
                    <v-menu
                      ref="birthdayMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="birthDate"
                          label="Birthday date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :landscape="!isMobile()"
                        v-model="birthDate"
                        :active-picker.sync="activePicker"
                        :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="
                          rawUsrData.basicInformation.birthDate = birthDate;
                          updateUser();
                        "
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <v-divider></v-divider>
                  <v-subheader>Personality & Interests</v-subheader>
                  <v-card-title>Things I Like</v-card-title>
                  <v-card-subtitle
                    >None of this information is required, but for a more personalized experience with MARCo, feel free
                    to fill in any or all of the fields below! You can choose from suggested options or type in your
                    own</v-card-subtitle
                  >
                  <v-tabs v-model="likedTabs" fixed-tabs>
                    <v-tab href="#liked-tab-0">Favorite Animals</v-tab>
                    <v-tab href="#liked-tab-1">Hobbies</v-tab>
                    <v-tab href="#liked-tab-2">Faith/Religion</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="likedTabs">
                    <v-tab-item key="4" value="liked-tab-0">
                      <v-combobox
                        style="padding-left: 15px"
                        label="Favorite Animals"
                        filled
                        multiple
                        small-chips
                        v-model="rawUsrData.userPersonality.userClassifications['liked-animals']"
                        :items="animalsList"
                        item-text="name"
                        item-value="value"
                      ></v-combobox>
                    </v-tab-item>
                    <v-tab-item key="5" value="liked-tab-1">
                      <v-combobox
                        style="padding-left: 15px"
                        label="Hobbies"
                        filled
                        multiple
                        small-chips
                        v-model="rawUsrData.userPersonality.userClassifications['classifications-hobbies']"
                        :items="hobbiesList"
                        item-text="name"
                        item-value="value"
                      ></v-combobox>
                    </v-tab-item>
                    <v-tab-item key="6" value="liked-tab-2">
                      <v-combobox
                        style="padding-left: 15px"
                        label="Faith/Religion"
                        filled
                        small-chips
                        v-model="rawUsrData.userPersonality.userClassifications['classifications-religion']"
                        :items="faithList"
                        item-text="name"
                        item-value="value"
                      ></v-combobox>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-btn
                    style="margin-left: 15px; margin-bottom: 8px"
                    elevation="0"
                    color="secondary"
                    @click="updateUserPreferences()"
                    >Save Changes</v-btn
                  >

                  <v-divider></v-divider>

                  <!--<v-text-field
                    style="padding-left: 15px"
                    label="Sync to MARCo"
                    placeholder="QQQQQ"
                    v-model="syncCode"
                    :disabled="isSyncingApp"
                    @keypress="checkForSyncAttempt"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        color="secondary darken--2"
                        :disabled="syncCode.length != 5 || isSyncingApp"
                        @click="syncAccountHandler.syncToMARCo()"
                        ><v-icon>mdi-sync</v-icon>Sync</v-btn
                      >
                    </template>
                  </v-text-field>

                  <v-switch
                    style="padding-left: 15px"
                    :label="isSyncingApp ? 'Disconnect from MARCo' : 'Control MARCo from this device'"
                    @click="decideSyncHandling"
                    :value="isSyncingApp"
                    color="secondary"
                  >
                  </v-switch>

                  <v-alert style="padding-left: 15px" v-if="isSyncingApp" color="info" text icon="mdi-head-sync-outline" prominent>
                    <v-row align="center">
                      <v-col class="grow"> You are currently syncing to a MARCo. End syncing to use the MARCo app alone. </v-col>
                      <v-col class="shrink">
                        <v-btn color="warning" @click="disconnectRealtimeSyncs"> <v-icon>mdi-sync-off</v-icon>Disconnect</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>-->

                  <v-divider></v-divider>
                  <v-subheader>Account Management</v-subheader>
                  <v-btn rounded depressed elevation="0" color="blue-grey" class="ma-2 white--text" @click="signOut"
                    ><v-icon>mdi-account-arrow-left-outline</v-icon> Sign Out</v-btn
                  >
                  <v-divider></v-divider>
                  <v-divider class="my-2"></v-divider>

                  <v-alert type="error" prominent outlined text border="right"
                    >DANGER ZONE: Anything below this line is irreversible.</v-alert
                  >
                  <v-dialog v-model="deleteAccountModal">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        class="white--text rounded-pill"
                        style="box-shadow: red 2px 12px 24px -12px"
                        v-on="on"
                        >⚠️ DELETE MY ACCOUNT</v-btn
                      >
                    </template>
                    <v-sheet class="pa-5">
                      <h2 color="red">ARE YOU <i>SURE</i> YOU WANT TO DELETE YOUR ACCOUNT?</h2>
                      <span>🚨This action CANNOT be undone.🚨</span>
                      <v-card-actions>
                        <v-btn text @click="deleteAccountModal = false">NO</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          elevation="0"
                          color="red"
                          class="white--text"
                          @click="deleteUserData()"
                          :loading="deleteLoading"
                          >YES</v-btn
                        >
                      </v-card-actions>
                    </v-sheet>
                  </v-dialog>

                  <!-- <m-empty>Testing the empty</m-empty>
                            <v-btn @click="startingEmotionForm">Show emotion form</v-btn>-->

                  <v-switch
                    v-if="false"
                    v-model="allowAutoGeneratedResponses"
                    @click="
                      rawUsrData.userPersonality.userGeneral.allowAutoGeneratedResponses = allowAutoGeneratedResponses;
                      updateUser();
                    "
                    label="Allow Auto Generated Responses"
                  ></v-switch>
                </v-form>
                <v-list-item></v-list-item>
                <v-list-item></v-list-item>
                <v-list-item></v-list-item>
              </v-container>
            </v-tab-item>
            <v-tab-item key="1" value="tab-1">
              <m-device-control> </m-device-control>
            </v-tab-item>
            <!--Second is the user's preferences-->
            <v-tab-item key="2" value="tab-2">
              <v-container>
                <v-form>
                  <v-subheader>MARCo Preferences</v-subheader>
                  <v-card-title>Choose your language:</v-card-title>
                  <v-select
                    v-model="rawUsrData.userPersonality.userGeneral.spokenLanguage"
                    :items="langs"
                    item-text="language"
                    item-value="code"
                    dense
                    outlined
                    rounded
                    label="Language"
                    @change="
                      previewMsg = translateText(
                        previewingMsgDefault,
                        rawUsrData.userPersonality.userGeneral.spokenLanguage
                      );
                      updateUserPreferences();
                    "
                  >
                  </v-select>
                  <v-card-title>Choose MARCo's Voice:</v-card-title>
                  <v-select
                    style="padding-left: 15px"
                    v-model="rawUsrData.userPersonality.userGeneral.preferredVoice"
                    :items="voiceProfs"
                    item-text="name"
                    item-value="id"
                    dense
                    label="Voice Profile"
                    @change="updateUserPreferences()"
                  >
                  </v-select>
                  <v-btn
                    text
                    color="secondary"
                    block
                    class="mx-2 my-3"
                    @click="previewMARCoVoice(previewingMsgDefault)"
                    :loading="previewingVoice"
                  >
                    <v-icon>mdi-volume-high</v-icon> Preview
                  </v-btn>
                  <v-divider></v-divider>
                  <v-card-title>Content Preferences</v-card-title>
                  <p class="px-4">
                    Here you can let MARCo know what you want to see and what you definitely don't want to see in
                    activities and chats.
                  </p>

                  <ContentModeration :userId="uid"></ContentModeration>
                  <v-divider class="pt-4"></v-divider>
                  <v-card-title>Notification Preferences</v-card-title>
                  <v-text-field
                    style="padding-left: 15px"
                    label="Phone Number:"
                    append-outer-icon="mdi-content-save-outline"
                    @click:append-outer="savePhone"
                    placeholder="5555555555"
                    v-model="rawUsrData.basicInformation['basic-user-phone-num']"
                    @input="phoneNumModified = true"
                  >
                    <template v-slot:prepend>
                      <v-dialog v-model="countryCodeHandler.countryCodeDialog">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            :color="countryCodeHandler.selectedCountryCode.length > 0 ? 'black' : 'red'"
                            v-on="on"
                            @click="loadCountryCodes()"
                            >{{
                              countryCodeHandler.selectedCountryCode.length > 0
                                ? countryCodeHandler.selectedCountryCode.split('-')[0]
                                : '+?'
                            }}</v-btn
                          >
                        </template>
                        <v-card class="pa-2">
                          <v-card-title>Set country code:</v-card-title>
                          <v-divider></v-divider>
                          <v-text-field
                            v-model="countryCodeHandler.filter"
                            filled
                            rounded
                            dense
                            class="mx-4"
                            clearable
                            label="Filter"
                            style="margin-top: 5px"
                            prepend-icon="mdi-filter"
                          ></v-text-field>
                          <v-radio-group
                            style="max-height: 45vh; overflow: scroll"
                            v-if="countryCodeHandler.isLoaded"
                            v-model="countryCodeHandler.selectedCountryCode"
                          >
                            <v-radio
                              v-for="code in filteredCountryCodes"
                              :key="`${code['dial_code']}-${code.code}`"
                              :label="`${code['dial_code']} - ${code.name}`"
                              :value="`${code['dial_code']}-${code.code}`"
                            ></v-radio>
                          </v-radio-group>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-btn text @click="countryCodeHandler.countryCodeDialog = false">Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                rawUsrData.basicInformation.countryCode = countryCodeHandler.selectedCountryCode;
                                updateUser();
                                updateMessagingSubscriptions();
                                countryCodeHandler.countryCodeDialog = false;
                              "
                              >SET</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-text-field>
                  <v-fade-transition>
                    <v-btn
                      text
                      color="secondary"
                      block
                      @click="sendPreviewText()"
                      class="mx-1 my3"
                      :disabled="!(countryCodeHandler.selectedCountryCode.length > 0)"
                      v-if="phoneNumModified"
                    >
                      Send Sample Message
                    </v-btn>
                  </v-fade-transition>

                  <v-subheader
                    v-if="
                      rawUsrData.basicInformation['basic-user-phone-num'].length > 0 &&
                      countryCodeHandler.selectedCountryCode.length < 1
                    "
                  >
                    <span style="color: red">Press the "+?" icon to add a country code.</span></v-subheader
                  >

                  <v-alert prominent type="info" icon="mdi-flask"
                    >6/16/2022 - MARCo's text messages are in beta for robot users only. To set a phone notification
                    schedule, you must be syncing to a MARCo. Emergency text messages will not be affected.</v-alert
                  >

                  <v-subheader>Regular checkins:</v-subheader>

                  <v-card-subtitle>MARCo can text me to check in on me:</v-card-subtitle>

                  <v-radio-group
                    style="margin-left: 15px"
                    v-model="dailyContactSubscriptions.contactFrequency"
                    @change="updateMessagingSubscriptions"
                    :readonly="!isSyncingApp"
                  >
                    <v-radio label="Daily" value="5"> </v-radio>
                    <v-radio label="Every other day" value="4"> Every other day </v-radio>
                    <v-radio label="Weekly" value="3"> Weekly </v-radio>
                    <v-radio label="Bi-Weekly" value="2"> </v-radio>
                    <v-radio label="Monthly" value="1"> </v-radio>
                    <v-radio label="Never" value="0"> </v-radio>
                  </v-radio-group>

                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><v-icon>mdi-sleep</v-icon> Quiet Hours</v-list-item-title>
                      <v-list-item-subtitle>When is MARCo NOT allowed to text you?</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list two-line>
                    <template v-for="(quietHour, q) in dailyContactSubscriptions.quietHours">
                      <v-list-item :key="`quietTitle-${q}`">
                        <v-list-item-content
                          ><v-card-subtitle>Quiet Hours {{ q + 1 }}</v-card-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-dialog :ref="`deletequiethour-${q}`" height="200px" v-model="quietHour.showRemoveDialog">
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>

                            <v-card height="200" class="pa-2">
                              <v-card-title color="red darken-2">Remove this quiet hour?</v-card-title>
                              <v-card-subtitle>This action cannot be undone.</v-card-subtitle>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-btn text @click="quietHour.showRemoveDialog = false">NO</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="error"
                                  @click="
                                    quietHour.showRemoveDialog = false;
                                    removeQuietHour(q);
                                  "
                                  >YES</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-list-item-action>
                      </v-list-item>
                      <v-dialog
                        :ref="`timepickdialog-${q}`"
                        v-model="quietHour.showStartTimeDialog"
                        :return-value.sync="timepicktime"
                        persistent
                        width="290px"
                        :key="`quietstartend-dialog-${q}`"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="quietHour.startTime"
                            label="Start Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker v-if="quietHour.showStartTimeDialog" v-model="quietHour.startTime" full-width>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="quietHour.showStartTimeDialog = false"> Cancel </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              quietHour.showStartTimeDialog = false;
                              updateMessagingSubscriptions();
                            "
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                      <v-dialog
                        :ref="`timepickdialog2-${q}`"
                        v-model="quietHour.showEndTimeDialog"
                        persistent
                        width="290px"
                        :key="`quietend-dialog-${q}`"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="quietHour.endTime"
                            label="End Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker v-if="quietHour.showEndTimeDialog" v-model="quietHour.endTime" full-width>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="quietHour.showEndTimeDialog = false"> Cancel </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              quietHour.showEndTimeDialog = false;
                              updateMessagingSubscriptions();
                            "
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                      <v-divider :key="`ruleDivider-${q}`"></v-divider>
                    </template>
                  </v-list>
                  <v-list-item-group>
                    <v-divider></v-divider>
                    <v-list-item @click="addQuietHourRule">
                      <v-list-item-action>
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-card-subtitle>Add quiet hours</v-card-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list-item-group>

                  <!-- <v-select
                                v-model="contactFrequency"
                                :items="contactFrequencyItems"
                                item-text="text"
                                item-value="value"
                                dense
                                label="Text frequency"
                                :disabled="!rawUsrData.basicInformation['basic-user-phone-num']"
                                :error-messages="rawUsrData.basicInformation['basic-user-phone-num'] ? 'Choose how frequently you want MARCo to text you.':'Fill in a phone number under settings first.'"
                                :error="!rawUsrData.basicInformation['basic-user-phone-num']"
                                >
                                </v-select> -->
                </v-form>
                <v-list-item></v-list-item>
                <v-list-item></v-list-item>
                <v-list-item></v-list-item>
              </v-container>
            </v-tab-item>

            <!--Third is the Customized Preferences-->

            <!--Fourth is the Linked Accounts-->
            <v-tab-item key="3" value="tab-3">
              <v-container>
                <v-card-title>My Linked Accounts:</v-card-title>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon :color="syncAccountHandler.syncedAccounts.includes('google') ? 'primary' : 'grey'"
                        >mdi-google</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content
                      :class="syncAccountHandler.syncedAccounts.includes('google') ? 'primary--text' : 'grey--text'"
                    >
                      Google
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :disabled="syncAccountHandler.syncedAccounts.includes('google')"
                        @click="syncAccountHandler.syncGoogle()"
                      >
                        <v-icon>mdi-account-plus-outline</v-icon>
                        Add
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon :color="syncAccountHandler.syncedAccounts.includes('facebook') ? 'primary' : 'grey'"
                        >mdi-facebook</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content
                      :class="syncAccountHandler.syncedAccounts.includes('facebook') ? 'primary--text' : 'grey--text'"
                    >
                      Facebook
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :disabled="syncAccountHandler.syncedAccounts.includes('facebook')"
                        @click="syncAccountHandler.syncFacebook()"
                      >
                        <v-icon>mdi-account-plus-outline</v-icon>
                        Add
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon :color="syncAccountHandler.syncedAccounts.includes('apple') ? 'primary' : 'grey'"
                        >mdi-apple</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content
                      :class="syncAccountHandler.syncedAccounts.includes('apple') ? 'primary--text' : 'grey--text'"
                    >
                      Apple
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :disabled="syncAccountHandler.syncedAccounts.includes('apple')"
                        @click="syncAccountHandler.syncApple()"
                      >
                        <v-icon>mdi-account-plus-outline</v-icon>
                        Add
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon :color="syncAccountHandler.syncedAccounts.includes('password') ? 'primary' : 'grey'"
                        >mdi-email-outline</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content
                      :class="syncAccountHandler.syncedAccounts.includes('password') ? 'primary--text' : 'grey--text'"
                    >
                      Email and Password
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn @click="newEmailForm = true">
                        <v-icon>mdi-account-plus-outline</v-icon>
                        Add
                      </v-btn>
                    </v-list-item-action>
                    <v-dialog :fullscreen="true" :width="isMobile() ? '100vw' : '33vw'" v-model="newEmailForm">
                      <v-card width="100%" class="pa-4">
                        <v-container>
                          <v-card-text>Sync new Email:</v-card-text>
                          <v-divider></v-divider>
                          <v-form>
                            <v-text-field
                              prepend-icon="mdi-email-outline"
                              label="Your Email"
                              v-model="syncAccountHandler.email1"
                              :rules="[syncAccountHandler.emailRules.required, syncAccountHandler.emailRules.isEmail]"
                            ></v-text-field>
                            <v-text-field
                              prepend-icon="mdi-email-outline"
                              label="Confirm Email"
                              v-model="syncAccountHandler.email2"
                              :rules="[
                                syncAccountHandler.emailRules.required,
                                syncAccountHandler.emailRules.isEmail,
                                syncAccountHandler.emailRules.emailMatch,
                              ]"
                            ></v-text-field>
                            <v-text-field
                              v-model="syncAccountHandler.pwd1"
                              :rules="[syncAccountHandler.emailRules.required, syncAccountHandler.emailRules.min]"
                              :append-icon="syncAccountHandler.showPwd1 ? 'mdi-eye' : 'mdi-eye-off'"
                              label="New Password"
                              :type="syncAccountHandler.showPwd1 ? 'text' : 'password'"
                              @click:append="syncAccountHandler.showPwd1 = !syncAccountHandler.showPwd1"
                            ></v-text-field>
                            <v-text-field
                              v-model="syncAccountHandler.pwd2"
                              :rules="[syncAccountHandler.emailRules.required, syncAccountHandler.emailRules.pwdMatch]"
                              :append-icon="syncAccountHandler.showPwd2 ? 'mdi-eye' : 'mdi-eye-off'"
                              label="Confirm Password"
                              :type="syncAccountHandler.showPwd2 ? 'text' : 'password'"
                              @click:append="syncAccountHandler.showPwd2 = !syncAccountHandler.showPwd2"
                            ></v-text-field>
                          </v-form>
                          <v-card-actions>
                            <v-btn text @click="newEmailForm = false">Cancel</v-btn>
                            <v-btn
                              text
                              color="primary"
                              :disabled="!syncAccountHandler.emailFormIsValid()"
                              @click="syncAccountHandler.syncEmailAndPwd()"
                              >Submit</v-btn
                            >
                          </v-card-actions>
                        </v-container>
                      </v-card>
                    </v-dialog>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list>
              </v-container>
              <v-list-item></v-list-item>
              <v-list-item></v-list-item>
              <v-list-item></v-list-item>
            </v-tab-item>

            <!--Fifth is About MARCo Online-->
            <v-tab-item key="4" value="tab-4">
              <v-container>
                <v-card-title>Kiosk Version:</v-card-title>
                <v-card-subtitle>1.2.4</v-card-subtitle>
                <v-divider></v-divider>
                <v-card-title>Legal</v-card-title>
                <v-card-subtitle>Copyright MARCo Health Inc. 2023</v-card-subtitle>
                <v-card-actions
                  ><v-btn text @click="getPrivacyTerms(true)">Privacy Policy</v-btn>
                  <v-btn text @click="getPrivacyTerms(false)">Terms and Conditions</v-btn></v-card-actions
                >
                <v-divider></v-divider>
                <v-card-title>Safety and Efficacy:</v-card-title>
                <p class="my-profile-text" style="text-decoration: none">
                  It is our committment to providing a safe and helpful experience with MARCo. To this end, we strive to
                  be transparent about the sources of the advice that MARCo provides. Below, you may find a list of the
                  clinical resources used in the development of MARCo's mental health assisting methodology and advice:
                </p>
                <br />
                <ul>
                  <li class="my-profile-text">
                    Recommendations for the CSSRS and any high-risk decisions come from the Lighthouse Project of
                    Columbia University and the American Foundation for Suicide Prevention.
                    <a href="https://cssrs.columbia.edu" target="_blank">Learn more here</a>
                  </li>
                  <br />
                  <br />
                  <li class="my-profile-text">
                    Talk Support activities derived from "A Therapist's Guide to Brief Cognitive Behaivoral Therapy" by
                    Jeffrey A. Cully, Ph.D and Andra L. Teten, Ph.D<a
                      href="https://mirecc.va.gov/visn16/docs/therapists_guide_to_brief_cbtmanual.pdf"
                      target="_blank"
                      >Learn more here</a
                    >
                  </li>
                  <br />
                  <br />
                  <li class="my-profile-text">
                    Additional Talk Support advice and stress relief advice is derived from Queen's University "Your
                    Best You, Managing Your Anxiety" developed by Mary Acreman, PhD; Jenn Bossio, PhD; Carole-Ann
                    Vatcher, MSW, RSW; Freeman Woolnough, MEd, CCC<a href="https://hcds.queensu.ca" target="_blank"
                      >Learn more here</a
                    >
                  </li>
                  <br />
                  <br />
                </ul>
                <p class="my-profile-text">
                  MARCo is not a medical device. The advice, exercises, activities, and information that MARCo provides
                  is not intended to diagnose, cure, prevent, or mitigate any illnesses. Before making any medical
                  decisions, consult a doctor or licensed professional. MARCo should be used in conjunction with a
                  healthy lifestyle and regular treatment if applicable.
                </p>
                <br />
                <p class="my-profile-text">
                  If you have any questions, comments, or concerns regarding the safety, efficacy, or methodology of
                  MARCo, you may contact our safety officer at:
                  <br />
                  <a href="mailto:safety@marcohealthtech.com">safety@marcohealthtech.com</a>
                </p>
                <v-list-item></v-list-item>
                <v-list-item></v-list-item>
                <v-list-item></v-list-item>
                <v-btn @click="factoryReset">Factory Reset</v-btn>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showPrivacyTerms">
        <v-card class="rounded-xl" elevation="12">
          <v-card-title>{{ isPrivacyPolicy ? 'Privacy Policy' : 'Terms and Conditions' }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="max-height: 45vh; overflow: scroll">
            <!--<m-privacy-policy v-if="isPrivacyPolicy"></m-privacy-policy>
                    <m-terms-conditions v-if="!isPrivacyPolicy"></m-terms-conditions>-->
            <div v-html="privacyTermsData"></div>
            <v-progress-circular indeterminate v-if="typeof privacyTermsData !== `string`"></v-progress-circular>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              text
              outlined
              color="primary"
              @click="
                showPrivacyTerms = false;
                privacyTermsData = null;
              "
              >I Understand</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="toast"
        timeout="3500"
        elevation="0"
        bottom
        color="rgba(0,0,0,0)"
        transition="scale-transition"
        style="z-index: 20004"
      >
        <v-alert v-bind:type="alertType" elevation="8" width="100%" height="100%" style="z-index: 20004">{{ alertMsg }}</v-alert>
      </v-snackbar>

      <v-snackbar :timeout="2000" v-model="marcoinsnackbar" color="light-blue accent-3" elevation="8" height="60">
        <v-list-item dense>
          <v-list-item-avatar>
            <v-img src="/assets/images/marcoin.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content> {{ marcoinsAdded }} MARCoins earned! </v-list-item-content>
        </v-list-item>
      </v-snackbar>

      <v-dialog v-model="defaultLoginHandler.showDefaultLogin" persistent>
        <m-default-login
          :rawUsrData="rawUsrData"
          :mode="defaultLoginHandler.mode"
          @switchMode="defaultLoginHandler.switchMode"
          @closePinDialog="defaultLoginHandler.showDefaultLogin = false"
          @showToast="showToast"
          @setDefaultUsrPin="defaultLoginHandler.setDefaultUsrPin"
          @removeDefaultUser="defaultLoginHandler.removeDefaultUser"
        >
        </m-default-login>
      </v-dialog>

      <v-dialog persistent v-model="micOn" v-if="!isJournaling && !showEmotionForm">
        <v-card min-height="80%" class="rounded-xl" elevation="0">
          <v-row class="my-2">
            <v-avatar class="pulse-css"
              ><v-img src="/assets/images/svgs/microphone-technology-svgrepo-com.svg" width="24"> </v-img
            ></v-avatar>
          </v-row>
          <v-card-text v-if="recordedTranscript.length > 0 || transientRecordedTranscript.length > 0">
            <span>{{ recordedTranscript }}</span>
            <span style="font-weight: bold; color: #2eb813">{{ transientRecordedTranscript }}</span>
          </v-card-text>
          <v-card-subtitle v-else>Speak now</v-card-subtitle>
        </v-card>
      </v-dialog>

      <div>
        <v-bottom-sheet v-model="syncingDialog" persistent no-click-animation>
          <v-sheet class="openingSheet">
            <v-window v-model="syncWindow" continuous touchless>
              <v-window-item :value="1">
                <v-card-title>Sync to MARCo:</v-card-title>
                <v-card-subtitle>Pick how you want to connect.</v-card-subtitle>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item v-for="(item, i) in syncOptions" :key="`syncoption-${i}`" @click="item.action()">
                    <v-list-item-avatar>
                      <v-icon color="primary">{{ item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>{{ item.title }}</v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-window-item>

              <v-window-item :value="2">
                <h4>Choose a network:</h4>
                <v-divider></v-divider>
                <v-window v-model="networkSetupStep" touchless>
                  <v-window-item :value="0">
                    <h3>Is your MARCo powered on and connected to wifi?</h3>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" color="primary" @click="syncWindow++">YES</v-btn>
                      <v-btn text @click="networkSetupStep++">No</v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="1">
                    <v-alert text color="info">
                      <h5>Do you want to use the network this device is connected to?</h5>

                      <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

                      <v-row align="center" no-gutters>
                        <v-col class="grow">
                          <v-btn elevation="0" color="info" class="rounded-pill" @click="getCurrentNetwork">YES</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="shrink">
                          <v-btn elevation="0" class="rounded-pill" color="info" outlined @click="chooseNetwork"
                            >NO</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-window-item>

                  <v-window-item :value="2">
                    <v-list style="max-height: 30vh; overflow: auto">
                      <v-list-item @click="networkSetupStep++">
                        <v-list-item-icon><v-icon>mdi-plus</v-icon></v-list-item-icon>
                        <v-list-item-content> Add New Network </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <div v-if="networks.length < 1">
                        <v-skeleton-loader
                          v-for="n in 4"
                          type="list-item-avatar, divider"
                          :key="`network-skeleton-${n}`"
                        ></v-skeleton-loader>
                      </div>
                      <div v-if="networks.length > 0">
                        <v-list-item
                          active-class="blue--text"
                          v-for="(network, n) in networks"
                          :key="`network-${n}`"
                          @click="selectNetwork(network)"
                        >
                          <v-list-item-avatar>
                            <v-icon color="blue darken-2">{{
                              `mdi-wifi-strength-${rssiCalculator(network.level)}`
                            }}</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>{{ network.SSID }}</v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-window-item>
                  <v-window-item :value="3">
                    <v-container fluid>
                      <v-subheader>Network name:</v-subheader>

                      <h4 v-if="currNetwork.SSID">{{ currNetwork.SSID }}</h4>
                      <v-text-field label="Network Name" v-model="currSSID" v-if="!currNetwork.SSID"></v-text-field>

                      <v-subheader>Network Password:</v-subheader>
                      <v-text-field
                        label="Password"
                        v-model="currNetworkPWD"
                        hint="Not required, enter if there is a password"
                        persistent-hint
                        :append-icon="showNetworkPwd ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showNetworkPwd = !showNetworkPwd"
                        :type="showNetworkPwd ? 'text' : 'password'"
                      ></v-text-field>

                      <v-btn @click="networkSetupStep++" block rounded text color="secondary"
                        >Next <v-icon>mdi-arrow-right</v-icon></v-btn
                      >
                    </v-container>
                  </v-window-item>

                  <!-- <v-window-item :value="4">
                                        <v-row justify="center">
                                            <v-spacer></v-spacer>
                                            <v-col cols="9">
                                                <v-skeleton-loader type="card-avatar" v-if="typeof(wifiQR) != 'object'"></v-skeleton-loader>
                                            <div id="wifiQRCode"></div>
                                        </v-col>
                                            <v-spacer></v-spacer>
                                            </v-row>
                                    </v-window-item>-->

                  <v-window-item :value="4">
                    <v-card-title>How should we connect?</v-card-title>
                    <v-list>
                      <v-list-item @click="generateNetworkQR">
                        <v-list-item-icon>
                          <v-icon color="blue">mdi-qrcode-scan</v-icon>
                        </v-list-item-icon>
                        QR Code
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="verifyBLE(getBluetoothList)">
                        <v-list-item-icon>
                          <v-icon color="blue">mdi-bluetooth</v-icon>
                        </v-list-item-icon>
                        Bluetooth
                        <v-spacer></v-spacer>
                        <v-list-item-action>
                          <v-chip dense><v-icon>mdi-flask</v-icon> Beta</v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-window-item>

                  <v-window-item :value="5">
                    <v-row justify="center">
                      <v-spacer></v-spacer>
                      <v-col cols="12">
                        <v-skeleton-loader type="card-avatar" v-if="typeof wifiQR != 'object'"></v-skeleton-loader>
                        <div id="wifiQRCode"></div>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                      <h5 class="text-center">Tap once MARCo has connected</h5>
                      <v-btn
                        block
                        rounded
                        elevation="0"
                        color="primary"
                        @click="
                          this.syncWindow = 4;
                          this.decideSyncHandling();
                        "
                        >DONE</v-btn
                      >
                    </v-row>
                  </v-window-item>

                  <v-window-item :value="6">
                    <v-card-title>Scanning for MARCos...</v-card-title>
                    <v-progress-linear color="blue" indeterminate></v-progress-linear>
                  </v-window-item>

                  <v-window-item :value="7">
                    <h3>No MARCo's were found.</h3>
                    <span>Please ensure that your MARCo is powered on and you selected bluetooth on your MARCo.</span>
                    <span
                      >Do you want to try again? Select no to choose an alternate method of connecting MARCo to a
                      network.</span
                    >
                    <v-card-actions>
                      <v-btn elevation="0" rounded color="warning" @click="networkSetupStep = 4">NO</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" color="primary" rounded @click="verifyBLE(getBluetoothList)">YES</v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="8">
                    <v-fade-transition>
                      <v-card-title :key="`connection-title-${connectionTitleStep}`">{{
                        connectionTitles[connectionTitleStep]
                      }}</v-card-title>
                    </v-fade-transition>

                    <!--<v-list-item>
                                            <v-list-item-content>{{marcoBle}}</v-list-item-content>
                                        </v-list-item>
                                        <v-btn @click="connectBle(successfulConnection,errorConnection)">Connect</v-btn>
                                        <v-btn @click="bondBle">Bond</v-btn>-->
                  </v-window-item>
                </v-window>
                <v-divider></v-divider>
                <v-btn block text @click="networkSetupStep > 0 ? networkSetupStep-- : syncWindow--">BACK</v-btn>
                <v-list-item></v-list-item>
              </v-window-item>

              <v-window-item :value="3">
                <v-card-title>Great! Now let's connect...</v-card-title>

                <v-list class="text-left">
                  <v-list-item>1. On MARCo, tap "Wake MARCo" if you have not already</v-list-item>
                  <v-list-item>2. On MARCo, tap the far left button that says "Sync from device"</v-list-item>
                  <v-list-item>3. Enter the code that appears below (capitalization does not matter):</v-list-item>
                </v-list>

                <v-text-field
                  label="Sync to MARCo"
                  placeholder="QQQQQ"
                  v-model="syncCode"
                  :disabled="isSyncingApp"
                  @keypress="checkForSyncAttempt"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      elevation="0"
                      color="secondary darken--2"
                      :disabled="syncCode.length != 5 || isSyncingApp"
                      rounded
                      @click="syncAccountHandler.syncToMARCo()"
                      ><v-icon>mdi-sync</v-icon> Sync</v-btn
                    >
                  </template>
                </v-text-field>
                <v-list-item></v-list-item>
              </v-window-item>

              <v-window-item :value="4">
                <v-card-title> Syncing to MARCo... </v-card-title>
                <v-progress-linear color="primary" indeterminate></v-progress-linear>
              </v-window-item>
            </v-window>
            <v-divider></v-divider>
            <v-card-actions>
              <v-dialog v-model="confirmCloseSync" dark persistent width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined rounded text small color="warning" block v-on="on" v-bind="attrs">Cancel Sync</v-btn>
                </template>
                <v-card class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" color="blue-grey darken-3" dark>
                  <div class="grey--text text--lighten-1 text-body-2 mb-4">
                    Are you sure you want to cancel syncing to MARCo?
                  </div>

                  <v-btn class="ma-1" color="grey" plain @click="confirmCloseSync = false"> NO </v-btn>

                  <v-btn
                    class="ma-1"
                    color="error"
                    plain
                    @click="
                      confirmCloseSync = false;
                      syncingDialog = false;
                      syncWindow = 1;
                      showFab = true;
                    "
                  >
                    YES
                  </v-btn>
                </v-card>
              </v-dialog>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-sheet>
        </v-bottom-sheet>
      </div>
      <!-- modal -->
      <v-dialog v-model="likeDislikeDialog" width="400" @click:outside="clearFormAndFile()">
        <div class="dialog white custom-dialog-wrapper" style="padding-top: 24px">
          <v-form v-model="form.valid">
            <v-stepper v-model="e1">
              <v-stepper-items>
                <v-stepper-content step="1">
                  <div
                    style="display: flex; justify-content: center; align-items: center; flex-direction: column"
                    v-if="likeDislikeDialog"
                  >
                    <p class="text-center text-h6" style="font-weight: bold">
                      What did you think of this last activity?
                    </p>
                    <like-and-dislike-button
                      @upvote="handleUpvoteWish"
                      @downvote="handleDownvoteWish"
                    ></like-and-dislike-button>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <img
                    width="84px"
                    height="84px"
                    class="mx-auto d-block mb-4"
                    src="/assets/content-moderation/images/image_1.png"
                    alt="icon"
                  />
                  <h3 class="text-h5 text-center grey--text text--darken-3" style="font-weight: 500">
                    You Liked This!
                  </h3>
                  <p class="text-center" style="font-weight: bold mb-4">
                    Easily access this again by saving details you'll remember it by.
                  </p>
                  <v-text-field
                    class="mb-1"
                    color="green"
                    label="Title"
                    placeholder="Enter title"
                    hint="What should this media be called?"
                    outlined
                    persistent-hint
                    v-model="form.title"
                    :rules="form.titleRule"
                    :counter="20"
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    class="mb-1"
                    color="green"
                    label="Description"
                    placeholder="Enter your description"
                    hint="A few word summary"
                    outlined
                    persistent-hint
                    v-model="form.desc"
                    :rules="form.descRule"
                    :counter="40"
                    required
                  >
                  </v-text-field>
                  <v-select
                    class="mb-1"
                    color="green"
                    :items="form.defaultType"
                    item-text="label"
                    item-value="value"
                    outlined
                    persistent-hint
                    label="Type"
                    v-model="form.type"
                    :rules="form.typeRule"
                    required
                  ></v-select>
                  <div class="d-flex justify-space-between align-center">
                    <v-btn text class="green--text" elevation="0" rounded @click="toggleDialog" v-if="form.isEditing">
                      CLOSE
                    </v-btn>
                    <v-btn text class="green--text" elevation="0" rounded @click="e1 = 1" v-else> BACK </v-btn>
                    <v-btn
                      color="green darken-1"
                      class="white--text"
                      elevation="0"
                      rounded
                      @click="e1 = 3"
                      :disabled="!form.valid"
                    >
                      NEXT
                    </v-btn>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <h3 class="text-h5 text-center grey--text text--darken-3 mb-6" style="font-weight: 500">Usage</h3>
                  <p class="text-caption-1 text-center grey--text text--darken-3 mb-4" style="font-weight: 500">
                    Pick a few tags that represent when this is best used.
                  </p>
                  <v-chip-group
                    v-model="form.tags"
                    column
                    multiple
                    class="chip-group"
                    active-class="green darken--1 white--text"
                  >
                    <v-chip class="ma-1" filter v-for="item of form.defaultTags" v-bind:key="item" :value="item">
                      {{ item }}
                    </v-chip>
                  </v-chip-group>
                  <div class="d-flex justify-space-between align-center">
                    <v-btn text class="green--text" elevation="0" rounded @click="e1 = 2"> BACK </v-btn>
                    <v-btn
                      color="green darken-1"
                      class="white--text"
                      elevation="0"
                      rounded
                      :loading="isSaving"
                      @click="handleSave()"
                    >
                      SAVE
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </div>
      </v-dialog>

      <!--Save and exit dialog-->
      <v-dialog
        v-model="showSaveAndExitDialog"
        persistent
        max-width="500px"
        transition="dialog-bottom-transition"
        dark
        class="rounded-xl"
      >
        <v-card class="pa-4 rounded-xl">
          <v-card-title>
            <m-empty msg="Aw! Leaving already?"></m-empty>
          </v-card-title>
          <v-card-text>
            <p>Do you want to end your session here?</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelSaveAndExit">No</v-btn>
            <v-btn text color="primary" @click="saveAndExit">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--Last session bottom sheet-->
      <m-last-session-rating
        :showLastSessionRating="showLastSessionRating"
        :isMobile="isMobile()"
        :userId="rawUsrData.basicInformation.userID"
        @close="
          showLastSessionRating = false;
          showToast('Thanks for your feedback!');
        "
      ></m-last-session-rating>
    </v-main>
  </v-app>
</template>

<script>
import { ref, set, onValue, serverTimestamp } from 'firebase/database';
import { addDoc, collection, doc, getDoc, setDoc, Timestamp, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { EventBus } from './eventBus.js';
import ContentModeration from './contentModeration/ContentModeration.vue';
import empty from './empty.vue';
import axios from 'axios';

const mLastSessionRating = () => import('./ui/lastSessionRating.vue');
function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  console.log(urlparameter);
  return urlparameter;
}

var launchImmediately = getUrlParam('reactLaunch', false) || getUrlParam('isreact', false);
// Your web app's Firebase configuration
const memotion = () => import('./emotionForm.vue');
const marcorenderer = () => import('./marco-renderer.vue');
const chatbody = () => import('./chatbody.vue');
const mpopup = () => import('./popup.vue');

import { Capacitor } from '@capacitor/core';

import { Browser } from '@capacitor/browser';

import { Device } from '@capacitor/device';

console.log('The type of Browser is ' + typeof Browser);
console.log(Browser);

import { LocalNotifications } from '@capacitor/local-notifications';
console.log('LocalNotifications', LocalNotifications);

const marcodefaultusrcard = () => ({
  component: import('./defaultLoginHandler.vue'),
});

import requestBody from '../assets/json/requestBody.json';
import LikeDislikeButtonVue from './ui/LikeDislikeButton.vue';

const isCordova = typeof cordova == 'object';

var startupMessage = getUrlParam('startupmessage', 'startupMessage');
var finishedID = getUrlParam('session_id', false);
var annualSubComplete = getUrlParam('annualsub', false) == 'true';

// Safari 3.0+ "[object HTMLElementConstructor]"
var isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
var isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Chrome 1 - 79
var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

var ua = window.navigator.userAgent;
var iOSBase = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
var webkitExists = !!ua.match(/WebKit/i);
var isiOSSafari = iOSBase && webkitExists && !ua.match(/CriOS/i);

var sessionID = '' + Math.floor(Math.random() * 999999999) + '';
requestBody.sessionID = sessionID;
export default {
  components: {
    'marco-renderer': marcorenderer,
    'm-chat': chatbody,
    'm-popup': mpopup,
    'm-default-login': marcodefaultusrcard,
    'm-emotion-form': memotion,
    ContentModeration,

    'like-and-dislike-button': LikeDislikeButtonVue,
    'm-device-control': () => import('./deviceControls.vue'),
    'm-empty': empty,
    'm-last-session-rating': mLastSessionRating,
  },
  computed: {
    filteredCountryCodes() {
      if (typeof this.countryCodeHandler.filter !== 'string') {
        console.log('Returning the country codes');
        return this.countryCodeHandler.countryCodes;
      } else {
        console.log('Filtering the country codes');
        return this.countryCodeHandler.countryCodes.filter((code) =>
          code.name.toLowerCase().includes(this.countryCodeHandler.filter.toLowerCase())
        );
      }
    },
  },
  data: function () {
    return {
			showWidget: false,
      widgetKind: null,
      defaultTemplate: null,
      showLastSessionRating: false,
      showSaveAndExitDialog: false,
      screeningData: null,
      fillerTimeout: null,
      fillerTimeoutCount: 0,
      fileField: '',
      fileResults: 'Pending...',
      idelTimeout: null,
      isSaving: false,
      e1: 1,
      form: {
        valid: false,
        title: '',
        titleRule: [
          (v) => !!v || 'Title is required',
          (v) => v.length <= 20 || 'Title must be less than 20 characters',
        ],
        desc: '',
        descRule: [
          (v) => !!v || 'Description is required',
          (v) => v.length <= 40 || 'Description must be less than 20 characters',
        ],
        type: '',
        typeRule: [(v) => !!v || 'Type is required'],
        defaultType: [
          { value: 'video', label: 'Video' },
          { value: 'audio', label: 'Audio' },
          { value: 'link', label: 'Link' },
          { value: 'activity', label: 'Activity' },
          { value: 'article', label: 'Article' },
          { value: 'game', label: 'Game' },
        ],
        src: '',
        tags: [],
        defaultTags: ['Bored', 'Calm', 'Tired', 'Lonely', 'Relaxing', 'Busy', 'Stressed', 'Sad', 'Angry'],
      },
      mediaSrc2: '',
      likeDislikeDialog: false,
      uid: null,
      showFirstTimeDialog: false,
      dailyContactSubscriptions: {
        contactFrequency: 0,
        quietHours: [],
      },
      popupOpacity: 1,
      isJournaling: false,
      startupStep: 1,
      maxStartupSteps: 6,
      setupOption: -1,
      deviceVolume: 90,
      setAsDefault: false,
      countryCodeHandler: {
        countryCodes: [],
        isLoaded: false,
        filter: null,
        selectedCountryCode: '',
        countryCodeDialog: false,
      },

      showEmotionForm: false,
      emotionFormMode: 0,
      privacyTermsAgreement: false,
      previewingVoice: false,
      langs: [],
      previewMsg: [`This is what your MARCo will sound like`],
      syncWindow: 1,

      networks: [],
      currNetwork: {},
      networkSetupStep: 0,
      showNetworkPwd: false,
      wifiQR: false,

      connectionTitleStep: 0,
      marcoBle: {},
      bleService: '',
      bleCharacteristic: '',
      currSSID: '',
      currNetworkPWD: '',
      currNetworkSecurity: '',
      networkDialog: false,
      syncingDialog: false,
      confirmCloseSync: false,
      phoneNumRules: [(v) => (v.length < 12 && v.length > 9) || 'Not a valid phone number'],
      nextPhoneNum: '',
      isCordova: isCordova,
      isDevenv: false,
      marcoinsAdded: 0,
      trialDaysLeft: -1,
      deleteAccountModal: false,
      trialsnackbar: false,
      marcoinsnackbar: false,
      timerTime: 0,
      payDialog: false,
      allowPayCancel: true,
      orderLoading: false,
      defaultLoginHandler: {
        mode: 0,
        showDefaultLogin: false,
        defaultUserInfo: {
          defaultLogin: false,
          defaultUserID: false,
        },
        isDefaultUser: false,
        switchMode: (e) => {
          this.defaultLoginHandler.mode = e;
        },
        handlePINSetting: () => {
          if (this.rawUsrData.basicInformation.syncPin) {
            this.defaultLoginHandler.mode = 2;
            this.defaultLoginHandler.showDefaultLogin = true;
          } else {
            this.defaultLoginHandler.mode = 0;
            this.defaultLoginHandler.showDefaultLogin = true;
          }
        },
        changePIN: () => {
          this.defaultLoginHandler.mode = 0;
          this.defaultLoginHandler.showDefaultLogin = true;
        },

        removeDefaultUser: async () => {
          this.rawUsrData.basicInformation.allowSync = false;
          this.defaultLoginHandler.defaultUserInfo = {
            defaultLogin: false,
            defaultUserID: false,
          };

          this.updateUser();
          this.defaultLoginHandler.showDefaultLogin = false;
        },

        askToRemove: async () => {
          this.defaultLoginHandler.mode = 4;
          this.defaultLoginHandler.showDefaultLogin = true;
          this.appendTextWithAudio(
            {
              responseText: ["Are you sure you'd like to remove yourself as the default user?"],
            },
            'neutral'
          );
        },
        setDefaultUsrPin: (pin) => {
          //First, check that the pin is valid and secure
          if (pin.length < 3) {
            this.marcoTalk('Sorry, try choosing a more secure PIN, one with at least three buttons pressed.', 'sad');
          } else if (
            this.defaultLoginHandler.defaultUserInfo.defaultLogin &&
            this.defaultLoginHandler.defaultUserInfo.defaultUserID !== this.rawUsrData.basicInformation.userID
          ) {
            this.marcoTalk(
              'Uh oh, looks like someone is already set up as the default user. Have them sign out first.',
              'sad'
            );
            this.showToast('Someone is already set as the default user', 'error');
            this.defaultLoginHandler.showDefaultLogin = false;
          } else {
            this.rawUsrData.basicInformation.syncPin = pin;
            this.rawUsrData.basicInformation.allowSync = true;
            this.updateUser(async () => {
              this.showToast('Your new PIN has been set!', 'success');
              this.defaultLoginHandler.showDefaultLogin = false;
            });
          }
        },
      },

      subscriptionCode: '',
      activePicker: '0',
      chatbarTxt: '',
      langCode: 'en',

      marcoIsCalled: false,
      recognition: false,
      iosSpeech: false,
      phoneNumModified: false,
      emoteArray: [],
      allowAutoGeneratedResponses: false,
      rootEventHub: {},
      birthDate: '',
      timepicktime: 0,
      recordedTranscript: '',
      transientRecordedTranscript: '',
      sfxHandler: {
        bkgAudio: new Audio(),
        bkgAudioVolume: 0.4,
        eventAudio: new Audio(),
        audioClick: () => {
          if (!this.isSyncingApp) {
            this.sfxHandler.eventAudio.src = '/assets/audio/sfx/ui-click.mp3';
            this.sfxHandler.eventAudio.play();
          } else {
            EventBus.$emit('audioHandler', 'click');
          }
        },
        audioWoosh: () => {
          if (!this.isSyncingApp) {
            this.sfxHandler.eventAudio.src = '/assets/audio/sfx/woosh-1.mp3';
            this.sfxHandler.eventAudio.play();
          } else {
            EventBus.$emit('audioHandler', 'woosh');
          }
        },
        audioPop: () => {
          if (!this.isSyncingApp) {
            this.sfxHandler.eventAudio.src = '/assets/audio/sfx/ui-popup.mp3';
            this.sfxHandler.eventAudio.play();
          } else {
            EventBus.$emit('audioHandler', 'pop');
          }
        },
        audioCoin: () => {
          if (!this.isSyncingApp) {
            this.sfxHandler.eventAudio.src = '/assets/audio/sfx/coin-1.mp3';
            this.sfxHandler.eventAudio.play();
          } else {
            EventBus.$emit('audioHandler', 'coin');
          }
        },
        audioPurchase: () => {
          if (!this.isSyncingApp) {
            this.sfxHandler.eventAudio.src = '/assets/audio/sfx/purchase-sfx-1.mp3';
            this.sfxHandler.eventAudio.play();
          } else {
            EventBus.$emit('audioHandler', 'purchase');
          }
        },
        switchAudioPref: (audioFile) => {
          this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.audioFiles.default = audioFile;
          setTimeout(() => {
            this.updateUser();
            this.sfxHandler.handleBkgAudio();
          }, 800);
        },
        handleBkgAudio: () => {
          let audioArray = this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.audioFiles.default.sounds;
          if (audioArray.length > 0) {
            this.bkgAudioVolume = this.rawUsrData.userPersonality.userGeneral.preferredBkgVolume;

            try {
              this.bkgAudio.pause();
            } catch (err) {
              console.log('There is no audio to pause');
            }

            if (typeof this.bkgAudio != 'object') {
              this.bkgAudio = new Audio();
            }
            this.bkgAudio.dataset.index = 0;

            this.bkgAudio.volume = this.rawUsrData.userPersonality.userGeneral.preferredBkgVolume
              ? this.rawUsrData.userPersonality.userGeneral.preferredBkgVolume
              : 0.3; //TODO: GET VALUE FROM A VOLUME SLIDER OR OTHER INPUT

            if (audioArray.length > 1) {
              this.bkgAudio.addEventListener('ended', () => {
                if (parseInt(this.bkgAudio.dataset.index) < audioArray.length - 1) {
                  this.bkgAudio.dataset.index = parseInt(this.bkgAudio.dataset.index) + 1;
                } else {
                  this.bkgAudio.dataset.index = 0;
                }

                this.bkgAudio.src = audioArray[parseInt(this.bkgAudio.dataset.index)];
                this.bkgAudio.pause();
                this.bkgAudio.load();
                this.bkgAudio.play();
              });
            } else {
              this.bkgAudio.loop = true;
            }

            this.bkgAudio.src = audioArray[parseInt(this.bkgAudio.dataset.index)];
            this.bkgAudio.load();
            this.bkgAudio.play();
          }
        },
      },
      syncAccountHandler: {
        emailRules: {
          required: (value) => !!value || 'Required.',
          min: (v) => v.length >= 8 || 'Min 8 characters',
          emailMatch: (value) => value == this.syncAccountHandler.email1 || `Emails do not match`,
          pwdMatch: (value) => value == this.syncAccountHandler.pwd1 || `Passwords do not match`,
          isEmail: (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Invalid e-mail.';
          },
        },
        emailFormIsValid: () => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            this.syncAccountHandler.email1 == this.syncAccountHandler.email2 &&
            pattern.test(this.syncAccountHandler.email1) &&
            this.syncAccountHandler.pwd1 == this.syncAccountHandler.pwd2 &&
            this.syncAccountHandler.pwd1.length >= 8
          );
        },
        email1: '',
        email2: '',
        pwd1: '',
        pwd2: '',
        showPwd1: false,
        showPwd2: false,
        syncedAccounts: [],
        syncGoogle: () => {
          if (!launchImmediately || typeof cordova != 'object') {
            var googleProvider = new this.$auth.GoogleAuthProvider();

            this.$auth.currentUser
              .linkWithPopup(googleProvider)
              .then(() => {
                this.showToast('Your Google Account was successfully linked!', 'success', 2400);
              })
              .catch((err) => {
                this.showToast(`${err}`, 'error', 5000);
              });
          } else {
            try {
              Capacitor.Plugins.CapacitorFirebasethis.$auth.signIn({ providerId: 'google.com' }).then((data) => {
                var token = data.idToken;
                var credential = firebase.this.$auth.GoogleAuthProvider.credential(token);
                this.$auth.currentUser
                  .linkWithCredential(credential)
                  .then(() => {
                    this.showToast('Your Google Account was successfully linked!', 'success', 2400);
                  })
                  .catch((err) => {
                    this.showToast(`${err}`, 'error', 5000);
                  });
              });
            } catch (err) {
              this.showToast(err, 'error', 5000);
            }
          }
        },
        syncApple() {
          if (!launchImmediately || typeof cordova != 'object') {
            var provider = new firebase.this.$auth.OAuthProvider('apple.com');
            provider.addScope('email');
            provider.addScope('name');
            this.$auth.currentUser
              .linkWithPopup(provider)
              .then(() => {
                this.showToast('Your Apple Account was successfully linked!', 'success', 2400);
              })
              .catch((err) => {
                this.showToast(`${err}`, 'error', 5000);
              });
          } else {
            try {
              Capacitor.Plugins.CapacitorFirebasethis.$auth.signIn({ providerId: 'apple.com' }).then((data) => {
                var token = data.idToken;
                var credential = firebase.this.$auth.FacebookAuthProvider.credential(token);
                this.$auth.currentUser
                  .linkWithCredential(credential)
                  .then(() => {
                    this.showToast('Your Apple Account was successfully linked!', 'success', 2400);
                  })
                  .catch((err) => {
                    showToast(`${err}`, 'error', 5000);
                    this.showToast('Your Apple Account was successfully linked!', 'success', 2400);
                  })
                  .catch((err) => {
                    showToast(`${err}`, 'error', 5000);
                  });
              });
            } catch (err) {
              this.showToast(err, 'error', 5000);
            }
          }
        },
        syncFacebook() {
          if (!launchImmediately || typeof cordova != 'object') {
            var facebookProvider = new firebase.this.$auth.FacebookAuthProvider();

            this.$auth.currentUser
              .linkWithPopup(facebookProvider)
              .then(() => {
                this.showToast('Your Facebook Account was successfully linked!', 'success', 2400);
              })
              .catch((err) => {
                this.showToast(`${err}`, 'error', 5000);
              });
          } else {
            try {
              Capacitor.Plugins.CapacitorFirebasethis.$auth.signIn({ providerId: 'facebook.com' }).then((data) => {
                var token = data.idToken;
                var credential = firebase.this.$auth.GoogleAuthProvider.credential(token);
                this.$auth.currentUser
                  .linkWithCredential(credential)
                  .then(() => {
                    this.showToast('Your Facebook Account was successfully linked!', 'success', 2400);
                  })
                  .catch((err) => {
                    this.showToast(`${err}`, 'error', 5000);
                  });
              });
            } catch (err) {
              this.showToast(`${err}`, 'error', 5000);
            }
          }
        },
        syncEmailAndPwd: () => {
          var credential = this.$auth.EmailAuthProvider.credential(
            this.syncAccountHandler.email1,
            this.syncAccountHandler.pwd1
          );

          this.$auth.currentUser
            .linkWithCredential(credential)
            .then((result) => {
              this.newEmailForm = false;
              this.showToast(`Added email successfully`, 'success', 2500);
            })
            .catch((error) => {
              this.showToast(`${error}`, 'error', 2500);
            });
        },
        openSyncDialog: () => {
          this.showFab = false;
          this.syncingDialog = true;
        },
        syncToMARCo: async () => {
          this.handleRealtimeSyncs();
          const syncRef = await getDoc(doc(this.$db, 'deviceAuth', this.syncCode.toUpperCase()));

          if (syncRef.exists()) {
            this.showToast('Syncing...', 'info', 2500);

            setTimeout(() => {
              this.startupStep += 1;
              this.syncingDialog = false;
              this.showFab = true;
            }, 3500);

            if (!this.rawUsrData.usedMARCoRobot) {
              await setDoc(
                syncRef,
                {
                  currentUser: this.rawUsrData.basicInformation.userID,
                  firstTime: true,
                },
                { merge: true }
              );
              //syncRef.set({"currentUser":this.rawUsrData.basicInformation.userID,"firstTime":true});
              this.rawUsrData.usedMARCoRobot = true;
              this.updateUser();
            } else {
              await setDoc(
                syncRef,
                {
                  currentUser: this.rawUsrData.basicInformation.userID,
                  firstTime: false,
                },
                { merge: true }
              );

              //syncRef.set({"currentUser":this.rawUsrData.basicInformation.userID,"firstTime":false});
            }

            this.isSyncingApp = true;
          } else {
            this.showToast('Could not sync. Double check your entry.', 'error', 2000);
          }
        },
      },
      dailyTracking: {
        suggestedActs: [],
        chosenActs: [],
        completedActs: 0,
        progressVal: 0,
        requestNotificationCapability: false,
        hasRequestedNotificationCapabilityToday: false,
        handleActivityNotifications: async (mode = 0) => {
          let localNotifs = Capacitor.Plugins.LocalNotifications;
          switch (mode) {
            case 0:
              {
                //This is for adding notifications

                //First, initialize notifications

                let hasPermission = await localNotifs.areEnabled();
                if (hasPermission.value) {
                  let notifications = [];
                  notifications.push({
                    title: `You can still do it!`,
                    body: `Jump back in with MARCo and get started on your goals`,
                    id: 'dailyAct-1',
                    schedule: { at: new Date(Date.now() + 3600 * 1000) },
                    sound: null,
                    attachments: null,
                    actionTypeId: '',
                    extra: null,
                  });
                  notifications.push({
                    title: `Don't give up!`,
                    body: `Your goals are within your reach, jump back in with MARCo to accomplish them.`,
                    id: 'dailyAct-2',
                    schedule: { at: new Date(Date.now() + 3600 * 3000) },
                    sound: null,
                    attachments: null,
                    actionTypeId: '',
                    extra: null,
                  });

                  localNotifs
                    .schedule({
                      notifications: notifications,
                    })
                    .then((result) => {
                      console.log('Notifications scheduled.');
                    })
                    .catch((err) => {
                      //console.log(err);
                      console.log('Notifications could not be scheduled.');
                    });
                } else {
                  if (!this.dailyTracking.hasRequestedNotificationCapabilityToday) {
                    this.dailyTracking.hasRequestedNotificationCapabilityToday = true;
                    this.dailyTracking.requestNotificationCapability = true;
                  } else {
                    this.dailyTracking.requestNotificationCapability = false;
                  }
                }
              }
              break;
            case 1:
              {
                //This is for updating notifications
              }
              break;
            case 2:
              {
                //This is for clearing all notifications if they are completed

                localNotifs.getPending().then((notifs) => {
                  let nots = notifs.notifications;
                  let removeArray = [];
                  nots.map((a) => {
                    if (a.id.includes('dailyAct')) {
                      removeArray.push(a);
                    }
                  });

                  localNotifs.cancel({
                    notifications: removeArray,
                  });
                });
              }
              break;
          }
        },
      },

      mediaSrc: '',
      toast: false,
      alertType: 'success',
      alertMsg: 'Hi!',
      windowCase: 0,
      navSelect: false,
      currPopupTitle: 'Media Player',
      popupEnabled: false,
      audioContext: null,
      source: null,
      iosAudio: new Audio(),
      isSyncingHardware: false,
      isSyncingApp: false, //TODO: UPDATE FOR MORE ELEGANT SOLUTION FOR IS SYNCING
      syncActivityDescription: 'Would you like to open this activity on your phone or on MARCo?',
      syncActivityDialogue: false,
      currSyncActivityAction: function () {
        return 0;
      },
      explainSyncDialog: false,
      newWindowUrl: '',
      rawUsrData: {
        basicInformation: {
          'basic-given-name': 'default_name',
          'basic-last-name': 'default_last',
          'basic-gender': 'null',
          'basic-age': 'null',
          'basic-emergency-phone-num': [],
          'basic-user-phone-num': '',
          userID: '',
          basicUserEmail: '',
        },
        userPersonality: {
          userClassifications: {
            'liked-animals': [],
            'classifications-hobbies': [],
            'classifications-job': 'null',
            'classifications-religion': 'null',
            'classification-ethnicity': 'null',
            'classification-sex-orient': 'null',
          },
          userGeneral: {
            spokenLanguage: 'en',
            preferredVoice: 0,
            firstDate: '',
            lastSessionDate: '',
            dayStreak: 0,
            preferredBkgVolume: 0.3,
            accountInfo: {
              accountType: 'Trial',
              accountKey: '',
              accountManager: {
                name: 'MARCo Technologies',
                id: '',
              },
              startDate: '',
              renewalDate: '',
              MARCoins: 0,
              desktopThemeNum: 1,
              purchases: {
                backgrounds: { default: 'defaultBkgnd', all: [] },
                marcos: { default: 'default', all: [] },
                headAccessories: { default: 'none', all: [] },
                audioFiles: {
                  default: {
                    name: 'Default Nature',
                    sounds: [
                      'https://firebasestorage.googleapis.com/v0/b/newagent-e3a67.appspot.com/o/Audio%2Fbkgs%2Fnature-sounds.mp3?alt=media&token=b01aee89-6f95-4caf-bc56-ace9c21b00b0',
                    ],
                  },
                  all: [
                    { name: 'No audio', sounds: [] },
                    {
                      name: 'Default Nature',
                      sounds: [
                        'https://firebasestorage.googleapis.com/v0/b/newagent-e3a67.appspot.com/o/Audio%2Fbkgs%2Fnature-sounds.mp3?alt=media&token=b01aee89-6f95-4caf-bc56-ace9c21b00b0',
                      ],
                    },
                  ],
                },
              },
            },
          },
        },
        knowsCBT: false,
      },
      usrSessionSummary: {
        template: '',
      },
      sessionTitle: '',
      usrProf: '/assets/images/marco-wave.png',
      message: 'Hello World',
      usrAccountType: 'Trial',
      usrmarcoins: 0,
      deleteLoading: false,
      usrPhoneNum: false,
      showBottomNav: true,
      navDrawer: true,
      showFab: true,
      globalCBTRequest: false,
      menuFab: false,
      audioOn: true,
      micOn: false,
      chatPopup: false,
      pomodoroCase: -1,
      syncCode: '',
      checkSyncingDevice: false,
      likedTabs: 0,
      backedupJournal: false,
      currVoice: 'en-US-Standard-D',
      voiceProfs: [],
      usrLikedAnimals: [],
      usrHobbies: [],
      usrFaith: '',

      ShowSettings: false,
      defaultUsrSettings: null,
      usrDisplayName: '',
      onboardingEvents: [],
      settingsTab: 0,
      newEmailForm: false,
      splashScreen: true,
      talkCase: 0,
      cbtGoals: '',
      requestBody: requestBody,
      fullActivities: {},
      isDarkMode: new Date().getHours() < 6 || new Date().getHours() > 20,

      showPrivacyTerms: false,
      isPrivacyPolicy: false,
      privacyTermsData: null,
      speechBubText: '',
      chatHandler: {
        msgsRead: 0,
        totMessages: 0,
        openMedia: false,
        currClickElement: '',
        quickReplies: [],
        chatMsgs: [],
        sentMsg: (msg) => {
          //First, make sure that the chat messages are not empty
          if (this.chatHandler.chatMsgs.length > 0) {
            //If the last message is a quick reply, remove it

            if (this.chatHandler.chatMsgs[this.chatHandler.chatMsgs.length - 1].quickReplies.length > 0) {
              this.chatHandler.chatMsgs.splice(this.chatHandler.chatMsgs.length - 1, 1);
            }
            this.chatHandler.chatMsgs.splice(this.chatHandler.chatMsgs.length, 0, {
              isMsg: true,
              quickReplies: [],
              isUsr: true,
              isCbt: this.globalCBTRequest,
              key: this.chatHandler.chatMsgs.length,
              embedMedia: false,
              isSlider: false,
              checkBoxes: false,
              timestamp: `${new Date()}`,
            });
            this.$set(this.chatHandler.chatMsgs[this.chatHandler.chatMsgs.length - 1], 'msgText', `${msg}`);
            if (this.chatPopup) {
              this.chatHandler.msgsRead += 1;
            }
            this.chatHandler.totMessages += 1;
            this.sfxHandler.eventAudio.src = '/assets/audio/sfx/ui-cartoon-pop-1.m4a';
            this.sfxHandler.eventAudio.volume = 0.2;
            this.sfxHandler.eventAudio.play();
          }
        },
        appendMARCosResponse: (message) => {
          this.chatHandler.chatMsgs.splice(this.chatHandler.chatMsgs.length, 0, {
            isMsg: true,
            quickReplies: [],
            isUsr: false,
            isCbt: this.globalCBTRequest,
            key: this.chatHandler.chatMsgs.length,
            embedMedia: false,
            isSlider: false,
            checkBoxes: false,
            timestamp: `${new Date()}`,
          });
          this.$set(this.chatHandler.chatMsgs[this.chatHandler.chatMsgs.length - 1], 'msgText', `${message}`);
          this.$set(this.chatHandler.chatMsgs[this.chatHandler.chatMsgs.length - 1], 'timestamp', `${new Date()}`);
          if (this.chatPopup) {
            this.chatHandler.msgsRead += 1;
          }
          this.chatHandler.totMessages += 1;
        },
        appendQuickReplies: (replies) => {
          /*this.chatHandler.chatMsgs.splice(this.chatHandler.chatMsgs.length,0,{isMsg:false,quickReplies:[],isUsr:false,isCbt:this.globalCBTRequest,key:this.chatHandler.chatMsgs.length,embedMedia:false,
                    isSlider:false,
                    checkBoxes:false,});
            this.$set(this.chatHandler.chatMsgs[this.chatHandler.chatMsgs.length-1],'quickReplies',replies);
             if(this.chatPopup){this.chatHandler.msgsRead += 1;} 
             this.chatHandler.totMessages += 1;*/
          this.chatHandler.quickReplies = replies;
        },
        appendClickElement: (src) => {
          console.log('Appending ' + src);
          this.chatHandler.chatMsgs.splice(this.chatHandler.chatMsgs.length, 0, {
            isMsg: false,
            quickReplies: [],
            isUsr: false,
            isCbt: this.globalCBTRequest,
            key: this.chatHandler.chatMsgs.length,
            embedMedia: true,
            isSlider: false,
            checkBoxes: false,
          });
          this.$set(this.chatHandler.chatMsgs[this.chatHandler.chatMsgs.length - 1], 'embedMedia', src);
          if (this.chatPopup) {
            this.chatHandler.msgsRead += 1;
          }
          this.chatHandler.totMessages += 1;
          if (!this.chatPopup) {
            if (!this.isSyncingApp && !this.isSyncingHardware) {
              this.chatHandler.openMedia = true;
              this.chatHandler.currClickElement = src;
            } else {
              this.handleDeviceMARCoSwitch('Open activitiy on MARCo or on app?', () => {
                return this.clickElement(src);
              });
            }
          }
        },
      },
      highalertHandler: {
        stepArray: [
          {
            num: 1,
            desc: 'Have you wished you were dead or wished you could go to sleep and not wake up?',
            speech: [
              'First question, in the past month have you wished you were dead or wished you could go to sleep and not wake up?',
            ],
          },
          {
            num: 2,
            desc: 'Have you had any thought about killing yourself?',
            speech: ['Next question, in the past month have you actually had any thought about killing yourself?'],
          },
          {
            num: 3,
            desc: 'Have you thought about how you might do this, make yourself not alive anymore?',
            speech: [
              "I'm so sorry to hear this. Have you thought about how you might do this, taking your own life I mean?",
            ],
          },
          {
            num: 4,
            desc: 'Have you had any intention of acting on these thoughts, as opposed to just thinking about them?',
            speech: [
              'Have you had any intention of acting on these thoughts of killing yourself, as opposed to you  have the thoughts but you definitely would not act on them?',
            ],
          },
          {
            num: 5,
            desc: 'Have you started to work out or worked out the details of how to kill yourself?',
            speech: [
              'Have you started to work out or worked out the details of how to kill yourself? Do you intend to carry out this plan?',
            ],
          },
          {
            num: 6,
            desc: 'In the past three months, have you done anything or prepared to do anything to end your life?',
            speech: [
              'Okay, in the past three months now, have you done anything, started to do anything, or prepared to do anything to end your life? For instance, writing notes, leaving a will, collecting pills, holding a gun, etc.',
            ],
          },
        ],
        ansArray: [
          { yesNo: '', desc: '' },
          { yesNo: '', desc: '' },
          { yesNo: '', desc: '' },
          { yesNo: '', desc: '' },
          { yesNo: '', desc: '' },
          { yesNo: '', desc: '' },
        ],
        currStep: 1,
        showDescBox: false,
        initializeCSSRS: () => {
          //document.getElementById('cssrsstep').innerHTML = "Step " + this.currStep;

          //genSpeech(this.stepArray[this.currStep-1].speech);
          let nextSpeech = this.highalertHandler.stepArray[this.highalertHandler.currStep - 1].speech;
          this.appendTextWithAudio({ responseText: [nextSpeech] }, 'neutral');
        },

        stepNo: () => {
          this.highalertHandler.ansArray[this.highalertHandler.currStep - 1].yesNo = 'no';

          if (this.highalertHandler.currStep != 2 && this.highalertHandler.currStep != 6) {
            this.highalertHandler.currStep += 1;
            //document.getElementById('cssrsstep').innerHTML = "Step " + this.currStep;

            let nextSpeech = this.highalertHandler.stepArray[this.highalertHandler.currStep - 1].speech;
            this.appendTextWithAudio({ responseText: [nextSpeech] }, 'neutral');
          } else if (this.highalertHandler.currStep == 2) {
            this.highalertHandler.currStep = 6;
            //document.getElementById('cssrsstep').innerHTML = "Step " + this.currStep;

            let nextSpeech = this.highalertHandler.stepArray[this.highalertHandler.currStep - 1].speech;
            this.appendTextWithAudio({ responseText: [nextSpeech] }, 'neutral');
          } else if (this.highalertHandler.currStep == 6) {
            this.highalertHandler.completeForm();
          }
        },

        stepYes: () => {
          this.highalertHandler.showDescBox = true;
          this.highalertHandler.ansArray[this.highalertHandler.currStep - 1].yesNo = 'Yes';
          let descArr = [
            "If you can, please use the keyboard to describe your answer in a little bit more detail, then press submit when you're done.",
            "If it's possible, please use the keyboard to elaborate on your answer a little bit more.",
            'Could you please type to describe your answer a little bit more?',
            "Can you use the keyboard to describe what you mean, don't worry about being right or wrong.",
            'Please describe this a little bit more in your own words using the keyboard',
            'Describe what you mean for me please using the keyboard.',
          ];
          let selectedDesc = descArr[Math.floor(Math.random() * descArr.length)];
          this.appendTextWithAudio({ responseText: [selectedDesc] });
        },

        stepYesSubmit: (description) => {
          this.highalertHandler.showDescBox = false;
          this.highalertHandler.ansArray[this.highalertHandler.currStep - 1].desc = description;

          if (this.highalertHandler.currStep != 6) {
            this.highalertHandler.currStep += 1;
            //document.getElementById('cssrsstep').innerHTML = "Step " + this.currStep;

            let nextSpeech = this.highalertHandler.stepArray[this.highalertHandler.currStep - 1].speech;
            this.appendTextWithAudio({ responseText: [nextSpeech] });
          } else {
            this.highalertHandler.completeForm();
          }
        },

        completeForm: () => {
          let speechJSON = JSON.parse(JSON.stringify(requestBody));
          speechJSON.cssrsForm.isTrue = true;
          speechJSON.cssrsForm.responseJSON = this.highalertHandler.ansArray;

          let speechArray;

          /*"cssrsForm":{
			"isTrue":false,
			"responseJSON":{},
			"recommendationMessage":"",
			"highestStep":0
		}*/

          //Generate recommendations

          let highestStep = 0;

          for (let i = 0; i < this.highalertHandler.ansArray.length; i++) {
            if (this.highalertHandler.ansArray[i].yesNo.toLowerCase() == 'yes') {
              highestStep = i + 1;
            }
          }

          if (highestStep == 0) {
            speechJSON.cssrsForm.recommendationMessage =
              ' They have given no indication of a desire to die or kill themselves. However, they have given me indication that they are at risk of harming themselves, so please check in as soon as possible.';
            speechArray = [
              "Okay, so from what you told me, you don't have any desire to die or to make yourself not alive anymore. However, I understand you might still be at risk for harm. I have pulled up some resources on the screen, please do not hesitate to use any of them for your situation. If you feel like you might be in an absolute emergency, please call 911.",
            ];
          } else if (highestStep == 1) {
            speechJSON.cssrsForm.recommendationMessage = ` They have given me an indication that they have a want to die, however, they have had no suicidal ideation or intent. Please check in with them as soon as possible or find a referral for screening.`;
            speechArray = [
              "So, from what you told me, you have thought about wanting to not be alive anymore, but you have not had any thoughts to take your own life. Please remember that there is always hope, and that there is always a way to overcome these thoughts. I have pulled up some resources on the screen, I highly recommend you use them for your situation. If you feel like you might be in an absolute emergency, please call 911, and don't think twice if you think it will help. I promise you that no possible harm can come from you calling 911 if you think it can help.",
            ];
          } else if (highestStep > 1 && highestStep < 4) {
            speechJSON.cssrsForm.recommendationMessage =
              ` They have had thoughts about killing themselves, however, expressed an unwillingness to do so. They answered "yes" to "` +
              this.highalertHandler.stepArray[highestStep - 1].desc +
              `". It is urgent you either get in contact or find a referral for someone who can appropriately screen them.`;
            speechArray = [
              "So, from what you told me, you have thought about wanting to take your own life, but you do not believe you would carry out any plan or have intention of going through with this. Please remember that there is always hope, and that you are so loved, especially by me. I have pulled up some resources on the screen, please use either the phone hotline or text hotline to get in contact with someone immediately. If you feel like you might be in an absolute emergency, please call 911, and don't think twice if you think it will help. I promise you that no possible harm can come from you calling 911 if you think it can help.",
            ];
          } else if (highestStep >= 4) {
            speechJSON.cssrsForm.recommendationMessage =
              ` IT IS HIGHLY RECOMMENDED YOU IMMEDIATELY GET IN CONTACT WITH THE USER AND CALL EITHER LOCAL EMERGENCY SERVICES OR 911 IF YOU CANNOT REACH THEM. They have had expressed a serious intention on taking their own life. The most severe indication was they answered "yes" to "` +
              this.highalertHandler.stepArray[highestStep - 1].desc +
              `" saying, when asked to describe, "${
                this.highalertHandler.ansArray[highestStep - 1].desc
              }". It is urgent you either get in contact or find an emergency service.`;
            speechArray = [
              'Please remember that you are so loved, and you are irreplaceable. Based on what you told me, I think that it would be best for you to get in contact with a professional or emergency service. I would recommend that a call to local emergency services or 911 would be best, and please understand that there are no possible negative repercussions if you choose to call 911; everybody is here to help you in your situation. I have some additional resources on the screen if you would prefer to get on a lifeline via phone or text with a professional, but please do not hesitate to contact any of these.',
            ];
          }

          this.serverRequest(speechJSON);

          let selectedSpeech = speechArray[Math.floor(Math.random() * speechArray.length)];
          this.appendTextWithAudio({ responseText: [selectedSpeech] });
        },
      },
    };
  },
  destroyed() {
    clearTimeout(this.idelTimeout);
  },
  methods: {
		widgetHeaderExplain(e) {
      this.appendTextWithAudio({ responseText: [e] });
    },
		close() {
      this.widgetInfo = null;
      this.showWidget = false;
      this.defaultTemplate = null;
    },
    openSaveAndQuitDialog: function () {
      this.showSaveAndExitDialog = true;
      this.navDrawer = false;
      this.sfxHandler.pauseBkgAudio();
      this.sfxHandler.audioWoosh();
    },
    cancelSaveAndExit: function () {
      this.showSaveAndExitDialog = false;
      this.sfxHandler.playBkgAudio();
      this.sfxHandler.audioWoosh();
    },
    saveAndExit: function () {
      this.showToast('Saving...', 'info', 2500);
      setTimeout(() => {
        EventBus.$emit('loadingOverlay');
        setTimeout(() => {
          let url = window.location.href;
          if (url.indexOf('?') > -1) {
            url += '&showLastSessionRating=true';
          } else {
            url += '?showLastSessionRating=true';
          }
          window.location.href = url;
        }, 1500);
      }, 1500);
    },
    readFilePath: async function () {
      console.log('reading file', this.fileField);
      const results = await this.$readObject(this.fileField);
      this.fileResults = results;
    },
    idelTimeoutFun() {
      console.log('do whatever');
    },
    startIdelTimeout() {
      console.log('timeout started');
      this.idelTimeout = setTimeout(this.idelTimeoutFun, 5 * 1000);
      console.log(this.idelTimeout);
    },
    stopIdelTimeout() {
      console.log('timeout stop');
      clearTimeout(this.idelTimeout);
    },
    factoryReset() {
      EventBus.$emit('factory-reset');
    },

    clearFormAndFile() {
      this.form.valid = false;
      this.form.title = '';
      this.form.desc = '';
      this.form.type = '';
      this.form.src = '';
      this.form.tags = [];
      this.e1 = 0;
    },
    async handleSave() {
      this.isSaving = true;
      const contentRef = collection(
        this.$db,
        'users',
        this.rawUsrData.basicInformation.userID,
        'content',
        'preferredContent',
        'content'
      );
      const newData = {
        title: this.form.title,
        desc: this.form.desc,
        type: this.form.type,
        from: 0,
        pref: 2,
        src: this.form.src,
        tags: this.form.tags,
      };
      await addDoc(contentRef, { ...newData, lastUpdate: serverTimestamp() });
      this.clearFormAndFile();
      this.likeDislikeDialog = false;
      this.e1 = 0;
      this.isSaving = false;
      this.showToast('Great! Added to favorites', 'success', 2500);
    },
    async handleUpvoteWish() {
      // this.likeDislikeDialog = false
      this.form.src = this.mediaSrc2;
      // this.showToast("Great! Added to favorites","success",2500)
      // const contentRef = collection(this.$db, "users", this.rawUsrData.basicInformation.userID, "content", "preferredContent", "content");
      // const newData = {
      //   title: "This is a video",
      //   desc: "",
      //   type: "VIDEO",
      //   from: 0,
      //   pref: 2,
      //   src: this.mediaSrc2,
      //   tags: [],
      // };
      // await addDoc(contentRef, { ...newData, lastUpdate: serverTimestamp() });
      this.e1 = 2;
      this.mediaSrc2 = '';
    },
    async handleDownvoteWish() {
      this.clearFormAndFile();
      this.likeDislikeDialog = false;
      this.showToast("You won't see this again", 'success', 2500);
      const contentRef = collection(
        this.$db,
        'users',
        this.rawUsrData.basicInformation.userID,
        'content',
        'preferredContent',
        'content'
      );
      const newData = {
        title: 'This is a video',
        desc: '',
        type: 'VIDEO',
        from: 0,
        pref: 0,
        src: this.mediaSrc2,
        tags: [],
      };
      await addDoc(contentRef, { ...newData, lastUpdate: serverTimestamp() });

      this.mediaSrc2 = '';
    },
    openNewWindow: function () {
      Browser.open({ url: this.newWindowUrl });
      this.likeDislikeDialog = true;
    },
    handleDeviceMARCoSwitch: function (desc, action) {
      //If there is not currently syncing between MARCo and another device (i.e., phone, tablet, laptop,etc.) then return the action itself
      //eslint-disable-next-line
      if ((!this.isSyncingApp && !this.isSyncingHardware) || true) {
        return action();
      } else {
        //If there is syncing between MARCo and another device, then open a dialogue on both screens with the option to open the action on either MARCo or the device
        this.syncActivityDescription = desc;
        this.currSyncActivityAction = action;
        this.syncActivityDialogue = true;

        //Create a realtime listener for the sync activity dialogue that will close the dialogue if the user chooses to open the action on the other device

        set(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/syncActivityDevice`), {
          appLaunch: false,
          hardwareLaunch: false,
        });
        //Listen to the readonly value of the sync activity from the connected device for a change
        onValue(
          ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/syncActivityDevice`),
          (responseSnap) => {
            const response = responseSnap.val();
            if (response.appLaunch) {
              //If it is launched on the app, close the dialogue and run the activity
              this.syncActivityDialogue = false;

              //Turn off the realtime sync
              this.currSyncActivityAction();

              off(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/syncActivityDevice`));
            } else if (response.hardwareLaunch) {
              //If it is launched on the hardware, close the dialogue and run the activity
              this.syncActivityDialogue = false;

              //Turn off the realtime sync
              this.currSyncActivityAction();

              off(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/syncActivityDevice`));
            }
          }
        );
      }
    },
    launchSyncActivityOnMARCo() {
      //First, set the realtime sync to show that it has launched on MARCo
      set(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/syncActivityMARCo`), {
        appLaunch: false,
        hardwareLaunch: true,
      });

      //Then, stop listening to the sync activity from the connected device
      off(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/syncActivityDevice`));

      //Finally, launch the action and close the dialogue

      this.syncActivityDialogue = false;
    },
    launchSyncActivityOnDevice() {
      //First, set the realtime sync to show that it has launched on MARCo
      ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/syncActivityMARCo`).set({
        appLaunch: true,
        hardwareLaunch: false,
      });

      //Then, stop listening to the sync activity from the connected device
      off(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/syncActivityDevice`));

      //Finally, close the dialogue and run the activity
      this.currSyncActivityAction();
      this.syncActivityDialogue = false;
    },
    closePopups() {
      this.$refs.mobilepopup.toggleTransparent(false);
      setTimeout(() => {
        this.popupEnabled = false;
        this.chatPopup = false;
        this.isJournaling = false;
      }, 250);
    },
    updateVoice(voiceProf) {
      this.rawUsrData.userPersonality.userGeneral.preferredVoice = voiceProf;
      this.updateUserPreferences();
    },
    loadLogoUrl: async function () {
      let doc = await getDoc(
        doc(this.$db, 'productKeys', this.rawUsrData.userPersonality.userGeneral.accountInfo.accountManager.id)
      );

      if (doc.exists()) {
        let dat = doc.data();

        return `../../enterprise/assets/images/${dat.logoURL}`;
      } else {
        return '/assets/images/meditationcardbkg.jpg';
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        case 'md':
          return false;
        case 'lg':
          return false;
        case 'xl':
          return false;
      }
    },
    addQuietHourRule: function () {
      this.dailyContactSubscriptions.quietHours.splice(this.dailyContactSubscriptions.quietHours.length, 0, {
        startTime: null,
        endTime: null,
        showStartTimeDialog: false,
        showEndTimeDialog: false,
        isActive: false,
        showRemoveDialog: false,
      });

      this.$set(
        this.dailyContactSubscriptions.quietHours[this.dailyContactSubscriptions.quietHours.length - 1],
        'isActive',
        true
      );
    },
    removeQuietHour: function (index) {
      this.dailyContactSubscriptions.quietHours.splice(index, 1);
      this.updateMessagingSubscriptions();
      this.showToast('Removed quiet hours', 'success');
    },
    sendPreviewText: async function () {
      this.showToast('Sending a test message...', 'info');
      const rawResponse = await fetch(`${this.$host}/sendtestnotification`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNum: this.rawUsrData.basicInformation['basic-user-phone-num'],
          countryCode: this.countryCodeHandler.selectedCountryCode,
        }),
      });

      var p = await rawResponse.json();
      this.showToast('Sent a test message!', 'success');
    },
    startingEmotionForm() {
      this.showEmotionForm = true;
      this.emotionFormMode = 0;
    },
    endingEmotionForm() {
      this.showEmotionForm = true;
      this.emotionFormMode = 1;
    },
    selectTalkCase() {
      /*this.talkCase = talkCase;
      var talkRequest = JSON.parse(JSON.stringify(requestBody));

      talkRequest.talkCase = talkCase;

      this.serverRequest(talkRequest);*/
      this.showEmotionForm = false;
      this.sndTxt('I want to talk');
    },
    saveEmotionForm: async function (entry) {
      //Save the user's input twice - to a summary sheet and to a daily sheet

      //Get the day and time and format
      let present = new Date();
      let entryTitle = `d${present.getDate()}m${
        present.getMonth() + 1
      }y${present.getFullYear()}t${present.getHours()}:${present.getMinutes()}`;
      this.emoteArray.push(entry.generalEmote.toLowerCase());
      this.emoteArray.push(entry.specificEmote.toLowerCase());

      //Switch between the questionnaire at the start or end of the user's session with MARCo
      switch (this.emotionFormMode) {
        case 0:
          {
            this.usrSessionSummary.preScores.push(entry);

            //Update last session date and calculate streaks

            let queueDate = Timestamp.fromDate(present);

            let lastDate = this.usrSessionSummary.lastSessionDate.toDate();

            if (present.setUTCHours(0, 0, 0, 0) - new Date(lastDate).setUTCHours(0, 0, 0, 0) == 1 * 86400000) {
              //The streak continues!
              this.usrSessionSummary.lastSessionDate = queueDate;
              this.usrSessionSummary.dayStreak += 1;
              this.usrSessionSummary.maxDayStreak =
                this.usrSessionSummary.dayStreak > this.usrSessionSummary.maxDayStreak
                  ? this.usrSessionSummary.dayStreak
                  : this.usrSessionSummary.maxDayStreak;
            } else if (present.setUTCHours(0, 0, 0, 0) - new Date(lastDate).setUTCHours(0, 0, 0, 0) > 1 * 86400000) {
              this.usrSessionSummary.lastSessionDate = queueDate;
              this.usrSessionSummary.dayStreak = 1;
            }
          }
          break;
        case 1:
          {
            this.usrSessionSummary.postScores.push(entry);
          }
          break;
      }

      //Update the user document
      await setDoc(
        doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/sessionRecords/summary`),
        this.usrSessionSummary
      );
      this.manageMARCoins('add', 40);

      //Based on the mode, either proceed to talk/activity if this is at the start of a session, or end the session at the end.
      switch (this.emotionFormMode) {
        case 0:
          //Generate MARCo's response and then append it

          if (entry.specificEmote.toLowerCase() !== 'unsure') {
            let req = { user: this.rawUsrData, emotions: entry };
            console.log('Requesting emotion score...');
            console.log(req);

            const rawResponse = await fetch(`${this.$host}/genresponse/emotionscore`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(req),
            });
            var q = await rawResponse.json();
            this.appendTextWithAudio({ responseText: q.responseText }, q.emotion, () => {
              console.log('Q123 is done.');
              this.$refs.emotionform.stepUp();
            });
          }

          break;
        case 1:
          this.showEmotionForm = false;
          break;
      }

      this.loadUserSessionLogs();
    },
    updateMessagingSubscriptions: async function () {
      this.dailyContactSubscriptions.usrPhone = this.rawUsrData.basicInformation['basic-user-phone-num'];
      this.dailyContactSubscriptions.usrCountryCode = this.countryCodeHandler.selectedCountryCode;
      let contactTimeOffsetRef = new Date();
      this.dailyContactSubscriptions.timezoneOffset = contactTimeOffsetRef.getTimezoneOffset();
      //this.dailyContactSubscriptions.lastContact = new Timestamp();
      this.dailyContactSubscriptions.usrName = this.rawUsrData.basicInformation['basic-given-name'];

      let req = {
        usrID: this.rawUsrData.basicInformation.userID,
        settings: JSON.parse(JSON.stringify(this.dailyContactSubscriptions)),
      };
      const rawResponse = await fetch(`${this.$host}/setnotificationsub`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });
      var q = await rawResponse.json();
      console.log(q);
    },
    loadMessagingSubscriptions: async function () {
      if (typeof this.rawUsrData.basicInformation.countryCode === 'string') {
        this.countryCodeHandler.selectedCountryCode = this.rawUsrData.basicInformation.countryCode;
      }
      console.log('The contact subscriptions BEFORE loading data.');
      console.log(this.dailyContactSubscriptions);
      let req = { usrID: this.rawUsrData.basicInformation.userID };
      const rawResponse = await fetch(`${this.$host}/getnotificationsub`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });
      var q = await rawResponse.json();
      for (var subKey in this.dailyContactSubscriptions) {
        if (typeof q[subKey] !== 'undefined') {
          this.dailyContactSubscriptions[subKey] = q[subKey];
        }
      }
      console.log('The contact subscriptions AFTER loading data.');
      console.log(this.dailyContactSubscriptions);
    },
    switchDarkMode: function () {
      this.isDarkMode = !this.isDarkMode;
      // console.log("Is it dark mode?")
      this.$emit('setdarkmode', this.isDarkMode);
    },
    saveJournal: function (entry) {
      this.backedupJournal = entry;
    },
    firstTimeStepper: function () {
      console.log(this.setupOption);
      switch (this.setupOption) {
        case 0:
          this.startupStep += 2;
          this.maxStartupSteps += 5;
          break;
        case 1:
          this.startupStep += 1;
          setTimeout(() => {
            this.showFab = true;
          }, 3500);
          break;
      }
    },
    callMARCo: async function (marcoID = 'DefaultMARCo') {
      if (!this.payDialog && this.allowPayCancel) {
        let marco = marcoID == 'default' ? 'DefaultMARCo' : marcoID;
        var callRequest = JSON.parse(JSON.stringify(requestBody));

        callRequest.onboardResponse = startupMessage;

        let silentStart = getUrlParam('silentstart', false);

        if (!silentStart) {
          this.serverRequest(callRequest);
        }

        //console.log(this.$refs);
        if (!this.isMobile()) {
          // await this.$refs.marcowindow.callMARCo(marco);
          // this.$refs.marcowindow.updateHeadAccessory(this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.headAccessories.default);
          // this.$refs.marcowindow.updateVidBackground(this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.backgrounds.default);
        } else {
          // await this.$refs.marcomobilewindow.callMARCo(marco);
          //this.$refs.marcomobilewindow.updateHeadAccessory(this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.headAccessories.default);
          //this.$refs.marcomobilewindow.updateVidBackground(this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.backgrounds.default);
        }

        if (!this.isSyncingApp) {
          this.sfxHandler.handleBkgAudio();

          let callAudio = new Audio();

          callAudio.src = '/assets/audio/sfx/marco-call-2.mp3';

          callAudio.play();
        }

        this.marcoIsCalled = true;
        EventBus.$emit('initializedStatus', true);
      }
    },
    addEmergencyContact() {
      if (this.nextPhoneNum.length > 9 && this.nextPhoneNum.length < 12) {
        this.rawUsrData.basicInformation['basic-emergency-phone-num'].push(this.nextPhoneNum);
        // this.updateUserPreferences();
        this.nextPhoneNum = null;
        // this.showToast("Added a new emergency contact", 'success',2000);
      }
    },

    checkToAppendEmergency() {
      if (event.keycode == 13 || event.which == 13) {
        this.addEmergencyContact();
      }
    },
    loadCountryCodes: async function () {
      let codeDoc = await getDoc(doc(this.$db, 'public', 'countryCodes'));

      if (codeDoc.exists() && !this.countryCodeHandler.isLoaded) {
        let c = codeDoc.data();
        //this.countryCodeHandler.countryCodes = c.codes;
        let codes = c.codes;
        console.log(codes.length);
        for (let i = 0; i < codes.length; i++) {
          this.countryCodeHandler.countryCodes.splice(this.countryCodeHandler.countryCodes.length, 0, codes[i]);
          console.log(this.countryCodeHandler.countryCodes.length);
        }
        console.log(this.countryCodeHandler.countryCodes);
        this.countryCodeHandler.isLoaded = true;
      }
    },
    marcoListen() {
      if (!this.isSyncingApp) {
        //  const marcoAvatar = this.isMobile() ? this.$refs.marcomobilewindow:this.$refs.marcowindow;
        // marcoAvatar.MARCoListen();
      }
    },
    endListen() {
      if (!this.isSyncingApp) {
        //const marcoAvatar = this.isMobile() ? this.$refs.marcomobilewindow:this.$refs.marcowindow;
        try {
          //	marcoAvatar.marcoEyes.animations.normal();
        } catch (err) {
          console.log(err);
        }

        //     marcoAvatar.marcoMouth.animations.smile();
      }
    },
    checkForSend: function (e) {
      if (event.keycode == 13 || event.which == 13) {
        this.sndTxt(this.chatbarTxt);
      }
    },
    checkForSyncAttempt: function (e) {
      if (event.keycode == 13 || event.which == 13) {
        if (this.syncCode.length == 5) {
          this.syncAccountHandler.syncToMARCo();
        }
      }
    },
    translateText: async function (msg, langCode) {
      let formattedMsg;
      if (typeof msg !== 'object') {
        formattedMsg = [msg];
      } else {
        formattedMsg = msg;
      }

      let req = { text: formattedMsg };
      const rawResponse = await fetch(`${this.$host}/translate/${langCode}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });
      var q = await rawResponse.json();
      return q.text;
    },
    sndTxt: async function (msg) {
      let sendMsg = msg;

      if (
        this.rawUsrData.userPersonality.userGeneral.spokenLanguage !== 'en' ||
        !this.rawUsrData.userPersonality.userGeneral.spokenLanguage.includes('en')
      ) {
        console.log('Translation 3');
        let msgArr = await this.translateText(msg, 'en');
        console.log('The translated text is: ' + msgArr);
        sendMsg = msgArr[0];
      }

      this.chatHandler.quickReplies = [];
      this.chatHandler.sentMsg(msg);

      let txtJSON = JSON.parse(JSON.stringify(this.requestBody));
      txtJSON.dialogText = sendMsg;
      this.chatbarTxt = '';
      if (this.allowAutoGeneratedResponses) {
        txtJSON.contextualConvos = '';
        for (let i = 3; i > 0; i--) {
          txtJSON.contextualConvos += `${
            this.chatHandler.chatMsgs[this.chatHandler.chatMsgs.length - i].isUsr ? '*U:' : '*M:'
          }`;
          txtJSON.contextualConvos += `${this.chatHandler.chatMsgs[this.chatHandler.chatMsgs.length - i].msgText}`;
        }

        txtJSON.contextualConvos += '*M:';
      } else {
        txtJSON.contextualConvos = false;
      }
      this.serverRequest(txtJSON);
      //Start a filler timeout for MARCo to say filler words in case it takes a while for the response to come back
      this.fillerTimeout = setInterval(() => {
        if (this.fillerTimeoutCount > 3) {
          clearInterval(this.fillerTimeout);
          this.fillerTimeoutCount = 0;
        }
        const fillerWords = [
          'Hmm...',
          'Mhm. Mhmm...',
          'Okay, so...',
          'Okay. Okay.',
          'One sec...',
          'Mhm.',
          'Hm. Hm. Hm.',
          'I see.',
          'Ok.',
          'uhm. Hm.',
          'Mm-hmm, Mmm-hmm.',
          'One sec.',
        ];
        this.appendTextWithAudio(
          { responseText: [fillerWords[Math.floor(Math.random() * fillerWords.length)]] },
          'neutral'
        );
        this.fillerTimeoutCount++;
      }, 2500);
    },
    selectNetwork(network) {
      this.currNetwork = network;
      this.currSSID = network.SSID;
      this.networkSetupStep++;
    },
    openNetworkDialog: async function () {
      this.networkDialog = true;
      if (typeof WifiWizard2 == 'object') {
        try {
          this.networks = await WifiWizard2.scan();
        } catch (err) {
          console.log(err);
        }
      }
    },
    chooseNetwork: async function () {
      this.networkSetupStep++;
      if (typeof WifiWizard2 == 'object') {
        try {
          this.networks = await WifiWizard2.scan();
        } catch (err) {
          console.log(err);
        }
      }
    },
    getBluetoothList() {
      console.log('Getting ble list...');
      this.networkSetupStep = 6;
      if (typeof bluetoothle == 'object') {
        try {
          bluetoothle.startScan(
            (res) => {
              console.log(res.name);
              if (!!res.name && res.name.includes('MARCo')) {
                console.log(res);
                this.marcoBle = res;
                bluetoothle.stopScan();
                this.networkSetupStep = 8;
                try {
                  bluetoothle.close(
                    (success) => {
                      console.log(success);
                      this.connectBle(this.successfulConnection, this.errorConnection);
                    },
                    (error) => {
                      console.warn(error);
                      this.connectBle(this.successfulConnection, this.errorConnection);
                    },
                    {
                      address: this.marcoBle.address,
                    }
                  );
                } catch (err) {
                  console.warn(error);
                }
              }
            },
            (err) => {
              console.log(err);
            }
          );

          setTimeout(() => {
            bluetoothle.stopScan();
            if (!this.marcoBle.address) {
              this.networkSetupStep = 7;
            }
          }, 30000);
        } catch (err) {
          console.log(err);
        }
      }
    },
    verifyBLE(callback) {
      console.log('Verifying ble...');
      if (typeof bluetoothle == 'object') {
        try {
          bluetoothle.initialize((result) => {
            //Check if bluetooth is initalized
            if (result.status === 'enabled') {
              return callback();
            } else {
              try {
                bluetoothle.enable((enableResult) => {
                  console.log(enableResult);
                  return callback();
                });
              } catch (err) {
                console.err(err);
                return callback();
              }
            }
          }, {});
        } catch (err) {
          console.log(err);
        }
      }
    },
    connectBle(successCallback, errorCallback) {
      bluetoothle.connect(successCallback, errorCallback, {
        address: this.marcoBle.address,
      });
    },
    successfulConnection(result) {
      console.log('Successfully connected!');
      console.log(result);
      this.connectionTitleStep = 2;

      this.bondBle();
    },
    errorConnection(err) {
      console.warn('Connection failed');
      console.log(err);
      this.connectionTitleStep = 2;

      try {
        bluetoothle.reconnect(
          this.successfulConnection,
          (err) => {
            console.log('Failed on reconnect...');
            console.log(err);
          },
          {
            address: this.marcoBle.address,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    bondBle() {
      bluetoothle.bond(
        (result) => {
          console.log(result);
          this.discoverServices(this.writeBleWifiMsg);
        },
        (error) => {
          console.warn(error);
          this.discoverServices(this.writeBleWifiMsg);
        },
        {
          address: this.marcoBle.address,
        }
      );
    },
    discoverServices(successCallback, errorCallback = false) {
      bluetoothle.discover(
        (services) => {
          console.log(services);
          this.bleCharacteristic = services.services[2].characteristics[2].uuid;
          this.bleService = services.services[2].uuid;
          this.connectionTitleStep += 2;
          return successCallback();
        },
        (err) => {
          console.warn(err);
          if (errorCallback) {
            return errorCallback();
          }
        },
        {
          address: this.marcoBle.address,
        }
      );
    },
    writeBleWifiMsg() {
      let msg = `WIFI:WPA;${this.currNetworkPWD.length > 0 ? 'P:' + this.currNetworkPWD + ';' : ''}S:${
        this.currSSID
      };U:${this.rawUsrData.basicInformation.userID}`;
      var encodedUnicodeString = bluetoothle.encodeUnicode(msg);
      this.connectionTitleStep += 2;
      bluetoothle.write(
        (b) => {
          console.log(b);
          this.connectionTitleStep += 2;
        },
        (e) => {
          console.warn(e);
        },
        {
          address: this.marcoBle.address,
          characteristic: this.bleCharacteristic,
          service: this.bleService,
          value: encodedUnicodeString,
        }
      );
    },

    generateNetworkQR() {
      this.networkSetupStep++;
      this.wifiQR = false;

      if (typeof QRCode === 'undefined') {
        var s = document.createElement('script'); // Create a script element
        s.type = 'text/javascript'; // optional in html5
        s.async = true; // asynchronous? true/false
        s.src = '../../../js/qrcode.js';
        var fs = document.getElementsByTagName('script')[0]; // Get the first script
        fs.parentNode.insertBefore(s, fs);
      }

      let qrGenerator = setInterval(() => {
        try {
          console.log(typeof QRCode);
          document.getElementById('wifiQRCode').innerHTML = '';

          this.wifiQR = new QRCode(document.getElementById('wifiQRCode'), {
            text: `WIFI:WPA;${this.currNetworkPWD.length > 0 ? 'P:' + this.currNetworkPWD + ';' : ''}S:${
              this.currSSID
            };U:${this.rawUsrData.basicInformation.userID}`,
            height: window.innerWidth - 30,
            width: window.innerWidth - 30,
          });
          clearInterval(qrGenerator);
        } catch (err) {
          console.log(err);
        }
      }, 300);
    },
    getCurrentNetwork: async function () {
      if (typeof WifiWizard2 == 'object') {
        try {
          this.currSSID = await WifiWizard2.getConnectedSSID();
          console.log(this.currSSID);
          this.networkSetupStep = 3;
        } catch (err) {
          console.log(err);
        }
      } else if (isCordova) {
        this.showToast('Please update the app to use this feature.', 'error', 2500);
      } else {
        this.showToast('Can only do this in the app.', 'error', 2500);
      }
    },
    rssiCalculator(level) {
      let num = parseInt(level);

      if (num > -50) {
        return 4;
      } else if (num > -60) {
        return 3;
      } else if (num > -70) {
        return 2;
      } else {
        return 1;
      }
    },

    switchSpeechRecognition() {
      // const marcoAvatar = this.isMobile() ? this.$refs.marcomobilewindow:this.$refs.marcowindow;

      if (this.micOn) {
        this.micOn = false;
        if (!this.iosSpeech) {
          this.sndTxt(this.recordedTranscript);
        } else {
          this.iosSpeech.stop();
        }
      } else {
        this.micOn = true;
        if (this.recognition && !isiOSSafari) {
          var currentSpeech = '';
          this.recognition.onresult = (event) => {
            // event is a SpeechRecognitionEvent object.
            // It holds all the lines we have captured so far.
            // We only need the current one.
            var current = event.resultIndex;

            // Get a transcript of what was said.
            var transcript = event.results[current][0].transcript;
            //   log('#log2',transcript);
            // log('#text_log',transcript);

            // Add the current transcript to the contents of our Note.
            currentSpeech += transcript;
            this.recordedTranscript += transcript;
          };

          this.recognition.onstart = () => {
            //   instructions.text('Voice recognition activated. Try speaking into the microphone.');
            //	marcoAvatar.MARCoListen();
          };

          this.recognition.onspeechend = () => {
            this.recognition.stop();
            this.micOn = false;

            setTimeout(() => {
              this.sndTxt(currentSpeech);
              this.recordedTranscript = '';
            }, 500);
          };

          this.recognition.continuous = false;

          this.recognition.start();
        } else if (this.iosSpeech) {
          this.iosSpeech.hasPermission().then((data) => {
            //console.log(data.permission);
            if (data.permission) {
              //   marcoAvatar.MARCoListen();
              this.iosSpeech
                .start({
                  language: 'en-US',
                  maxResults: 2,
                  prompt: 'Speak to MARCo',
                  partialResults: false,
                  popup: true,
                })
                .then((speechObj) => {
                  console.log(speechObj);
                  let resultSpeech = speechObj.matches[0];
                  this.sndTxt(resultSpeech);
                  this.micOn = false;
                });

              this.showToast('Tap the mic icon again when finished', 'info', 1500);
            } else {
              this.iosSpeech.requestPermission();
            }
          });
        } else {
          this.showToast("Sorry, your browser doesn't seem to support this feature at the moment.", 'error', 3000);
        }
      }
    },

    deleteUserData() {
      //TODO: Actually delete user data
      this.deleteLoading = true;

      setTimeout(() => {
        this.deleteAccountModal = false;
        this.deleteLoading = false;

        deleteDoc(doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}`))
          .then(() => {
            this.$auth.currentUser.delete();
          })
          .catch((error) => {
            console.log(error);
            this.showToast('There was a problem removing your data, please try again.', 'error');
          });
      }, 2500);
    },

    addActivities: async function (acts) {
      for (let a = 0; a < acts.length; a++) {
        this.dailyTracking.chosenActs.push(acts[a]);
      }

      this.showToast('Activities have been added!', 'success', 1500);
      this.dailyTracking.progressVal = parseInt(
        (this.dailyTracking.completedActs / this.dailyTracking.chosenActs.length) * 100
      );
      //console.log(this.dailyTracking.chosenActs);

      const activityDaily = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/daily`);
      const activityMaster = (this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/master`);
      let present = new Date();
      let entryTitle = `d${present.getDate()}m${present.getMonth() + 1}y${present.getFullYear()}`;

      try {
        let activityDailyDoc = await getDoc(activityDaily);

        if (activityDailyDoc.exists()) {
          let actDoc = activityDailyDoc.data();
          actDoc[entryTitle] = this.dailyTracking.chosenActs;

          setDoc(activityDaily, actDoc);
        } else {
          let actDoc = {};
          actDoc[entryTitle] = this.dailyTracking.chosenActs;
          setDoc(activityDaily, actDoc);
        }

        this.dailyTracking.handleActivityNotifications(0);
      } catch (error) {
        console.log(error);
      }
    },
    serverRequest: async function (req, callback = false) {
      req.cbtRequest = this.globalCBTRequest;
      req.currentUser.requestTimestamp = new Date();
      req.currentUser.timestampOffset = req.currentUser.requestTimestamp.getTimezoneOffset();
      console.log('Requesting the server...');
      console.log('The request is:');
      console.log(req);
      var responseJSON;

      const rawResponse = await fetch(`${this.$host}/clicked`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });
      responseJSON = await rawResponse.json();
      clearInterval(this.fillerTimeout);
      this.fillerTimeout = null;

      if (callback) {
        callback(responseJSON);
        if (this.isSyncingApp) {
          this.updateRealtimeResponseSync(responseJSON);
        }
      } else {
        if (this.isSyncingApp) {
          //this.updateRealtimeResponseSync(responseJSON);

          //If the user's preferred language is not english, first things first, update any responseText in the responseJSON to use that user's language
          if (
            this.rawUsrData.userPersonality.userGeneral.spokenLanguage !== 'en' ||
            !this.rawUsrData.userPersonality.userGeneral.spokenLanguage.includes('en')
          ) {
            console.log('Translation 1');
            responseJSON.responseText = await this.translateText(
              responseJSON.responseText.join(' '),
              this.rawUsrData.userPersonality.userGeneral.spokenLanguage
            );
          }
          EventBus.$emit('updateRealtimeResponseSync', responseJSON);
        }
        this.paramHandler(responseJSON);
        console.log(JSON.stringify(responseJSON));
      }
    },
    appendText: async function (response, callback) {
      const chatRef = this.isMobile() ? this.$refs.mobilechatform : this.$refs.desktopchatform;
      console.log('The response text is: ', response.responseText);
      if (response.responseText && response.responseText.length > 0) {
        //Preprocess text in case there are additional splits

        let currText = response.responseText;
        //For some reason, some response text will come in with an empty first string, so if the first string is empty, remove it
        if (currText[0] == '') {
          currText.shift();
        }
        let finalText = [];

        for (let i = 0; i < currText.length; i++) {
          console.log('Running iteration ', i, ' of the text array');
          console.log('The current text is: ', currText[i]);
          if (currText[i].includes('<split-speech/>')) {
            let nextArray = currText[i].split('<split-speech/>');
            for (let n = 0; n < nextArray.length; n++) {
              finalText.push(nextArray[n]);
            }
          } else {
            //finalText.push(currText[i]);
            let textArr = [];
            let text = currText[i];

            if (text[text.length - 1] !== '.' && text[text.length - 1] !== '!' && text[text.length - 1] !== '?') {
              text += '.';
            }
            textArr = text.match(/[^\.!\?]+[\.!\?]+/g);

            finalText.push(textArr[0]);

            for (let j = 1; j < textArr.length; j++) {
              if (finalText[finalText.length - 1].length + textArr[j].length < 312) {
                finalText[finalText.length - 1] += textArr[j];
              } else {
                finalText.push(textArr[j]);
              }
            }
          }
        }
        console.log('The final text array is: ', finalText);

        if (
          this.rawUsrData.userPersonality.userGeneral.spokenLanguage !== 'en' ||
          !this.rawUsrData.userPersonality.userGeneral.spokenLanguage.includes('en')
        ) {
          console.log('Translation 4');
          finalText = await this.translateText(finalText, this.rawUsrData.userPersonality.userGeneral.spokenLanguage);

          console.log(response.responseText);
          let currentResponse = 0;
          let addText = setInterval(() => {
            if (currentResponse == finalText.length) {
              /*if(isMobile)
									{
										document.getElementById("speechbubbletext").innerHTML = response.responseText[0].substr(0,137) + "...";
										document.getElementById("speech-prong").click();
										
									}*/
              clearInterval(addText);
            } else {
              this.chatHandler.appendMARCosResponse(finalText[currentResponse]);
              currentResponse += 1;
            }
          }, 1500);

          setTimeout(() => {
            this.speechBubText = finalText.join(' ');
            return callback(finalText);
          }, 1500);
        } else {
          console.log(response.responseText);
          let currentResponse = 0;
          let addText = setInterval(() => {
            if (currentResponse == finalText.length) {
              /*if(isMobile)
									{
										document.getElementById("speechbubbletext").innerHTML = response.responseText[0].substr(0,137) + "...";
										document.getElementById("speech-prong").click();
										
									}*/
              clearInterval(addText);
            } else {
              this.chatHandler.appendMARCosResponse(finalText[currentResponse]);
              currentResponse += 1;
            }
          }, 1500);

          setTimeout(() => {
            this.speechBubText = finalText.join(' ');
            return callback(finalText);
          }, 1500);
        }
      }
    },
    appendTextWithAudio: async function (response, emote = 'netural', callback = false) {
      if (!this.isSyncingApp) {
        this.appendText(response, (finalText) => {
          try {
            this.marcoTalk(finalText, emote, this.currVoice);
          } catch (err) {
            console.log(err);
          }
          if (callback) {
            return callback();
          }
        });
      } else {
        let tempSpeech = [];
        if (typeof response.responseText !== 'object') {
          tempSpeech.push(response.responseText);
        } else {
          tempSpeech = response.responseText;
        }
        console.log('The speech is: ' + tempSpeech);

        if (
          this.rawUsrData.userPersonality.userGeneral.spokenLanguage !== 'en' ||
          !this.rawUsrData.userPersonality.userGeneral.spokenLanguage.includes('en')
        ) {
          console.log('Translation 5');
          let spokenSpeech = await this.translateText(
            tempSpeech,
            this.rawUsrData.userPersonality.userGeneral.spokenLanguage
          );
          console.log(spokenSpeech);
          let speechJSON = JSON.parse(JSON.stringify(requestBody));

          speechJSON.echo = spokenSpeech;

          console.log(speechJSON);
          this.serverRequest(speechJSON);
        } else {
          let spokenSpeech = tempSpeech;
          console.log(spokenSpeech);
          let speechJSON = JSON.parse(JSON.stringify(requestBody));

          speechJSON.echo = spokenSpeech;

          console.log(speechJSON);
          this.serverRequest(speechJSON);
        }
      }
    },

    journalSend: function (obj) {
      var journalJSON = JSON.parse(JSON.stringify(requestBody));
      journalJSON.journalAnalysis.isTrue = true;
      journalJSON.dialogText = '';
      journalJSON.journalAnalysis.content = obj.entry;
      journalJSON.journalAnalysis.userLabels = obj.emotions;
      this.serverRequest(journalJSON);
      this.manageMARCoins('add', 30);
    },
    paramHandler: async function (response) {
      //Do something eventually

      var speechEmote = 'happy'; //TODO: Fix this so that it plays MARCo's actual emotion
      if (response.marcosEmotion) {
        speechEmote = response.marcosEmotion;
      }

      const chatRef = this.isMobile() ? this.$refs.mobilechatform : this.$refs.desktopchatform;
      const windowRef = !this.isMobile() ? this.$refs.desktoppopup : this.$refs.mobilepopup;
      console.log(windowRef);
      console.log(response);
      this.appendText(response, async (finalText) => {
        if (!this.isSyncingApp) {
          this.marcoTalk(finalText, speechEmote, this.currVoice);
        }

        if (response.launchStartupQuestionnaire) {
          setTimeout(() => {
            this.showEmotionForm = true;
          }, 1500);
        }
        if (response.quickReplyArray) {
          this.chatHandler.appendQuickReplies(response.quickReplyArray);
        }
        if (response.embedMedia.isTrue) {
          this.mediaSrc = response.embedMedia.mediaSource;
        }
        if (response.cbtGoals) {
          this.cbtGoals = response.cbtGoals;
        }
        if (response.clickElement.isTrue) {
          if (response.clickElement.elementID != 'close_media') {
            this.chatHandler.appendClickElement(response.clickElement.elementID);
          } else {
            this.popupEnabled = false;
            this.isJournaling = false;
          }
          //document.getElementById(response.clickElement.elementID).click();
          /*if(isMobile){
					$("#mobileMediaBar").fadeIn();
				} */
        }
        if (response.intentName) {
          this.checkActivity(response.intentName);
        }
				if (response.widget) {
          console.log(response.widget);
          /**
           * TODO: Add widget to the chat
           * Widget structure:
           *
           * {
           *  "kind":"chart",
           *  "data":{
           * ...
           *    }
           */
          console.log(response.widget);
          /**
           * TODO: Add widget to the chat
           * Widget structure:
           *
           * {
           *  "kind":"chart",
           *  "data":{
           * ...
           *    }
           */
          this.widgetInfo = response.widget;
          this.defaultTemplate = response.widget.template;
          this.showWidget = true;

          //TODO: Pass the widget template to the widget

          //TODO: Pass the widget template to the widget
        }
        if (response.newWindow.isTrue) {
          //childWindow = window.open(jsonResponse.newWindow.src);
          //window.open(response.newWindow.src,'_blank');
          this.newWindowUrl = response.newWindow.src;
          this.chatHandler.appendClickElement('new-window-overlay');
          this.mediaSrc2 = response.newWindow.src;
        }

        if (response.currEmotions) {
          this.emoteArray = response.currEmotions;
          //openActivityScheduler(response.currEmotions);
        }
        if (response.journalEntry) {
          let present = new Date();
          let entryTitle = `d${present.getDate()}m${present.getMonth() + 1}y${present.getFullYear()}`;
          const journalCollection = doc(
            this.$db,
            `/users/${this.rawUsrData.basicInformation.userID}/journals/regularEntries/${entryTitle}`
          );

          let jDoc = await getDoc(journalCollection);

          if (jDoc.exists()) {
            let journals = jDoc.data();
            journals[entryTitle] = response.journalEntry;
            await setDoc(journalCollection, journals);
          } else {
            let journals = {};
            journals[entryTitle] = response.journalEntry;
            await setDoc(journalCollection, journals);
          }
        }
        if (response.onboardingEvents) {
          if (this.dailyTracking.chosenActs.length < 1) {
            this.dailyTracking.chosenActs.push({
              name: 'Play two truths and a lie',
              whatToSay: 'Play two truths and a lie!',
              marcoins: 125,
              intent: 'Online Onboarding - Two Truths and Lie Start',
            });
          }
          this.onboardingEvents = [];
          for (let i = 0; i < response.onboardingEvents.length; i++) {
            let fields = response.onboardingEvents[i].structValue.fields;

            this.onboardingEvents.push(fields.target.stringValue);
          }
        }

        if (response.switchWindow.isTrue) {
          //Based on the number response of the switchWindow value, change the current window to a different page
          //KEY: 0 = home page; 1 = meditation page; 2 = add user page; 3 = psychotherapy page; 4 = boot page; 5 = settings page; 6 = wifi settings page
          switch (response.switchWindow.switchNum) {
            //TODO: Insert case numbers

            case 0:
              //This is opening the friend journal
              this.handleDeviceMARCoSwitch('Chat as friends on MARCo or on app?', () => {
                this.popupEnabled = true;
                this.windowCase = 2;
                this.currPopupTitle = 'Chat as Friends';
                setTimeout(() => {
                  this.$refs.mobilepopup.$refs.journal.launchFriendDialog();
                  this.isJournaling = true;
                }, 500);
              });

              break;
            case 1:
              //This is opening open talks
              this.handleDeviceMARCoSwitch('Talk on MARCo or on app?', () => {
                this.popupEnabled = true;
                this.windowCase = 2;
                this.currPopupTitle = 'Open Talks';
                setTimeout(() => {
                  this.$refs.mobilepopup.$refs.journal.openTalkHandler2.openOpenTalks();
                  this.isJournaling = true;
                }, 500);
              });
              break;
            /* case 0:
                    document.location.href = '../views/main.html'; 
                    break;
                case 1:
                    document.location.href = '../views/med-page.html'; 
                    break;
                case 2: 
                    document.location.href = '../views/add-user-form.html';
                    break;
                case 3:
                    document.location.href = '../views/cbt-page.html';
                    break;
                
                case 5:
                    document.location.href = '../views/settings-page.html';
                    break;
                case 6:
                    document.location.href = '../views/wifi-page.html';
                    break;
		        */
            case 4:
              //document.location.href = '../views/boot-page.html';
              EventBus.$emit('initializedStatus', false);
              //this.marcoIsCalled = false;
              setTimeout(() => {
                window.location.reload();
              }, 2000);

              break;
            case 7:
              this.popupEnabled = true;
              this.currPopupTitle = 'Pomodoro Timer';
              this.pomodoroCase = 0;
              this.windowCase = 7;
              break;
            case 8:
              this.popupEnabled = true;
              this.currPopupTitle = 'Pomodoro Timer';
              this.pomodoroCase = 1;
              this.windowCase = 7;
              break;
            case 9:
              this.highalertHandler.initializeCSSRS();
              this.popupEnabled = true;
              this.currPopupTitle = 'You are not alone';
              this.windowCase = 6;
              break;
            case 11:
              //This is the goodbye form
              this.popupEnabled = true;
              this.currPopupTitle = 'Feedback';
              this.windowCase = 11;

              break;
            /*case 12:
					document.location.href='../views/sleep-page.html';
                    break;*/

            //TODO: Add cases for other pages
            default:
              //document.location.href = '../views/main.html';
              //document.getElementById('Case: ' + jsonResponse.switchWindow.isTrue);
              break;
          }
        }
        if (response.timer.isTrue) {
          this.pomodoroCase = -1;
          // console.log("The timer results are " + JSON.stringify(response.timer))
          this.timerTime = parseInt(response.timer.millis);
          this.popupEnabled = true;
          this.currPopupTitle = 'Timer';
          this.windowCase = 7;
        }
      });
    },
    previewMARCoVoice: function (speech = `Hello`) {
      let speechJSON = JSON.parse(JSON.stringify(requestBody));
      this.previewingVoice = true;

      speechJSON.speak = speech;
      speechJSON.speechVoice = this.rawUsrData.userPersonality.userGeneral.preferredVoice;

      /*this.serverRequest(speechJSON, (response) => {
        if (!isSafari && !isiOSSafari) {
          this.toAudioBuff(response.audioContent.data, (buff) => {
            this.playSound(buff, (event) => {
              //document.getElementById("speech-prong").click();
              this.previewingVoice = false;
            });
          });
        }
        //ELSE it is safari and we need to use an audio tag instead
        else {
          let audioDatURI = "data:audio/wav;base64," + response.base64Audio;
          this.iosAudio.src = audioDatURI;
          this.iosAudio.play();
          this.iosAudio.addEventListener("ended", () => {
            this.iosAudio.currentTime = 0;
            // console.log("ended");
            this.previewingVoice = false;
          });
        }
      });*/

      EventBus.$emit('onboardTalk', [speech]);

      setTimeout(() => {
        this.previewingVoice = false;
      }, 3000);
    },
    marcoTalk: function (speech = `Hello`, emotion = 'happy', voiceProfID = 'en-US-Standard-D') {
      if (this.marcoIsCalled) {
        //   const marcoAvatar = this.isMobile() ? this.$refs.marcomobilewindow:this.$refs.marcowindow;
        //TODO: Add code to make MARCo actually speak
        requestBody.dialogText = '';
        let tempSpeech = ``;
        if (typeof speech == 'object') {
          console.log('it is an object');
          console.log(speech);
          tempSpeech += `<speak>`;
          for (let m = 0; m < speech.length; m++) {
            let next = speech[m].replace('<speak>', '');
            next = next.replace('</speak>', '');
            tempSpeech += next + ' ';
          }

          tempSpeech += `</speak>`;
        } else {
          tempSpeech = `${speech}`;
        }
        //	console.log("The speech is: " + tempSpeech);

        let spokenSpeech = tempSpeech;

        try {
          //	marcoAvatar.marcoEyes.animations.normal();
        } catch (err) {
          console.log(err);
        }

        if (this.audioOn && !this.isSyncingApp) {
          //	console.log(`The spoken speech is ${spokenSpeech}`);

          //utterance.voice = document.getElementById("voiceSelector")?speechSynthesis.getVoices[document.getElementById("voiceSelector").value]:speechSynthesis.getVoices()[voiceProfID];
          let speechJSON = JSON.parse(JSON.stringify(requestBody));

          speechJSON.speak = spokenSpeech;
          speechJSON.speechVoice = this.rawUsrData.userPersonality.userGeneral.preferredVoice;

          this.serverRequest(speechJSON, (response) => {
            //marcoAvatar.marcoHead.animations.rest();

            if (emotion == 'neutral') {
              //marcoAvatar.marcoMouth.animations.neutralTalk();
              this.$marcoStartTalking();
            } else if (emotion == 'sad') {
              this.$marcoStartTalking();
              //marcoAvatar.marcoMouth.animations.frownTalk();
            } else if (emotion == 'happy') {
              this.$marcoStartTalking();
              //marcoAvatar.marcoMouth.animations.smileTalk();
            } else {
              this.$marcoStartTalking();
              //marcoAvatar.marcoMouth.animations.smileTalk();
            }
            //console.log("The audio content is " + response.audioContent.data)
            if (!isSafari && !isiOSSafari) {
              this.toAudioBuff(response.audioContent.data, (buff) => {
                console.log('Audio buffer from client:');
                console.log(buff);
                this.playSound(buff, (event) => {
                  setTimeout(async () => {
                    //document.getElementById("speech-prong").click();
                    // marcoAvatar.marcoHead.animations.bobRotate();
                    if (emotion == 'neutral') {
                      this.$marcoStopTalking();
                      //this.$marcoNeutral();
                      //	marcoAvatar.marcoMouth.animations.neutral();
                    } else if (emotion == 'sad') {
                      this.$marcoStopTalking();
                      //	marcoAvatar.marcoMouth.animations.frown();
                    } else if (emotion == 'happy') {
                      this.$marcoStopTalking();
                      //marcoAvatar.marcoMouth.animations.smile();
                    } else {
                      this.$marcoStopTalking();
                      //marcoAvatar.marcoMouth.animations.smile();
                    }
                  }, 250);
                });
              });
            }
            //ELSE it is safari and we need to use an audio tag instead
            else {
              let audioDatURI = 'data:audio/wav;base64,' + response.base64Audio;
              this.iosAudio.src = audioDatURI;
              this.iosAudio.play();
              this.iosAudio.addEventListener('ended', () => {
                this.iosAudio.currentTime = 0;
                // console.log("ended");
                // marcoAvatar.marcoHead.animations.bobRotate();
                if (emotion == 'neutral') {
                  //	marcoAvatar.marcoMouth.animations.neutral();
                } else if (emotion == 'sad') {
                  //marcoAvatar.marcoMouth.animations.frown();
                } else if (emotion == 'happy') {
                  //marcoAvatar.marcoMouth.animations.smile();
                } else {
                  //marcoAvatar.marcoMouth.animations.smile();
                }
              });
            }
          });
        }
      }
    },
    updateVidBkg(bkgName) {
      this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.backgrounds.default = bkgName;
      this.updateUser();
      if (!this.isMobile()) {
        this.$refs.marcowindow.updateVidBackground(
          this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.backgrounds.default
        );
      } else {
        this.$refs.marcomobilewindow.updateVidBackground(
          this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.backgrounds.default
        );
      }
    },
    saveName() {
      //Write the user's name and alert that it is done
      this.rawUsrData.basicInformation['basic-given-name'] = this.usrDisplayName.split(' ')[0];
      this.rawUsrData.basicInformation['basic-last-name'] =
        this.usrDisplayName.split(' ')[this.usrDisplayName.split(' ').length - 1];

      this.updateUser();

      this.showToast('Saved your new name!', 'success', 1500);
    },
    savePhone() {
      //Save the user's phone number and alert that it is done
      if (this.countryCodeHandler.selectedCountryCode.length > 0) {
        this.updateUser();
        this.updateMessagingSubscriptions();
        this.showToast('Saved your phone number!', 'success', 1500);
      } else {
        this.showToast("Please add a country code by pressing the '+?' icon", 'warning', 3500);
      }
    },
    showPreviousSuggestedActivities(array) {
      this.emoteArray = array;
      this.popupEnabled = true;
      this.currPopupTitle = 'Activities & Goals';
      this.windowCase = 5;
    },
    signOut: function () {
      //Log the user out
      this.$auth
        .signOut()
        .then(function () {
          EventBus.$emit('loadingOverlay', true);
          this.$router.push(`/login?isreact=${launchImmediately}`);
        })
        .catch(function (err) {
          this.showToast('There was a problem there! Whoops!', 'error', 3000);
        });
    },
    checkActivity: async function (intentArray) {
      let wasCompleted = false;
      for (let a = 0; a < this.dailyTracking.chosenActs.length; a++) {
        if (
          intentArray.includes(this.dailyTracking.chosenActs[a].intent) &&
          !this.dailyTracking.chosenActs[a].complete &&
          !wasCompleted
        ) {
          wasCompleted = true;

          this.$set(this.dailyTracking.chosenActs[a], 'complete', true);
          //console.log("Before running - ");
          //console.log(this.dailyTracking);
          this.dailyTracking.completedActs += 1;
          this.$set(
            this.dailyTracking,
            'progressVal',
            parseInt((this.dailyTracking.completedActs / this.dailyTracking.chosenActs.length) * 100)
          );
          if (this.dailyTracking.completedActs == this.dailyTracking.chosenActs.length) {
            this.dailyTracking.handleActivityNotifications(2);
          }
          // console.log("After running - ")
          // console.log(this.dailyTracking);
          //TODO: Uncomment for launch!!!!
          this.manageMARCoins('add', this.dailyTracking.chosenActs[a].marcoins);

          const activityDaily = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/daily`);
          const activityMaster = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/master`);
          let present = new Date();
          let entryTitle = `d${present.getDate()}m${present.getMonth() + 1}y${present.getFullYear()}`;
          const activityDailyDoc = await getDoc(activityDaily);
          if (activityDailyDoc.exists()) {
            let actDoc = activityDailyDoc.data();
            actDoc[entryTitle] = this.dailyTracking.chosenActs;

            setDoc(activityDaily, actDoc);
          } else {
            let actDoc = {};
            actDoc[entryTitle] = this.dailyTracking.chosenActs;
            setDoc(activityDaily, actDoc);
          }
        }
      }

      if (intentArray.includes('Online Onboarding - Start - yes')) {
        //$("#onboardingTrigger").click();
      }
    },
    showToast(msg, type = 'info', timeout = 3500) {
      this.alertMsg = msg;
      this.alertType = type;
      this.toast = true;
      setTimeout(() => {
        this.toast = false;
      }, timeout);
    },
    switchMARCo(MARCoID) {
      if (!this.isMobile()) {
        this.$refs.marcowindow.switchMARCo(MARCoID);
      } else {
        this.$refs.marcomobilewindow.switchMARCo(MARCoID);
      }
      this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.marcos.default = MARCoID;
      this.updateUser();
      //this.updateHeadAccess();
    },
    clickElement: function (src) {
      console.log('Clicking #' + src);
      document.getElementById(src).click();
    },
    switchWindow: function (win) {
      this.windowCase = win;
    },
    playSound: function (buffer, callback) {
      if (this.source) {
        try {
          this.source.stop();
        } catch (err) {
          console.log(err);
        }
      }
      this.source = this.audioContext.createBufferSource(); // creates a sound source
      this.source.buffer = buffer; // tell the source which sound to play
      this.source.connect(this.audioContext.destination); // connect the source to the context's destination (the speakers)
      this.source.start ? this.source.start(0) : this.source.noteOn(0); // play the source now
      this.source.onended = function (event) {
        return callback(event); // note: on older systems, may have to use deprecated noteOn(time);
      };
    },
    marcoSpeakOnOff: function () {
      this.audioOn = !this.audioOn;
      this.sfxHandler.audioClick();
      if (this.source) {
        try {
          this.source.stop();
        } catch (err) {
          console.log(err);
        }
      }
      this.iosAudio.pause();
    },

    toAudioBuff: function (buf, callback) {
      var ab = new ArrayBuffer(buf.length);
      var view = new Uint8Array(ab);
      for (var i = 0; i < buf.length; ++i) {
        view[i] = buf[i];
      }
      this.audioContext.decodeAudioData(ab, (buffer) => {
        return callback(buffer);
      });
    },

    arrayBufferToBase64: function (buffer, callback) {
      let binary = '';
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      callback(window.btoa(binary));
    },
    submitGoodbye: async function (entries) {
      this.popupEnabled = false;
      this.isJournaling = false;
      this.showToast('Submitted!', 'success');
      let prePostRef = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/sessionRecords/prePostScores`);

      let doc = await getDoc(this.$db, prePostRef);
      if (doc.exists()) {
        let d = doc.data();
        d.entries.push(entries);
        prePostRef.set(d);
      } else {
        prePostRef.set({
          entries: [entries],
        });
      }

      this.popupEnabled = false;
      this.isJournaling = false;
      setTimeout(() => {
        this.manageMARCoins('add', 40);
      });
    },
    updateUser: async function (callback = false) {
      await setDoc(doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}`), this.rawUsrData);

      if (this.isSyncingApp) {
        //Quickly toggle the realtime database to tell MARCo there is an update to the raw user data
        EventBus.$emit('updateUser', '');
        setTimeout(() => {
          EventBus.$emit('updateUser', this.rawUsrData.basicInformation.userID);
        }, 300);
      }

      if (callback) {
        return callback();
      }
    },
    loadUserSessionLogs: async function (callback = false) {
      //Set the reference document for the user's summary doc
      let userSummaryDoc = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/sessionRecords/summary`);

      //Get the user's summary sheet first and if it does not exist, get the template and write it from the template
      let summaryDoc = await getDoc(userSummaryDoc);

      if (summaryDoc.exists()) {
        this.usrSessionSummary = summaryDoc.data();
      } else {
        //The document does not exist, get it from the template and write it here
        let templateDoc = await getDoc(doc(this.$db, `/users/template_v1/sessionRecords/summary`));

        if (templateDoc.exists()) {
          this.usrSessionSummary = templateDoc.data();
        } else {
          console.err('MAJOR ERROR: NO TEMPLATE FOUND');
        }
      }

      //Create a comprehensive session log
      /* let templateComprehensive = db.doc('/users/template_v1/sessionRecords/DAILY_TEMPLATE');

            templateComprehensive.get().then((doc) =>{
                if(doc.exists){
                    let document = doc.data();
                  

                //Switch between the questionnaire at the start or end of the user's session with MARCo
                    switch(this.mode){

                        case 0:
                            document.preScores.push(entry);
                            break;
                        case 1:
                            document.postScores.push(nextEntry);
                            break;
                    }

                    db.doc(`/users/${this.rawUsrData.basicInformation.userID}/sessionRecords/${entryTitle}`).set(document);

                }
                else{
                    console.err("MAJOR ERROR: NO TEMPLATE FOUND")
                }
            }) */
    },
    updateUserPreferences: function () {
      this.updateUser(() => {
        this.showToast('Preferences saved!', 'success', '1500');
      });
    },
    loadDefaultUsrSettings: function () {
      if (typeof this.rawUsrData.userPersonality.userGeneral.allowAutoResponses !== 'boolean') {
        this.rawUsrData.userPersonality.userGeneral.allowAutoGeneratedResponses = this.allowAutoGeneratedResponses;
        this.updateUser();
      } else {
        this.allowAutoGeneratedResponses = this.rawUsrData.userPersonality.userGeneral.allowAutoGeneratedResponses;
      }
    },
    manageMARCoins: function (mode, amount, callback = false) {
      console.log('The mode is ' + mode);
      console.log('The amount is ' + amount);
      if (typeof amount !== 'undefined') amount = parseInt(amount);
      else amount = 40;
      if (!this.rawUsrData.userPersonality.userGeneral.MARCoins) {
        this.rawUsrData.userPersonality.userGeneral.MARCoins = 0;
        this.updateUser();
      }

      if (mode == 'add' && typeof amount === 'number') {
        this.rawUsrData.userPersonality.userGeneral.MARCoins += amount * (this.isSyncingApp ? 2 : 1);
        this.updateUser();
        this.sfxHandler.audioCoin();
        this.marcoinsnackbar = true;
        this.marcoinsAdded = amount;
      }
      if (mode == 'subtract' && typeof amount === 'number') {
        this.rawUsrData.userPersonality.userGeneral.MARCoins -= amount;
        this.updateUser();
      }

      if (callback) {
        return callback(this.rawUsrData.userPersonality.userGeneral.MARCoins);
      }
    },

    makePurchase: function (args) {
      let product = args.product;
      let type = args.type;
      let id;

      if (type == 'audioFiles') {
        id = {
          name: product.displayName,
          sounds: product.files,
        };
      } else {
        id = product.backendName;
      }
      this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases[type].all.push(id);

      this.manageMARCoins('subtract', product.marcoinsOrig - product.marcoinDiscount, (coins) => {
        this.updateUser();

        this.showToast('Your purchase was successful!', 'success', 2500);
        this.sfxHandler.audioPurchase();
      });
    },

    checkUsrType: async function (accountType) {
      let formattedAccount = accountType;
      formattedAccount = formattedAccount.replace(/-/g, ', ');
      this.usrAccountType = formattedAccount;

      if (accountType == 'Administrator' || accountType == 'Monthly' || accountType == 'Annual') {
        //this.$router.push("https://www.marcohealthtech.com/marcodemo/marconline";
        //$("#upgradeBtn").hide();
      } else if (accountType.includes('Full')) {
        //Check if renewal is needed
        //$("#upgradeBtn").text("Manage");
        this.upgradeBtnLink = '/pay?allowreturn=true';

        //If they are just full, do nothing.
        //If they are monthly, check first whether they are web or mobile and run accordingly
        this.upgradeBtnText = 'Manage';

        if (accountType.includes('Web')) {
          this.upgradeBtnLink = '#';

          const payResp = await fetch(
            `${this.$host}/payment/checksub/${this.rawUsrData.userPersonality.userGeneral.accountInfo.customerID}`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ returnURL: window.location.href }),
            }
          );
          let result = await payResp.json();
          if (!result.subbed || result.subbed == 'false') {
            this.$router.push('/pay');
          } else {
            //this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = accountType.includes('Annual')?new Date(renewalDate+1000*60*60*24*365):new Date(renewalDate+1000*60*60*24*30);
          }

          /*	$("#upgradeBtn").attr("href","#");
				$("#upgradeBtn").on("click", function(){
					$.post(`/payment/webportal`,{customerID:this.rawUsrData.userPersonality.userGeneral.accountInfo.customerID,returnURL:window.location.href},function(result){
						//this.$router.push(result.url;
						console.log(result);
						this.$router.push(result.url;
					});
				})

                
					$.post(`/payment/checksub/${this.rawUsrData.userPersonality.userGeneral.accountInfo.customerID}`,{returnURL:window.location.href},function(result){
							console.log(result);	
						if(!result.subbed || result.subbed == 'false')
							{
								this.$router.push('/pay';
							}
							else{
								//this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = accountType.includes('Annual')?new Date(renewalDate+1000*60*60*24*365):new Date(renewalDate+1000*60*60*24*30);
							}
					}) */
        }
        //If they are annual, check first whether they are web or mobile and run checks accordingly
        else if (accountType.includes('Mobile')) {
          //They paid on mobile, so check if the store exists and if so, check if they own the product
          //If there is no store, we will need to run a check on their device
          /*	this.mobileStoreHandler(function(isOwned){
						if(!isOwned)
						{
							this.$router.push('/pay?isreact=true';
						}
					})*/

          this.payDialog = false;
        }
      } else if (accountType == 'Pay-As-You-Go') {
        /*if(currUsr.userPersonality.userGeneral.accountInfo.numCredits){
						   	if(currUsr.userPersonality.userGeneral.accountInfo.numCredits > 0)
								{
									
									currUsr.userPersonality.userGeneral.accountInfo.numCredits -= 1;
									
									updateUsr(currUsr,()=>{
										//this.$router.push(`https://www.marcohealthtech.com/marcodemo/marconline?numcreditsremaining=${currUsr.userPersonality.userGeneral.accountInfo.numCredits}`;
										
									})
								}
							else
								{
									this.$router.push(`/login?isreact=${launchImmediately}.html`;
									
								}
						   }
						else
							{
									currUsr.userPersonality.userGeneral.accountInfo.numCredits =0;
									updateUsr(currUsr, ()=> {
										this.$router.push(`/login?isreact=${launchImmediately}.html`;
									})
									
							}*/
      } else if (accountType == 'Trial') {
        //$("#upgradeBtn").text("Upgrade!");
        //$("#upgradeBtn").attr("href","/pay?allowreturn=true");
        //Get the start date of the user

        //this.betaHandler.showBetaDialog = true;

        let startDate = new Date(this.rawUsrData.userPersonality.userGeneral.firstDate);
        let d = new Date();
        let difference = 7 * 24 * 3600 * 1000 - (d - startDate);
        console.log('The difference is ' + difference);
        this.trialDaysLeft = Math.ceil(difference / (24 * 3600 * 1000));
        //this.trialsnackbar = true;

        if (difference < 0) {
          //The trial is expired
          //currUsr.userPersonality.userGeneral.accountInfo.accountType = "Full";
          /*updateUsr(currUsr,()=>{
											this.$router.push(`/login?isreact=${launchImmediately}`;
										})*/
          //this.$router.push(`/pay`;
          //this.payDialog = true;
          // this.allowPayCancel = false;
        } else {
          //this.$router.push(`/`;
        }
      }
    },

    getPrivacyTerms: async function (isPrivacy = true) {
      this.isPrivacyPolicy = isPrivacy;
      this.showPrivacyTerms = true;
      if (isPrivacy) {
        const privacyRaw = await fetch(`${this.$host}/privacy-policy`, {
          method: 'GET',
        });
        var privacyData = await privacyRaw.text();
        var parser = new DOMParser();
        var doc = parser.parseFromString(privacyData, 'text/html');
        this.privacyTermsData = new XMLSerializer().serializeToString(doc);
      } else {
        const termsRaw = await fetch(`${this.$host}/terms-and-conditions`, {
          method: 'GET',
        });
        var termsData = await termsRaw.text();
        var parser = new DOMParser();
        var doc = parser.parseFromString(termsData, 'text/html');

        this.privacyTermsData = new XMLSerializer().serializeToString(doc);
      }
    },
    updateRealtimeResponseSync: function (response) {
      set(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/responseSyncs`), {
        payload: response,
        fromMARCo: false,
      });
    },
    handleRealtimeSyncs: function () {
      console.log('Handling the realtime syncs...');
      this.appendTextWithAudio({
        responseText: [`You ${this.isSyncingApp ? 'started' : 'stopped'} syncing to MARCo.`],
      });

      set(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/isSyncingSoftware`), true);

      onValue(
        ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/responseSyncs`),
        (responseSnap) => {
          console.log('Message coming in, boss.');
          const response = responseSnap.val();
          if (response.fromMARCo) {
            console.log("It's from MARCo.");
            console.log(response.payload);
            this.paramHandler(response.payload);
          }
        }
      );

      onValue(
        ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/isSyncingSoftware`),
        (isSyncSnap) => {
          const isSyncing = isSyncSnap.val();

          if (this.isSyncingApp && !isSyncing) {
            set(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/isSyncingSoftware`), true);
          }
        }
      );
      this.isSyncingHardware = true;
      this.marcoIsCalled = true;
    },

    disconnectRealtimeSyncs: function () {
      set(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}`), {
        eventSyncs: false,
        responseSyncs: false,
        isSyncingHardware: false,
        isSyncingSoftware: false,
      });
      this.showToast('You have disconnected from MARCo.', 'info', 2500);
      this.explainSyncDialog = false;
      this.isSyncingApp = false;
      this.appendTextWithAudio({
        responseText: [`You ${this.isSyncingApp ? 'started' : 'stopped'} syncing to your MARCo.`],
      });

      this.marcoIsCalled = false;
    },
    forceRealtimeSyncs: function () {
      set(ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}`), {
        eventSyncs: false,
        responseSyncs: false,
        isSyncingHardware: true,
        isSyncingSoftware: true,
      });
      this.showToast(`You can now control your MARCo from your ${this.isMobile() ? 'phone' : 'device'}.`, 'info', 2500);
      this.explainSyncDialog = false;
      this.isSyncingApp = true;
      this.isSyncingHardware = true;

      this.appendTextWithAudio({
        responseText: [`You ${this.isSyncingApp ? 'started' : 'stopped'} syncing to your MARCo.`],
      });
    },
    decideSyncHandling: function () {
      if (this.isSyncingApp) {
        this.disconnectRealtimeSyncs();
        setTimeout(() => {
          this.syncingDialog = false;
          this.showFab = true;
        }, 2500);
      } else {
        this.forceRealtimeSyncs();
        setTimeout(() => {
          this.syncingDialog = false;
          this.showFab = true;
        }, 2500);
      }
    },
  },
  created() {
    /*this.rootEventHub.on('manageMARCoins',(e)=>{
          this.manageMARCoins(e.mode,e.amount)
      })*/
    EventBus.$on('paramHandler', (e) => {
      this.paramHandler(e);
    });

    EventBus.$on('genspeech', (e) => {
      this.appendTextWithAudio(e.response, e.emotion);
    });

    EventBus.$on('show-toast', (e) => {
      console.log('`The toast is`: ' + JSON.stringify(e));
      this.showToast(e.message, e.color, 3500);
    });

    EventBus.$on('transcript', (transcript) => {
      console.log('The transcript is: ' + JSON.stringify(transcript));
      if (transcript.isListening) {
        if (!this.marcoIsCalled) {
          this.callMARCo(this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.marcos.default);
        }
        if (!this.isJournaling && !this.showEmotionForm && this.marcoIsCalled) {
          this.micOn = true;
          this.transientRecordedTranscript = '';

          //Determine where the partial results need to go to

          this.transientRecordedTranscript = transcript.isFinal ? '' : transcript.nextSpeech;
          this.recordedTranscript = transcript.speech;
        } else if (this.isJournaling) {
          this.$refs.mobilepopup.$refs.journal.micButtonPress();
          this.$refs.mobilepopup.$refs.journal.appendJournal(transcript.nextSpeech, transcript.isFinal);
        } else if (this.showEmotionForm) {
          this.$refs.emotionform.handleTranscript(transcript.nextSpeech, transcript.isFinal);
          this.$refs.emotionform.micButtonPress();
        }
      }
      if (!transcript.isListening) {
        if (this.isJournaling) {
          this.$refs.mobilepopup.$refs.journal.micButtonRelease();
        } else if (this.showEmotionForm) {
          this.$refs.emotionform.micButtonRelease();
        } else if (this.recordedTranscript.length > 0 || this.transientRecordedTranscript.length > 0) {
          this.micOn = false;
          this.recordedTranscript = transcript.speech;

          this.sndTxt(this.recordedTranscript);

          this.transientRecordedTranscript = '';
          this.recordedTranscript = '';
        } else {
          this.micOn = false;
        }
      }
    });

    EventBus.$on('connectionStatus', (status) => {
      this.isSyncingApp = status;
    });

    EventBus.$emit('checkConnectionStatus');

    EventBus.$on('go-to-setting', () => {
      this.settingsTab = 0;
      setTimeout(() => {
        document.getElementById('birthday')?.scrollIntoView({ behavior: 'smooth' });
      }, 10);
      console.log(this.$refs.dobInput);
    });
    EventBus.$on('button-pressed', async (e) => {
      //this.handleButtonPress(e);

      console.log('The button was pressed and the event is: ' + e);
      console.log('MARCo is called: ' + this.marcoIsCalled);

      if (!this.marcoIsCalled) {
        this.callMARCo(this.rawUsrData.userPersonality.userGeneral.accountInfo.purchases.marcos.default);
      }

      if (!this.isJournaling && !this.showEmotionForm && this.marcoIsCalled) {
        this.micOn = true;
      } else if (this.isJournaling) {
        this.$refs.mobilepopup.$refs.journal.micButtonPress();
      }

      try {
        this.speechAudio.pause();
        this.source.stop();
      } catch (err) {
        console.log('No audio to stop.');
        console.warn(err);
      }

      //If it is not journaling, just use the default speech recognition
      //This code for testing only
      let canUseVoice = await this.$speech.available();

      if (canUseVoice) {
        this.$speech.start({
          language: 'en-US',
          maxResults: 2,
          partialResults: true,
        });
        this.$marcoListen();
        // listin to partial results
      } else {
        this.$marcoError();
      }
    });
    EventBus.$on('end-journal', async (e) => {
      this.isJournaling = false;
      this.$speech.stop();
      this.micOn = false;
    });

    EventBus.$on('start-journal', async (e) => {
      this.isJournaling = true;
      this.$speech.stop();
      this.micOn = false;
    });

    EventBus.$on('streaming-transcript', async (e) => {
      let transcript = e.text;
      let status = e.status;

      if (this.isJournaling) {
        this.$refs.mobilepopup.$refs.journal.appendJournal(transcript, !status.toLowerCase().includes('partial'));
      }
    });

    this.$speech.addListener('partialResults', (data) => {
      console.log('The partial data is', data);
      console.log('partialResults was fired', data.value);

      //Determine where the partial results need to go to
      if (!this.isJournaling && !this.showEmotionForm) {
        console.log(data);
        if (data.status.includes('listening')) {
          this.transientRecordedTranscript = data.value[0];
        } else {
          this.transientRecordedTranscript = '';
          this.recordedTranscript += data.value[0];
        }
      } else if (this.isJournaling) {
        this.$refs.mobilepopup.$refs.journal.appendJournal(data.value[0], !data.status.includes('listening'));
      } else if (this.showEmotionForm) {
        this.$refs.emotionform.handleTranscript(data.value[0], !data.status.includes('listening'));
      }
    });
    /**
     * This method will stop listening for utterance
     * @param none
     * @returns void
     */

    EventBus.$on('button-released', async (e) => {
      // this.handleButtonRelease(e);
      setTimeout(async () => {
        this.micOn = false;
        try {
          this.$speech.stop();
        } catch (e) {
          console.log('Error stopping speech recognition.');
          console.log(e);
        }

        try {
          this.$closeStreamingSpeech();
        } catch (e) {
          console.log('Error stopping streaming speech recognition.');
          console.log(e);
        }

        if (this.isJournaling) {
          this.$refs.mobilepopup.$refs.journal.micButtonRelease();
        } else if (this.recordedTranscript.length > 0 || this.transientRecordedTranscript.length > 0) {
          this.recordedTranscript += this.transientRecordedTranscript;

          this.sndTxt(this.recordedTranscript);

          this.transientRecordedTranscript = '';
          this.recordedTranscript = '';
        } else {
          this.$marcoError();
          this.recordedTranscript = '';
          if (this.audioOn && typeof this.speechAudio !== 'boolean') {
            //	console.log(`The spoken speech is ${spokenSpeech}`);

            //utterance.voice = document.getElementById("voiceSelector")?speechSynthesis.getVoices[document.getElementById("voiceSelector").value]:speechSynthesis.getVoices()[voiceProfID];
            let speechJSON = JSON.parse(JSON.stringify(requestBody));

            speechJSON.speak = [
              'Sorry.',
              'My bad.',
              'Sorry, continuing.',
              "Where was I? That's right.",
              'Oops, sorry.',
            ][Math.floor(Math.random() * 6)];
            speechJSON.speechVoice = this.rawUsrData.userPersonality.userGeneral.preferredVoice;

            this.serverRequest(speechJSON, (response) => {
              //marcoAvatar.marcoHead.animations.rest();

              //console.log("Audio error: "+ err);

              let resumeAudio = new Audio();

              let audioDatURI = 'data:audio/wav;base64,' + response.base64Audio;
              resumeAudio.src = audioDatURI;
              resumeAudio.play();
              resumeAudio.addEventListener('ended', () => {
                //document.getElementById("speech-prong").click();
                // marcoAvatar.marcoHead.animations.bobRotate();
                /*ipcRenderer.send('stoptalking');
							if(!!emotion && emotion == "neutral") {
									//marcoAvatar.marcoMouth.animations.neutral();
								ipcRenderer.send('marconeutral');
                                
                                    }
								else if(!!emotion && emotion == "sad")
									{
										//marcoAvatar.marcoMouth.animations.frown();
										ipcRenderer.send('marcofrown');
									}
								else if(!!emotion && emotion == "happy")
									{
										//marcoAvatar.marcoMouth.animations.smile();
										ipcRenderer.send('marcosmile');
									}
								else
									{
										//marcoAvatar.marcoMouth.animations.smile();
										ipcRenderer.send('marcosmile');
									}
                                this.speechAudio = false;*/
                resumeAudio = null;

                this.speechAudio.play();
              });

              if (!!emotion && emotion == 'neutral') {
                //marcoAvatar.marcoMouth.animations.neutralTalk();
                this.$marcoStartTalking();
              } else if (!!emotion && emotion == 'sad') {
                //marcoAvatar.marcoMouth.animations.frownTalk();
                this.$marcoStartTalking();
              } else if (!!emotion && emotion == 'happy') {
                //marcoAvatar.marcoMouth.animations.smileTalk();
                this.$marcoStartTalking();
              } else {
                //marcoAvatar.marcoMouth.animations.smileTalk();
                this.$marcoStartTalking();
              }
              //console.log("The audio content is " + response.audioContent.data)
            });
          }
        }
      }, 550);
    });
  },
  beforeMount: async function () {
    this.showLastSessionRating = getUrlParam('showLastSessionRating', false) == 'true';

    //Set up the nonreactive properties
    this.setupOptions = [
      {
        name: 'In-Person',
        icon: '/assets/images/meditationcardbkg.jpg',
      },
      {
        name: 'Online',
        icon: '/assets/images/marco-wave.png',
      },
    ];
    (this.previewingMsgDefault = `This is what your MARCo will sound like.`),
      (this.syncOptions = [
        {
          icon: 'mdi-sync-circle',
          title: 'Pair to last MARCo',
          action: () => {
            console.log('Quick pairing...');
            this.syncWindow = 4;
            this.decideSyncHandling();
          },
        },
        {
          icon: 'mdi-numeric',
          title: 'Enter Code',
          action: () => {
            console.log('Entering code...');
            console.log(this.syncWindow);
            this.syncWindow = 3;
          },
        },
        {
          icon: 'mdi-plus-circle-multiple-outline',
          title: 'Setup New Device',
          action: () => {
            console.log('Adding new device...');
            this.syncWindow = 2;
          },
        },
      ]);
    (this.connectionTitles = [
      'Found a MARCo, Attempting to Connect...',
      'Could not connect, try again?',
      'Asking to bond...',
      'Bond failed, make sure you accept. Try again?',
      'Sending info...',
      'Write failed, try again?',
      'Verifying....',
      'Could not connect, are you sure the info is correct?',
    ]),
      (this.animalsList = [
        { name: 'Cats', value: 'cat' },
        { name: 'Dogs', value: 'dog' },
        { name: 'Birds', value: 'bird' },
        { name: 'Monkeys', value: 'monkey' },
        { name: 'Foxes', value: 'fox' },
        {
          name: 'Horses',
          value: 'horse',
        },
        {
          name: 'Pandas',
          value: 'panda',
        },
        {
          name: 'Sloths',
          value: 'sloth',
        },
        {
          name: 'Rabbits',
          value: 'rabbit',
        },
        {
          name: 'Lizards',
          value: 'lizard',
        },
        {
          name: 'Snakes',
          value: 'snake',
        },
      ]);
    this.hobbiesList = [
      { name: 'Playing music', value: 'music' },
      { name: 'Makeup', value: 'makeup' },
      { name: 'Art', value: 'general art' },
      { name: 'Drawing', value: 'drawing' },
      { name: 'Painting', value: 'painting' },
      {
        name: 'Theater',
        value: 'theater',
      },
      {
        name: 'Gaming',
        value: 'gaming',
      },
      {
        name: 'Magic/Magic Tricks',
        value: 'magic',
      },
      {
        name: 'Cooking',
        value: 'cooking',
      },
      {
        name: 'Photography',
        value: 'photography',
      },
      {
        name: 'Reading',
        value: 'reading',
      },
      {
        name: 'Writing',
        value: 'writing',
      },
      {
        name: 'Playing/Watching Sports',
        value: 'sports',
      },
    ];
    this.faithList = [
      { name: 'Christianity', value: 'christianity' },
      { name: 'Judaism', value: 'judaism' },
      { name: 'Islam', value: 'islam' },
      { name: 'Hinduism', value: 'hinduism' },
      { name: 'Buddhism', value: 'buddhism' },
      {
        name: 'Spiritual/New Religion',
        value: 'spiritual',
      },
      {
        name: 'Sikh',
        value: 'sikh',
      },
      {
        name: 'Taoism',
        value: 'taoism',
      },
      {
        name: 'Atheism',
        value: 'atheism',
      },
    ];
    this.navItems = [
      {
        title: 'Media Player',
        id: 'custom-media-player',
        icon: 'mdi-motion-play-outline',
        key: 1,
        color: 'teal',
        badgeCriteria: '',
        action: () => {
          if (this.marcoIsCalled || this.isSyncingApp) {
            this.sfxHandler.audioClick();
            this.popupEnabled = true;
            this.currPopupTitle = 'Media Player';
            this.windowCase = 1;
          } else {
            this.showToast('Call or sync to MARCo to open this activity!', 'warning');
          }
        },
      },
      {
        title: !this.globalCBTRequest ? 'My Journal' : 'My Open Talks',
        icon: 'mdi-book-open-variant',
        id: 'journal-overlay',
        key: 2,
        color: 'cyan',
        badgeCriteria: '#journal-overlay a',
        action: () => {
          if (this.marcoIsCalled || this.isSyncingApp) {
            this.sfxHandler.audioClick();
            this.popupEnabled = true;
            this.currPopupTitle = !this.globalCBTRequest ? 'My Journal' : 'My Open Talks';
            this.windowCase = 2;
            try {
              this.onboardingEvents.splice(this.onboardingEvents.indexOf('#journal-overlay a'), 1);
            } catch (err) {
              console.log(err);
            }
          } else {
            this.showToast('Call or sync to MARCo to open this activity!', 'warning');
          }
        },
      },
      {
        title: 'Activities & Goals',
        icon: 'mdi-calendar-month',
        key: 5,
        id: 'activityScheduleBar',
        color: 'light-blue accent-4',
        badgeCriteria: '#activityScheduleBar a',
        action: () => {
          if (this.isSyncingApp || this.marcoIsCalled) {
            this.sfxHandler.audioClick();
            this.popupEnabled = true;
            this.currPopupTitle = 'Activities & Goals';
            this.windowCase = 5;
            try {
              this.onboardingEvents.splice(this.onboardingEvents.indexOf('#activityScheduleBar a'), 1);
            } catch (err) {
              console.log(err);
            }
          } else {
            this.showToast('Call or sync to MARCo to open this activity!', 'warning');
          }
        },
      },
      {
        title: 'Settings',
        icon: 'mdi-cog-outline',
        key: 6,
        id: 'menu-icon',
        color: 'purple accent-4',
        badgeCriteria: '#menu-icon a',
        action: () => {
          this.sfxHandler.audioClick();
          this.sfxHandler.audioWoosh();

          //Attempt to preload additional settings outside of user file

          /*db.collection("contactLists").doc("dailyTextSubscriptions").collection(this.rawUsrData.basicInformation.userID).doc("permissions").get().then((doc) =>{
                  if(doc.exists){
                      //Check if the user is subscribed to text message notifications
                      let usrSubscriptions = doc.data();

                      this.dailyContactSubscriptions = JSON.parse(JSON.stringify(usrSubscriptions));

                  }
                  else{
                      console.log("The user might not have set permissions for daily contact yet.");
                      
                      db.collection("contactLists").doc("dailyTextSubscriptions").get().then((templateDoc) =>{
                          let templateUsrSubscriptionDoc = templateDoc.data();
                          let templateUsrSub = JSON.parse(JSON.stringify(templateUsrSubscriptionDoc.template));

                          templateUsrSub.usrName = this.rawUsrData.basicInformation["basic-given-name"];
                          templateUsrSub.usrPhone = this.rawUsrData.basicInformation['basic-user-phone-num'];
                          templateUsrSub.usrID = this.rawUsrData.basicInformation.userID;
                          let contactTimeOffsetRef = new Date();
                          templateUsrSub.timezoneOffset = contactTimeOffsetRef.getTimezoneOffset();
                            this.dailyContactSubscriptions = JSON.parse(JSON.stringify(templateUsrSub));
                          db.collection("contactLists").doc("dailyTextSubscriptions").collection(this.rawUsrData.basicInformation.userID).doc("permissions").set(templateUsrSub);
                      });


                  }
              })*/

          this.loadMessagingSubscriptions();
          this.ShowSettings = true;

          try {
            this.onboardingEvents.splice(this.onboardingEvents.indexOf('#menu-icon a'), 1);
          } catch (err) {
            console.log(err);
          }
        },
      },
    ];

    this.navDrawer = false;

    //Get the current user data
    var user = this.$auth.currentUser;

    var userCheckerStep = 0;
    var userChecker = setInterval(async () => {
      console.log('The user is: ');
      console.log(user);
      if (user != null) {
        clearInterval(userChecker);
        this.uid = user?.uid;

        user.providerData.forEach(async (profile) => {
          //console.log(`The profile is ${JSON.stringify(profile)}`);
          try {
            let nextId = profile.providerId.toLowerCase();
            nextId = nextId.replace('.com', '');
            this.syncAccountHandler.syncedAccounts.push(nextId);
            //TODO: Populate a list for automatic rendering of linked accounts
            //	$(`#${nextId}-auth-list`).removeClass("inactive");
            //	$(`#${nextId}LinkBtn`).hide();
          } catch (err) {
            console.log(err);
          }
        });
        //name = user.displayName;

        let email = user.email;
        let photoUrl = user.photoURL ? user.photoURL : '';
        let emailVerified = user.emailVerified;
        let uid = user.uid; // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.

        //requestBody.currentUser.basicInformation["basic-given-name"] = name.split(' ')[0];
        //requestBody.currentUser.basicInformation["basic-last-name"] = name.split(' ')[name.split(' ').length-1];
        //document.getElementById("renameUser").value = name;

        let usrDocName = uid;
        requestBody.currentUser.basicInformation.userID = uid;

        const usrRef = doc(this.$db, `/users/${usrDocName}`);
        const usrDoc = await getDoc(usrRef);

        if (usrDoc.exists()) {
          this.rawUsrData = usrDoc.data();
          this.loadUserSessionLogs();
          this.rawUsrData.userPersonality.userGeneral.lastSessionDate = new Date();
          let firstDate = new Date(this.rawUsrData.userPersonality.userGeneral.firstDate);
          requestBody.currentUser.knowsCBT = this.rawUsrData.knowsCBT;
          //requestBody.currentUser.basicInformation["basic-emergency-phone-num"] = this.rawUsrData.basicInformation["basic-emergency-phone-num"];
          requestBody.currentUser.basicInformation = this.rawUsrData.basicInformation;
          this.birthDate = this.rawUsrData.basicInformation.birthDate ? this.rawUsrData.basicInformation.birthDate : '';
          requestBody.currentUser.userPersonality.userClassifications =
            this.rawUsrData.userPersonality.userClassifications;
          this.checkUsrType(this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType);
          //$("#currUsrAccntType").text(this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType);
          this.usrDisplayName =
            this.rawUsrData.basicInformation['basic-given-name'] +
            ' ' +
            this.rawUsrData.basicInformation['basic-last-name'];

          //Correct language codes

          this.manageMARCoins('get', 0);
          if (typeof this.rawUsrData.userPersonality.userGeneral.accountInfo.desktopThemeNum !== 'number') {
            this.rawUsrData.userPersonality.userGeneral.accountInfo.desktopThemeNum = 1;
            this.updateUser();
          }

          this.loadMessagingSubscriptions();
          if (this.rawUsrData.userPersonality.userGeneral.spokenLanguage === 'en-US') {
            this.rawUsrData.userPersonality.userGeneral.spokenLanguage = 'en';
            this.updateUser();
          }

          const activityDaily = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/daily`);
          const activityMaster = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/master`);
          let present = new Date();
          let entryTitle = `d${present.getDate()}m${present.getMonth() + 1}y${present.getFullYear()}`;
          let activityDailyDoc = await getDoc(activityDaily);

          if (activityDailyDoc.exists()) {
            let docDat = activityDailyDoc.data();
            if (typeof docDat[entryTitle] === 'object') {
              this.dailyTracking.chosenActs = docDat[entryTitle];

              this.dailyTracking.chosenActs.map((e) => {
                if (e.complete) {
                  this.dailyTracking.completedActs += 1;
                  this.$set(
                    this.dailyTracking,
                    'progressVal',
                    parseInt((this.dailyTracking.completedActs / this.dailyTracking.chosenActs.length) * 100)
                  );
                }
              });
            }
          }

          const updatedActs = onSnapshot(
              doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/daily`),
              (doc) => {
                let docDat = doc.data();
                if (typeof docDat[entryTitle] === 'object') {
                  this.dailyTracking.chosenActs = docDat[entryTitle];

                  this.dailyTracking.chosenActs.map((e) => {
                    if (e.complete) {
                      this.dailyTracking.completedActs += 1;
                      this.$set(
                        this.dailyTracking,
                        'progressVal',
                        parseInt((this.dailyTracking.completedActs / this.dailyTracking.chosenActs.length) * 100)
                      );
                    }
                  });
                }
              }
            );

          if (finishedID) {
            if (annualSubComplete) {
              this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = new Date(
                Date.now() + 1000 * 60 * 60 * 24 * 365
              );
              this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full-Web-Annual';
              this.updateUser();
            } else {
              this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = new Date(
                Date.now() + 1000 * 60 * 60 * 24 * 30
              );
              this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full-Web-Monthly';
              this.updateUser();
            }
          }
        } else {
          //alert("This is your first time here! Creating a user profile!");
          /*document.getElementById("onboardOverlay").click();
            
            
            startupMessage = "firstTimeUser";*/
          //this.$router.push(`/marcodemo/staging.html`;
          //document.getElementById("onboardName").innerHTML = requestBody.currentUser.basicInformation["basic-given-name"];
          this.rawUsrData['basicInformation']['basic-given-name'] =
            requestBody.currentUser.basicInformation['basic-given-name'];
          this.rawUsrData['basicInformation']['basic-last-name'] =
            requestBody.currentUser.basicInformation['basic-last-name'];
          this.rawUsrData['basicInformation']['basicUserEmail'] = user.email;
          this.rawUsrData.userPersonality.userGeneral.firstDate = '' + new Date();
          this.rawUsrData.basicInformation.userID = uid;

          this.updateUser();
          this.loadUserSessionLogs();

          if (finishedID) {
            if (annualSubComplete) {
              this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = new Date(
                Date.now() + 1000 * 60 * 60 * 24 * 365
              );
              this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full-Web-Annual';
              this.updateUser();
            } else {
              this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = new Date(
                Date.now() + 1000 * 60 * 60 * 24 * 30
              );
              this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full-Web-Monthly';
              this.updateUser();
            }
          }

          //if(!isSafari && !isiOSSafari)
          //document.getElementById("callMARCoIcon").click();
        }

        if (photoUrl.length > 2) {
          this.usrProf = photoUrl;
        }

        // screening
        const ref = doc(this.$db, 'users', this.rawUsrData.basicInformation.userID, 'sessionRecords', 'screenings');
        const screeningDoc = await getDoc(ref);
        const screening = screeningDoc.data();

        let frequencyInMilliseconds = {
          Daily: 24 * 60 * 60 * 1000, // 1 day
          'Every other day': 2 * 24 * 60 * 60 * 1000, // 2 days
          'Twice Weekly': 3.5 * 24 * 60 * 60 * 1000, // 3.5 days
          Weekly: 7 * 24 * 60 * 60 * 1000, // 1 week
          'Bi-Weekly': 14 * 24 * 60 * 60 * 1000, // 2 weeks
          Monthly: 30 * 24 * 60 * 60 * 1000, // 1 month (approximate)
          Quarterly: 91 * 24 * 60 * 60 * 1000, // 3 months (approximate)
          Annually: 365 * 24 * 60 * 60 * 1000, // 1 year
          Once: Infinity, // To handle "Once" frequency
        };
        let firstScreening = '';
        if (screening?.screenings) {
          for (let i = 0; i < screening.screenings.length; i++) {
            const element = screening.screenings[i];
            const lastIssueDate = new Date(element.lastIssueDate);
            console.log('lastIssueDate', lastIssueDate);
            const currentDate = new Date();
            console.log('currentDate', currentDate);
            const timeDifference = currentDate - lastIssueDate;
            console.log('timeDifference', timeDifference);
            console.log('Frequency is: ', element.frequency);
            console.log('Frequency in milliseconds is: ', frequencyInMilliseconds[element.frequency]);
            console.log('Is it active? ', element.active);
            if (timeDifference > frequencyInMilliseconds[element.frequency] && element.active) {
              firstScreening = element.screeningID;
              console.log('firstScreening', firstScreening);
            }
          }
        }
        if (firstScreening) {
          console.log('first screening is ' + firstScreening);
          const screeningData = await new Promise((resolve, reject) => {
            axios
              .get(`${this.$host}/v1/get/screenings/questions/${firstScreening}`)
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => reject(error));
          });
          console.log('-------------------------------------screening data');
          console.log(screeningData);
          this.screeningData = screeningData;
        } else {
          console.log('No screening available');
        }
        var initializeRequest = JSON.parse(JSON.stringify(requestBody));

        EventBus.$emit('loadingOverlay', false);
        EventBus.$emit('checkConnectionStatus');

        setTimeout(() => {
          this.serverRequest(initializeRequest);
          this.splashScreen = false;

          this.loadDefaultUsrSettings();

          /* if(startupMessage == "firstTimeUser"){
        this.showFab = false;
    }*/
          if (startupMessage == 'firstTimeRobot') {
            this.syncingDialog = true;
          }

          /*onValue(
            ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/isSyncingSoftware`),
            (snapshot) => {
              let isSyn = snapshot.val();
            }
          );*/
        }, 2500);
      } else {
        if (userCheckerStep == 6) {
          clearInterval(userChecker);
          if (!this.$route.name.includes('login')) {
            EventBus.$emit('loadingOverlay', true);
            this.$router.push(`/login?isreact=${launchImmediately}`);
          }
        } else {
          userCheckerStep += 1;
          user = this.$auth.currentUser;
        }

        //this.showFirstTimeDialog = true;

        //this.showFirstTimeDialog = true;
      }
    }, 1500);

    try {
      // Fix up for prefixing
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();
      this.source = audioContext.createBufferSource();
    } catch (e) {
      //console.log('Web Audio API is not supported in this browser');
    }

    var SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    try {
      if (!isiOSSafari) {
        this.recognition = new SpeechRecognition();
      } else {
        try {
          this.iosSpeech = Capacitor.Plugins.SpeechRecognition;
        } catch (err2) {
          console.log('There is also no native speech recognition');
        }
      }
    } catch (err) {
      //console.log("There is no speech recognition support for this browser");
      try {
        this.iosSpeech = Capacitor.Plugins.SpeechRecognition;
      } catch (err2) {
        console.log('There is also no native speech recognition');
      }
    }

    //First, load the activity database
    let actRef = doc(this.$db, '/public/activities');
    let actDoc = await getDoc(actRef);
    if (actDoc.exists()) {
      let docdata = actDoc.data();
      this.fullActivities = docdata.activities;
    }

    const langRef = doc(this.$db, '/public/langs');
    const langDoc = await getDoc(langRef);
    if (langDoc.exists()) {
      let docdata = langDoc.data();
      this.langs = docdata.text;
    }

    const voiceRef = doc(this.$db, '/public/standardVoices');
    const voiceDoc = await getDoc(voiceRef);
    if (voiceDoc.exists()) {
      let docdata = voiceDoc.data();
      this.voiceProfs = docdata.voices;
    }

    onAuthStateChanged(this.$auth, async (user) => {
      if (user) {
        // User is signed in.

        //console.log(`The user is ${JSON.stringify(user)}`);
        var user = this.$auth.currentUser;

        if (user != null) {
          user.providerData.forEach(async (profile) => {
            //console.log(`The profile is ${JSON.stringify(profile)}`);
            try {
              let nextId = profile.providerId.toLowerCase();
              nextId = nextId.replace('.com', '');
              this.syncAccountHandler.syncedAccounts.push(nextId);
              //TODO: Populate a list for automatic rendering of linked accounts
              //	$(`#${nextId}-auth-list`).removeClass("inactive");
              //	$(`#${nextId}LinkBtn`).hide();
            } catch (err) {
              console.log(err);
            }
          });
          //name = user.displayName;

          let email = user.email;
          let photoUrl = user.photoURL ? user.photoURL : '';
          let emailVerified = user.emailVerified;
          let uid = user.uid; // The user's ID, unique to the Firebase project. Do NOT use
          // this value to authenticate with your backend server, if
          // you have one. Use User.getToken() instead.

          //requestBody.currentUser.basicInformation["basic-given-name"] = name.split(' ')[0];
          //requestBody.currentUser.basicInformation["basic-last-name"] = name.split(' ')[name.split(' ').length-1];
          //document.getElementById("renameUser").value = name;

          let usrDocName = uid;
          requestBody.currentUser.basicInformation.userID = uid;

          const usrRef = doc(this.$db, `/users/${usrDocName}`);
          const usrDoc = await getDoc(usrRef);

          if (usrDoc.exists()) {
            this.rawUsrData = usrDoc.data();
            this.loadUserSessionLogs();
            this.rawUsrData.userPersonality.userGeneral.lastSessionDate = new Date();
            let firstDate = new Date(this.rawUsrData.userPersonality.userGeneral.firstDate);
            requestBody.currentUser.knowsCBT = this.rawUsrData.knowsCBT;
            //requestBody.currentUser.basicInformation["basic-emergency-phone-num"] = this.rawUsrData.basicInformation["basic-emergency-phone-num"];
            requestBody.currentUser.basicInformation = this.rawUsrData.basicInformation;
            this.birthDate = this.rawUsrData.basicInformation.birthDate
              ? this.rawUsrData.basicInformation.birthDate
              : '';
            requestBody.currentUser.userPersonality.userClassifications =
              this.rawUsrData.userPersonality.userClassifications;
            this.checkUsrType(this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType);
            //$("#currUsrAccntType").text(this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType);
            this.usrDisplayName =
              this.rawUsrData.basicInformation['basic-given-name'] +
              ' ' +
              this.rawUsrData.basicInformation['basic-last-name'];

            //Correct language codes

            this.manageMARCoins('get', 0);
            if (typeof this.rawUsrData.userPersonality.userGeneral.accountInfo.desktopThemeNum !== 'number') {
              this.rawUsrData.userPersonality.userGeneral.accountInfo.desktopThemeNum = 1;
              this.updateUser();
            }

            this.loadMessagingSubscriptions();
            if (this.rawUsrData.userPersonality.userGeneral.spokenLanguage === 'en-US') {
              this.rawUsrData.userPersonality.userGeneral.spokenLanguage = 'en';
              this.updateUser();
            }

            const activityDaily = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/daily`);
            const activityMaster = doc(this.$db, `/users/${this.rawUsrData.basicInformation.userID}/activities/master`);
            let present = new Date();
            let entryTitle = `d${present.getDate()}m${present.getMonth() + 1}y${present.getFullYear()}`;
            let activityDailyDoc = await getDoc(activityDaily);

            if (activityDailyDoc.exists()) {
              let docDat = activityDailyDoc.data();
              if (typeof docDat[entryTitle] === 'object') {
                this.dailyTracking.chosenActs = docDat[entryTitle];

                this.dailyTracking.chosenActs.map((e) => {
                  if (e.complete) {
                    this.dailyTracking.completedActs += 1;
                    this.$set(
                      this.dailyTracking,
                      'progressVal',
                      parseInt((this.dailyTracking.completedActs / this.dailyTracking.chosenActs.length) * 100)
                    );
                  }
                });
              }
            }

            if (finishedID) {
              if (annualSubComplete) {
                this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = new Date(
                  Date.now() + 1000 * 60 * 60 * 24 * 365
                );
                this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full-Web-Annual';
                this.updateUser();
              } else {
                this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = new Date(
                  Date.now() + 1000 * 60 * 60 * 24 * 30
                );
                this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full-Web-Monthly';
                this.updateUser();
              }
            }
          } else {
            //alert("This is your first time here! Creating a user profile!");
            /*document.getElementById("onboardOverlay").click();
					
					
					startupMessage = "firstTimeUser";*/
            //this.$router.push(`/marcodemo/staging.html`;
            //document.getElementById("onboardName").innerHTML = requestBody.currentUser.basicInformation["basic-given-name"];
            this.rawUsrData['basicInformation']['basic-given-name'] =
              requestBody.currentUser.basicInformation['basic-given-name'];
            this.rawUsrData['basicInformation']['basic-last-name'] =
              requestBody.currentUser.basicInformation['basic-last-name'];
            this.rawUsrData['basicInformation']['basicUserEmail'] = user.email;
            this.rawUsrData.userPersonality.userGeneral.firstDate = '' + new Date();
            this.rawUsrData.basicInformation.userID = uid;

            this.updateUser();
            this.loadUserSessionLogs();

            if (finishedID) {
              if (annualSubComplete) {
                this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = new Date(
                  Date.now() + 1000 * 60 * 60 * 24 * 365
                );
                this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full-Web-Annual';
                this.updateUser();
              } else {
                this.rawUsrData.userPersonality.userGeneral.accountInfo.renewalDate = new Date(
                  Date.now() + 1000 * 60 * 60 * 24 * 30
                );
                this.rawUsrData.userPersonality.userGeneral.accountInfo.accountType = 'Full-Web-Monthly';
                this.updateUser();
              }
            }

            //if(!isSafari && !isiOSSafari)
            //document.getElementById("callMARCoIcon").click();
          }

          if (photoUrl.length > 2) {
            this.usrProf = photoUrl;
          }

          var initializeRequest = JSON.parse(JSON.stringify(requestBody));

          EventBus.$emit('loadingOverlay', false);

          setTimeout(() => {
            this.serverRequest(initializeRequest);
            this.splashScreen = false;

            this.loadDefaultUsrSettings();

            /* if(startupMessage == "firstTimeUser"){
                this.showFab = false;
            }*/
            if (startupMessage == 'firstTimeRobot') {
              this.syncingDialog = true;
            }

            onValue(
              ref(this.$rt, `MARCo/deviceSyncs/${this.rawUsrData.basicInformation.userID}/isSyncingSoftware`),
              (snapshot) => {
                let isSyn = snapshot.val();
              }
            );
          }, 2500);
        } else {
          EventBus.$emit('loadingOverlay', true);
          try {
            clearInterval(userChecker);
          } catch (e) {
            console.log(e);
          }
          this.$router.push(`/login?isreact=${launchImmediately}`);
          this.showFirstTimeDialog = true;
        }
      } else {
        // No user is signed in.
        //this.$router.push(`/login?isreact=${launchImmediately}`
        console.log('No user signed in');
        EventBus.$emit('loadingOverlay', true);
        try {
          clearInterval(userChecker);
        } catch (e) {
          console.log(e);
        }
        this.$router.push(`/login?isreact=${launchImmediately}`);

        //this.showFirstTimeDialog = true;
      }
    });

    EventBus.$emit('initializedStatus', false);
  },
};
</script>

<style>
.fab-container {
  z-index: 1000;
}
.openingSheet {
  min-height: 200px;
  max-height: 65vh;
  border-radius: 35px 35px 0px 0px;
  padding-top: 30px;
  padding-left: 15px;
  /* background: linear-gradient(-12deg, #2eb813, #3ce7b1, #23a6d5, #23d5ab);*/
  background-size: 400% 400%;
  background: white;
}

.app-nav > .v-navigation-drawer__content {
  border-image: linear-gradient(to top, #00c81e, #0a84ff);
  border-image-slice: 2;
  border-width: 14px;
  border-right: 14px solid #00c81e;
}

.chatbar.v-text-field > .v-input__control > .v-input__slot {
  border-radius: 2rem;
  box-shadow: none;
  border: 1px solid white;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.leftCol {
  position: relative;
}
.leftCol .v-dialog__content {
  position: absolute;
  top: 0;
  left: 0;
}
.onboard-pulse {
  background-color: #1de9b6;
  animation: onboard-pulse-anim 2s infinite;
  -moz-box-shadow: 0 0 0 0 rgb(0, 200, 50);
  box-shadow: 0 0 0 0 rgba(44, 204, 143, 1);
  border: 2px solid white;
}
.pulse-css {
  width: 15vh;
  height: 15vh;
  border-radius: 15vh;
  background: #98e3ad;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
  padding: 2%;
  animation: onboard-pulse-anim 3s linear infinite;
}

@-webkit-keyframes onboard-pulse-anim {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(0, 197, 20);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(44, 204, 143, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 204, 143, 0);
  }
}
@keyframes onboard-pulse-anim {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 204, 143, 0.4);
    box-shadow: 0 0 0 0 rgba(44, 204, 143, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(44, 204, 143, 0);
    box-shadow: 0 0 0 10px rgba(44, 204, 143, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 204, 143, 0);
    box-shadow: 0 0 0 0 rgba(44, 204, 143, 0);
  }
}

.soothing-gradient-bg {
  background: linear-gradient(-45deg, #2eb813, #3ce7b1, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.popup-dialog {
  z-index: 9;
}

.customcarousel .v-carousel__controls__item.v-btn {
  color: rgba(0, 111, 255, 0);
  border: 1px solid rgba(0, 111, 255, 0.8);
  transform: scale(0.6);
}

.customcarousel .v-carousel__controls__item.v-btn.v-btn--active {
  color: rgba(0, 111, 255, 0.7);
  border: 0px solid rgba(0, 111, 255, 0.8);
  transform: scale(1);
}

.theme--dark.v-expansion-panels .v-expansion-panel.outlined-rounded-expansion {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid white;
  box-shadow: none;
  border-radius: 50px;
  max-height: 100vh;
  max-width: 95vw;
}

.v-bottom-sheet.v-dialog.openingSheet {
  max-height: 90vh;
  border-radius: 35px 35px 0px 0px;
  padding-top: 15px;
  padding-left: 10px;
  background: linear-gradient(-12deg, #2eb813, #3ce7b1, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  color: white;
  /*animation: gradientBG 15s ease infinite;*/
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-15 11:0:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.slide-in-bottom:nth-child(1) {
  animation-delay: 0.2s;
}
.slide-in-bottom:nth-child(2) {
  animation-delay: 0.4s;
}
.slide-in-bottom:nth-child(3) {
  animation-delay: 0.6s;
}
.slide-in-bottom:nth-child(4) {
  animation-delay: 0.8s;
}
.slide-in-bottom:nth-child(5) {
  animation-delay: 1s;
}

.icon-bg .v-overlay__scrim {
  background-size: 50% 50%;

  background: linear-gradient(-45deg, #28d30588, #027ed188, #23d5ab88),
    url('../../public/assets/images/bkg-patterns/look-at-marco-bkg.jpg'), rgb(0, 149, 255);
  animation: iconbganim 40s linear infinite;
}

.icon-bg {
  animation: iconbganim 40s linear infinite;
}
@keyframes iconbganim {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% -200%;
  }
}
</style>
