import Vue from 'vue'; //Import the Vue library
import { EventBus } from './components/eventBus.js'; //Import the event bus to allow for communication between components
import App from './App.vue'; //Import the main app page
import Home from './components/Home.vue'; //Import the home page
//import Launch from "./components/launch.vue"; //Import the launch page
import Login from './components/Login.vue'; //Import the login page
import speech from './components/voiceRecTest.vue';
import VueRouter from 'vue-router'; //Import the Vue Router library to manage the navigation between pages
import vuetify from './plugins/vuetify'; //Imports the vuetify library for the front end styling
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import { Preferences } from '@capacitor/preferences'; //Import the preferences plugin to manage local storage
//import AudioInputTest from './components/audioInputTest.vue'
import FirstTime from './components/firstTime.vue';

import { initializeApp } from 'firebase/app'; //Import the Firebase library

import { getDatabase, ref, set, onValue } from 'firebase/database'; //Import the Firebase Realtime Database library
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; //Import the Firebase Firestore library
import { getAuth } from 'firebase/auth'; //Import the Firebase Authentication library

var firebaseConfig = {
  apiKey: 'AIzaSyDSkUUF_ZSBkrH_Y8bS3tnixlyPyETYdpk',
  authDomain: 'newagent-e3a67.firebaseapp.com',
  databaseURL: 'https://newagent-e3a67.firebaseio.com',
  projectId: 'newagent-e3a67',
  storageBucket: 'newagent-e3a67.appspot.com',
  messagingSenderId: '713994289875',
  appId: '1:713994289875:web:f6e024bf677a719d360a26',
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const rt = getDatabase(firebaseApp); //Realtime database
const auth = getAuth(firebaseApp);

import { SpeechRecognition } from '@capacitor-community/speech-recognition';
import { BleClient, numbersToDataView, ScanMode } from '@capacitor-community/bluetooth-le';
import { communicationHandler } from './js/communicationHandler.js';
import NotificationScheduler from './js/notificationScheduler.js';

const commlink = new communicationHandler(Preferences, db, rt);
commlink.initializeCommlink();
function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}
function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  console.log(urlparameter);
  return urlparameter;
}

var isDev = getUrlParam('dev', false);
Vue.config.productionTip = false;
export const $host = !isDev
  ? 'https://app.marcohealthtech.com'
  : `${window.location.protocol}//${window.location.hostname}`;
Vue.prototype.$host = $host;
Vue.prototype.$db = db;
Vue.prototype.$rt = rt;
Vue.prototype.$auth = auth;
Vue.prototype.$speech = SpeechRecognition;
Vue.prototype.$ble = BleClient;
Vue.prototype.$notification = new NotificationScheduler();
var streamingSpeechToken = {
  token: null,
  timestamp: new Date('1995-12-17T03:24:00'),
};
var socket = null;
var recorder = null;

const audioStream = new AudioContext();
const dest = audioStream.createMediaStreamDestination();

const getStreamingSpeech = async function () {
  const response = await fetch(`${this.$host}/speech/v2/assemblytoken`); // get temp session token from server.js (backend)
  const data = await response.json();

  if (data.error) {
    console.warn(data.error);
  } else {
    const { token } = data;
    streamingSpeechToken.token = token;
    //streamingSpeechToken.timestamp = new Date();
  }

  console.log('The token is: ' + JSON.stringify(streamingSpeechToken.token));
  // establish wss with AssemblyAI (AAI) at 16000 sample rate
  socket = await new WebSocket(
    `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${streamingSpeechToken.token}`
  );

  // handle incoming messages to display transcription to the DOM
  socket.onmessage = (message) => {
    const res = JSON.parse(message.data);
    console.log(res);
    let nextText = res.text;
    let nextStatus = res.message_type;

    EventBus.$emit('streaming-transcript', {
      text: nextText,
      status: nextStatus,
    });
    //const keys = Object.keys(texts);
    //console.log(texts);
    /*keys.sort((a, b) => a - b);
      for (const key of keys) {
        if (texts[key]) {
          this.msg = ` ${texts[key]}`;
        }
      } */
  };

  socket.onerror = (event) => {
    console.error(event);
    socket.close();
  };

  socket.onclose = (event) => {
    console.log(event);
    socket = null;
  };

  socket.onopen = () => {
    if (!audioinput.isCapturing()) {
      // Start with default values and let the plugin handle conversion from raw data to web audio
      audioinput.start({
        // The Sample Rate in Hz.
        // For convenience, use the audioinput.SAMPLERATE constants to set this parameter.
        sampleRate: audioinput.SAMPLERATE.VOIP_16000Hz,

        // Maximum size in bytes of the capture buffer. Should be a power of two and <= 16384.
        bufferSize: 4096,

        // The number of channels to use: Mono (1) or Stereo (2).
        // For convenience, use the audioinput.CHANNELS constants to set this parameter.
        channels: audioinput.CHANNELS.MONO,

        // The audio format. Currently PCM_16BIT and PCM_8BIT are supported.
        // For convenience, use the audioinput.FORMAT constant to access the possible
        // formats that the plugin supports.
        format: audioinput.FORMAT.PCM_16BIT,

        // Specifies if the audio data should be normalized or not.
        normalize: true,

        // Specifies the factor to use if normalization is performed.
        normalizationFactor: 32767.0,
        streamToWebAudio: true,
        audioContext: audioStream,
        // Defines how many chunks will be merged each time, a low value means lower latency
        // but requires more CPU resources.
        concatenateMaxChunks: 10,

        // Specifies the type of the type of source audio your app requires.
        // For convenience, use the audioinput.AUDIOSOURCE_TYPE constants to set this parameter:
        // -DEFAULT
        // -CAMCORDER - Microphone audio source with same orientation as camera if available.
        // -UNPROCESSED - Unprocessed sound if available.
        // -VOICE_COMMUNICATION - Tuned for voice communications such as VoIP.
        // -MIC - Microphone audio source. (Android only)
        // -VOICE_RECOGNITION - Tuned for voice recognition if available (Android only)
        audioSourceType: audioinput.AUDIOSOURCE_TYPE.MIC,
      });

      // Connect the audioinput to the speaker(s) in order to hear the captured sound
      audioinput.connect(dest);

      recorder = new RecordRTC(dest.stream, {
        type: 'audio',
        mimeType: 'audio/webm;codecs=pcm', // endpoint requires 16bit PCM audio
        recorderType: StereoAudioRecorder,
        timeSlice: 250, // set 250 ms intervals of data that sends to AAI
        desiredSampRate: 16000,
        numberOfAudioChannels: 1, // real-time requires only one channel
        bufferSize: 4096,
        audioBitsPerSecond: 128000,
        ondataavailable: (blob) => {
          console.log(blob);
          const reader = new FileReader();
          reader.onload = () => {
            const base64data = reader.result;

            // audio data must be sent as a base64 encoded string
            if (socket) {
              socket.send(JSON.stringify({ audio_data: base64data.split('base64,')[1] }));
            }
          };
          reader.readAsDataURL(blob);
        },
      });

      recorder.startRecording();

      console.log('Capturing audio!');

      //disableStartButton();
    } else {
      alert('Already capturing!');
    }
  };
};

const closeStreamingSpeech = async function () {
  if (socket) {
    socket.send(JSON.stringify({ terminate_session: true }));
    socket.close();
    socket = null;
    streamingSpeechToken.token = null;
  }

  if (recorder) {
    recorder.pauseRecording();
    recorder = null;
  }

  if (window.audioinput && audioinput.isCapturing()) {
    audioinput.stop();
    // disableStopButton();
  }
};

Vue.prototype.$getStreamingSpeech = getStreamingSpeech;
Vue.prototype.$closeStreamingSpeech = closeStreamingSpeech;

//Set up an encoder and decoder for BLE and define all of the messages that are in MARCo's vocabulary
const bleEncoder = new TextEncoder();
const MARCO_ERROR_CODE = numbersToDataView(bleEncoder.encode('E'));
const MARCO_LISTEN_CODE = numbersToDataView(bleEncoder.encode('L'));
const MARCO_DONE_LISTENING_CODE = numbersToDataView(bleEncoder.encode('D'));
const MARCO_START_TALKING_CODE = numbersToDataView(bleEncoder.encode('T'));
const MARCO_STOP_TALKING_CODE = numbersToDataView(bleEncoder.encode('t'));
const MARCO_PWR_OFF = numbersToDataView(bleEncoder.encode('F'));
const MARCO_PWR_ON = numbersToDataView(bleEncoder.encode('N'));
const MARCO_SMILE_CODE = numbersToDataView(bleEncoder.encode('S'));
const MARCO_FROWN_CODE = numbersToDataView(bleEncoder.encode('f'));
const MARCO_NEUTRAL_CODE = numbersToDataView(bleEncoder.encode('n'));
const MARCO_MOUTH_OPEN_CODE = numbersToDataView(bleEncoder.encode('o'));
const MARCO_START_SERVOS_CODE = numbersToDataView(bleEncoder.encode('M'));
const MARCO_KILL_SERVOS_CODE = numbersToDataView(bleEncoder.encode('k'));

const globalAudio = new Audio();

const MARCO_RECEIVED_EVENTS = {
  B: {
    name: 'MARCO_BUTTON_PRESSED',
    action: async function () {
      console.log('Button pressed');
      EventBus.$emit('button-pressed');
    },
  },
  b: {
    name: 'MARCO_BUTTON_RELEASED',
    action: async function () {
      console.log('Button released');
      EventBus.$emit('button-released');
    },
  },
};

var device = null;
var readWrite = null;
var writeStream = null;
var readStream = null;
var networkStream = null;
var deviceIDStream = null;
var objectStream = null;
var commandStream = null;

let attempts = 0;
let scanAttempts = 0;

const saveDevice = async () => {
  await Preferences.set({
    key: 'device',
    value: JSON.stringify(device),
  });
};

const loadDevice = async () => {
  const deviceString = await Preferences.get({ key: 'device' });
  if (deviceString.value) {
    device = JSON.parse(deviceString.value);
  } else {
    console.log('Device has not been previously connected or could not be found.');
  }
};

const completeDeviceSetup = async () => {
  await Preferences.set({
    key: 'hasBeenUsed',
    value: 'true',
  });
};

const factoryReset = async () => {
  await Preferences.set({
    key: 'hasBeenUsed',
    value: 'false',
  });
};

EventBus.$on('factory-reset', async () => {
  await factoryReset();
});

EventBus.$on('setup-finished', async () => {
  await completeDeviceSetup();
});

//loadDevice();

const launchBLE = async function (maxScanAttempts = 5) {
  await BleClient.initialize();
  console.log('BLE initialized');
  EventBus.$emit('ble-initialized');
  EventBus.$emit('ble-message', 'Bluetooth initialized...');
  scanAttempts = 0;

  if (!device) {
    try {
      await BleClient.stopLEScan();
    } catch (err) {
      console.log('Error stopping scan');
      console.log(err);
    }

    launchScan(maxScanAttempts, async (success) => {
      if (success) {
        console.log('Scan successfully found a device');
        //Now, connect to the device
        // eslint-disable-next-line
        attempts = 0;
        return await connectToDevice();
      } else {
        console.log('Scan failed to find a device...');
        EventBus.$emit('ble-scan-failed');
        EventBus.$emit('ble-message', 'Could not find a MARCo, trying rescanning...');
      }
    });
  } else {
    try {
      await BleClient.disconnect(this.device.deviceId);
    } catch (err) {
      console.log('Error disconnecting');
      console.log(err);
    }

    //Now, connect to the device
    attempts = 0;
    return await connectToDevice();
  }
};

const launchScan = async function (maxScanAttempts, callback = false) {
  EventBus.$emit('ble-scan-running', scanAttempts);
  let endScanTimeout = setTimeout(async () => {
    await BleClient.stopLEScan();
    console.log('stopped scanning');
    if (!device) {
      //A device was not yet found. If it is less than the maximum scans, try again.
      if (scanAttempts < maxScanAttempts) {
        scanAttempts++;
        console.log('Scan attempt ' + scanAttempts);
        launchScan(maxScanAttempts, callback);
        EventBus.$emit('ble-message', `Scanning for MARCo... Attempt ${scanAttempts} of ${maxScanAttempts}`);
      } else {
        console.log('Scan failed to find a device. Try again.');
        scanAttempts = 0;
        return callback(false);
      }
    }
  }, 20000);

  setTimeout(async () => {
    try {
      await BleClient.requestLEScan(
        {
          //services: [HEART_RATE_SERVICE],
          scanMode: ScanMode.SCAN_MODE_LOW_LATENCY,
        },
        async (result) => {
          console.log('received new scan result', JSON.stringify(result));
          if (result.device.name) {
            if (
              result.device.name.toLowerCase().includes('adafruit bluefruit le') ||
              result.device.name.toLowerCase().includes('marco')
            ) {
              EventBus.$emit('ble-message', `Found a MARCo! Trying to connect`);
              EventBus.$emit('ble-marco-found');
              console.log('Found the device');
              device = result.device;
              console.log(device);

              saveDevice();

              BleClient.stopLEScan();
              try {
                await BleClient.disconnect(device.deviceId);
              } catch (err) {
                console.log('Error disconnecting');
                console.log(err);
              }

              clearTimeout(endScanTimeout);
              scanAttempts = 0;
              return callback(true);
            }
          }
        }
      );
    } catch (err) {
      console.log('Error scanning');
      console.log(err);
    }
  }, 300);
};

const connectToDevice = async () => {
  EventBus.$emit('ble-connect-attempt', attempts);

  try {
    await BleClient.disconnect(device.deviceId);
  } catch (err) {
    console.log('Error disconnecting');
    console.log(err);
  }

  try {
    await BleClient.connect(device.deviceId);

    console.log('Connected to device');
    console.log(device);
    const discovery = await BleClient.discoverServices(device.deviceId);
    console.log(discovery);
    // return device;
    const services = await BleClient.getServices(device.deviceId);
    console.log('Got services', services);
    console.log(services);

    //Now, get the read and write service and characteristics

    //There are two types of devices - a MARCo and a MARCo Lite. The MARCo has a built in SBC that just needs BLE to set up wifi and device connection. The MARCo Lite needs BLE for hardware control. The MARCo Lite has a different UUID for the read/write service.

    if (device.name.toLowerCase().includes('adafruit bluefruit le')) {
      readWrite = services[4];
      readStream = readWrite.characteristics[0];
      writeStream = readWrite.characteristics[1];

      //await BleClient.write(this.device.deviceId, this.readWrite.uuid, this.writeStream.uuid, this.$MARCO_ERROR_CODE);
      // await BleClient.write(this.device.deviceId, this.readWrite.uuid, this.writeStream.uuid, this.$MARCO_LISTEN_CODE);
      //Now, read the characteristics
      /*BleClient.read(device.deviceId, "6e400001-b5a3-f393-e0a9-e50e24dcca9e", "6e400003-b5a3-f393-e0a9-e50e24dcca9e").then((data) => {
            console.log("Read data");
            console.log(data);
        }).catch((err) => {
            console.log("Error reading data");
            console.log(err);
        });*/
      console.log('Connected to the device and got the services and characteristics');
      EventBus.$emit('ble-connected');
      EventBus.$emit('ble-services-received');
      //Start listening for incoming messages
      await BleClient.startNotifications(device.deviceId, readWrite.uuid, readStream.uuid, async (value) => {
        // console.log('current button');
        let nextCommand = await parseSerialString(value);
        console.log('Next command', nextCommand);

        try {
          MARCO_RECEIVED_EVENTS[nextCommand.trim()].action();
        } catch (err) {
          console.log('Error parsing command');
          console.log(err);
        }
      });
    } else {
      console.log('The device name is ' + device.name);
      readWrite = services[2];
      networkStream = readWrite.characteristics[2];
      deviceIDStream = readWrite.characteristics[3];
      objectStream = readWrite.characteristics[4];
      commandStream = readWrite.characteristics[5];

      EventBus.$emit('ble-connected');
    }
  } catch (error) {
    console.log('Error connecting to device');
    EventBus.$emit('ble-message', `Error connecting to MARCo...`);
    console.log(error);
    if (attempts < 50) {
      attempts++;
      connectToDevice();
      EventBus.$emit('ble-message', `Connected to a MARCo, trying to communicate, attempt ${attempts} of 50`);
    } else {
      return error;
    }
  }
};

async function parseSerialString(value) {
  console.log('Trying to parse serial string');
  console.log(value);
  const decoder = new TextDecoder();
  const decoded = decoder.decode(value);
  console.log(decoded);
  return decoded;
}

Vue.prototype.$launchBLE = launchBLE;

Vue.prototype.$marcoError = async function (callback = null) {
  //Check if the device exists
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, writeStream.uuid, MARCO_ERROR_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoListen = async function (callback = null) {
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, writeStream.uuid, MARCO_LISTEN_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoStartTalking = async function (callback = null) {
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, writeStream.uuid, MARCO_START_TALKING_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoStopTalking = async function (emotion = 'happy', callback = null) {
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, writeStream.uuid, MARCO_STOP_TALKING_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoSmile = async function (callback = null) {
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, writeStream.uuid, MARCO_SMILE_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoFrown = async function (callback = null) {
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, writeStream.uuid, MARCO_FROWN_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoNeutral = async function (callback = null) {
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, writeStream.uuid, MARCO_NEUTRAL_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoOpen = async function (callback = null) {
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, writeStream.uuid, MARCO_MOUTH_OPEN_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

const sendDockID = async function (dockID) {
  globalAudio.src = '/assets/audio/sfx/marco-sender.mp3';
  globalAudio.play();
  if (device !== null) {
    try {
      await BleClient.write(
        device.deviceId,
        readWrite.uuid,
        deviceIDStream.uuid,
        numbersToDataView(bleEncoder.encode(dockID))
      );
      EventBus.$emit('dock-id-sent', dockID);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
      EventBus.$emit('dock-id-send-failed', dockID);
    }
  }
};

Vue.prototype.$sendDockID = sendDockID;

Vue.prototype.$getDefaultDockID = async function () {
  //eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (device !== null) {
      try {
        const defaultDockBuffer = await BleClient.read(device.deviceId, readWrite.uuid, deviceIDStream.uuid);
        let defaultDock = '';
        for (var i = 0; i < defaultDockBuffer.buffer.byteLength; i++) {
          defaultDock += String.fromCharCode(defaultDockBuffer.getUint8(i));
        }
        globalAudio.src = '/assets/audio/sfx/marco-receiver.mp3';
        globalAudio.play();
        resolve(defaultDock);
      } catch (e) {
        console.log('There was an error writing to the device:', e);
        reject(e);
      }
    }
  });
};

Vue.prototype.$getDeviceNetworkStatus = async function () {
  //eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (device !== null) {
      try {
        const networkStatusBuffer = await BleClient.read(device.deviceId, readWrite.uuid, networkStream.uuid);
        let networkStatusObjString = '';
        for (var i = 0; i < networkStatusBuffer.buffer.byteLength; i++) {
          networkStatusObjString += String.fromCharCode(networkStatusBuffer.getUint8(i));
        }
        let networkStatusObj = JSON.parse(networkStatusObjString);
        console.log('networkStatusObj', networkStatusObj);
        let networkStatus = typeof networkStatusObj === 'object' ? networkStatusObj.status : networkStatusObj;
        networkStatus = networkStatus.toLowerCase() === 'true';
        globalAudio.src = '/assets/audio/sfx/marco-receiver.mp3';
        globalAudio.play();
        resolve({
          status: networkStatus,
          attempting: networkStatus.connectionAttempts,
        });
      } catch (e) {
        console.log('There was an error reading the network status from the device:', e);
        reject(e);
      }
    }
  });
};

Vue.prototype.$sendDeviceNetwork = async function (ssid, password = '') {
  globalAudio.src = '/assets/audio/sfx/marco-sender.mp3';
  globalAudio.play();

  let msg = `WIFI:WPA;${password.length > 0 ? 'P:' + password + ';' : ''}S:${ssid};U:${commlink.dockID}`;
  var encodedUnicodeString = numbersToDataView(bleEncoder.encode(msg));
  console.log(encodedUnicodeString);
  if (device !== null) {
    try {
      await BleClient.write(device.deviceId, readWrite.uuid, networkStream.uuid, encodedUnicodeString, {
        timeout: 10000,
      });
      EventBus.$emit('network-sent');
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

/**
 * @function sendObject
 * @description Sends an object/file to the MARCo device
 * @param {String} object - the stringified version of the data to send
 * @param {String} path - the end file path of data being sent
 */

Vue.prototype.$sendObject = async function (object, path) {
  globalAudio.src = '/assets/audio/sfx/marco-sender.mp3';
  globalAudio.play();

  const val = { object: object, path: path };
  if (device !== null) {
    try {
      await BleClient.write(
        device.deviceId,
        readWrite.uuid,
        objectStream.uuid,
        numbersToDataView(bleEncoder.encode(val)),
        { timeout: 10000 }
      );
      EventBus.$emit('object-sent', path);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

/**
 * @function readObject
 * @description Reads an object/file from the MARCo device
 * @param {String} path - the end file path of data to be recovered
 * @returns {String} - the stringified version of the data recovered
 */

Vue.prototype.$readObject = async function (path) {
  //eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (device !== null) {
      console.log('Yes, we are reading it!');
      try {
        //First, write to the device the path of the file to be read
        const val = { read: path };
        console.log('Just about to write to device', val, 'with path', path);
        var encodedUnicodeString = numbersToDataView(bleEncoder.encode(JSON.stringify(val)));
        await BleClient.write(device.deviceId, readWrite.uuid, objectStream.uuid, encodedUnicodeString, {
          timeout: 10000,
        });
        console.log('Wrote to device');

        //Then, read the file from the device
        const objectBuffer = await BleClient.read(device.deviceId, readWrite.uuid, objectStream.uuid);
        let objectString = '';
        for (var i = 0; i < objectBuffer.buffer.byteLength; i++) {
          objectString += String.fromCharCode(objectBuffer.getUint8(i));
        }

        //The objectString is a stringified version of the object, so parse to JSON
        objectString = JSON.parse(objectString);

        var result = objectString.contents;

        console.log('objectString', objectString);

        //The objectString contains four parameters, one is the currentChunk and the other is the maxChunk. If the currentChunk is less than the maxChunk, then we need to continue reading and appending to the contents
        while (parseInt(objectString.chunksLeft) > 0) {
          const objectBuffer = await BleClient.read(device.deviceId, readWrite.uuid, objectStream.uuid);
          objectString = '';
          for (var i = 0; i < objectBuffer.buffer.byteLength; i++) {
            objectString += String.fromCharCode(objectBuffer.getUint8(i));
          }
          objectString = JSON.parse(objectString);
          result += objectString.contents;

          console.log('There are ' + objectString.chunksLeft + ' chunks left');
        }

        globalAudio.src = '/assets/audio/sfx/marco-receiver.mp3';
        globalAudio.play();

        resolve(result);
      } catch (e) {
        console.log('There was an error writing to the device:', e);
        reject(e);
      }
    }
  });
};

EventBus.$on('checkDockID', async (param) => {
  let isEqual = commlink.compareDockIDs(param);

  console.log('Dock ID is equal? ' + isEqual);
  if (!isEqual) {
    sendDockID(commlink.dockID);
  } else {
    EventBus.$emit('dock-id-valid');
    EventBus.$emit('connectionStatus', true);
  }
});

//Create the router for switching to different pages
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      redirect: async (to) => {
        console.log('Trying to redirect to the appropriate page...');
        /*function getCookie(c_name) {
          var c_value = document.cookie;
          var c_start = c_value.indexOf(" " + c_name + "=");
          if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
          }
          if (c_start == -1) {
            c_value = null;
          } else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
              c_end = c_value.length;
            }
            c_value = c_value.substring(c_start, c_end);
          }
          return c_value;
        }*/

        const checkSession = async () => {
          const c = await Preferences.get({ key: 'hasBeenUsed' });
          console.log('Visited: ', c.value);
          if (c.value === 'true') {
            console.log('Redirecting to home page...');
            return '/home';
          } else {
            console.log('Redirecting to welcome page...');
            return '/firstTime';
          }

          //setCookie("visited", "yes", 365); // expire in 1 year; or use null to never expire
        };
        let result = await checkSession();
        console.log('Result of checkSession: ', result);
        console.log(typeof result);
        console.log(router);
        return router.push(result);
      },
    },
    { path: '/home', component: Home },
    { path: '/login', component: Login },
    { path: '/speech', component: speech },
    { path: '/firstTime', component: FirstTime },
  ],
});

//Initialize the application
new Vue({
  vuetify,
  router,
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  themes: {
    light: {
      primary: '#4caf50',
      secondary: '#03a9f4',
      accent: '#009688',
      error: '#f44336',
      warning: '#ff9800',
      info: '#2196f3',
      success: '#8bc34a',
    },
    dark: {
      primary: '#006117',
      secondary: '#0173BA',
      accent: '#01baa4',
      error: '#e73120',
    },
  },

  render: (h) => h(App),
}).$mount('#app');

//Now, create an interval to check for the device updates once every hour
setInterval(async () => {
  EventBus.$emit('check-for-update');
}, 10800000);
