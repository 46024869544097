import { LocalNotifications } from '@capacitor/local-notifications';
import { NOTIFICATION_INDEX } from './constant';


class NotificationScheduler {

  granted = false
  constructor() {
    // check the permission and request for it
    LocalNotifications.checkPermissions().then(permissions => {
      if (permissions.display !== "granted") {
        LocalNotifications.requestPermissions().then(permissions => {
          this.log(permissions)
          if (permissions.display === "granted") {
            this.granted = true
          } else {
            this.granted = false
          }
        })
      } else {
        this.granted = true
      }
      this.log(permissions)
    })
  }
  log(...rest) {
    console.log("NOTIFICATION: ", ...rest)
  }
  /**
   * Schedule a notification.
   *
   * @param {Object} options - The options for scheduling the notification.
   * @param {string} options.id - The id of the notification.
   * @param {string} options.title - The title of the notification.
   * @param {string} options.body - The body of the notification.
   * @param {Date} options.date - The date when the notification should be scheduled.
   * @return {Promise} A promise that resolves with the scheduled notifications.
   */
  async schedule({ id, title, body, date }) {
    const n = await LocalNotifications.schedule({
      notifications: [
        {
          title: title,
          body: body,
          id: id,
          schedule: {
            at: date,
            allowWhileIdle: true
          },
          sound: null,
          attachments: null,
          actionTypeId: "",
          extra: null
        }
      ]
    });
    this.log('scheduled notifications', n);
  }
  /**
   * Cancels a scheduled notification.
   *
   * @param {number} id - The ID of the notification to cancel.
   * @return {Promise} A promise that resolves when the notification is canceled.
   */
  async cancel(id) {
    const n = await LocalNotifications.cancel({ notifications: [{ id }] });
    this.log('cancel scheduled notifications', n);
  }

  //TODO: @Soumen - Create additional functions per use case of notification scheduling


  async scheduleNotificationForActivity() {
    const d = new Date()
    const hr = d.getHours()
    if (hr < 18) {
      if (hr < 12) {
        this.schedule({
          id: await this.convertIDStringToNumber(NOTIFICATION_INDEX['1_PM_NOTIFICATION']),
          title: "Good Afternoon!",
          body: "Halfway through the day! You deserve a break - check in with MARCo to keep on your mental health goals",
          date: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 13, 0, 0, 0)
        })
      }
      this.schedule({
        id: await this.convertIDStringToNumber(NOTIFICATION_INDEX['6_PM_NOTIFICATION']),
        title: "Good Evening!",
        body: "There's still time to finish your mental health goals for today. Take an end of day break to just relax and recharge with MARCo",
        date: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 18, 0, 0, 0)
      })
    }
    else if (hr >= 18) {
      this.schedule({
        id: await this.convertIDStringToNumber(NOTIFICATION_INDEX['1_HR_NOTIFICATION']),
        title: "Good Evening!",
        body: "There's still time to finish your mental health goals for today. Take an end of day break to just relax and recharge with MARCo",
        date: new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours() + 1, d.getMinutes(), 0, 0)
      })
    }
  }

 async removeNotificationForActivity() {
    const d = new Date()
    this.cancel(await this.convertIDStringToNumber(NOTIFICATION_INDEX['1_PM_NOTIFICATION']))
    this.cancel(await this.convertIDStringToNumber(NOTIFICATION_INDEX['6_PM_NOTIFICATION']))
    this.cancel(await this.convertIDStringToNumber(NOTIFICATION_INDEX['1_HR_NOTIFICATION']))
    //  call function to remove notifications for daily activities, and then replace with one congratulatory notification saying something like ""
    this.schedule({
      id: await this.convertIDStringToNumber(NOTIFICATION_INDEX['COMPLIMENT_HR_NOTIFICATION']),
      title: "Good Evening!",
      body: "We're so proud of you, you finished your goals for today!",
      date: new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours() + 1, d.getMinutes(), 0, 0)
    })
  }


  async scheduleNotificationAfterCreateItp(data) {
    let i = 0;
    data.forEach(async item => {
      let nextID = 9000 + i;
      this.schedule({
        id: nextID,
        title: "Good Morning!",
        body: item.message,
        date: new Date(item.date)
      })
      i++;
    })
  }

  /**
   * @function convertIDStringToNumber
   * @description Converts a string ID to a number ID by switching each character to its ASCII value
   * @param {string} id - The ID of the notification as a string
   * @return {Promise} The ID of the notification as a number
   */
  convertIDStringToNumber(id) {

    return new Promise((resolve, reject) => {
      let newID = ""
      for (let i = 0; i < id.length; i++) {
        newID += id.charCodeAt(i)
      }
      resolve(parseInt(newID))
    });
  }


}

export default NotificationScheduler