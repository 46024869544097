<template>
  <div class="combobox">
    <label class="text-h6 gray--text text--lighten-1" style="font-weight: 500">Don’t show me:</label>
    <v-combobox
      v-model="model"
      :filter="filter"
      :hide-no-data="!search"
      :items="items"
      :search-input.sync="search"
      hide-selected
      label="Search for an option"
      multiple
      chips
      solo
      @change="changeSelections"
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading ml-2">Create</span>
          <v-chip :color="`${colors[nonce - 1]} lighten-1`">
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip v-if="item === Object(item)" v-bind="attrs" :color="`${item.color} lighten-1`" :input-value="selected">
          <span class="pr-2">
            {{ item.text }}
          </span>
          <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-chip :color="`${item.color} lighten-1`" dark>
          {{ item.text }}
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { EventBus } from '../../eventBus';

export default {
  name: 'RestrictedContent',

  props: ['userId'],
  data: () => ({
    isLoading: true,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'], // storing all colors
    items: [
      { header: 'Select an option or create one' },
      {
        text: 'Animals: Cats',
        color: 'blue',
      },
      {
        text: 'Cartoon Violence',
        color: 'red',
      },
      {
        text: 'Death',
        color: 'teal',
      },
      {
        text: 'Suggestive Themes',
        color: 'orange',
      },
    ],
    nonce: 1,
    model: [], // storing selected value
    search: null, // search result
  }),

  async mounted() {
    // retrieving all previously selected value and store into model array
    this.isLoading = true;
    const settingsDocRef = doc(this.$db, 'users', this.userId, 'content', 'settings');
    const settingsDoc = await getDoc(settingsDocRef);
    const settings = settingsDoc.data();
    const userContentFilters = settings.userContentFilters;
    this.model = userContentFilters.map((item) => ({
      text: item,
      color: this.colors[this._random(0, this.colors.length - 1)],
    }));
    this.isLoading = false;
  },

  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;
      this.model = val.map((v) => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };
          this.items.push(v);
          this.nonce++;
        }
        return v;
      });
    },
  },

  methods: {
    async changeSelections(data) {
      // added new entry update whole array
      const settingsDocRef = doc(this.$db, 'users', this.userId, 'content', 'settings');
      await updateDoc(settingsDocRef, {
        userContentFilters: data.map((item) => item.text),
        lastUpdate: serverTimestamp(),
      });
      EventBus.$emit('show-toast', 'Item Updated');
    },
    // filtering element
    filter(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : '');
      const text = hasValue(itemText);
      const query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },
    // generating random number for colors
    _random(minimum, maximum) {
      return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    },
  },
};
</script>

<style></style>
