<template>
  <div>
    <v-btn class="g-button mb-4" block elevation="0" rounded color="blue white--text" @click="toggleDialog">
      <v-icon left color="white" size="20"> mdi-plus </v-icon>
      ADD FAVORITE CONTENT
    </v-btn>
    <div class="filter-wrapper mb-2">
      <div class="filter">
        <v-icon left size="20"> mdi-filter-variant </v-icon>
        <v-chip
          class="filter-chip"
          v-for="item of filter"
          v-bind:key="item.value"
          :color="item.enabled ? 'grey darken-1' : ''"
          :class="item.enabled ? 'white--text' : ''"
          @click="handleFilter(item.value)"
        >
          {{ item.title }}
        </v-chip>
      </div>
    </div>
    <div class="my-content-items-wrapper">
      <div class="content-items" v-for="item of getData" v-bind:key="item.id">
        <div>
          <img :src="images[item.type]" style="width: 50px" alt="image" />
          <div>
            <p class="text-caption" style="font-weight: 600; margin-bottom: 0px">{{ item.title }}</p>
            <p class="text-caption" style="margin-bottom: 2px">{{ item.desc }}</p>
            <div class="chip-wrapper">
              <v-chip
                small
                class="green lighten-4 green--text"
                style="border: 1px solid currentColor !important"
                v-if="item.from === 0"
              >
                <img src="/assets/content-moderation/icons/icon_7.png" width="14px" class="mr-1" alt="" />
                MARCo
              </v-chip>
              <v-chip
                small
                color="blue lighten-4 blue--text"
                style="border: 1px solid currentColor !important"
                v-else-if="item.from === 1"
              >
                <v-icon class="mr-1" size="14"> mdi-account </v-icon>
                User
              </v-chip>
              <v-chip small color="blue lighten-4 blue--text" style="border: 1px solid currentColor !important" v-else>
                <v-icon class="mr-1" size="14"> mdi-security </v-icon>
                Admin
              </v-chip>
              <v-chip
                small
                color="red lighten-4 red--text"
                style="border: 1px solid currentColor !important"
                v-if="item.pref === 0"
              >
                <v-icon class="mr-1" size="14"> mdi-cancel </v-icon>
                Blocked
              </v-chip>
              <v-chip
                small
                color="blue lighten-4 blue--text"
                style="border: 1px solid currentColor !important"
                v-else-if="item.pref === 1"
              >
                <v-icon class="mr-1" size="14"> mdi-information </v-icon>
                No Preferences
              </v-chip>
              <v-chip
                small
                color="orange lighten-4 orange--text"
                style="border: 1px solid currentColor !important"
                v-else
              >
                <v-icon class="mr-1" size="14"> mdi-star-circle </v-icon>
                Favorite
              </v-chip>
            </div>
          </div>
        </div>
        <div style="min-width: 72px">
          <v-btn elevation="0" color="gray" icon @click="handleEdit(item)">
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn elevation="0" color="gray" icon @click="handleDelete(item)">
            <v-icon> mdi-trash-can </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="my-2">
        <div class="pagination-wrapper my-2">
          <p class="ma-0 text-caption grey--text text--darken-1">
            {{ this.pagination.page * this.pagination.limit - this.pagination.limit + 1 }}-{{
              this.pagination.page * this.pagination.limit > getData.length
                ? list.length
                : this.pagination.page * this.pagination.limit
            }}
            of
            {{ list.length }}
          </p>
          <div class="pagination-controls-wrapper">
            <v-btn elevation="0" small color="gray" icon @click="handlePrev" :disabled="pagination.page === 1">
              <v-icon size="30"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn
              elevation="0"
              small
              color="gray"
              icon
              @click="handleNext"
              :disabled="pagination.page + 1 > pagination.totalPage"
            >
              <v-icon size="30"> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" width="400" class="dialog-wrapper" persistent>
      <div class="dialog white custom-dialog-wrapper">
        <div class="modal-content-head mb-4">
          <h4 class="text-center white--text text-h4 py-6">New Favorites</h4>
          <img src="/assets/content-moderation/images/image_9.png" class="top-img" />
        </div>
        <v-form v-model="form.valid">
          <v-stepper v-model="e1">
            <v-stepper-items>
              <v-stepper-content step="1">
                <img class="mx-auto d-block mb-4" src="/assets/content-moderation/icons/icon_1.png" alt="icon" />
                <h3 class="text-h5 text-center grey--text text--darken-3 mb-4" style="font-weight: 500">
                  Link Content:
                </h3>
                <v-text-field
                  class="mb-2"
                  color="green"
                  label="Web Link"
                  placeholder="Enter your link"
                  hint="Type a valid URL"
                  outlined
                  persistent-hint
                  v-model="form.src"
                  @input="onWebLinkChange"
                >
                </v-text-field>
                <div class="v-or mb-5">
                  <v-divider></v-divider>
                  <span
                    class="text-center grey--text text--darken-2 white px-2 text-subtitle-2"
                    style="font-weight: 500"
                  >
                    OR
                  </span>
                </div>
                <div class="upload-button">
                  <input type="file" class="file-input" ref="file" @change="onFileChange" />
                  <v-icon size="30" class="plus-button"> mdi-plus </v-icon>
                  <p>Upload your own files</p>
                </div>
                <v-alert
                  class="mt-4"
                  text
                  outlined
                  color="red"
                  icon="mdi-fire"
                  style="border-radius: 1rem; border-width: 2px !important"
                  v-if="file.isError"
                >
                  ERROR: {{ file.error }}
                </v-alert>
                <div
                  class="content-items px-2 py-2 mt-4"
                  style="border: 2px solid gray; border-radius: 1rem"
                  v-else-if="file.file"
                >
                  <div>
                    <img src="/assets/content-moderation/icons/icon_4.png" width="40px" alt="image" />
                    <div>
                      <p class="text-caption" style="font-weight: 600; margin-bottom: 0px">
                        {{ file.fileName }}
                      </p>
                      <p class="text-caption grey--text text--lighten-1" style="margin-bottom: 0px">
                        {{ file.fileSize }}
                      </p>
                    </div>
                  </div>
                  <v-btn elevation="0" color="gray" icon @click="deleteFile" :disabled="file.isUploading">
                    <v-icon> mdi-trash-can </v-icon>
                  </v-btn>
                </div>
                <div class="d-flex justify-space-between align-center mt-6">
                  <v-btn
                    text
                    class="green--text"
                    elevation="0"
                    rounded
                    @click="toggleDialog"
                    :disabled="file.isUploading"
                  >
                    BACK
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    class="white--text"
                    elevation="0"
                    rounded
                    :loading="file.isUploading"
                    @click="handleUploadFile"
                    :disabled="file.file || form.src ? false : true"
                  >
                    NEXT
                  </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="2">
                <img
                  width="84px"
                  height="84px"
                  class="mx-auto d-block mb-4"
                  src="/assets/content-moderation/images/image_1.png"
                  alt="icon"
                />
                <h3 class="text-h5 text-center grey--text text--darken-3 mb-4" style="font-weight: 500">
                  Media Information
                </h3>
                <v-text-field
                  class="mb-1"
                  color="green"
                  label="Title"
                  placeholder="Enter title"
                  hint="What should this media be called?"
                  outlined
                  persistent-hint
                  v-model="form.title"
                  :rules="form.titleRule"
                  :counter="20"
                  required
                  :disabled="form.isEditing && form.from === 0"
                >
                </v-text-field>
                <v-text-field
                  class="mb-1"
                  color="green"
                  label="Description"
                  placeholder="Enter your description"
                  hint="A few word summary"
                  outlined
                  persistent-hint
                  v-model="form.desc"
                  :rules="form.descRule"
                  :counter="40"
                  required
                >
                </v-text-field>
                <v-select
                  class="mb-1"
                  color="green"
                  :items="form.defaultType"
                  item-text="label"
                  item-value="value"
                  outlined
                  persistent-hint
                  label="Type"
                  v-model="form.type"
                  :rules="form.typeRule"
                  required
                  :disabled="form.isEditing && form.from === 0"
                ></v-select>
                <div class="d-flex justify-space-between align-center">
                  <v-btn text class="green--text" elevation="0" rounded @click="toggleDialog" v-if="form.isEditing">
                    CLOSE
                  </v-btn>
                  <v-btn text class="green--text" elevation="0" rounded @click="e1 = 1" v-else> BACK </v-btn>
                  <v-btn
                    color="green darken-1"
                    class="white--text"
                    elevation="0"
                    rounded
                    @click="e1 = 3"
                    :disabled="!form.valid"
                  >
                    NEXT
                  </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="3">
                <img
                  width="84px"
                  height="84px"
                  class="mx-auto d-block mb-4"
                  src="/assets/content-moderation/icons/icon_2.png"
                  alt="icon"
                />
                <h3 class="text-h5 text-center grey--text text--darken-3 mb-6" style="font-weight: 500">Usage</h3>
                <p class="text-caption-1 text-center grey--text text--darken-3 mb-4" style="font-weight: 500">
                  Pick a few tags that represent when this is best used.
                </p>
                <v-chip-group
                  v-model="form.tags"
                  column
                  multiple
                  class="chip-group"
                  active-class="green darken--1 white--text"
                >
                  <v-chip class="ma-1" filter v-for="item of form.defaultTags" v-bind:key="item" :value="item">
                    {{ item }}
                  </v-chip>
                </v-chip-group>
                <div class="d-flex justify-space-between align-center">
                  <v-btn text class="green--text" elevation="0" rounded @click="e1 = 2"> BACK </v-btn>
                  <v-btn
                    color="green darken-1"
                    class="white--text"
                    elevation="0"
                    rounded
                    :loading="isSaving"
                    @click="handleSave(form.editingId)"
                  >
                    NEXT
                  </v-btn>
                </div>
              </v-stepper-content>
              <v-stepper-content step="4">
                <img class="mx-auto d-block mb-4" src="/assets/content-moderation/icons/icon_3.png" alt="icon" />
                <h3 class="text-h5 text-center grey--text text--darken-3 mb-4" style="font-weight: 500">All done!</h3>
                <p class="text-caption-1 text-center grey--text text--darken-3 mb-4" style="font-weight: 500">
                  Your content has been successfully added!
                </p>
                <v-btn block color="green darken-1" class="white--text" elevation="0" rounded @click="toggleDialog">
                  Done!
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { EventBus } from '../../eventBus';

export default {
  name: 'MyContent',

  props: ['userId'],
  data() {
    return {
      form: {
        from: -1,
        isEditing: false,
        editingId: '',
        valid: false,
        title: '',
        titleRule: [
          (v) => !!v || 'Title is required',
          (v) => v.length <= 20 || 'Title must be less than 20 characters',
        ],
        desc: '',
        descRule: [
          (v) => !!v || 'Description is required',
          (v) => v.length <= 40 || 'Description must be less than 20 characters',
        ],
        type: '',
        typeRule: [(v) => !!v || 'Type is required'],
        defaultType: [
          { value: 'video', label: 'Video' },
          { value: 'audio', label: 'Audio' },
          { value: 'link', label: 'Link' },
          { value: 'activity', label: 'Activity' },
          { value: 'article', label: 'Article' },
          { value: 'game', label: 'Game' },
        ],
        src: '',
        tags: [],
        defaultTags: ['Bored', 'Calm', 'Tired', 'Lonely', 'Relaxing', 'Busy', 'Stressed', 'Sad', 'Angry'],
      },
      file: {
        uploadURI: ' /content/add/:userID',
        allowType: ['image/png', 'image/jpeg', 'video/mp4', 'audio/mpeg'],
        allowSize: 1 * 1024 * 1024,
        isError: false,
        error: '',
        isUploading: false,
        fileSize: 0,
        file: false,
        fileName: null,
      },
      filter: [
        { value: 0, title: 'All Content', enabled: true },
        { value: 1, title: 'Videos', enabled: false },
        { value: 2, title: 'Audio', enabled: false },
        { value: 3, title: 'Blocked', enabled: false },
        { value: 4, title: 'MARCo', enabled: false },
        { value: 5, title: 'User', enabled: false },
      ],
      images: {
        activity: '/assets/content-moderation/icons/icon_3.png',
        video: '/assets/content-moderation/icons/icon_2.png',
        audio: '/assets/content-moderation/icons/icon_1.png',
        article: '/assets/content-moderation/icons/icon_6.png',
        game: '/assets/content-moderation/icons/icon_5.png',
      },
      pagination: {
        limit: 5,
        page: 1,
        totalPage: 0,
      },
      rawList: [],
      list: [],
      dialog: false,
      e1: 1,
      isLoading: true,
      isSaving: false,
      isDeleting: false,
    };
  },
  async mounted() {
    this.list = this.rawList;
    // calculate the total page number
    this.pagination.totalPage = Math.ceil(this.list.length / this.pagination.limit);

    this.isLoading = true;
    const collectionRef = collection(this.$db, 'users', this.userId, 'content', 'preferredContent', 'content');

    const contentDoc = await getDocs(collectionRef);
    let newList = [];
    contentDoc.forEach((doc) => {
      newList.push({ id: doc.id, ...doc.data() });
    });
    this.rawList = newList;
    this.list = this.rawList;
    // calculate the total page number
    this.pagination.totalPage = Math.ceil(this.list.length / this.pagination.limit);
    this.isLoading = false;
  },
  methods: {
    toggleDialog: function () {
      this.dialog = !this.dialog;
      this.e1 = 1;
      this.clearFormAndFile();
    },

    handlePrev: function () {
      if (this.pagination.page === 1) return;
      this.pagination.page -= 1;
    },

    handleNext: function () {
      if (this.pagination.page + 1 > this.pagination.totalPage) return;
      this.pagination.page += 1;
    },

    paginate(data) {
      let page = this.pagination.page;
      let perPage = this.pagination.limit;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.splice(from, to);
    },

    handleFilter(value) {
      // TODO: Improve the filter
      if (value === 0) {
        this.filter = this.filter.map((item) =>
          item.value === value ? { ...item, enabled: true } : { ...item, enabled: false }
        );
        this.list = [...this.rawList];
      } else {
        // this.filter = this.filter.map((item) =>
        //     item.value === 0
        //         ? { ...item, enabled: false }
        //         : item.value === value
        //         ? { ...item, enabled: !item.enabled }
        //         : { ...item }
        // );
        this.filter = this.filter.map((item) =>
          item.value === 0
            ? { ...item, enabled: false }
            : item.value === value
            ? { ...item, enabled: !item.enabled }
            : { ...item, enabled: false }
        );
        this.list = this.rawList.filter((item) => {
          let match = false;
          let video = this.filter[1].enabled;
          let audio = this.filter[2].enabled;
          let blocked = this.filter[3].enabled;
          let marco = this.filter[4].enabled;
          let user = this.filter[5].enabled;
          if (video) {
            match = item.type === 'video';
          } else if (audio) {
            match = item.type === 'audio';
          } else if (blocked) {
            match = item.pref === 2;
          } else if (marco) {
            match = item.from === 0;
          } else if (user) {
            match = item.from === 1;
          }
          return match;
        });
      }
      this.pagination.page = 1;
      this.pagination.totalPage = Math.ceil(this.list.length / this.pagination.limit);
    },

    onWebLinkChange() {
      this.deleteFile();
    },

    onFileChange(e) {
      this.form.src = null;
      this.file.isError = false;
      this.file.error = null;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      // check for file type
      if (!this.file.allowType.includes(files[0].type)) {
        this.file.isError = true;
        this.file.error = 'Unsupported File';
        return;
      }
      // check for file size
      if (files[0].size > this.file.allowSize) {
        this.file.isError = true;
        this.file.error = 'File size exceeded max file size ' + this.file.allowSize / (1024 * 2) + 'MB';
        return;
      }
      this.file.file = files[0];
      this.file.fileName = files[0].name;
      const kb = (files[0].size / 1024).toFixed(1);
      const mb = (files[0].size / 1024 / 1024).toFixed(1);
      this.file.fileSize = kb < 1024 ? kb + 'KB' : mb + 'MB';
    },

    async handleUploadFile() {
      console.log('The file is:');
      console.log(this.file);
      if (this.form.src && !this.file.file) {
        this.e1 = 2;
        return;
      }
      //POST request to upload file
      const formData = new FormData();
      formData.append('file', this.file.file);
      console.log('boundary', formData._boundary);

      const boundary = '----WebKitFormBoundary' + new Date().getTime();
      const headers = {
        'Content-Type': 'multipart/form-data; boundary=' + boundary,
        Accept: 'application/json',
      };

      fetch(`${this.$host}/content/${this.userId}/upload`, {
        method: 'POST',
        headers: headers,
        body: formData,
      });

      this.file.isUploading = true;
      setTimeout(() => {
        EventBus.$emit('show-toast', 'File Upload Successfully');
        this.form.src = 'file-link';
        this.file.isUploading = false;
        this.e1 = 2;
      }, 1500);
    },

    deleteFile() {
      this.$refs.file.value = null;
      this.file.isError = false;
      this.file.error = null;
      this.file.file = null;
      this.file.fileName = null;
      this.file.fileSize = null;
    },

    async handleSave(editing) {
      this.isSaving = true;
      const contentRef = collection(this.$db, 'users', this.userId, 'content', 'preferredContent', 'content');
      if (editing) {
        const newData = {
          title: this.form.title,
          desc: this.form.desc,
          type: this.form.type,
          src: this.form.src,
          tags: this.form.tags,
        };
        const contentDocRef = doc(
          this.$db,
          'users',
          this.userId,
          'content',
          'preferredContent',
          'content',
          this.form.editingId
        );
        await updateDoc(contentDocRef, { ...newData, lastUpdate: serverTimestamp() });
        EventBus.$emit('show-toast', 'Item Saved Successfully');
        this.rawList = this.rawList.map((item) => (item.id === this.form.editingId ? { ...item, ...newData } : item));
      } else {
        const newData = {
          title: this.form.title,
          desc: this.form.desc,
          type: this.form.type,
          from: 0,
          pref: 2,
          src: this.form.src,
          tags: this.form.tags,
        };
        let newItem = await addDoc(contentRef, { ...newData, lastUpdate: serverTimestamp() });
        this.rawList = [...this.rawList, { id: newItem.id, ...newData }];
        this.pagination.totalPage = Math.ceil(this.list.length / this.pagination.limit);
      }

      this.list = [...this.rawList];
      this.e1 = 4;
      this.isSaving = false;
    },

    handleEdit(item) {
      this.e1 = 2;
      this.form.isEditing = true;
      this.form.editingId = item.id;
      this.form.valid = true;
      this.form.title = item.title;
      this.form.desc = item.desc;
      this.form.type = item.type;
      this.form.src = item.src;
      this.form.tags = item.tags;
      this.form.from = item.from;
      this.dialog = !this.dialog;
    },

    async handleDelete(data) {
      this.isDeleting = true;
      const contentDocRef = doc(this.$db, 'users', this.userId, 'content', 'preferredContent', 'content', data.id);
      await deleteDoc(contentDocRef);
      this.rawList = this.rawList.filter((item) => item.id !== data.id);
      this.list = [...this.rawList];
      this.pagination.totalPage = Math.ceil(this.list.length / this.pagination.limit);
      this.isDeleting = false;
    },

    clearFormAndFile() {
      this.form.isEditing = false;
      this.form.editingId = '';
      this.form.valid = false;
      this.form.title = '';
      this.form.desc = '';
      this.form.type = '';
      this.form.src = '';
      this.form.tags = [];
      this.form.from = -1;

      this.file.file = null;
      this.file.isError = false;
      this.file.error = '';
      this.file.isUploading = false;
      this.file.fileSize = 0;
      this.file.fileName = null;
    },
  },
  computed: {
    getData: function () {
      return this.paginate([...this.list]);
    },
  },
};
</script>

<style></style>
